<template>
	<div class="mod-specific">
		<div class="train-modal__header">
			<span>훈련시킬 모델-편집</span>

			<span id="mod-closebtn" class="train-modal__close-btn" @click="close_btn">
				<i class="far fa-times-circle"></i>
				<!--<b-icon icon="x" id="mod-closebtn" v-b-tooltip.hover.bottom="node_module_delete"></b-icon> -->
			</span>
		</div>
		<div class="mod-rete">
			<TrainModelEditorDock :layer-groups="layerGroups"/>
			<div id="rete-editor">
				<div>
					<button @click ='testClick'> test</button>
				</div>
				<div class="mod-operation-btn-group">
					<span id="mod-arrangebtn" class="arrange-btn" v-b-tooltip.hover="node_module_cleanup"><i class="fas fa-bars"></i></span>
					<span id="mod-undobtn" class="undo-btn" v-b-tooltip.hover.bottom="rete_undo"><i class="fas fa-undo"></i></span>
					<span id="mod-redobtn" class="redo-btn" v-b-tooltip.hover.bottom="rete_redo"><i class="fas fa-undo"></i></span>
					<span id="mod-jsonbtn" class="json-btn" v-b-tooltip.hover.bottom="json_console_check"><i class="fas fa-chevron-left"></i><i class="fas fa-chevron-right"></i></span>
					<span id="mod-trashbtn" class="trash-btn" v-b-tooltip.hover.bottom="rete_download"><i class="fas fa-trash-alt"></i></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TrainModelEditorDock from './TrainModelEditorDock/TrainModelEditorDock.vue';
	import { createFlowEditor } from './editor';

	import Rete from 'rete';
	import { numSocket } from '../../sockets.js'

	import store from '@/store';

	import korean from '@/language/korean.json';
	import english from '@/language/english.json';

	import InputAndOutput from '../tf-json-data/Layers/tfjs_layers_InputAndOutput.json';
	import Basic from '../tf-json-data/Layers/tfjs_layers_Basic.json';
	import Convolutional from '../tf-json-data/Layers/tfjs_layers_Convolutional.json';
	import Pooling from '../tf-json-data/Layers/tfjs_layers_Pooling.json';
	import Merge from '../tf-json-data/Layers/tfjs_layers_Merge.json';
	import Normalization from '../tf-json-data/Layers/tfjs_layers_Normalization.json';
	import Padding from '../tf-json-data/Layers/tfjs_layers_Padding.json';

	import * as ncai from '@/ncai-core/ncai.js'
	
	export default {
		name: 'modal',
		data() {
			return {
				// 다국어 처리 설정
				// 모델 상세보기 내의 rete editor 상단 버튼 부분
				node_module_cleanup: '',
				rete_undo: '',
				rete_redo: '',
				json_console_check: '',
				rete_download: '',
				node_module_delete: '',

				// 언어 설정 변경
				localization: '',
				//editor 설정
				editor: '',
				layerGroups: {
					InputAndOutput,
					Basic,
					Convolutional,
					Pooling,
					Merge,
					Normalization,
					Padding
				}
			}
		},
		props: ['node', 'emitter'],
		components: {
			TrainModelEditorDock
		},
		async mounted() {
			console.log('train_model_edit');

			this.editor = await createFlowEditor(this.node.data.editor_data, this.layerGroups);

			/* global navigator */
			let locale = navigator.language || navigator.userLanguage;

			this.localization = locale.substring(0, 2);

			// 언어 설정 테스트
			// this.localization = 'en';

			switch (this.localization) {
				case 'ko':
					this.node_module_cleanup = korean.model_details_modal.node_module_cleanup;
					this.rete_undo = korean.model_details_modal.rete_undo;
					this.rete_redo = korean.model_details_modal.rete_redo;
					this.node_module_delete = korean.model_details_modal.node_module_delete;
					return;

				case 'en':
					this.node_module_cleanup = english.model_details_modal.node_module_cleanup;
					this.rete_undo = english.model_details_modal.rete_undo;
					this.rete_redo = english.model_details_modal.rete_redo;
					this.node_module_delete = english.model_details_modal.node_module_delete;
					return;
			}

		},
		methods: {
			close_btn() {

				const inputs = [];

				this.editor.nodes.forEach(n => {
					if (n.name == 'InputLayer') {
						inputs.push(n);
					}
				});

				/* 전체 노드 제거 */
				this.node.inputs.forEach(i => {
					i.connections.forEach(c => {
						this.emitter.removeConnection(c);
					})
				});

				this.node.inputs.clear();

				inputs.forEach(i => {
					const input_name = i.data.name;
					const input_shape = i.data.shape ? '입력' + i.data.shape : '입력';

					this.node.addInput(new Rete.Input(input_name, input_shape, numSocket, true));
				});

				this.node.update();

				this.node.data.input_data = this.input_data(this.node);

				const editordata = this.editor.toJSON();

				const topology = this.toTopology(editordata);

				console.log(JSON.stringify(editordata));
				
				console.log(JSON.stringify(topology));
			
				editordata.topology = topology;

				this.node.data.editor_data = editordata;

				this.$emit('close_train_model_edit');
			},
			input_data(node) {
				return Array.from(node.inputs.values()).map(i => {
					return {
						key: i.key,
						name: i.name
					};
				});
			},
			toTopology(editordata) {
				let topology = {
					class_name: "Model",
					config: {
						name: "model1",
						layers: [],
						input_layers: [],
						output_layers: []
					},
					keras_version: "tfjs-layers 2.7.0",
					backend: "tensor_flow.js"
				}

				for (let i in editordata.nodes) {

					let node = editordata.nodes[i];
					let data = {}
					
					for(let i in node.data){
						if(i != 'group'){
							data[i] = node.data[i];
						}
					}

					if (node.name != "Output") {
						let layer_info = {
							name: node.data.name,
							group : node.data.group,
							class_name: node.name,
							config: data,
							inbound_nodes: []
						}

						if (node.name == "InputLayer") {
							topology.config.input_layers.push([layer_info.name, 0, 0]);
						}
						else {
							let inputs = [];
							for (let input in node.inputs) {
								node.inputs[input].connections.map((conn) => {
									inputs.push([conn.output.replace("_output", ""), 0, 0, {}]);
								})
							}
							layer_info.inbound_nodes.push(inputs);
						}

						topology.config.layers.push(layer_info);
					}
					else {
						for (let input in node.inputs) {
							node.inputs[input].connections.map((conn) => {
								topology.config.output_layers.push([conn.output.replace("_output", ""), 0, 0]);
							})
						}
					}
				}
				sessionStorage.setItem("model_topology", JSON.stringify(topology));
				return topology;
			},
			testClick() {
				//ncai.test();

				const editordata = this.editor.toJSON();

				const topology = this.toTopology(editordata);

				
				ncai.setModel(topology);
			}
		},

		computed: {
			inputs() {

			}
		}
	}
</script>

<style scoped>
	/* 모델 상세보기 전체 부분 크기 설정 */

	.mod-specific {
		width: 100% !important;
		min-height: 100%;
		background-color: #f4f7fc;
		display: flex;
		flex-direction: column;
		border: 1px solid;
		border-image-slice: 1;
		border-width: 1px;
		border-image-source: linear-gradient(to right, rgba(81, 45, 168, 1), rgba(81, 45, 168, 0.7));
		overflow: hidden;
	}

	.train-modal__header {
		min-height: 50px;
		background: rgba(81, 45, 168, 1);
		/* fallback for old browsers */
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		padding: 0 30px;
	}

	.train-modal__header .train-modal__close-btn {
		display: inline-block !important;
		width: 30px;
		height: 30px;
		font-size: 20px;
		cursor: pointer;
	}

	/* ================================= */

	/* rete 사이즈 조절 */

	.mod-rete {
		width: 100% !important;
		height: 100% !important;
		display: flex;
		padding: 30px;
	}

	/* ================ */

	/* rete 상단 버튼 그룹 */

	.mode-rete .mod-operation-btn-group {
		width: 100%;
		display: flex;
	}

	/* ================== */

	#rete-editor {
		width: 100% !important;
		max-height: 750px !important;
		background-color: #ffffff;
		background-image: radial-gradient(rgba(81, 45, 168, 0.07) 10%, transparent 0), radial-gradient(rgba(81, 45, 168, 0.07) 10%, transparent 0);
		background-position: 0 0, 10px 10px;
		background-size: 10px 10px;
		border: 2px solid;
		border-image-slice: 1;
		border-width: 1px;
		border-image-source: linear-gradient(to right, rgba(81, 45, 168, 1), rgba(81, 45, 168, 0.7));
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		position: relative;
		padding: 5px;
	}

	#rete-editor .mod-operation-btn-group {
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
	}

	#rete-editor .node {
		background-color: red !important;
		border: 1px solid black !important;
	}

	/*=============================================*/

	#mod-redobtn {
		transform: rotate(0deg) scaleX(-1);
	}
</style>

