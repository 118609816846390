import { Control } from 'rete';
import DataPreprocessFunctionVueControl from './DataPreprocessFunctionVueControl.vue';

export default class DataPreprocessFunctionControl extends Control {
    constructor(emitter, key, readonly = false) {
        super(key);
        this.component = DataPreprocessFunctionVueControl;
        this.props = {
            emitter,
            ikey: key,
            type: 'number',
            readonly,
            change: () => this.onChange()
        }
    };

    setValue(value) {
        const ctx = this.vueContext || this.props;
        ctx.value = value;
    }

    onChange() {}
}
