<template>
	<div id="VueWebcamControl">
		<div class="video_part">
			<video ref="video" id="video" width="140" height="100" autoplay />
			<!--@click="capture()"-->
			<b-button id="snap" v-longclick="() => capture()" @click="capture()">Snap Photo</b-button>
		</div>
		<div class="canvas_part">
		<canvas ref="canvas" id="canvas" width="320" height="240" />
			<ul>
				<li v-for="(c, idx) in captures" :key="idx" v-if="idx < 10">
					<img id="capimage" :src="c" height="5">
				</li>
			</ul>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'VueWebcamControl',
		data() {
			return {
				video: {},
				canvas: {},
				captures: []
			}
		},
		mounted() {
			this.video = this.$refs.video
			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices.getUserMedia({
						video: true
					})
					.then(stream => {
						this.video.srcObject = stream
					})
			}
		},
		methods: {
			capture() {
				this.canvas = this.$refs.canvas
				let context = this.canvas.getContext('2d').drawImage(this.video, 0, 0, 320, 240)
				this.captures.push(canvas.toDataURL('image/png'))
				console.log(this.captures)
			}
		},
	}
</script>

<style scoped>
	#VueWebcamControl {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.video_part {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.canvas_part {
		display: flex;
		justify-content: flex-start;

	}

	#video {
		border: 1px solid;
	}

	#canvas {
		display: none;
	}

	ul {
		display: grid;
		align-items: center;
		text-align: center;
		grid-template-columns: repeat(5, 1fr);
		background: #000000;
	}

	li {
		display: inline-block;
		padding: 3px;
	}

	#video {
		transform: rotateY(180deg);
		-webkit-transform: rotateY(180deg);
		/* Safari and Chrome */
		-moz-transform: rotateY(180deg);
		/* Firefox */
	}

	#canvas {
		transform: rotateY(180deg);
		-webkit-transform: rotateY(180deg);
		/* Safari and Chrome */
		-moz-transform: rotateY(180deg);
		/* Firefox */
	}

	#capimage {
		background: #ffffff;
		transform: rotateY(180deg);
		-webkit-transform: rotateY(180deg);
		/* Safari and Chrome */
		-moz-transform: rotateY(180deg);
		/* Firefox */
	}
</style>
