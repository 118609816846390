<template>
        <div class="input-unit">
            <label>{{ikey}}</label>
            <b-form-checkbox @change="onChange" v-model="value">
                </b-form-checkbox>
        </div>
</template>

<script>
    // vuex
    import store from '@/store';

    export default {
        name: "InputParameter",
        data() {
            return {
                value: this.getData(this.ikey)
            }
        },
        props: ['ikey', 'emitter', 'getData', 'putData'],
        mounted() {
            
        },
        methods: {
            onChange() {
                this.setData();
            },
            setData() {
                this.putData(this.ikey, !this.value);
                console.log(this.getData(this.ikey));
            }
        }
    }
</script>

<style scoped>
    .input-unit {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .input-unit label {
        font-size: 8px;
        color: #555555;
    }
</style>
