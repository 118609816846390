import { Control } from 'rete';
import TrainModelVueControl from './TrainModelVueControl.vue';

export default class TrainModelControl extends Control {
	constructor(emitter, key, readonly = false) {
		super(key);
		this.component = TrainModelVueControl;
		this.props = {
			emitter,
			ikey: key,
			type: 'number',
			readonly,
			getNode: () => this.getNode()
		};
	}
	
	setInputData(value) {
		const ctx = this.vueContext || this.props;
		ctx.input_data = value;
	}
	
	onChange() {}
}