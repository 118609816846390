<template>
    <!--데이터 생성 전체-->
    <div class="CreateRawDataForm_outer">
        <!--데이터 생성 헤더-->
        <div class="CreateRawDataForm_header">
            <label>새로운 데이터 생성</label>
        </div>
        <!--데이터 생성 헤더 END-->
        <!--데이터 생성 내용-->
        <div class="CreateRawDataForm_content">
            <div class="Data_Form">
                <label>데이터 이름</label>
                <input class="data_name" type="text" v-model="new_data.name" />
                <label>데이터 종류</label>
                <select class="data_type" v-model="new_data.data.type">
                        <option v-for="option in type_options" v-bind:value="option.value">
                		{{ option.text }}
                	</option>
                </select>
                <label>데이터 개수</label>
                <input class="data_size" type="number" v-model="new_data.data.dataSize" />
                <label>데이터 설명</label>
                <input class="data_describe" type="text" v-model="new_data.explain" />
            </div>
        </div>
        <!--데이터 생성 내용 END-->
        <!--데이터 생성 하단-->
        <div class="CreateRawDataForm_footer">
            <div class="footer_buttonGroup">
                <b-button variant="primary" @click="createNewData">데이터 생성</b-button>
                <b-button variant="warning" @click="exitForm">취소</b-button>
            </div>
        </div>
        <!--데이터 생성 하단 END-->
    </div>
    <!--에디터 생성 전체 END-->
</template>
<script>
    export default {
        name: 'CreateRawDataForm',
        data() {
            return {
                // 새로운 데이터 생성
                new_data: {
                    name: '',
                    explain: '',
                    data: {
                        dataSize: 0,
                        type: '',
                        INPUT_WIDTH: 0,
                        INPUT_HEIGHT: 0,
                        INPUT_CHANNELS: 0
                    }
                },

                // 타입 옵션
                type_options: [
                    { text: '이미지', value: 'image' },
                    { text: '텍스트', value: 'text' }
                ]
            }
        },
        methods: {
            createNewData() {
                // 콘솔 확인
                // console.log(this.new_data);
                
                // 모달 닫기 실행 
                this.$emit('hideForm', this.new_data);
            },
            exitForm() {
                
                // 모달 닫기 실행 
                this.$emit('hideForm');
            }
        }
    }
</script>
<style scoped>
    /*데이터 생성 폼 전체*/

    .CreateRawDataForm_outer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /*데이터 생성 헤더*/

    .CreateRawDataForm_header {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .CreateRawDataForm_header label {
        font-size: 20px;
        font-weight: bold;
    }

    /*데이터 생성 내용*/

    .CreateRawDataForm_content {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /*데이터 폼*/

    .Data_Form {
        display: flex;
        flex-direction: column;
    }

    .Data_Form label {
        font-size: 16px;
        font-weight: bold;
        opacity: 0.8;
    }

    .Data_Form input {
        width: 450px !important;
        height: 35px;
        border-radius: 5px !important;
    }

    .Data_Form select {
        width: 450px !important;
        height: 35px;
        border-radius: 5px !important;
    }

    .data_describe {
        height: 80px !important;
    }

    /*데이터 생성 하단*/

    .CreateRawDataForm_footer {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .footer_buttonGroup button {
        width: 130px;
        height: 35px;
        margin: 10px;
    }
</style>
