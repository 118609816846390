<template>
    <div class="dock">
         <!--v-b-toggle.collapse-a-->
        <div class="dock-label" v-b-toggle.modelDetailMenus>
            <b-icon icon="chevron-down" font-scale="0.8" shift-v="-3"></b-icon>
            <!--노드 구성 토글 버튼 부분-->
            <!--노드 구성 -->
            <label class="ml-2">{{ node_config_label }}</label>
        </div>
        <b-collapse visible id="modelDetailMenus" class="dock-item-list">
             <div class="dock-item">
                <div class="node 데이터입력" id="datainput">
                    <!--데이터 입력 노드 생성-->
                    <!--데이터 입력(input)-->
                    <div class="title">{{ node_input }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node Conv2d" id="conv2d">
                    <!--CONVOLUTION 2D 노드 생성-->
                    <!--Conv2d-->
                    <div class="title">{{ node_Conv2d }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node MaxPooling2d" id="maxpooling2d">
                    <!--MAXPOOLING 2D 노드 생성-->
                    <!--MaxPooling2d-->
                    <div class="title">{{node_MaxPooling2d}}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node 플랫튼(flatten)" id="flatten">
                    <!--플랫튼 노드 생성-->
                    <!--플랫튼(flatten)-->
                    <div class="title">{{ node_flatten }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node 덴스(dense)" id="dense">
                    <!--덴스 노드 생성-->
                    <!--덴스(dense)-->
                    <div class="title">{{ node_dense }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node 데이터출력" id="dataoutput">
                    <!--데이터출력 노드 생성-->
                    <!--데이터출력(output)-->
                    <div class="title">{{ node_output }}</div>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
    // 언어 설정 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: "dock-item",
        data() {
            return {

                // 다국어 설정
                node_config_label: '',
                node_input: '',
                node_Conv2d: '',
                node_MaxPooling2d: '',
                node_flatten: '',
                node_dense: '',
                node_output: '',

                // 언어 설정
                localization: ''
            }
        },
        mounted() {
            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // 언어 설정 테스트
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.node_config_label = korean.model_details_dock.node_config_label;
                    this.node_input = korean.model_details_dock.node_input;
                    this.node_Conv2d = korean.model_details_dock.node_Conv2d;
                    this.node_MaxPooling2d = korean.model_details_dock.node_MaxPooling2d;
                    this.node_flatten = korean.model_details_dock.node_flatten;
                    this.node_dense = korean.model_details_dock.node_dense;
                    this.node_output = korean.model_details_dock.node_output;
                    return;
                case 'en':
                    this.node_config_label = english.model_details_dock.node_config_label;
                    this.node_input = english.model_details_dock.node_input;
                    this.node_Conv2d = english.model_details_dock.node_Conv2d;
                    this.node_MaxPooling2d = english.model_details_dock.node_MaxPooling2d;
                    this.node_flatten = english.model_details_dock.node_flatten;
                    this.node_dense = english.model_details_dock.node_dense;
                    this.node_output = english.model_details_dock.node_output;
                    return;
            }
        },
        methods: {}
    }
</script>

<style scoped>
    .dock {
        width: 300px;
        height: 100%;
        color: #555555;
        background-color: #ffffff;
        margin-right: 15px;
        border: 2px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(to right, rgba(81, 45, 168, 0.4), rgba(81, 45, 168, 0.4));
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 2px 15px;
    }

    .dock .dock-label {
        padding-left: 20px;
    }

    .dock .dock-item{
        display: flex;
        justify-content: center;
    }

    .dock-item .node {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f4f5f7;
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;
        border-radius: 4px;
        background-color: #F0F0F7 !important;
        color: #555555 !important;
        width: 220px;
        height: 45px;
        padding-left: 20px;
        cursor: pointer;
        margin-top: 15px;
    }

    .dock .node span {
        height: 30px !important;
        width: 30px !important;
        margin-left: 5px;
    }

    .title {
        display: inline-block;
        width: 100%;
        text-align: left;
        font-size: 13px !important;
        color: #252746 !important;
        background: transparent;
    }
</style>
