<template>
        <div class="input-unit">
            <label>name</label>
            <input type="text" v-model.value="name" @input="onInput"></input>
                <div>
                <label >trainable</label>
            <b-form-checkbox @change="onChange" v-model="trainable">
                </b-form-checkbox>
            </div>
            
        </div>
</template>

<script>
    // vuex
    import store from '@/store';

    export default {
        name: "NameParameter",
        data() {
            return {
                name: this.getData("name"),
                trainable: this.getData("trainable") === false ? false : true
            }
        },
        props: ['ikey', 'emitter', 'getData', 'putData'],
        mounted() {
        },
        methods: {
            onInput() {
                this.setName();
            },
            setName() {
                this.putData("name", this.name);
            },

            onChange() {
                this.setData();
            },
            setData() {
                this.putData("trainable", !this.trainable);
            }
        },
        computed: {

        }
    }
</script>

<style scoped>
    .input-unit {
        display: block;
        flex-direction: column;
        justify-content: center;
    }

    .input-unit label {
        font-size: 8px;
        color: #555555;
    }

    .input-unit input {
        width: 180px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid #999;
    }
</style>
