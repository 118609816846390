import { render, staticRenderFns } from "./OptionCheckBox.vue?vue&type=template&id=94ebd060&scoped=true&"
import script from "./OptionCheckBox.vue?vue&type=script&lang=js&"
export * from "./OptionCheckBox.vue?vue&type=script&lang=js&"
import style0 from "./OptionCheckBox.vue?vue&type=style&index=0&id=94ebd060&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94ebd060",
  null
  
)

export default component.exports