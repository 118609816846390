const state = {
    nodeSelected: null,
    nodeSelected_name: null,

    // data input name
    data_input_name: '',
    data_input_count: 0,

    // main editor data size
    input_data: {
        IMAGE_WIDTH: 0,
        IMAGE_HEIGHT: 0,
        IMAGE_CHANNELS: 0,
        kinds: '',
    },

    iris_input_data: {
        IMAGE_WIDTH: 0,
        IMAGE_HEIGHT: 0,
        IMAGE_CHANNELS: 0
    },

    // model layer
    model_layer: 0,

    data_input_connect: '',

    // data model connect
    mnist_connect: false,
    model_save_con: false,

    // train test ratio
    // train_test_ratio: 10,
}

const mutations = {
    changeNodeSelected(state, nodeSelected) {
        state.nodeSelected = nodeSelected
    },
    changeNodeSelected_name(state, nodeSelected_name) {
        state.nodeSelected_name = nodeSelected_name
    },

    // data input name
    change_data_input_name(state, data_input_name) {
        state.data_input_name = data_input_name
    },

    // data input count
    // change_data_input_count(state, data_input_count) {
    //     state.data_input_count = data_input_count
    // },

    // main editor data input
    change_data_size(state, data_size) {
        let data_split = data_size.split('X');

        state.input_data.IMAGE_WIDTH = Number(data_split[0]);
        state.input_data.IMAGE_HEIGHT = Number(data_split[1]);
    },
    change_data_color(state, data_color) {

        switch (data_color) {

            case 'rgb':
                state.input_data.IMAGE_CHANNELS = 3;
                return;
            case 'gray':
                state.input_data.IMAGE_CHANNELS = 1;
                return;
        }
    },
    change_data_kinds(state, data_kinds) {
        switch (data_kinds) {
            case '숫자':
                state.input_data.kinds = data_kinds;
                return;
        }
    },
    change_iris_data_size(state, iris_data_size) {
        let data_split = iris_data_size.split('X');
        state.iris_input_data.IMAGE_WIDTH = Number(data_split[0]);
        state.iris_input_data.IMAGE_HEIGHT = Number(data_split[1]);
    },


    // model layer
    change_model_layer(state, model_layer) {
        state.model_layer = model_layer
    },

    change_data_input_connect(state, data_input_connect) {

        state.data_input_connect = data_input_connect
        // if(state.data_input_name === data_input_connect) {
        //     state.data_input_connect = true
        // } else {
        //     state.data_input_connect = false
        // }
    },

    // data and model connect
    change_mnist_connect(state, mnist_connect) {
        state.mnist_connect = mnist_connect;
    },

    // model_save_con
    change_model_save_con(state, model_save_con) {
        state.model_save_con = model_save_con
    },

    // // train test ratio
    // change_train_test_ratio(state, train_test_ratio) {
    //     state.train_test_ratio = train_test_ratio
    // }

}

const actions = {
    call_node_selected({ state, commit }, { nodeSelected }) {
        commit('changeNodeSelected', nodeSelected);
    },
    call_node_selected_name({ state, commit }, { nodeSelected_name }) {
        commit('changeNodeSelected_name', nodeSelected_name)
    },

    // set data input name
    call_data_input_name({ state, commit }, { data_input_name }) {
        commit('change_data_input_name', data_input_name);
    },

    // set data input count
    // call_data_input_count({ state, commit }, { data_input_count }) {
    //     commit('change_data_input_count', data_input_count);
    // },


    // main editor data input
    call_data_size({ state, commit }, { data_size }) {
        commit('change_data_size', data_size);
    },

    // 메인 에디터 아이리스 데이터 사이즈 입력
    call_iris_data_size({ state, commit }, { iris_data_size }) {
        commit('change_iris_data_size', iris_data_size)
    },
    // main editor data color
    call_data_color({ state, commit }, { data_color }) {
        commit("change_data_color", data_color);
    },
    call_data_kinds({ state, commit }, { data_kinds }) {
        commit("change_data_kinds", data_kinds);
    },

    // model layer
    call_model_layer({ state, commit }, { model_layer }) {
        commit("change_model_layer", model_layer)
    },

    call_data_input_connect({ state, commit }, { data_input_connect }) {
        commit('change_data_input_connect', data_input_connect)
    },


    // data and model connect 
    call_mnist_connect({ state, commit }, { mnist_connect }) {
        commit("change_mnist_connect", mnist_connect);
    },

    // model save con
    call_model_save_con({ state, commit }, { model_save_con }) {
        commit("change_model_save_con", model_save_con)
    },

    // train test data ratio
    // call_train_test_ratio({ state, commit }, { train_test_ratio }) {
    //     commit('change_train_test_ratio', train_test_ratio)
    // }


}

const getters = {
    getNodeSelected(state) {
        return state.nodeSelected
    },
    getNodeSelected_name(state) {
        return state.nodeSelected_name
    },

    // data input name
    get_data_input_name(state) {
        return state.data_input_name
    },

    // data input count
    // get_data_input_count(state) {
    //     return state.data_input_count
    // },

    get_data_input_connect(state) {
        return state.data_input_connect
        // if (state.data_input_name === state.data_input_connect) {
        //     console.log(state.data_input_name)
        //     let connect_state = true;
        //     return connect_state
        // }
        // else {
        //     console.log(state.data_input_name)
        //     let connect_state = false;
        //     return connect_state
        // }
    },


    // main editor data input
    get_input_data(state) {
        return state.input_data
    },
    
    get_input_iris_data(state) {
      return state.iris_input_data  
    },

    // model layer
    get_model_layer(state) {
        return state.model_layer
    },

    // data model connect
    get_mnist_connect(state) {
        return state.mnist_connect
    },

    // model save con
    get_model_save_con(state) {
        return state.model_save_con
    },

    // // train test ratio
    // get_train_test_ratio(state) {
    //     return state.train_test_ratio
    // }
}

export default {
    state,
    mutations,
    actions,
    getters
}
