import { Control } from 'rete';
import RawDataVueControl from './RawDataVueControl.vue';

export default class RawDataControl extends Control {
	constructor(emitter, key, readonly = false) {
		super(key);
		this.component = RawDataVueControl;
		this.props = {
			emitter,
			ikey: key,
			type: 'number',
			readonly,
			//노드 정보를 전달하기 위한 함수
			getNode : () => this.getNode()
		};
	}
	
	setValue(value) {
		const ctx = this.vueContext || this.props;
		ctx.value = value;
	}
	
	onChange() {}
}