<template>
    <div class="advanced">
        <div class="advanced-param" @click="collapseAdvanced">
            <label>고급</label>
            <b-icon :icon="datapreprocess_advance_icon"/>
        </div>
            <b-collapse id="datapreprocess-advance" v-model="datapreprocess_advance_visible">
                <div class="input-unit" v-for="(param, name) in type">
                    <label>{{name}}</label>
                    <input type="text" v-if="paramType(param.input_value) == 'input'" v-model="value[name]" @input="onInput(name)"></input>
                    <b-form-checkbox v-if="paramType(param.input_value) == 'check'" v-model="value[name]" @change="onInput(name)"></b-form-checkbox>
                    <select v-if="paramType(param.input_value).select" v-model="value[name]" @change="onInput(name)">
                        <option v-for="option in paramType(param.input_value).select">
                            {{option.replace(/\'/gi, '')}}
                        </option>
                    </select>
                </div>
            </b-collapse>
    </div>
</template>
<script>
    import Rete from "rete";

    import InputParameterVueControl from './InputParameterVueControl.vue';
    import CheckBoxParameterVueControl from './CheckBoxParameterVueControl.vue';
    import SelectParameterVueControl from './SelectParameterVueControl.vue';

    export default {
        name: 'AdvancedVue',
        components: {
            InputParameterVueControl,
            CheckBoxParameterVueControl,
            SelectParameterVueControl
        },
        data() {
            return {
                value: this.getNode().data || {},
                // 컬랩스 부분 상태
                datapreprocess_advance_visible: false,
                datapreprocess_advance_icon: 'plus',
            }
        },
        props: ['emitter', 'getData', 'putData', 'type', 'getNode'],
        mounted() {
        },
        methods: {
            // 컬랩스 부분 메서드 // 아이콘 변경 // 컬랩스 visible
            collapseAdvanced() {
                this.datapreprocess_advance_visible = !this.datapreprocess_advance_visible;
                if (this.datapreprocess_advance_visible === true) {
                    this.datapreprocess_advance_icon = 'dash';
                }
                else {
                    this.datapreprocess_advance_icon = 'plus';
                }
            },

            paramType(input_value) {

                const type = input_value.replace(/[\(\)]/gi, '').split('|');

                if ((/^\'/).test(type[0])) {
                    return { select: type };
                }
                else if (type[0] == 'boolean') {
                    return "check"
                }
                else {
                    return "input"
                }

            },

            onInput(key) {
                this.putData(key, this.value[key]);

                console.log(this.getData(key));
            },
        },

        computed: {



        },
    }
</script>
<style scoped>
    /*데이터 전처리 노드 고급 버튼 지정*/

    .advanced-param {
        width: 184px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-bottom: 1px solid rgb(238, 238, 238);
    }


    .advanced-param label {
        font-size: 13px !important;
        color: #7784FB;
    }

    .advanced-param .b-icon {
        width: 25px;
        height: 25px;
        margin-left: auto;
        color: #7784FB;
    }

    .input-unit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 6px 0px;
    }

    .input-unit label {
        font-size: 8px;
        color: #555555;
    }

    .input-unit input,
    select {
        width: 180px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid #999;
    }
</style>
