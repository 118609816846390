<template>
    <div class="data_preprocess_edit">
        <div class="data_preprocess_edit__header">
			<span>데이터 전처리-편집</span>
			<span class="data_preprocess_edit__close-btn" @click="onClick_close">
                <i class="far fa-times-circle"></i>
			</span>
		</div>
		<div class="data_preprocess_edit_body">
            <DataPreprocessEditDock :function-groups="functionGroups"/>
            <div class="data_preprocess_editor">
                <div ref="editor" id="data_preprocess_rete">
                    <div>
					<button @click ='testClick'> test</button>
				</div>
                    <div class="operation-btn-group">
                        <!--v-b-tooltip.hover="node_module_cleanup"-->
                        <!--<b-icon icon="justify" id="data_preprocess_arrange" class="arrange-btn"></b-icon>-->
                        <span id="data_preprocess_arrange" class="arrange-btn"><i class="fas fa-bars"></i></span>
                        <!--v-b-tooltip.hover.bottom="node_module_delete"-->
                        <b-icon icon="code" id="data_preprocess_json" class="json-btn"></b-icon>
                        <!--<b-icon icon="trash" id="data_preprocess_trash" class="trash-btn"></b-icon>-->
                        <span id="data_preprocess_trash" class="trash-btn"><i class="fas fa-trash-alt"></i></span>
                        <!--@click="close_btn"-->
                    </div>
                </div>
            </div>
		</div>
    </div>
</template>
<script>
    import { createFlowEditor } from './editor.js';

    import DataPreprocessEditDock from './DataPreprocessModalEditorDock/DataPreprocessModalEditorDock.vue';

    import InputAndOutput from './DataPreprocessor-json/InputAndOutput.json';
    // import DataPreprocessFunction from './DataPreprocessor-json/DataPreprocessFunction.json';
    import Monitoring from './DataPreprocessor-json/Monitoring.json';
    
    import random from './DataPreprocessor-json/random.json';
    import sliceandjoining from './DataPreprocessor-json/sliceandjoining.json';
    import transformation from './DataPreprocessor-json/transformation.json';
    
    

    export default {
        name: 'data_preprocess_edit',
        data() {
            return {
                editor: '',
                functionGroups: {
                    InputAndOutput,
                    // DataPreprocessFunction,
                    random,
                    sliceandjoining,
                    transformation,
                    Monitoring
                    
                }
            }
        },
        props: ['editorData', 'getNode'],
        components: {
            DataPreprocessEditDock
        },
        async mounted() {
            /* 
                getNode 데이터 안에서 editor_data(데이터 전 처리의 모달 안에 있는 노드에 관한 데이터들)을 뽑아서
                createFlowEditor 함수의 아규먼트로 넣는다.
            */
            const { data: { editor_data } } = this.getNode();
            this.editor_data = editor_data;
            this.editor = await createFlowEditor(this.editor_data, this.functionGroups);
        },
        methods: {
            onClick_close() {
                const editordata = this.editor.toJSON();
                this.$emit('close_data_preprocess_edit', editordata);
            },
            testClick(){
                console.log("=====testClick======");
            }
        },
    }
</script>
<style scoped>
    .data_preprocess_edit {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(to right, rgba(81, 45, 168, 1), rgba(81, 45, 168, 0.7));
    }

    .data_preprocess_edit__header {
        height: 50px;
        background: rgba(81, 45, 168, 1);
        /* fallback for old browsers */
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 0 30px;
    }

    .data_preprocess_edit__header .data_preprocess_edit__close-btn {
        display: inline-block !important;
        width: 30px;
        height: 30px;
        font-size: 20px;
        cursor: pointer;
    }

    .data_preprocess_edit_body {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: rgba(244, 247, 252, 1);
        padding: 30px;
    }

    .data_preprocess_editor {
        width: 100% !important;
        height: 100% !important;
    }

    #data_preprocess_rete {
        width: 100% !important;
        min-height: 750px !important;
        max-height: 750px !important;
        background-color: #ffffff;
        background-image: radial-gradient(rgba(81, 45, 168, 0.07) 10%, transparent 0), radial-gradient(rgba(81, 45, 168, 0.07) 10%, transparent 0);
        background-position: 0 0, 10px 10px;
        background-size: 10px 10px;
        border: 2px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(to right, rgba(81, 45, 168, 1), rgba(81, 45, 168, 0.7));
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        position: relative;
        padding: 5px;
    }

    .operation-btn-group {
        display: flex;
        justify-content: center;
        margin-left: 1rem;
        margin-top: 1rem;
        z-index: 2;
    }

    #data_preprocess_rete .node {
        background: #F0F0F7 !important;
        border: none !important;
        border-top: 7px solid #FF6565 !important;
        border-radius: 6px !important;
        box-shadow: 1px 1px 2px #ddd;
        color: #ccc !important;
        min-width: 120px !important;
        width: 145px;
        display: flex;
        flex-direction: column;
    }


    #data_preprocess_rete .node:hover {
        background: #D0CAE0 !important;
    }

    #data_preprocess_rete .node.selected {
        background: #D0CAE0 !important;
        border: none!important;
        border-top: 7px solid #FF6565 !important;
    }

    /*socket */

    .socket {
        width: 15px !important;
        height: 15px !important;
        border: 2px solid #555555 !important;
        background: #ffffff !important;
    }

    .socket:hover {
        border: 2px solid #555555 !important;
        background: #FF6565 !important;
        /*background: #D0CAE0 !important;*/
        /*background: #FF1A1A !important;*/
    }

    .socket.input {
        /*background: #FF1A1A !important;*/
        position: relative;
        /*margin-left: -9px !important;*/
        top: 10px;
    }

    .socket.output {
        /*background: #FF1A1A !important;*/
        /*margin-right: -9px !important;*/
        position: relative;
    }


    .connection .main-path {
        stroke: #8C8C8C !important;
        stroke-width: 2px !important;
    }

    /* node Control */

    .node .control {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        order: 4;
    }

    /*connection line*/

    /*.connection .main-path {*/

    /*stroke: #000000 !important;*/

    /*	stoke: #4D4D4D !important;*/

    /*	stroke-width: 1px !important;*/

    /*}*/

    select,
    input {
        width: 100px !important;
        height: 20px;
        border-radius: 30px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid #999;
        font-size: 10px !important;
    }
</style>
