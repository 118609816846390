import Vue from 'vue';
import VueRouter from 'vue-router';

// Page 불러오기
import LoginPage from '@/pages/LoginPage';
import MainPage from '@/pages/MainPage';

import WorkSpaceComp from '@/components/MainPage/Workspaces/WorkSpaceComp';

import Contents from '@/components/MainPage/Contents/Contents';

import InferenceComp from '@/components/MainPage/Contents/Inferences/InferenceComp';
import LecturesComp from '@/components/MainPage/Contents/Lectures/LecturesComp';
import TrainComp from '@/components/MainPage/Contents/Trainmodels/TrainComp';
import TrainComp2 from '@/components/MainPage/Contents/Trainmodels/TrainComp2';

import TrainManagerComp from '@/components/MainPage/Contents/TrainManager/TrainManagerComp';

// 튜토리얼 페이지 (테스트)
import Tutorials from '@/components/MainPage/Contents/Tutorials/Tutorials.vue';

// Vue 라우터 사용
Vue.use(VueRouter)


export const router = new VueRouter({
    mode: 'history',
    routes: [{
            // 상위 route 로그인 페이지
            path: '/',
            component: LoginPage
        },
        {
            // 메인 페이지 
            path: '/main',
            component: MainPage,
            children: [{
                    // 프로젝트 들어가기 
                    path: '/workspaces',
                    component: WorkSpaceComp
                },
                {
                    path: '/contents',
                    component: Contents,
                    children: [{
                            // 모델 만들기 페이지 
                            path: '/trainmodel',
                            component: TrainComp2
                        }, {
                            // 강의 페이지
                            path: '/lectures',
                            component: LecturesComp
                        },
                        {
                            // 추론 페이지
                            path: '/inference',
                            component: InferenceComp
                        },
                        // {
                        //     // 모델 훈련 2
                        //     path: '/trainmodel2',
                        //     component: TrainComp2
                        // },
                        {
                            // 모델 훈련 2
                            path: '/trainmanager',
                            component: TrainManagerComp
                        },
                        {
                            // 튜토리얼 페이지
                            path: '/tutorials',
                            component: Tutorials
                        }
                    ]
                },

            ]

        },

    ]
})
