import Rete from "rete";
import { numSocket } from '../sockets';
import DataPreprocessControl from './DataPreprocessControl';
import DataPreprocessSelectOption from './DataPreprocessSelectOption';


export default class DataPreprocessComponent extends Rete.Component {
    constructor() {
        super("데이터 전처리");
        this.data = {
            render: "vue"
        };
    }
    builder(node) {

        if (node.data.input_data) {
            node.data.input_data.forEach(n => {
                node.addInput(new Rete.Input(n.key, n.name, numSocket, true));
            });
        }

        if (node.data.output_data) {
            node.data.output_data.forEach(n => {
                node.addOutput(new Rete.Output(n.key, n.name, numSocket, true));
            });
        }

        //input, output 소켓의 이름을 컴포넌트명/노드id/_input|_output으로 식별
        const input_name = "preprocess_input1";
        const output_name = "preprocess_output1";


        const control1 = new DataPreprocessControl(this.editor, "preprocess");
        const control2 = new DataPreprocessSelectOption(this.editor, "preprocess2");
        // const input = new Rete.Input(input_name, "입력", numSocket, true);
        // const output = new Rete.Output(output_name, "출력", numSocket, false);

        node.addControl(control1);
        node.addControl(control2);

        return node;
    }
}
