/* eslint-disable no-unused-vars */

// import rete
import Rete from 'rete';

// IMPORT RETE.JS PLUGIN
import VueRenderPlugin from 'rete-vue-render-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import HistoryPlugin from 'rete-history-plugin';
import AutoArrangePlugin from 'rete-auto-arrange-plugin';
import AreaPlugin from 'rete-area-plugin';

import NodeComponent from './components/NodeComponent';

// RETE EDITOR 
export async function createFlowEditor(editordata, layerGroups) {
	// check createFlowEditor

	/* 각 그룹 이름 상수화 */
	const INPUT_AND_OUTPUT = "InputAndOutput";
	const BASIC = "Basic";
	const CONVOLUTIONAL = "Convolutional";
	const POOLING = "Pooling";
	const MERGE = "Merge";
	console.log('train model 에디터가 동작 하고 있습니다.');

	// CONTAINER 선언
	let container = document.querySelector('#rete-editor');

	// editor 설정 Rete.NodeEditor
	let editor = new Rete.NodeEditor('demo@0.0.1', container);
	let dock_label_name = null;

	// Plugin UMD 설정
	editor.use(ConnectionPlugin);
	editor.use(VueRenderPlugin);


	// 에디터 범위 설정
	editor.use(AreaPlugin, {
		background: false,
		snap: false,
		scaleExtendt: { min: 0.1, max: 1 },
		translateExtent: { width: 5000, height: 4000 }
	});


	editor.use(AutoArrangePlugin, {
		margin: { x: 50, y: 50 },
		depth: 100
	});

	// 뒤로가기, 앞으로 가기 부분
	editor.use(HistoryPlugin, { keyboard: true });

	// engine 설정
	let engine = new Rete.Engine('demo@0.0.1');

	// node arrange button
	const btn = document.getElementById('mod-arrangebtn');

	btn.addEventListener('click', btnClick, false);

	function btnClick(e) {
		// console.log('Arranging...');
		editor.trigger('arrange');
		editor.view.resize();
		AreaPlugin.zoomAt(editor, editor.nodes);

	}

	// node history button
	// const btn2 = document.getElementById('mod-undobtn');

	// btn2.addEventListener('click', btnClick2, false);

	// function btnClick2(e) {
	// 	editor.trigger("undo");
	// }

	// const btn3 = document.getElementById('mod-redobtn');

	// btn3.addEventListener('click', btnClick3, false);

	// function btnClick3(e) {
	// 	editor.trigger("redo");
	// }

	// JSON 형식으로 콘솔 찍기 (TEST 용)
	const btn4 = document.getElementById('mod-jsonbtn');

	btn4.addEventListener('click', btnclick4, false);

	function btnclick4(e) {
		e.preventDefault();
		const editordata = editor.toJSON();

		console.log(editordata);

		const json = JSON.stringify(editordata);

		console.log(json)
		// sessionStorage.setItem('editdata', editdata)
	}

	// 노드 지우기 버튼 
	const btn6 = document.getElementById('mod-trashbtn');

	btn6.addEventListener('click', btnClick6, false);

	function btnClick6(e) {
		e.preventDefault();
		console.log(e.target);
		switch (e.type) {
			case 'click':
				editor.selected.each(n => editor.removeNode(n));
				break;
			default:
				break;
		}
	}

	// 모달 닫기 버튼
	const close_btn = document.getElementById('mod-closebtn');

	close_btn.addEventListener('click', close_click, false);

	function close_click(e) {
		e.preventDefault();
		// save_model();
	}

	// 동적으로 컴포넌트를 등록하기 위한 함수
	const createComponent = (group, layer) => {

		// 입력받은 이름으로 JSON 파일 조회
		let config = layerGroups[group][layer]
		config.group = group;
		
		// JSON 객체 정보로 node 컴포넌트 생성
		let comp = new NodeComponent(config);


		//생성된 노드 컴포넌트를 엔진과 에디터에 등록
		editor.components.set(layer, comp);
		engine.components.set(layer, comp);

		return comp;
	}


	document.querySelectorAll('.modal-body .dock .node').forEach(item => {
		item.addEventListener('click', event => {
			createComponent(item.dataset.group, item.dataset.layer).
			createNode().then(n => {
				editor.addNode(n);
			});
		});
	});
	// sessionData 확인하여 초기 노드 설정
	
	if (editordata) {
		for (let n in editordata.nodes) {
			//에디터 확인 후 컴포넌트 등록 안되어 있을 시 생성전에 등록시킨다.
			if (!editor.components.get(editordata.nodes[n].name)) {
				const group = editordata.nodes[n].data.group;
				createComponent(group, editordata.nodes[n].name);
			}
		}
		editor.fromJSON(editordata);
	}



	// json 파일로 불러오는 컴포넌트 설정
	// nodecreated noderemoved connectioncreated connectionremoved connectionpath



	// 노드가 렌더될 때 나타나는 이벤트
	editor.on('rendernode', ({ el, node }) => {
		/* 타이틀 안에 생성된 닫기 아이콘을 누르면 노드 제거가 실행되는 이벤트 함수 */
		const removeNodeInTrainModel = (e) => editor.selected.each(n => editor.removeNode(n));

		const selected_node = el.childNodes[0];
		const node_title = selected_node.childNodes[0];
		const close_btn_container = document.createElement("span");
		const close_btn_in_node = document.createElement("i");

		// .title 클래스 안에 span 요소 생성
		// span 요소 안에 닫기 아이콘 생성
		console.log(selected_node);
		close_btn_in_node.className = "fas fa-times";
		close_btn_container.id = "closeBtnInTrainModelEdit";

		close_btn_container.appendChild(close_btn_in_node);
		node_title.appendChild(close_btn_container);

		/* 닫기 화면을 누르면, 노드가 사라짐(removeNodeInTrainModel) */
		close_btn_container.addEventListener("click", removeNodeInTrainModel);
		
		//선택된 노드의 그룹을 구별시키기 위해 클래스 이름 추가.
		switch(node.data.group){
			case INPUT_AND_OUTPUT:
				selected_node.classList.add(node.data.group);
				return;
			case BASIC:
				selected_node.classList.add(node.data.group);
				// 타이틀 색 분홍색으로 변경
				node_title.style.backgroundColor = "#ff7675";
				return;
			case CONVOLUTIONAL:
				selected_node.classList.add(node.data.group);
				// 타이틀 색 하늘색으로 변경
				node_title.style.backgroundColor = "#0984e3";
				return;
			case POOLING:
				selected_node.classList.add(node.data.group);
				// 타이틀 색 연두색으로 변경
				node_title.style.backgroundColor = "#6ab04c";
				return;
			case MERGE:
				selected_node.classList.add(node.data.group);
				// 타이틀 색 주황색으로 변경
				node_title.style.backgroundColor = "#f0932b";
				return;
		}
	});

	editor.on('process', async() => {
		console.log('processing...');
		await engine.abort();
		await engine.process(editor.toJSON());

		// =================================================
		// await AreaPlugin.zoomAt(editor, editor.nodes);
		// await editor.view.resize();
		// =================================================
	});

	return editor;
}
