<template>
    <!-- 메인 프로젝트 화면 -->
    <div class="workspaces_outer">
        <loading-component v-if="is_outer_loading"></loading-component>
        <div class="workspaces_wrapper">
            <!-- header -->
            <div class="workspaces_header">
                <!--워크스페이스 부분 헤더-->
                <!--Workspaces(프로젝트)-->
                <label>{{ workspaces_header }}</label>
            </div>
            <!-- body -->
            <div class="workspaces_content">
                <div class="workspace_list">
                    <!-- 새로운 프로젝트 만들기 MODAL -->
                    <!-- 임시로 닉네임 체크 후 게스트가 아니면 새로운 프로젝트 만들기 실행 가능-->
                    <div class="new_project_form" v-if="user_nick !='게스트'" @click="show_Create_New_Project">
                        <b-icon icon="plus-square-fill"></b-icon>
                        <!--새로 만들기 모달 부분-->
                        <!--새로 만들기-->
                        <label>{{ new_project_form_label }}</label>
                        <!--새로 만들기 부분 설명 -->
                        <!--workspace에 새로운 프로젝트를 만듭니다-->
                        <p>{{ new_project_form_explain }}</p>
                        <b-modal ref="new_workspace_form" hide-header hide-footer size="lg">
                            <new_workspace v-on:close_btn="hide_Create_New_Project" />
                        </b-modal>
                    </div>
    
                    <div class="new_project_form" v-else>
                        <b-icon icon="plus-square-fill"></b-icon>
                        <!--새로 만들기 모달 부분-->
                        <!--새로 만들기-->
                        <label>{{ new_project_form_label }}</label>
                        <!--새로 만들기 부분 설명 -->
                        <!--workspace에 새로운 프로젝트를 만듭니다-->
                        <p>{{ new_project_form_explain }}</p>
                    </div>
    
                    <!--게스트 로그인 시 화면 -->
                    <sample_workspace v-if="user_nick === '게스트'" />
                    <!--게스트 로그인 시 워크스페이스 화면 END-->
    
                    <!--카카오 로그인 시 워크스페이스 화면 -->
                    <div class="exist_workspaces" v-else v-for="(saved_workspace, index) in session_saved_workspaces" :key="index">
                        <workspace_card :authorize="saved_workspace.authorize" :project_name="saved_workspace.project_name" :project_rating="saved_workspace.project_rating" :project_explain="saved_workspace.project_explain" :user_profile_image="saved_workspace.user_profile_image"
                            v-on:delete="project_delete(saved_workspace, index)" v-on:workspace_open="workspace_project_button_click(saved_workspace)" />
                    </div>
                </div>
                <!-- workspaces_list end -->
            </div>
            <!-- workspaces_content end -->
        </div>
    </div>
    <!-- workspace_outer end-->
</template>
<script>
    // 새로운 프로젝트 만들기 =========================
    import new_workspace from './Newworkspaceform';
    // ================================================

    // 게스트 워크스페이스 화면 // 게스트 대쉬보드 화면 
    import sample_workspace from './GuestWorkspace';
    // ================================================
    // 회원 워크스페이스 화면 // 회원 대쉬보드 화면
    import workspace_card from './WorkspaceCard';
    // ================================================

    // 언어 설정 불러오기 =============================
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ================================================

    // 워크스페이스 리스트 (회원) =====================
    import workspacelist from './workspacelist_t';
    // ================================================
    
    import LoadingComponent from "@/components/Loading/Loading";

    // vuex store 이용
    import store from '@/store';


    export default {
        name: 'workspaces',
        data() {
            return {
                sort_workspace: '최근 업데이트순',
                session_saved_workspaces: [],
                // uploadmodel: [],

                // 다국어모드 설정
                workspaces_header: '',
                workspaces_search_placeholder: '',
                sort_workspace_recent: '',
                sort_workspace_name: '',

                project_label_myproject: '',

                new_project_form_label: '',
                new_project_form_explain: '',
                delete_project: '',
                current_project: '',
                project_open: '',

                // 언어 설정
                localization: '',

                // 임시 게스트 닉네임
                user_nick: '',

                // 임시 게스트 이미지 
                profile_image: '',
                
                //로딩 유무 판별
                is_outer_loading: true,

            }
        },
        components: {
            new_workspace,
            sample_workspace,
            workspace_card,
            LoadingComponent
        },
        methods: {
            // 새로운 프로젝트 만들기 모달 OPEN
            show_Create_New_Project() {
                this.$refs['new_workspace_form'].show();
            },
            // 새로운 프로젝트 만들기 모달 HIDE
            hide_Create_New_Project() {
                this.$refs['new_workspace_form'].hide();

                // 새로운 프로젝트 만들기 모달이 hide 되면서
                // 세션에 저장되어 있던 파일 다시 불러오기 refresh
                this.workspace_list_refresh();

            },
            // 세션에 저장한 워크스페이스 목록 다시 가져와서 화면에 반영하기
            workspace_list_refresh() {
                // 세션에 저장한 워크스페이스 목록 다시 가져오기
                let workspaces = sessionStorage.getItem('session_saved_workspaces');

                // 세션에서 가져온 워크스페이스 목록 제에슨 파싱
                let workspace_parse = JSON.parse(workspaces);

                // data 객체 내의 session_saved_workspaces 함수에 저장
                this.session_saved_workspaces = workspace_parse;

            },
            // 프로젝트 열기 CLICK
            workspace_project_button_click(saved_workspace) {
                // console.log(saved_workspace.project_rating)
                this.$router.push('trainmodel');
            },
            // 프로젝트 지우기 CLICK
            project_delete(saved_workspace, index) {
                // 클릭 확인용 콘솔
                // console.log(index);
                // console.log(saved_workspace);

                // 세션에 저장되어있는 목록 가져오기
                let workspaces = sessionStorage.getItem('session_saved_workspaces');

                // 세션에서 가져온 워크스페이스 목록 제이슨 파싱
                let workspace_parse = JSON.parse(workspaces);

                // 해당 인덱스의 프로젝트 지우기
                workspace_parse.splice(index, 1);

                // 값 변환 후 세션스토리지에 저장
                sessionStorage.setItem('session_saved_workspaces', JSON.stringify(workspace_parse));

                // 워크스페이스 화면에 다시 반영
                this.workspace_list_refresh();
            }
        },
        mounted() {

            // console.log('mounted!!')
            // 추후 통신을 통해 받아와서 워크스페이스 목록에 띄워줘야 할 부분

            // 세션스토리지에 저장되어 있는 유저 프로필 이미지 정보 가져오기
            let userinfo = sessionStorage.getItem('userInfo');

            let userinfo_parse = JSON.parse(userinfo);

            this.user_nick = userinfo_parse.nickname;

            this.profile_image = userinfo_parse.profile_image;

            // 게스트 아이디 대신 임시로 체크 (게스트 닉네임 체크)
            // 닉네임이 게스트가 아닐 경우에 세션 스토리지에 저장되어있는 워크스페이스 // 프로젝트 정보 가져오기 
            if (this.user_nick != '게스트') {
                // 세션 스토리지에서 저장된 워크스페이스가 없으면 세션 스토리지에 저장
                if (sessionStorage.getItem('session_saved_workspaces') === null) {
                    // 변수에 저장된 워크스페이스들 저장

                    // 화면에 반영하기
                    this.session_saved_workspaces = workspacelist.saved_workspaces;

                    // 세션에 저장하기 위해 stringify
                    let workspace_string = JSON.stringify(this.session_saved_workspaces);

                    // 세션 스토리지에 저장하기
                    sessionStorage.setItem('session_saved_workspaces', workspace_string);

                }
                else {
                    // 콘솔 확인 (else)
                    // console.log('세션에 이미 저장 되어 있습니다.');

                    // 세션에 저장한 워크스페이스 목록 다시 가져오기
                    let workspaces = sessionStorage.getItem('session_saved_workspaces');

                    // 세션에서 가져온 워크스페이스 목록 제에슨 파싱
                    let workspace_parse = JSON.parse(workspaces);

                    // data 객체 내의 session_saved_workspaces 함수에 저장
                    this.session_saved_workspaces = workspace_parse;
                }

                // =========================================================================================
            }
            else {
                console.log('게스트 로그인 상태 입니다.');
            }


            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.workspaces_header = korean.workspaces.workspaces_header;
                    this.workspaces_search_placeholder = korean.workspaces.workspaces_search_placeholder;
                    this.sort_workspace_recent = korean.workspaces.sort_workspace_recent;
                    this.sort_workspace_name = korean.workspaces.sort_workspace_name;
                    this.project_label_myproject = korean.workspaces.project_label_myproject;
                    this.new_project_form_label = korean.workspaces.new_project_form_label;
                    this.new_project_form_explain = korean.workspaces.new_project_form_explain;
                    this.delete_project = korean.workspaces.delete_project;
                    this.current_project = korean.workspaces.current_project;
                    this.project_open = korean.workspaces.project_open;
                    
                    // 로딩 끝
                    this.is_outer_loading = false;
                    return;
                case 'en':
                    this.workspaces_header = english.workspaces.workspaces_header;
                    this.workspaces_search_placeholder = english.workspaces.workspaces_search_placeholder;
                    this.sort_workspace_recent = english.workspaces.sort_workspace_recent;
                    this.sort_workspace_name = english.workspaces.sort_workspace_name;
                    this.project_label_myproject = english.workspaces.project_label_myproject;
                    this.new_project_form_label = english.workspaces.new_project_form_label;
                    this.new_project_form_explain = english.workspaces.new_project_form_explain;
                    this.delete_project = english.workspaces.delete_project;
                    this.current_project = english.workspaces.current_project;
                    this.project_open = english.workspaces.project_open;
                    
                    // 로딩 끝
                    this.is_outer_loading = false;
                    return;

            }
            // ==========================================================================================
        },
        // updated() {
        //     console.log(this.sort_workspace)
        // }
    }
</script>
<style scoped>
    /* 워크스페이스페이지 전체 크기 ======*/

    .workspaces_outer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #F5F6FA;
        /*position: relative;*/
        align-items: center;
    }

    /*====================================*/

    /* 워크스페이스페이지 헤더 ===========*/

    .workspaces_header {
        display: flex;
        align-items: center;
        margin-top: 12px;
    }

    .workspaces_header label {
        color: #858585;
        font-size: 35px;
        font-weight: bold;
    }

    /*====================================*/

    /* 워크스페이스페이지 콘텐츠 =========*/

    .workspaces_content {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3%;
    }

    /* 프로젝트 카드 리스트*/

    .workspace_list {
        /*width: 75%;*/
        width: 1400px;
        height: 600px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: auto;
    }

    .new_project_form {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    .new_project_form .b-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        color: #C2C2C2;
    }

    .new_project_form label {
        font-weight: bold;
        font-size: 20px;
        color: #C2C2C2;
    }

    .new_project_form p {
        font-size: 12px;
        color: #C2C2C2;
        text-align: center;
    }


    .new_project_form:hover {
        background: #eeeeee;
        cursor: pointer;
    }

    /*====================================*/
    
    /*반응형 (임시) */
    
    /*워크스페이스 페이지 1920px 이상 pc 일경우*/
    @media screen and (min-width: 1920px) {
    }
    
    
    /*====================================*/
    /*워크스페이스 페이지 1920px 이하 pc 일 경우*/
    @media screen and (max-width: 1024px) {
        
        .workspaces_search {
            width: 100%;
        }
        .workspaces_search select {
            margin-right: 30px;
        }
        
        .project_label {
            width: 100%;
        }
        
        .project_label label {
            margin-left: 30px;
    }
        
        .workspace_list {
            width: 100%;
            height: 440px;
            grid-template-columns: repeat(4, 1fr);
        }
        
        .new_project_form {
            width: 220px;
            height: 200px;
        }
        
        .new_project_form label {
            font-size: 15px;
        }
        
        .new_project_form p { 
            font-size: 10px;
        }
    }
    /*====================================*/
    
    
</style>
