import Vue from "vue";
import Rete from "rete";
import { numSocket } from '../sockets';
import RawDataControl from './RawDataControl';


export default class RawDataComponent extends Rete.Component {
	constructor() {
		super("원본데이터");
		this.data = {
			render: "vue"
		};
	}

	builder(node) {
		//output의 이름을 컴포넌트명노드ID_output로 지정
		// console.log("노드 정보",node);
		// console.log(this.template);
		// const output_name = "raw_data" + node.id + "_output";
		
		//노드에 raw_data 정보가 있으면 출력 소켓에 정보 출력, 없으면 '출력'을 기본값으로
		let output_name = node.data.raw_data ? '(' + node.data.raw_data.data.dataSize + ',' + node.data.raw_data.data.INPUT_WIDTH + ',' + node.data.raw_data.data.INPUT_HEIGHT + ',' +
			node.data.raw_data.data.INPUT_CHANNELS + ')' : "출력"

		// multiple 입출력 true
		let output1 = new Rete.Output("raw_data_output", output_name, numSocket, true);
		return node
			.addControl(new RawDataControl(this.editor, "raw_data"))
			.addOutput(output1);
	}
	//engine.process 실행시 각 컴포넌트 별로 실행되는 코드
	worker(node, inputs, outputs) {}
}