const state = {
    

    // 새로운 프로젝트 생성 FORM
    project: {
        name: '',
        explain: '',
        group: '',
        password: ''
    }
}

const mutation = {

}

const actions = {

}

const getters = {

    // 저장된 워크스페이스 내보내기
    get_saved_workspaces(state) {
        return state.saved_workspaces
    }

}

export default {
    state,
    mutation,
    actions,
    getters
}
