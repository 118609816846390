import Rete from "rete";
import { numSocket } from '../../sockets';
import MaxPoolingControl from '../../controls/MaxPoolingControl';

export default class MaxPoolingComponent extends Rete.Component {
	constructor() {
		super("maxPooling2d");
		this.data = {
			render: "vue"
		};
	}
	builder(node) {
		let input1 = new Rete.Input("input1","입력",numSocket);
		let output1 = new Rete.Output("output1","출력",numSocket);
		return node
			.addControl(new MaxPoolingControl(this.editor, "input"))
			.addInput(input1)
			.addOutput(output1);
	}
	worker() {}
}