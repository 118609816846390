<template>
    <!--로그인 이미지  보여요?-->
    <div class="loginpage">
        <loading-component v-if="isLoading"></loading-component>
        <div class="loginpage_img">
            <!--로그인 페이지 좌측 그림-->
            <img src="@/assets/login_image.png"/>
        </div>
        <!--로그인 작성 부분-->
        <b-card class="d-flex flex-column" no-header>
            
            <!--<image src="assets/logo.png"/>-->
            <!-- Login form -->
            <div class="company_name mb-5">
                <label>NOCODING AI</label>
            </div>
            <!--회사 이름 부분 end-->
            
            <!--로그인 페이지 환영 인사 부분-->
            <div class="welcome_talk">
                
                <!-- 2021 0121 이전 환영인사 글귀 -->
                <!--<label>{{ welcome_talk }}</label>-->
                <!-- 2021 0121 이전 환영인사 글귀 :: END -->
                
                <!-- 2021 0121 이후 환영인사 글귀-->
                <p class="lead" style="text-align:left">
                <h2>방문해 주셔서 감사합니다.</h2> <br/>
                <strong>nocodingAI</strong>는 코딩 없이 AI를 개발할 수 있는 사이트입니다. <br/><br/>
                프로그래밍을 모르셔도 됩니다.  AI를 아실 필요도 없습니다. <br/><br/>
                <strong>nocodingAI</strong>와 함께라면, 당신도 AI 전문가가 될 수 있습니다.
                </p>
                <!-- 2021 0121 이후 환영인사 글귀 :: END -->


            </div>
            <!--로그인 페이지 환영 인사 부분 end-->
            <!--<div v-if="!signed_in">-->
            <!--    <amplify-authenticator></amplify-authenticator>-->
            <!--</div>-->
            <!--<div v-else>-->
            <!--    <amplify-sign-out></amplify-sign-out>-->
            <!--</div>-->
            <div class="form-signin mt-5">
                <!--하단 로그인 버튼 그룹 부분 end-->
                <!--카카오 로그인 이미지 버튼 부분 (한국어 일때)-->
                <!--<img id="kakaoLogin" v-if="localization === 'ko'" src="../assets/kakao_login_medium_wide.png" @click="onClick_KakaoLogin"/>-->
                <!--카카오 로그인 이미지 버튼 부분 (영어 일때)-->
                <!--<img id="kakaoLogin" v-else-if="localization === 'en'" src="../assets/kakao_login_english_wide.png" @click="onClick_KakaoLogin"/>-->
                
                
                <!--2021 01 21 작업 부분 -->
                
                
                <!-- 이전 버전 로그인 폼 -->
                <!--<form v-if="!is_logged_on" class="form-siginin__amplify-auth" @submit="implementLogin">-->
                <!--    <input ref="input_email" class="amplify-auth__input-email" type="email" placeholder="Your Email" required/>-->
                <!--    <input ref="input_password" class="amplify-auth__input-password" type="password" placeholder="Your Password" required/>-->
                <!--    <button class="amplify-auth__submit" type="submit">LOGIN</button>-->
                <!--</form>-->
                <!-- 이전 버전 로그인 폼 :: END -->

                <!--<button v-else @click="implementLogOut">로그아웃</button>-->
                
                <!-- 이전 버전 게스트 로그인 -->
                <!--<b-button class="button_guest_login" @click="onClick_guestLogin">게스트 로그인</b-button>-->
                <!-- 이전 버전 게스트 로그인 :: END -->
                
                <!-- 2021 0121 게스트 로그인 // 시작하기 버튼으로 -->
                <b-button class="button_guest_login" @click="onClick_guestLogin">시작하기</b-button>
                <!-- 2021 0121 게스트 로그인 // 시작하기 버튼으로 :: END -->
                
                <!-- 이전 버전 계정 만들기 버튼 그룹-->
                <!--<div class="create-account-box">-->
                <!--    <b-button id="createModalBtn" v-b-modal.createAccountModal>계정 만들기</b-button>-->
                <!--    <b-modal id="createAccountModal" ref="signup-modal" centered hide-header hide-footer no-close-on-backdrop title="계정생성하기">-->
                        <!--계정 생성 모달 내용 부분-->
                <!--            <SignupForm @complete_signup="completeSignUp" @closeSignUpModal="closeSignUpModal" :is_error_about_signup="is_error_about_signup"/>-->
                <!--    </b-modal>-->
                <!--</div>-->
                <!-- 이전 버전 계정 만들기 버튼 그룹 :: END-->
                
                
                <!--2021 01 21 작업 부분 :: END -->
                
            </div>
            
            
            <!-- 로그인 폼 부분 -->
            <!--<b-form class="form-signin" @submit="onClick_DefaultLogin">-->
            <!--    <b-form-group id="signin-group" label-for="signin">-->
                    <!--아이디 입력 부분-->
            <!--        <b-form-input id="signin-email" type="email" v-model="signinform.email" required placeholder="아이디"></b-form-input>-->
                    <!--암호 입력 부분-->
            <!--        <b-form-input id="signin-password" type="password" v-model="signinform.password" required placeholder="암호"></b-form-input>-->
            <!--    </b-form-group>-->
                <!--로그인 정보 기억하기 부분-->
            <!--    <div class="remember-group">-->
                    <!--기억하기 체크 박스-->
            <!--        <div class="checkbox-group">-->
            <!--            <b-form-checkbox v-model="check" value="remember" unchecked-value= "not-remember"/>-->
                        <!-- 로그인 정보 기억하기onClick_guestLogin
                        <!-- 기억하기 -->
            <!--            <label>{{ login_inform_remeber_check }}</label>-->
            <!--        </div>-->
                    <!-- 비밀번호 찾기 부분 -->
            <!--        <div class="find-account">-->
                        <!-- 비밀 번호 찾기-->
                        <!-- 암호를 잊으셨습니까? -->
            <!--            <label>{{ question_find_account }}</label>-->
            <!--        </div>-->
            <!--    </div>-->
                <!--하단 로그인 버튼 그룹 부분-->
            <!--    <div class="login-button-group">-->
                    <!-- 로그인 버튼 -->
            <!--        <b-button class="signin-button" type="submit">LOGIN</b-button>-->
                    <!-- 계정 생성 버튼 -->
                    <!-- 계정 생성 -->
            <!--        <b-button variant="outline-dark" @click="show_create_account_form">{{ create_account }}</b-button>-->
            <!--    </div>-->
                <!--하단 로그인 버튼 그룹 부분 end-->
                    <!--카카오 로그인 이미지 버튼 부분 (한국어 일때)-->
            <!--        <img id="kakaoLogin" v-if="localization === 'ko'" src="../assets/kakao_login_medium_wide.png" @click="onClick_KakaoLogin"/>-->
                    <!--카카오 로그인 이미지 버튼 부분 (영어 일때)-->
            <!--        <img id="kakaoLogin" v-else-if="localization === 'en'" src="../assets/kakao_login_english_wide.png" @click="onClick_KakaoLogin"/>-->
                    
                    <!--게스트 로그인 버튼 부분 -->
            <!--        <b-button class="button_guest_login" @click="onClick_guestLogin">게스트 로그인</b-button>-->
                <!--계정 생성 모달 부분-->
                <!--계정 생성 모달 end-->
            <!-- </b-form>-->
            <!-- 로그인 폼 부분 end-->
            
            
            <!--bottom policy-->
            <div class="bottom_policy">
                <label>Term of use. Privacy policy</label>
            </div><!-- bottom policy end -->
        </b-card><!-- b-card end -->
        
        <!--이 컴포넌트 내 알림 모달-->
         <b-modal id="alarmModalInLoginPage" ref="signup-modal" centered hide-header hide-footer>
             <header class="alarmModalInLoginPage__header">
                 <div>{{title_in_alarm_modal}}</div>
                 <span @click="closeAlarmModalInLoginPage"><i class="far fa-times-circle"></i></span>
             </header>
             <main class="alarmModalInLoginPage__main">
                 <div>{{contents_in_alarm_modal}}</div>
             </main>
         </b-modal>
    </div><!-- loginpage end -->
</template>
<script>
    import axios from 'axios';
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';
    import { Auth } from "aws-amplify";

    // 언어 설정 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    // 회원가입 폼 불러오기
    import SignupForm from '@/components/LoginPage/SignupForm';
    import LoadingComponent from "@/components/Loading/Loading";

    // 게스트 이미지 불러오기
    import guest_image from '@/assets/guest.png';

    import dynamoDB from '@/js/dynamoDB.js'

    export default {
        name: "loginpage",
        data() {
            return {
                signinform: {
                    email: '',
                    password: '',
                },
                check: "not-remember",
                signupform: {
                    email: '',
                    password: '',
                },

                // 다국어 설정
                welcome_talk: '',
                login_inform_remeber_check: '',
                question_find_account: '',
                create_account: '',

                // 언어 check
                localization: '',
                is_logged_on: null,

                //알림 모달 제목과 내용
                title_in_alarm_modal: '',
                contents_in_alarm_modal: '',

                //로딩 끝남 유무
                isLoading: true,

                //가입 할 때 인증번호가 일치하지 않을 때 에러 객체 보냄
                is_error_about_signup: null
            }
        },
        components: {
            SignupForm,
            LoadingComponent
        },
        mounted() {
            /* global navigator */
            this.monitorLogState();
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);
            // if (locale !== 'ko') locale = 'en' 
            // 한국어가 아닌 경우 무조건 영어로 설정

            // this.$i18n.locale = locale
            // console.log(locale)
            this.localization = locale.substring(0, 2);

            /* 현재 사용자가 로그인 상태인지 아닌지를 판별*/
            this.monitorLogState();

            // this.localization = 'en';

            // 언어 별 분기 적용
            switch (this.localization) {
                // 한국어 일때
                case 'ko':
                    this.welcome_talk = korean.loginPage.welcome_talk;
                    this.Input_Id_placeholder = korean.loginPage.Input_Id_placeholder;
                    this.Input_password_placeholder = korean.loginPage.Input_password_placeholder;
                    this.login_inform_remeber_check = korean.loginPage.login_inform_remeber_check;
                    this.question_find_account = korean.loginPage.question_find_account;
                    this.create_account = korean.loginPage.create_account;
                    this.isLoading = false;
                    return;
                    // 영어 일때
                case 'en':
                    this.welcome_talk = english.loginPage.welcome_talk;
                    this.Input_Id_placeholder = english.loginPage.Input_Id_placeholder;
                    this.Input_password_placeholder = english.loginPage.Input_password_placeholder;
                    this.login_inform_remeber_check = english.loginPage.login_inform_remeber_check;
                    this.question_find_account = english.loginPage.question_find_account;
                    this.create_account = english.loginPage.create_account;
                    this.isLoading = false;
                    return;
            }
        },
        methods: {
            // 일반 로그인 부분
            insertUserinfoToStorage({ email, sub: cognitoId, nickname }, jwtToken) {
                const user_info = JSON.stringify({ email, cognitoId, nickname, jwtToken });
                sessionStorage.setItem("userInfo", user_info);
            },
            async monitorLogState() {
                try {
                    const { attributes, signInUserSession: { accessToken: { jwtToken } } } = await Auth.currentAuthenticatedUser();
                    this.insertUserinfoToStorage(attributes, jwtToken);
                    this.is_logged_on = true;
                    this.$router.push('inference');
                }
                catch (e) {
                    console.log(e);
                    sessionStorage.removeItem("userInfo");
                    this.is_logged_on = false;
                }
            },
            implementLogin(e) {
                e.preventDefault();
                const signIn = async() => {
                    try {
                        const { attributes, signInUserSession: { accessToken: { jwtToken } } } = await Auth.signIn(this.$refs.input_email.value, this.$refs.input_password.value);
                        this.insertUserinfoToStorage(attributes, jwtToken);
                        this.is_logged_on = true;
                        this.$router.push('inference');
                    }
                    catch ({ code, message }) {
                        if (code === "UserNotFoundException") {
                            this.title_in_alarm_modal = "존재하지 않는 유저";
                            this.contents_in_alarm_modal = "존재하지 않는 유저입니다.";
                            this.showAlarmModalInLoginPage();
                        }
                        else if (code === "NotAuthorizedException") {
                            this.title_in_alarm_modal = "ID / 비밀번호 에러";
                            this.contents_in_alarm_modal = "올바르지 않은 ID 또는 비밀번호가 입력되었습니다.";
                            this.showAlarmModalInLoginPage();
                        }
                        console.log(`에러 코드[${code}]: ${message}`);
                        sessionStorage.removeItem("userInfo");
                        this.is_logged_on = false;
                    }
                }

                signIn();
            },
            // async completeSignUp(email, confirmCode) {
            //     try {
            //         await Auth.confirmSignUp(email, confirmCode);
            //         this.hide_create_account_form();
            //         this.title_in_alarm_modal = "가입 완료";
            //         this.contents_in_alarm_modal = "가입이 완료되었습니다. 로그인 해주시기 바랍니다.";
            //         this.showAlarmModalInLoginPage();
            //     }
            //     catch ({ code, message }) {
            //         if (code === "CodeMismatchException") {
            //             this.is_error_about_signup = "인증 번호가 일치하지 않습니다.";
            //         }

            //         console.log('인증번호 에러[위치: pages[folder] -> LoginPage.vue -> completeSignUp(메서드)]', code, message);
            //     }
            // },
            
            onClick_guestLogin() {
                const userInfo = {
                    nickname: '게스트',
                    id: 'guest',
                    profile_image: guest_image,
                }

                // 받아온 정보를 세션에 넣기위해 변환
                let session_userInfo = JSON.stringify(userInfo);

                // 세션에 받아온 정보 저장
                sessionStorage.setItem("userInfo", session_userInfo);
                
                // 워크스페이스 페이지 이동 
                this.$router.push('workspaces');
            },

            // 카카오 로그인 (No library)
            // onClick_KakaoLogin() {
            //     // console.log(window.Kakao);
            //     window.Kakao.Auth.login({

            //         // port 80 ========================
            //         // scope: 'account_email, profile',
            //         // ================================

            //         // port 8080~ ========================================================
            //         scope: 'account_email, profile, gender, age_range, friends, birthday',
            //         // ===================================================================
            //         success: this.get_Kakao_Information,
            //     });
            // },

            // 로그인 정보 가져오기(카카오에서 가져온 로그인 정보)
            // get_Kakao_Information(authObj) {
            //     // console.log(authObj);
            //     window.Kakao.API.request({
            //         url: '/v2/user/me',
            //         success: res => {

            //             // res 콘솔로그 확인
            //             console.log(res);

            //             // 
            //             const kakao_account = res.kakao_account;

            //             const userInfo = {
            //                 nickname: kakao_account.profile.nickname,
            //                 profile_image: kakao_account.profile.profile_image_url,
            //                 email: kakao_account.email,
            //                 password: 'not yet password',
            //                 account_type: 2,
            //             }

            //             // ===================================================================
            //             // BACKEND AND DB 회원관리 POST 부분
            //             //  axios.post(`http://localhost:8080/account/kakao`,{
            //             //      email : userInfo.email,
            //             //      nickname : userInfo.nickname
            //             //  })
            //             //  .then(res => {
            //             //     console.log(res);
            //             //     console.log("데이터베이스에 회원 정보가 있음!");
            //             //  })
            //             //  .catch(err => {
            //             //      console.log(err);
            //             //     console.log("데이터베이스에 회원 정보가 없음!");
            //             //  })
            //             // ===================================================================



            //             // 받아온 정보를 세션에 넣기위해 변환
            //             let session_userInfo = JSON.stringify(userInfo);

            //             // 세션에 받아온 정보 저장
            //             sessionStorage.setItem("userInfo", session_userInfo);

            //             // 저장 후 정보 가져오기
            //             let get_session_userInfo = sessionStorage.getItem("userInfo");

            //             // 정보 파싱
            //             let session_userInfo_parse = JSON.parse(get_session_userInfo);


            //             // 세션에 저장한 정보 가져오기
            //             // store state에 닉네임 정보 올리기
            //             store.dispatch('call_nickname', { user_nickname: session_userInfo_parse.nickname });

            //             // store state에 프로필 이미지 정보 올리기
            //             store.dispatch('call_profile', { user_profile: session_userInfo_parse.profile_image });


            //             // TEST CONSOLE ======================================

            //             // console.log(store.getters.getuserInfo);

            //             // ===================================================

            //             // alert("로그인 성공!");
            //             // this.$bvModal.hide("bv-modal-example");

            //             this.$router.push('/inference');
            //         },
            //         fail: error => {
            //             this.$router.push("/errorPage");
            //             console.log(error);
            //         }
            //     })
            // },
            //modal 보여지기 click
            // show_create_account_form() {
            //     this.$refs['signup-modal'].show();
            // },
            //modal 없애기 click
            // hide_create_account_form() {
            //     this.$refs['signup-modal'].hide();
            // },
            /* GreetingMessageToCreateAccount 모달 열개/없애기 */
            showAlarmModalInLoginPage() {
                this.$bvModal.show("alarmModalInLoginPage");
            },
            closeAlarmModalInLoginPage() {
                this.$bvModal.hide("alarmModalInLoginPage");
                this.title_in_alarm_modal = "";
                this.contents_in_alarm_modal = "";
            },
            closeSignUpModal() {
                this.$bvModal.hide("createAccountModal");
            }
        },
    }
</script>
<style scoped>
    .loginpage {
        display: flex;
        /*width: 1920px;*/
        /*height: 977px;*/
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: #F5F6FA;
        position: relative;
    }


    .loginpage_img {
        width: 44%;
        height: 80%;
        display: flex;
        flex-direction: column-reverse;
    }

    .loginpage_img img {
        width: 814px;
        height: 594px;
    }


    .card {
        width: 30%;
        height: 80%;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        /*transition: 0.3s;*/
    }

    .card-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .company_name {
        margin-top: auto;
    }

    .company_name label {
        font-size: 30px;
        font-weight: bold;
        color: #4E57AA;
    }

    .welcome_talk {
        /*width: 369px;*/
    }

    .welcome_talk span {
        color: #858585;
        font-size: 16px;
        font-weight: bold;
    }

    .form-signin {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
    }

    .card-body input {
        width: 400px;
        height: 60px;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 2px solid #eeeeee;
        font-size: 15px !important;
    }

    .remember-group {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        margin-left: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .checkbox-group label {
        margin-bottom: none !important;
        font-size: 13px;
    }

    .find-account {
        margin-left: auto;
        margin-right: 10px;
    }

    .find-account label {
        font-size: 13px;
    }

    .find-account label:hover {
        cursor: pointer;
        /*background: #eeeeee;*/
        color: #858585;
    }

    .login-button-group {
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .login-button-group button {
        width: 45%;
        height: 50px;
    }

    .signin-button {
        background: #4E57AA;
    }

    .signin-button:hover {
        background: #373E78;
    }

    /* Amplify + Cognito 로그인 부분 */

    .form-siginin__amplify-auth {
        width: 369px;
    }

    .amplify-auth__input-email,
    .amplify-auth__input-password,
    .amplify-auth__submit {
        width: 100% !important;
        height: 55.34px !important;
        padding: 5px 10px !important;
        border-radius: 7px !important;
        margin: 0;
    }

    .amplify-auth__input-email,
    .amplify-auth__input-password {
        placeholder-color: red;
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
    }

    .amplify-auth__input-email:focus,
    .amplify-auth__input-password:focus {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.5) !important;
    }

    .amplify-auth__input-password {
        margin-top: 10px !important;
    }

    .amplify-auth__input-email::placeholder,
    .amplify-auth__input-password::placeholder {
        opacity: 0.5;
    }

    .amplify-auth__submit {
        background-color: rgba(255, 107, 107, 0.7);
        font-size: 20px;
        color: #fff;
        border: none;
        margin-top: 10px !important;
    }

    .amplify-auth__submit:hover {
        background-color: rgba(255, 107, 107, 1);
    }

    .amplify-auth__submit:focus {
        outline: none;
    }

    #createModalBtn {
        background-color: transparent;
        color: #4e57aa;
        border: none;
        opacity: 0.9;
    }

    #createModalBtn:focus {
        outline: none;
        box-shadow: none;
    }

    #createModalBtn:hover {
        opacity: 1;
        font-weight: bold;
    }

    .create-account-box {
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        margin-top: 20px;
        padding-top: 10px;
    }

    /*게스트 로그인 버튼 ========*/

    .button_guest_login {
        background: #4E57AA;
        /*width: 90%;*/
        width: 369px;
        height: 55.34px;
        margin-top: 10px;
        font-size: 20px;
        border-radius: 7px;
        border: none;
    }

    .button_guest_login:hover {
        background: #373E78;
    }

    /*===========================*/

    /*.signin-button {*/

    /*    width: 300px;*/

    /*    height: 45px;*/

    /*    margin-top: 20px;*/

    /*    margin-bottom: 5px;*/

    /*}*/

    #kakaoLogin {
        /*width: 90%;*/
        width: 369px;
        height: 55.34px;
        cursor: pointer;
    }

    /*.card-body .signup-modal {*/

    /*width: 300px;*/

    /*height: 45px;*/

    /*margin-bottom: 5px;*/

    /*}*/

    .form-signup {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .modal-dialog input {
        width: 300px !important;
        height: 40px !important;
        margin: 5px;
    }

    /* 가입 환영 모달 헤더 */

    .alarmModalInLoginPage__header {
        height: 45px;
        background-color: #ff7675;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, .6);
        padding: 0 20px;
    }

    .alarmModalInLoginPage__header span {
        font-size: 22px;
        cursor: pointer;
    }

    .alarmModalInLoginPage__main {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        padding: 20px;
    }

    /*.signup-button {*/

    /*    width: 300px;*/

    /*    margin-bottom: 5px;*/

    /*}*/

    .modal-dialog .modal-footer {
        display: none !important;
    }

    .bottom_policy {
        margin-top: auto;
    }

    /* 1920 이상일 경우 */

    @media screen and (min-width: 1920px) {
        .loginpage {
            height: 100%;
            width: 100%;
        }

        .card {
            width: 30%;
            height: 80%;
            border-radius: 10px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            /*transition: 0.3s;*/
        }
    }

    /* 1024 이하일 경우 */

    @media screen and (max-width: 1024px) {
        .loginpage {
            width: 1024px;
            height: 768px;
        }

        .loginpage_img {
            width: 44%;
            height: 80%;
            display: flex;
            flex-direction: column-reverse;
        }

        .loginpage_img img {
            width: 100%;
            height: 90%;
        }

        .card {
            width: 35%;
            height: 80%;
            border-radius: 10px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            /*transition: 0.3s;*/
        }


        .welcome_talk label {
            font-size: 13px;
        }

        .form-siginin__amplify-auth {
            width: 300px;
        }

        .amplify-auth__input-email,
        .amplify-auth__input-password,
        .amplify-auth__submit {
            height: 45px !important;
        }
        #createModalBtn {
            font-size: 13px;
        }

        .button_guest_login {
            width: 300px;
            height: 45px;
        }
        .bottom_policy {
            font-size: 13px;
        }
    }
</style>
