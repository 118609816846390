<template>
    <!-- 테이블 컴포넌트 아웃풋 -->
    <div class="table_com">
        <!--테이블 헤더 부분 -->
        <div class="table_header">
            <label>범위</label>
            <input type="number" v-model="iris_min"/>
            <label>~</label>
            <input type="number" v-model="iris_max"/>
    	    <b-button variant="primary" @click="csv_file_reload">보기</b-button>
	    </div>
	    <!--테이블 헤더 부분 END-->
	    
	    <!-- 테이블 내용 -->
	    <b-table sticky-header :items="csv_iris_data" head-variant="light" class="iris_csv_table"></b-table>
	    <!-- 테이블 내용 END -->
	</div>
	<!-- 테이블 컴포넌트 아웃풋 END -->
</template>
<script>
    // VUEX =====================
    import store from '@/store';
    // ==========================

    export default {
        name: "IrisDataTable",
        data() {
            return {
                // 아이리스 MIN 값 지정
                iris_min: 1,
                // 아이리스 데이터 SORTING값(DEFAULT 지정) 
                iris_max: 5,
            }
        },
        mounted() {
            this.csv_file_load();
        },
        methods: {
            // 최초 컴포넌트 시작시 데이터 불러오기
            csv_file_load() {
                // PAPA PARSE 이용 하여 s3에 저장되어 있는 아이리스 데이터 가져오기
                this.$papa.parse('https://nocoding-ai.s3.ap-northeast-2.amazonaws.com/datasets/iris_csv/iris_data.csv', {
                    download: true,
                    // 아이리스 데이터 부르기 성공시 
                    complete: function(results) {
                        // console.log("Finished:", results.data);
                        // 배열 초기화
                        let arr = [];
                        // 아이리스 데이터 변수로 지정
                        let iris = results.data;

                        // 아이리스 최초 값 지우기 (0,1,2,3,4)
                        // iris[0].slice(1, 1);
                        // delete iris[0];


                        // 아이리스 데이터 랜덤 설정 
                        iris.sort(() => {
                            return Math.random() - Math.random();
                        })
                        // i 변경 // 범위 설정 
                        for (let i = 0; i < 5; i++) {
                            for (let j = 0; j < iris[i].length; j++) {
                                // 아이리스 종류 변수 초기화
                                let iris_kind = '';

                                // 아이리스 품종 데이터가 0 일때
                                if (iris[i][4] === "0") {
                                    iris_kind = 'Iris-setosa'
                                }
                                // 아이리스 품종 데이터가 1 일때
                                else if (iris[i][4] === "1") {
                                    iris_kind = 'Iris-versicolor'
                                }
                                // 아이리스 품종 데이터가 2 일때
                                else if (iris[i][4] === "2") {
                                    iris_kind = 'Iris-virginica'
                                }

                                // 아이리스 데이터 재구성
                                let arr_content = {
                                    "꽃받침 길이": Number(iris[i][0]),
                                    "꽃받침 넓이": Number(iris[i][1]),
                                    "꽃입 길이": Number(iris[i][2]),
                                    "꽃잎 넓이": Number(iris[i][3]),
                                    "품종 번호": Number(iris[i][4]),
                                    "품종 이름": iris_kind,

                                }
                                // 순서대로 재구성 데이터 지정
                                arr[i] = arr_content;
                            }
                        }
                        // 의미 없는 첫번째 값 제거
                        // 0 1 2 3 4
                        delete arr[0];

                        // 배열 정리 
                        arr.sort();

                        // 파파 파스 이용후 바로 반영이 안되는 문제가 존재 
                        // 임시로 vuex 사용하여 COMPUTED 이용하여 즉각 반영 이용
                        // 아이리스 데이터 배열 VUEX STORE로 디스패치 
                        store.dispatch('call_csv_iris_data', { csv_iris_data: arr });
                    }
                });
            },
            // 아이리스 데이터 리로드 // 범위 설정에 맞게 테이블을 조정
            csv_file_reload() {
                // 아이리스 범위 값 변수로 지정
                let data_count = this.iris_max;

                // PAPA PARSE 이용 하여 s3에 저장되어 있는 아이리스 데이터 가져오기
                this.$papa.parse('https://nocoding-ai.s3.ap-northeast-2.amazonaws.com/datasets/iris_csv/iris_data.csv', {
                    download: true,
                    // 아이리스 데이터 부르기 성공시 
                    complete: function(results) {

                        // 배열 초기화
                        let arr = [];
                        // 아이리스 데이터 변수로 지정
                        let iris = results.data;

                        // 아이리스 데이터 랜덤 설정 
                        iris.sort(() => {
                            return Math.random() - Math.random();
                        })
                        // i 변경 // 범위 설정 
                        for (let i = 0; i < data_count + 1; i++) {
                            for (let j = 0; j < iris[i].length; j++) {
                                // 아이리스 종류 변수 초기화
                                let iris_kind = '';

                                // 아이리스 품종 데이터가 0 일때
                                if (iris[i][4] === "0") {
                                    iris_kind = 'Iris-setosa'
                                }
                                // 아이리스 품종 데이터가 1 일때
                                else if (iris[i][4] === "1") {
                                    iris_kind = 'Iris-versicolor'
                                }
                                // 아이리스 품종 데이터가 2 일때
                                else if (iris[i][4] === "2") {
                                    iris_kind = 'Iris-virginica'
                                }

                                // 아이리스 데이터 재구성
                                let arr_content = {
                                    "꽃받침 길이": Number(iris[i][0]),
                                    "꽃받침 넓이": Number(iris[i][1]),
                                    "꽃입 길이": Number(iris[i][2]),
                                    "꽃잎 넓이": Number(iris[i][3]),
                                    "품종 번호": Number(iris[i][4]),
                                    "품종 이름": iris_kind,

                                }
                                // 순서대로 재구성 데이터 지정
                                arr[i] = arr_content;
                            }
                        }
                        // 의미 없는 첫번째 값 제거
                        // 0 1 2 3 4
                        delete arr[0];

                        // 배열 정리 
                        arr.sort();
                        // 파파 파스 이용후 바로 반영이 안되는 문제가 존재 
                        // 임시로 vuex 사용하여 COMPUTED 이용하여 즉각 반영 이용
                        // 아이리스 데이터 배열 VUEX STORE로 디스패치 
                        store.dispatch('call_csv_iris_data', { csv_iris_data: arr });
                    }
                });
            }
        },
        //
        computed: {
            csv_iris_data() {
                return store.getters.get_csv_iris_data
            }
        }
    }
</script>
<style scoped>
    /*테이블 컴포넌트 전체 지정*/

    .table_com {
        width: 100%;
        height: 100%;
        margin-left: 10px;
    }

    /*테이블 컴포넌트 헤더 부분 지정 */

    .table_header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    /*테이블 컴포넌트 헤더 라벨 부분 */

    .table_header label {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0px !important;
        margin-right: 5px;
    }

    /*테이블 컴포넌트 헤더 인풋 부분 */

    .table_header input {
        width: 50px;
        height: 25px;
        border-radius: 5px;
        margin-right: 5px;
    }

    /*테이블 컴포넌트 헤더 버튼 부분 */

    .table_header button {
        height: 30px;
        font-size: 15px;
    }

    .b-table-sticky-header {
        width: 98%;
        /*height: 95%;*/
        height: 200px;
        overflow: none;
        font-size: 13px;
    }
</style>
