import * as tf from '@tensorflow/tfjs';
import * as tfvis from '@tensorflow/tfjs-vis';

import { classNames } from '@/ncai-core/tfjs_data.js'
import sampleModelTopology from '@/ncai-core/sampleTopologys/modeltopology_sample.json'
import sampleModelTopology2 from '@/ncai-core/sampleTopologys/modeltopology_sample2.json'
import irisModelTopology from '@/ncai-core/sampleTopologys/modeltopology_iris.json'

import * as ncai_data from '@/ncai-core/ncai_data.js'
import * as ncai_layer from '@/ncai-core/ncai_layer.js'
import * as ncai_model from '@/ncai-core/ncai_model.js'

import * as ncai_tutor1 from '@/ncai-core/tutorials/ncai_tutor1.js' //선형회귀..
import * as ncai_tutor2 from '@/ncai-core/tutorials/ncai_tutor2.js' //mnist

export function test() {
    console.log("ncai ==> test()");
    setModel(irisModelTopology);
    //ncai_tutor1.predictFromData2D();
    //ncai_tutor2.run();
    //console.log("==========tfjs_model.js=============", sampleModelTopology);
    // console.log("MEMORY:", tf.memory());
    //var model = setModel(irisModelTopology);
    //const surface = { name: '서머리', tab: 'Model Inspection'};
    // tfvis.show.layer(surface, model.getLayer(undefined, 1));
    //tfvis.show.modelSummary(surface, model);
    // setModel(sampleModelTopology2);
    // setModel(irisModelTopology);
    // console.log("MEMORY:", tf.memory());
    /*
    const input1 = tf.input({ shape: [2, 2] });
    const input2 = tf.input({ shape: [2, 3] });
    var x, x2;
    x = tf.layers.dense({ units: 32 }).apply(input1);
    x2 = tf.layers.dense({ units: 32 }).apply(x);
    x2 = tf.layers.dense({ units: 32 }).apply(x2);
    x2 = tf.layers.dense({ units: 32 }).apply(x2);
    x2 = tf.layers.dense({ units: 32 }).apply(x2);
    const model = tf.model({ inputs: input1, outputs: x2 });
    console.log(model.summary());
    
    const input1 = tf.input({ shape: [10] });
    const input2 = tf.input({ shape: [20] });
    const dense1 = tf.layers.dense({ units: 4 }).apply(input1);
    const dense2 = tf.layers.dense({ units: 8 }).apply(input2);
    const concat = tf.layers.concatenate().apply([dense1, dense2]);
    const output =
        tf.layers.dense({ units: 3, activation: 'softmax' }).apply(concat);
    const output2 =
        tf.layers.dense({ units: 4, activation: 'softmax' }).apply(concat);

    const model = tf.model({ inputs: [input1, input2], outputs: [output, output2] });
    model.summary();
    console.log(model.summary());
    const saveResult = model.save('localstorage://tfjs-sample-model-1');
    */

}

function sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
}


export async function setModel(modelTopology) {
    console.log("modelTopology:", modelTopology);

    var tf_layers = ncai_model.getTfLayers(modelTopology);
    const { input_layer_names, output_layer_names } = ncai_model.getInOutputLayerNamesFromInfo(modelTopology);
    const forwardNodeStructure = ncai_model.getNodeStructure(modelTopology,output_layer_names);
    ncai_model.applySymbolicTensorLayer(forwardNodeStructure, modelTopology, tf_layers);
    const model = tf.model({ inputs: ncai_model.getLayers(tf_layers,input_layer_names), outputs: ncai_model.getLayers(tf_layers,output_layer_names) });
    
    console.log(model.summary());
    return model;
}
