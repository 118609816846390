import * as tf from '@tensorflow/tfjs';

import store from "@/store";


const IMAGE_SIZE = 50176;
const NUM_CLASSES = 10;
const NUM_DATASET_ELEMENTS = 1;



export class MobileData {
    constructor() {
        let datasetImages = null;
    }

    async load(_this, model, image_path) {
        // console.log('@@@');

        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const imgRequest = new Promise((resolve, reject) => {
            img.crossOrigin = '';

            img.onload = () => {
                img.width = img.naturalWidth;
                img.height = img.naturalHeight;
                const datasetBytesBuffer =
                    new ArrayBuffer(IMAGE_SIZE * 3);
                const chunkSize = 1;
                canvas.width = 224;
                canvas.height = 224;
                let datasetBytesView = new Float32Array(IMAGE_SIZE * 3);
                ctx.drawImage(img, 0, 0, 224, 224);
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
                const bcolor = 255;

                for (let j = 0; j < imageData.length / 4; j++) {
                    let a = imageData[j * 4];
                    let r = imageData[j * 4 + 1] / 255;
                    let g = imageData[j * 4 + 2] / 255;
                    let b = imageData[j * 4 + 3] / 255;

                    datasetBytesView[j * 3] = r;
                    datasetBytesView[j * 3 + 1] = g;
                    datasetBytesView[j * 3 + 2] = b;
                }

                const xs = tf.tensor2d(datasetBytesView, [1, IMAGE_SIZE * 3]);
                this.predictiontest(model, xs);

            };
            img.src = image_path;

        });

    }


    async predictiontest(model, xs) {
        // const bmodel = this.model;
        const IMAGE_WIDTH = 224;
        const IMAGE_HEIGHT = 224;
        // const testData = this.imgArray();
        const testxs = xs.reshape([
            1,
            IMAGE_WIDTH,
            IMAGE_HEIGHT,
            3
        ]);
        // console.log(testxs);
        console.log('result');
        const rt = await model.predict(testxs).reshape([1001]);
        // console.log(rt);
        const argmax = await rt.argMax().data();
        console.log(argmax[0]);

        store.dispatch('call_max_value_name', { max_value_name: argmax[0] });
        var data = ''
        Promise.resolve(rt.array()).then(value => {
            console.log('value:', value[argmax[0]]);

            store.dispatch('call_max_value_percentage', { max_value_percentage: value[argmax[0]] });

            data = value;
        })

    }



    indexofMax(arr) {
        if (arr.length === 0) {
            return -1;
        }

        var max = arr[0];
        var maxIndex = 0;

        for (var i = 1; i < arr.length; i++) {
            if (arr[i] > max) {
                maxIndex = i;
                max = arr[i];
            }
        }

        return maxIndex;
    }

}
