<template>
	<!--웹캠 컴포넌트 -->
	<div class="webcam_outer">
		<div class="webcam_com">
			<div class="video_part">
				<canvas ref="canvas" id="output" style=""></canvas>
    			<video ref="video" id="video" playsinline autoplay  @canplaythrough="show_webcam()">
				</video>
			</div>
			<div class="webcam_result">
				<!--웹캠 시작 버튼-->
				<!--실행-->
				<b-button
					@click="onStart()"
					variant="primary">{{ start_webcam }}</b-button>
				<!--웹캠 중지 버튼-->
				<!--중지-->
				<b-button
					@click="onStop()"
					variant="primary">{{ stop_webcam }}</b-button>
			</div>
		</div>
	</div>
	<!--웹캠 컴포넌트 END-->
</template>
<script>
	// 언어 설정 JSON 파일 가져오기 ===============
	import korean from '@/language/korean.json';
	import english from '@/language/english.json';
	// ============================================

	// 변수 전역 설정 
	let video_webcam;
	let canvas_webcam;

	export default {
		name: 'webcam_com',
		data() {
			return {
				video: {},
				canvas: {},
				captures: [],
				play_capture: null,

				// 다국어 설정
				start_webcam: '',
				stop_webcam: '',

				// 언어 설정
				localization: ''

			};
		},
		mounted() {
			/* global navigator */
			console.log("mounted");
			// 전역 설정
			video_webcam = this.$refs.video;

			this.video = this.$refs.video;

			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices
					.getUserMedia({
						video: true,
					})
					.then((stream) => {
						this.video.srcObject = stream;
					});
			}

			// 브라우저 언어 설정 받아오기
			let locale = navigator.language || navigator.userLanguage;

			// 언어 설정 
			this.localization = locale.substring(0, 2);

			// 언어 설정 테스트
			// this.localization = 'en';

			// 언어에 따라서 분기
			switch (this.localization) {
				case 'ko':
					this.start_webcam = korean.webcam_comp.start_webcam;
					this.stop_webcam = korean.webcam_comp.stop_webcam;
					return;
				case 'en':
					this.start_webcam = english.webcam_comp.start_webcam;
					this.stop_webcam = english.webcam_comp.stop_webcam;
					return;
			}


		},
		methods: {
			show_webcam() {
				// 웹캠 스타트
				console.log("show_webcam");

				this.canvas = this.$refs.canvas;

				// 전역 설정
				canvas_webcam = this.canvas;

				this.canvas.width = 350;
				this.canvas.height = 350;

				let ctx = this.canvas.getContext('2d');
				ctx.clearRect(0, 0, 350, 350);
				ctx.strokeStyle = 'green';
				ctx.fillStyle = 'red';

				//미러 이미지로...
				ctx.translate(this.canvas.width, 0);
				ctx.scale(-1, 1);

				function draw_video_to_canvas() {
					// stats.begin();
					ctx.drawImage(video_webcam, 80, 0, 480, 480, 0, 0, 350, 350);
					// stats.end();
					const rafID = requestAnimationFrame(draw_video_to_canvas);
				};

				draw_video_to_canvas();
			},
			onStart() {
				//  콘솔 확인
				console.log("onStart click!");

				// 웹캠 스타트 버튼 이벤트 전달
				this.$emit('startBtClicked');

				// 실행 버튼 클릭시 0.5초 간격으로 인터벌을 두고 사진을 캡쳐 하기
				// this.play_capture = setInterval(() => {
				// 	this.capture();
				// }, 1000);
			},
			onStop() {
				// 콘솔 확인 
				console.log("onStop Click!");

				// 웹캠 스타트 버튼 이벤트 전달
				this.$emit('stopBtClicked');

				// 중지 버튼 클릭시 0.5초 인터벌 캡쳐 중지
				clearInterval(this.play_capture);
			},
			capture() {
				// 배열 초기화
				this.captures = [];
				// 캡처된 파일을 BASE64로 변환시키는 부분
				this.captures.push(canvas_webcam.toDataURL('image/png'));

				// BASE64를 sessionStrorage에 저장하는 부분
				let capture_image = this.captures[0];


				sessionStorage.setItem('session_Inference_Image', capture_image);

				// 웹캠 이미지 캡쳐 이벤트 전달
				this.$emit('capture');
			},

			// HAND POSE를 그려줄 캔버스 태그 내보내기
			getVideoCapture() {
				return canvas_webcam;
			}
		},
		destoryed() {
			console.log("webcamcomp destroyed")
		}
	};
</script>
<style scoped>
	.webcam_outer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.webcam_com {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.video_part {
		width: 350px;
		height: 350px;

	}

	.crop_line {
		width: 150px;
		height: 150px;
		border: 3px dashed #eeeeee;
		border-radius: 10px;
		position: relative;
		z-index: 2;
		top: 100px;
		left: 100px;
	}

	#video {
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
		visibility: hidden;
		/*border: 1px solid;*/
		/*background: #f5f6fa;*/
		background: #000000;
		/*border-radius: 10px;*/
		/*box-shadow: 1px 1px 2px #ddd;*/
		width: 640px;
		height: 480px;
		position: absolute;
		/*bottom: 150px;*/
		/* transform: rotateY(180deg); */
		/* -webkit-transform: rotateY(180deg); */
		/* Safari and Chrome */
		/* -moz-transform: rotateY(180deg); */
		/* Firefox */
	}

	#output {
		background: #f5f6fa;
		border-radius: 10px;
		box-shadow: 1px 1px 2px #ddd;
		width: 350px;
		height: 350px;
		position: absolute;

		/* transform: rotateY(180deg); */
		/* -webkit-transform: rotateY(180deg); */
		/* Safari and Chrome */
		/* -moz-transform: rotateY(180deg); */
		/* Firefox */
	}

	.webcam_result {
		width: 350px;
		height: 36px;
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
	}

	/* .capture_list {
		display: none;
		width: 50%;
		height: 40px;
		margin: 10px;
		border: 1px solid #17a2b8;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	} */

	.webcam_result button {
		width: 100px;
		height: 35px;
		margin-left: 5px;
	}

	.video_part .card-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	#canvas {
		display: none;
		/* transform: rotateY(180deg); */
		/* -webkit-transform: rotateY(180deg); */
		/* Safari and Chrome */
		/* -moz-transform: rotateY(180deg); */
		/* Firefox */
	}

	#capimage {
		background: #ffffff;
		width: 35px;
		height: 35px;
		/* transform: rotateY(180deg); */
		/* -webkit-transform: rotateY(180deg); */
		/* Safari and Chrome */
		/* -moz-transform: rotateY(180deg); */
		/* Firefox */
	}
</style>
