<template>
    <!--부트스트랩 상단 네비게이션 바 부분-->
    <b-navbar class="topnavbar">
        <!-- 프로그램 및 회사 이름 -->
        <div class="company-name">
            <b-navbar-brand class="m-auto" href="#">nocodingAI v0.7.1</b-navbar-brand>
        </div>
        <!-- 워크스페이스 이름 부분-->
        <div class="project-name">
            <div class="project_name_inner" @click="moveToWorkspace">WORKSPACE</div>
            <div class="project_name_inner">/</div>
            <div class="project_name_inner" @click="moveToTrainModel">임시 프로젝트</div>
    
            <!--페이지 이동전 확인 alert 모달 -->
            <b-modal ref="check_beforeMove_modal" hide-header hide-footer centered size="sm" body-class="p-0">
                    <div class="beforeMove_header">
                        <span>주의!</span>
                        <span class="train-modal__close-btn" @click="stay_page">
            				<b-icon icon="x" class="close_btn"></b-icon>
            			</span>
                    </div>
                    <div class="beforeMove_content">
                        <label>페이지를 이동하시겠습니까?</label>
                        <div class="beforeMove_buttonGroup">
                            <b-button class="move_btn" @click="move_page">이동하기</b-button>
                            <b-button class="stay_btn" @click="stay_page">닫기</b-button>
                        </div>
                    </div>                    
            </b-modal>
    
            <!-- breadcrumb test -->
            <!--<b-breadcrumb :items="location_items"></b-breadcrumb>-->
    
            <!--이전 navbar_brand-->
            <!--<b-navbar-brand class="ml-3 mr-3" href="/#/workspaces">WORKSPACE</b-navbar-brand>-->
            <!--진행중인 프로젝트 이름 부분-->
            <!--/-->
            <!--<b-navbar-brand class="ml-3 mr-3" href="/#/trainmodel">임시 프로젝트</b-navbar-brand>-->
        </div>
        <!--상단 네비게이션 바 우측 알람 및 로그인 정보 부분-->
        <div class="header-right ml-md-auto">
            <!--알람 아이콘 부분-->
            <b-icon icon="bell" class="bell-icon" />
            <hr>
            <!-- 로그인 정보 및 로그아웃 드롭다운 부분-->
            <b-dropdown id="login_inform_dropdown" variant="outline" :text="user_nick +'님'">
                <b-dropdown-item-button>Settings</b-dropdown-item-button>
                <b-dropdown-item-button>Profiles</b-dropdown-item-button>
                <b-dropdown-item @click="implementLogout">Logout</b-dropdown-item>
            </b-dropdown>
            <!-- 유저 이미지 -->
            <b-avatar id="avatar" :src="user_img"></b-avatar>
        </div>
    </b-navbar>
    <!--부트스트랩 상단 네비게이션 바 부분 end-->
</template>
<script>
    // 언어 설정 JSON 불러오기 
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    import Logo from '@/assets/favicon.png'
    import { Auth } from "aws-amplify";

    export default {
        name: 'topnavbar',
        data() {
            return {
                user_nick: '',
                user_img: '',

                // 언어 설정
                localization: '',

                move_location: '',

                // location_items: [{
                //         text: 'WORKSPACE',
                //         href: '/#/workspaces'
                //     },
                //     {
                //         text: '임시 프로젝트',
                //         href: '/#/trainmodel'
                //     },
                // ]

            }
        },
        methods: {

            // 워크스페이스로 이동
            moveToWorkspace() {
                this.$refs['check_beforeMove_modal'].show();

                this.move_location = 'move_to_workspace';
            },
            // 모델 만들기로 이동            
            moveToTrainModel() {
                this.$refs['check_beforeMove_modal'].show();
                this.move_location = 'move_to_trainmodel';
            },
            // 페이지 이동
            move_page() {
                this.$refs['check_beforeMove_modal'].hide();

                switch (this.move_location) {
                    case 'move_to_workspace':
                        this.$router.push('workspaces');
                        break;
                    case 'move_to_trainmodel':
                        this.$router.push('trainmodel');
                        break;
                }
            },
            // 페이지 머무르기
            stay_page() {
                this.$refs['check_beforeMove_modal'].hide();
                this.move_location = '';
            },

            implementLogout() {
                const signOut = async() => {
                    try {
                        await Auth.signOut();
                    }
                    catch (error) {
                        console.log(`에러 코드[${error.code}]: ${error.message}`);
                    }
                    finally {
                        sessionStorage.removeItem("userInfo");
                        sessionStorage.removeItem("current_structure");
                        sessionStorage.removeItem('guest_rawdata_list');
                        this.$router.push('/');
                        this.is_logged_on = false;
                    }
                }

                signOut();
            },

            // 로그아웃 
            onLogout() {
                // 로그아웃 콘솔 체크
                // console.log("onlogout button click!!");

                // 세션에 저장된 로그인 정보를 지우고 첫 로그인 페이지로 이동 
                // 추후 바뀔 부분
                sessionStorage.removeItem('userInfo');
                // 로그아웃 // 카카오 로그아웃 부분 체크 해주어야 함!
                // window.Kakao.Auth.logout(() => {

                    // 액세스토큰 제거 체크
                    // 토큰이 지워지면 null 값이 됨
                    // console.log(window.Kakao.Auth.getAccessToken());
                // });

                // port 80 ==================================
                // location.href = "https://www.learning-machine.net/"
                // ==========================================

                /* global location */
                // port 8080 ~ ==============================
                location.href = "https://0a0d987bee834676bbb4489ec850b424.vfs.cloud9.ap-northeast-2.amazonaws.com/#/"
                // ==========================================

                // alph ==========================================
                // location.href = "https://alpha.nocodingai.com"
                // ===============================================

                // test ==========================================
                // location.href = "https://test.nocodingai.com"
                // ===============================================

            }
        },
        mounted() {
            // 사용자 정보 가져오기
            let get_session_userInfo = sessionStorage.getItem("userInfo");

            let session_userInfo_parse = JSON.parse(get_session_userInfo);

            // 화면에 띄우기
            this.user_nick = session_userInfo_parse.nickname;
            this.user_img = session_userInfo_parse.profile_image;
        },
    }
</script>
<style scoped>
    /*상단 네비게이션 바 사이즈 지정*/

    .topnavbar {
        /*border-bottom: 1px solid #555555;*/
        background: #252746;
        /* fallback for old browsers *
        height: 60px;
        /* 포지션 지정*/
        height: 60px;
        position: relative;
        padding: 10px 20px;
        z-index: 1000;
    }

    img {
        width: 20px;
        height: 20px;
    }



    .company-logo {
        margin-left: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo {
        /*width: 67px;*/
        height: 30px;
    }

    /*회사 이름 부분 사이즈 지정*/

    .company-name {
        width: 10%;
        margin-left: 10px;
    }

    /* 링크 */

    .company-name a {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #fff !important;
    }

    /* 프로젝트 이름 부분 사이즈 지정*/

    .project-name {
        margin-left: 50px;
        display: flex;
        align-items: center;
        color: #7A7B85 !important;
    }

    .project_name_inner {
        font-size: 15px;
        font-style: solid;
        font-weight: bold;
        color: #7A7B85 !important;
        margin-left: 30px;
        background-color: none !important;
        cursor: pointer;
    }


    .beforeMove_header {
        height: 50px;
        background: rgba(81, 45, 168, 1);
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 0 30px;
    }

    .beforeMove_header .close_btn {
        width: 25px;
        height: 25px;
        cursor: pointer;
    }

    .beforeMove_content {
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .beforeMove_buttonGroup {
        display: flex;
    }

    .beforeMove_buttonGroup button {
        height: 30px;
        width: 80px;
        font-size: 13px;
        color: #ffffff;
        background: rgba(119, 132, 251, 0.7);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 12px;
        border: none;
        margin-top: 10px;

    }

    .stay_btn {
        margin-left: 20px;
    }

    /* 네비게이션 우측 사이즈 지정*/

    .header-right {
        display: flex;
        align-items: center;
    }



    .header-right-icons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 200px;
        margin-right: 30px;
    }

    .header-right-icons .searchicon label {
        margin-left: 5px;
    }

    .header-right-icons label {
        margin-bottom: 0 !important;
        font-size: 13px;
        color: #7A7B85;
    }

    .header-right-icons .b-icon.bi {
        color: #7A7B85;
    }

    .header-right hr {
        border: none;
        border-left: 1px solid #BCBCCB;
        height: 30px;
        width: 1px;
        margin-right: 30px;
        background: #BCBCCB;
    }

    .header-right .b-dropdown .btn {
        height: 40px;
        background-color: #252746;
        border: none;
        color: #fff;
        font-size: 13px;
    }

    .bell-icon {
        margin-right: 10px;
    }

    .header-right .b-dropdown .btn .show {
        height: 40px;
        background-color: #ffffff;
        border: none;
        color: #BCBCCB;
    }

    #avatar {
        margin: 10px 10px 10px 10px;
        width: 35px !important;
        height: 35px !important;
    }
</style>
