<template>
    <div class="DefaultMFASHIONVueControl">
      	<label>종류</label>
	    	<select v-model="kinds_selected">
		    	<option v-for="option in kinds_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>크기</label>
	    	<select v-model="size_selected">
		    	<option v-for="option in size_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>색상</label>
	    	<select v-model="color_selected">
		    	<option v-for="option in color_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>개수</label>
	    	<select v-model="amount_selected">
		    	<option v-for="option in amount_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
	    <label>현재 차원</label>
	    	<select v-model="current_dim_selected">
		    	<option v-for="option in current_dim_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';

    export default {
        name: 'DefaultMFASHIONVueControl',
        data() {
            return {
                kinds_selected: 'image',
                size_selected: '28X28',
                color_selected: 'gray',
                amount_selected: '65000',
                current_dim_selected: '(28,28,1)',
                kinds_options: [
                    { text: '이미지', value: 'image' },
                    { text: '텍스트', value: 'text' },
                    { text: '음성', value: 'voice' }
                ],
                size_options: [
                    { text: '224X224', value: '224X224' },
                    { text: '150X150', value: '150X150' },
                    { text: '32X32', value: '32X32' },
                    { text: '28X28', value: '28X28' },
                    { text: '16X16', value: '16X16' }
                ],
                color_options: [
                    { text: '컬러(RGB)', value: 'rgb' },
                    { text: '흑백(gray)', value: 'gray' },
                ],
                amount_options: [
                    { text: '65000', value: '65000' },
                    { text: '60000', value: '60000' },
                    { text: '50000', value: '50000' },
                    { text: '40000', value: '40000' },
                    { text: '30000', value: '30000' },
                    { text: '20000', value: '20000' },
                    { text: '10000', value: '10000' },

                ],
                current_dim_options: [
                    { text: '(224,224,3)', value: '(224,224,3)' },
                    { text: '(28,28,1)', value: '(28,28,1)' }
                ],
            }
        },
        methods: {},
    }
</script>

<style scoped>
    .DefaultMFASHIONVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .DefaultMFASHIONVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .DefaultMFASHIONVueControl select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    .DefaultMFASHIONVueControl button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        text-align: center;
        line-height: 12px;
        border: none;
    }
</style>
