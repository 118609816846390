<template>
    <div class="datapreprocess-container">
        <!--<b-button variant="primary" @click="showDataPreprocessModal">설정</b-button>-->

        <!--<b-modal ref="data_preprocess_modal" id="dataProcessEditModal" body-class="p-0" hide-header hide-footer centered size="xl">-->
        <!--    <DataPreprocessEdit v-on:close_data_preprocess_edit="hideDataPreprocessModal" :editor-data="editor_data" :getNode="getNode"/>-->
        <!--</b-modal>-->
    </div>
</template>

<script>
    import Rete from "rete";
    import { numSocket } from '../sockets';

    import DataPreprocessEdit from './DataPreprocessModalEditor/DataPreprocessModalEditor';
    import DataPreprocessorSelect from './DataPreprocessorSelectModal/DataProprocessorSelectModal';

    export default {
        name: 'data_preprocess',
        data() {
            return {
                editor_data: this.getData("editor_data"),
                input_data: this.getData("input_data"),
                
                // 컬랩스 부분 상태
                datapreprocess_advance_visible: false,
                datapreprocess_advance_icon: 'plus', 
            }
        },
        props: ['readonly', 'emitter', 'getData', 'putData', 'getNode'],
        components: {
            DataPreprocessEdit,
            DataPreprocessorSelect
        },
        methods: {
            showDataPreprocessModal() {
                this.$refs['data_preprocess_modal'].show();
            },
            hideDataPreprocessModal(modal_editor_data) {
                /* ================================함수 설정 부분=================================*/
                /* addedInputs / addedOutputs: 입/출력 value 값들을 배열 형태로 리턴 */
                const input_data = (node) => {
                    return Array.from(node.inputs.values()).map(i => ({ key: i.key, name: i.name }));
                };
                const output_data = (node) => {
                    return Array.from(node.outputs.values()).map(i => ({ key: i.key, name: i.name }));
                };
			    /*=================================================================================*/
			    
                this.$refs['data_preprocess_modal'].hide();
                this.putData("editor_data", modal_editor_data);
                this.editor_data = modal_editor_data;
                
                const nodes = Object.values(this.editor_data.nodes);
                
                // 커넥션 초기화
                this.getNode().inputs.forEach(i => {
					i.connections.forEach(c => {
						this.emitter.removeConnection(c);
					});
				});
				
				this.getNode().outputs.forEach(o => {
					o.connections.forEach(c => {
						this.emitter.removeConnection(c);
					});
				});
                
                /* 인풋과 아웃풋 제거 */
                this.getNode().inputs.clear();
                this.getNode().outputs.clear();
                
                
                /* editor_data의 인풋, 아웃풋 개수에 따라 현재 노드(데이터 전처리)에 입/출력 갯수 증가 */
                nodes.forEach((node, idx) => {
                   if(node.name === "Input"){
                       const input_id = this.getNode().inputs.size+1;
                       const input = new Rete.Input(`preprocess_input${input_id}`, "입력", numSocket, true);
                       this.getNode().addInput(input);
                   }
                   else if(node.name === "Output"){
                       const output_id = this.getNode().outputs.size+1;
                       const output = new Rete.Output(`preprocess_output${output_id}`, "출력", numSocket, false);
                       this.getNode().addOutput(output);
                   }
                });
                
                /* 처음 렌더링 받을 때,  input_data, output_data 에 주제에 맞게 입 출력 객체를 저장함*/
                this.getNode().data.input_data = input_data(this.getNode());
                this.getNode().data.output_data = output_data(this.getNode());
                this.getNode().update();
                
                const whole_data = this.emitter.toJSON();
                console.log(whole_data);
                console.log(this.getNode());
                sessionStorage.setItem('current_structure', JSON.stringify(whole_data));
            },
            
            // 컬랩스 부분 메서드 // 아이콘 변경 // 컬랩스 visible
            datapreprocess_advance_click() {
                this.datapreprocess_advance_visible = !this.datapreprocess_advance_visible;
                if(this.datapreprocess_advance_visible === true) {
                    this.datapreprocess_advance_icon = 'dash';
                }else {
                    this.datapreprocess_advance_icon = 'plus';
                }
            },
        },

        computed: {
            // 연결된 노드에서 전달된 데이터를 출력 형식에 맞게 변환하는 속성
            output_data() {
                if (this.input_data) {
                    // return "(" + this.input_data.data + ", " + this.input_data.size + ", " + this.input_data.INPUT_CHANNELS + ")";
                    // this.input_data array 변환되어서 수정함
                    return "(" + this.input_data[0].data + ", " + this.input_data[0].size + ", " + this.input_data[0].INPUT_CHANNELS + ")";
                }
                else {
                    return '';
                }
            },

        },
    }
</script>

<style scoped>
    .datapreprocess-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .datapreprocess-container button{
        width: 180px !important;
        height: 25px !important;
        font-size: 13px !important;
        color: #ffffff !important;
        line-height: 12px !important;
        background: rgba(119, 132, 251, 0.7) !important;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
        border:none;
    }
    
    .datapreprocess-container input{
        width: 180px !important;
        border-radius: 0;
    }
    

    .datapreprocess_advance {
        width: 100%;
        height: 22px;
        padding-top: 6px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        /*border-top: 1px solid #7784FB;*/
    }
    
    .datapreprocess-advance button{
        margin-top: 5px;
        height: 25px;
        width: 180px;
        font-size: 13px;
        color: #ffffff;
        background: rgba(119, 132, 251, 0.7);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 12px;
        border: none;
    }
</style>