<template>
    <!-- MNIST DATA INPUT -->
    <div class="DefaultMNISTVueControl">
        <div class="DefaultMNISTVueControl__option d-flex flex-column mb-4">
        	<label class="DefaultMNISTVueControl__label mb-1">크기</label>
    	    <select v-model="size_selected">
    		   	<option v-for="option in size_options" v-bind:value="option.value">
    		    	{{ option.text }}
    		    </option>
    	    </select>
        </div>
        
        <div class="DefaultMNISTVueControl__option d-flex flex-column mb-4">
        	<label class="DefaultMNISTVueControl__label mb-1">색상</label>
    	    <select v-model="color_selected">
    		   	<option v-for="option in color_options" v-bind:value="option.value">
    		    	{{ option.text }}
    		    </option>
    	    </select>
        </div>
        
        <div class="DefaultMNISTVueControl__option d-flex flex-column mb-4">
    	   	<label class="DefaultMNISTVueControl__label mb-1">훈련/테스트 비율</label>
            <b-form-input id="range_slide" v-model="train_test_ratio" type="range" min="0" max="100"></b-form-input>
            
            <b-form-group class="range_num position-relative">
                <b-form-input class="w-100 mt-0" id="range_number" type="number" v-model="train_test_ratio">
                </b-form-input>
                <div class="position-absolute" style="top:50%; transform: translateY(-50%); right: 5px; font-size:12px; ">%</div>
            </b-form-group>
        </div>
        
	</div>
	<!-- MNIST DATA INPUT -->
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';

    export default {
        name: 'DefaultMNISTVueControl',
        data() {
            return {
                size_selected: '',
                color_selected: '',
                size_options: [
                    { text: '224X224', value: '224X224' },
                    { text: '150X150', value: '150X150' },
                    { text: '32X32', value: '32X32' },
                    { text: '28X28', value: '28X28' },
                    { text: '16X16', value: '16X16' }
                ],
                color_options: [
                    { text: '컬러(RGB)', value: 'rgb' },
                    { text: '흑백(gray)', value: 'gray' },
                ],
            }
        },
        updated() {
            // 데이터 사이즈
            // console.log("Mnist data input updated console");

            let size = this.size_selected;
            let color = this.color_selected;

            // console.log(size, color);

            store.dispatch('call_data_size', { data_size: size });

            // 데이터 색상
            store.dispatch('call_data_color', { data_color: color });

        },
        mounted() {
            this.size_selected = '28X28';
            this.color_selected = 'gray';
            // this.train_test_ratio = 10;

            // store.dispatch('call_data_size', { data_size: '28X28' });
            // store.dispatch('call_data_color', { data_color: 'gray' });

            // MNIST DEFAULT 값
            store.dispatch('call_train_test_ratio', { train_test_ratio: 10 });
        },
        computed: {
            // vuex 상태관리로 train test 비율 Check
            train_test_ratio: {
                get() {
                    // 이미지 개수 에서 훈련 테스트 할 데이터 이미지 비율
                    let t_t_ratio = store.getters.get_train_test_ratio;

                    // 데이터 셋
                    let dataset_num = store.getters.get_dataset_num;

                    // 계산할 값으로 변환
                    let ratio_value = t_t_ratio * 0.01;

                    // 계산된 셋팅 값
                    let train_test_set = dataset_num * ratio_value;

                    // Train Set 값 (8)
                    let trainlabels_num = Math.round(train_test_set * 0.8);

                    // Test Set 값 (2)
                    let testlabels_num = Math.round(train_test_set * 0.2);

                    // vuex store에 등록 (훈련데이터 값)
                    store.dispatch("call_trainlabels_num", { trainlabels_num: trainlabels_num });
                    // vuex store에 등록 (테스트데이터 값)
                    store.dispatch('call_testlabels_num', { testlabels_num: testlabels_num });

                    return t_t_ratio

                },
                set(ratio) {
                    let t_t_ratio = ratio;

                    // 이미지 개수 에서 훈련 테스트 할 데이터 이미지 비율
                    store.dispatch("call_train_test_ratio", { train_test_ratio: t_t_ratio });

                    // 데이터 셋
                    let dataset_num = store.getters.get_dataset_num;


                    // 계산할 값으로 변환
                    let ratio_value = t_t_ratio * 0.01;

                    // 계산된 셋팅 값
                    let train_test_set = dataset_num * ratio_value;

                    // Train Set 값 (8)
                    let trainlabels_num = Math.round(train_test_set * 0.8);
                    // console.log(trainlabels_num)

                    // Test Set 값 (2)
                    let testlabels_num = Math.round(train_test_set * 0.2);
                    // console.log(testlabels_num)

                    // vuex store에 등록 (훈련데이터 값)
                    store.dispatch("call_trainlabels_num", { trainlabels_num: trainlabels_num });
                    // vuex store에 등록 (테스트데이터 값)
                    store.dispatch('call_testlabels_num', { testlabels_num: testlabels_num });

                    return t_t_ratio
                }
            }
        }
    }
</script>

<style scoped>
    /* MNIST DATA INPUT 전체 지장 */

    .DefaultMNISTVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    /* MNIST DATA INPUT 전체 라벨  */

    .DefaultMNISTVueControl label {
        font-size: 10px !important;
        text-align: left;
    }

    /* MNIST DATA INPUT 전체 셀렉트, 인풋 */

    .DefaultMNISTVueControl select,
    input {
        border-radius: 5px !important;
        width: 180px !important;
        box-shadow: none;
        border-color: rgba(0, 0, 0, 0.22) !important;
    }

    /*슬라이드 인풋 조절 */

    #range_slide {
        border: none !important;
        background: none !important;
        margin-bottom: 5px;
        box-shadow: none !important;
    }

    /*슬라이드 표시 인풋*/

    .range_num {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /*슬라이드 표시 라벨*/

    .range_num label {
        font-size: 10px !important;
        margin-left: 3px;
    }
    

    /*슬라이드 인풋 */

    #range_number {
        /*width: 160px !important;*/
        height: 25px !important;
    }
</style>
