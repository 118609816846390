import Rete from 'rete';
import { numSocket } from '../../sockets';
import DefaultMFASHIONControl from '../../controls/basic-controls/DefaultMFASHIONControl';

export default class DefaultMFASHIONComponent extends Rete.Component {
	constructor() {
		super("데이터입력(MNIST_FASHION)");
	}

	builder(node) {
		let out1 = new Rete.Output("output1", "value", numSocket);

		return node
			.addControl(new DefaultMFASHIONControl(this.editor, "num"))
			.addOutput(out1);
	}
	worker(node, inputs, outputs) {
		outputs["num"] = node.data.num;
	}
}
