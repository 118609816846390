import Rete from "rete";
import { numSocket } from '../../sockets';
import DataOutputControl from '../../controls/DataOutputControl';

export default class OutputComponent extends Rete.Component {
	constructor() {
		super("데이터출력");
		this.data = {
			render: "vue"
		};
	}
	builder(node) {
	    let input1 = new Rete.Input("input1", "입력",numSocket);
		return node
			.addControl(new DataOutputControl(this.editor, "input"))
			.addInput(input1);
	}
	worker() {}
}
