<template>
    <div class="dock">
        <div class="dock-label" v-b-toggle.collapse-a>
            <div class="dock-label__icon d-flex justify-content-start" style="width:20px;">
                <b-icon icon="chevron-down" font-scale="0.8" shift-v="-3"></b-icon>
            </div>
            <div class="dock-label__text">
                <!--노드 구성 토글 버튼 부분-->
                <!--노드 구성-->
                <label class="ml-0">{{ node_config_label }}</label>
            </div>
        </div>
        <b-collapse visible id="collapse-a">
            <div class="dock-item">
                <div class="dock-item__node" id="mnist_data">
                    <!--MNIST 데이터 노드 생성 MNIST 데이터-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ MNIST_DATA }}</span>
                    </div>
                </div>
            </div>
            <div class="dock-item">
                <div class="dock-item__node" id="iris_data">
                    <!--IRIS 데이터 노드 생성 IRIS 데이터-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ IRIS_DATA }}</span>
                    </div>
                </div>
            </div>
            <div class="dock-item">
                <div class="dock-item__node 모니터링" id="monitoring_data">
                    <!--데이터 모니터링 노드 생성-->
                    <!--데이터 모니터링-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ data_monitoring }}</span>
                    </div>
                </div>
            </div>
            <!--<div class="dock-item">-->
            <!--    <div class="dock-item__node 모니터링" id="monitoring_model">-->
                    <!--모델 모니터링 노드 생성-->
                    <!--모델 모니터링-->
            <!--        <div class="title">-->
            <!--            <span class="dock-item__node__icon"></span>-->
            <!--            <span class="dock-item__node__text">{{ model_monitoring }}</span>-->
            <!--        </div>-->
            <!--    </div>-->
            <!--</div>-->
            <div class="dock-item">
                <div class="dock-item__node 모델 저장" id="modelsave">
                    <!--모델 저장 노드 생성-->
                    <!--모델 저장-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ model_save }}</span>
                    </div>
                </div>
            </div>
        </b-collapse>
        <hr/>
        <div class="dock-label" v-b-toggle.collapse-c>
            <div class="dock-label__icon d-flex justify-content-start" style="width:20px;">
                <b-icon icon="chevron-down" font-scale="0.8" shift-v="-3"></b-icon>
            </div>
            <div class="dock-label__text">
                <!--기본 모델 토글 버튼 부분-->
                <!--기본 모델-->
                <label class="ml-0">{{ default_model_label }}</label>
            </div>
        </div>
        <b-collapse visible id="collapse-c">
            <div class="dock-item">
                <div class="dock-item__node" id="mnist_convnet">
                    <!--MNIST CONVOLUTION NET 모델 노드 생성-->
                    <!--MNIST ConvNet-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ MNIST_CONVNET }}</span>
                    </div>
                </div>
            </div>
            <div class="dock-item">
                <div class="dock-item__node" id="mnist_densenet">
                    <!--MNIST DENSE NET 모델 노드 생성-->
                    <!--MNIST DenseNet-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ MNIST_DENSENET }}</span>
                    </div>
                </div>
            </div>
            <div class="dock-item">
                <div class="dock-item__node" id="mnist_custom_cnn">
                    <!--MNIST Custom CNN 모델 노드 생성-->
                    <!--MNIST Custom CNN-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ MNIST_CUSTOM_CNN }}</span>
                    </div>
                </div>
            </div>
            <div class="dock-item">
                <div class="dock-item__node" id="iris_densenet">
                    <!--IRIS DENSE NET 모델 노드 생성-->
                    <!--IRIS DenseNet-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ IRIS_DENSENET }}</span>
                    </div>
                </div>
            </div>
        </b-collapse>
        <hr/>
        <div class="dock-label" v-b-toggle.collapse-d>
            <div class="dock-label__icon d-flex justify-content-start" style="width:20px;">
                <b-icon icon="chevron-down" font-scale="0.8" shift-v="-3"></b-icon>
            </div>
            <div class="dock-label__text">
                <!--훈련된 모델 토글 버튼 부분-->
                <!--훈련된 모델-->
                <label class="ml-0">{{ pretrained_model_label }}</label>
            </div>
        </div>
        <b-collapse visible id="collapse-d">
            <div class="dock-item">
                <div class="dock-item__node" id="mnist_test">
                    <!--MNIST MODEL 선택 노드 부분-->
                    <!--MNIST 모델-->
                    <div class="title">
                        <span class="dock-item__node__icon"></span>
                        <span class="dock-item__node__text">{{ MNIST_MODEL }}</span>
                    </div>
                </div>
            </div>
        </b-collapse>
        <hr/>
    </div>
</template>

<script>
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: "dock-item",
        data() {
            return {
                // 다국어 설정
                node_config_label: '',
                data_input_image: '',
                data_input_default: '',
                data_input_webcam: '',
                data_monitoring: '',
                model_monitoring: '',
                model_save: '',

                data_provided_label: '',
                MNIST_DATA: '',
                IRIS_DATA: '',

                default_model_label: '',
                MNIST_CONVNET: '',
                MNIST_DENSENET: '',
                MNIST_CUSTOM_CNN: '',
                IRIS_DENSENET: '',

                pretrained_model_label: '',
                MNIST_MODEL: '',

                // 언어 설정 
                localization: ''

            }
        },
        methods: {},
        mounted() {
            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;


            // localization 반영
            this.localization = locale.substring(0, 2);

            // 언어 설정 테스트
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.node_config_label = korean.trainmodel_dock.node_config_label;
                    this.data_input_image = korean.trainmodel_dock.data_input_image;
                    this.data_input_default = korean.trainmodel_dock.data_input_default;
                    this.data_input_webcam = korean.trainmodel_dock.data_input_webcam;
                    this.model_save = korean.trainmodel_dock.model_save;
                    this.data_monitoring = korean.trainmodel_dock.data_monitoring;
                    this.model_monitoring = korean.trainmodel_dock.model_monitoring;
                    this.data_provided_label = korean.trainmodel_dock.data_provided_label;
                    this.MNIST_DATA = korean.trainmodel_dock.MNIST_DATA;
                    this.IRIS_DATA = korean.trainmodel_dock.IRIS_DATA;
                    this.default_model_label = korean.trainmodel_dock.default_model_label;
                    this.MNIST_CONVNET = korean.trainmodel_dock.MNIST_CONVNET;
                    this.MNIST_DENSENET = korean.trainmodel_dock.MNIST_DENSENET;
                    this.MNIST_CUSTOM_CNN = korean.trainmodel_dock.MNIST_CUSTOM_CNN;
                    this.IRIS_DENSENET = korean.trainmodel_dock.IRIS_DENSENET;
                    this.pretrained_model_label = korean.trainmodel_dock.pretrained_model_label;
                    this.MNIST_MODEL = korean.trainmodel_dock.MNIST_MODEL;
                    return;
                case 'en':
                    this.node_config_label = english.trainmodel_dock.node_config_label;
                    this.data_input_image = english.trainmodel_dock.data_input_image;
                    this.data_input_default = english.trainmodel_dock.data_input_default;
                    this.data_input_webcam = english.trainmodel_dock.data_input_webcam;
                    this.model_save = english.trainmodel_dock.model_save;
                    this.data_monitoring = english.trainmodel_dock.data_monitoring;
                    this.model_monitoring = english.trainmodel_dock.model_monitoring;
                    this.data_provided_label = english.trainmodel_dock.data_provided_label;
                    this.MNIST_DATA = english.trainmodel_dock.MNIST_DATA;
                    this.IRIS_DATA = english.trainmodel_dock.IRIS_DATA;
                    this.default_model_label = english.trainmodel_dock.default_model_label;
                    this.MNIST_CONVNET = english.trainmodel_dock.MNIST_CONVNET;
                    this.MNIST_DENSENET = english.trainmodel_dock.MNIST_DENSENET;
                    this.MNIST_CUSTOM_CNN = english.trainmodel_dock.MNIST_CUSTOM_CNN;
                    this.IRIS_DENSENET = english.trainmodel_dock.IRIS_DENSENET;
                    this.pretrained_model_label = english.trainmodel_dock.pretrained_model_label;
                    this.MNIST_MODEL = english.trainmodel_dock.MNIST_MODEL;
                    return;
            }
        }
    }
</script>

<style scoped>
    .dock {
        width: 300px;
        min-height: 100%;
        max-height: 100%;
        color: #555555;
        background-color: #ffffff;
        padding: 2px 15px;
    }

    .dock .dock-label {
        padding-left: 6px;
        width: 220px !important;
        color: #616078 !important;
    }
    
    .dock .dock-label label{
        font-size: 14px !important;
    }


    .dock .dock-item {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }
    
    .dock .dock-item .dock-item__node{
        display: flex;
        align-items: center;
		background: #f4f5f7;
		border: 1px solid rgba(0, 0, 0, 0.125);
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		border-radius: 4px;
		background-color: #F0F0F7;
		color: #555555 !important;
		width: 220px;
		height: 45px;
		padding-left: 20px;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
    }
    
     .dock .dock-item .dock-item__node:hover{
         opacity: 1;
     }

    .dock .dock-item-list .dock-item {
        margin-top: 10px;
        border: 2px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(to right, rgba(81, 45, 168, 1), rgba(81, 45, 168, 0.7));
    }

    .dock-item .node {
        cursor: pointer;
        height: 45px !important;
        display: flex;
        align-items: center !important;
        justify-content: center;
        border: 2px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(to right, rgba(81, 45, 168, 1), rgba(81, 45, 168, 0.7));
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        color: #ccc !important;
    }

    .dock .node span {
        height: 30px !important;
        width: 30px !important;
        margin-left: 5px;
    }

    .title {
        font-size: 13px;
        color: #252746 !important;
        background: transparent;
    }

    .dock::-webkit-scrollbar {
        width: 3px;
    }

    .dock::-webkit-scrollbar-track {
        background-color: #F9F9F9;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.12);
    }

    .dock::-webkit-scrollbar-thumb {
        background-color: rgba(81, 45, 168, 0.7);
        border-radius: 20px !important;
    }

    /*반응형*/

    /* 1920 이상인 pc 경우 */

    @media screen and (min-width: 1920px) {
        .dock {
            height: 100% !important;
        }
    }

    /* 1024 이하인 pc 경우 */

    @media screen and (max-width: 1024px) {
        .dock {
            width: 150px !important;
            height: 710px !important;
            margin-right: 0px !important;
        }

        .dock-item .node {
            width: 80px !important;
        }

        .dock .node .title {
            margin-left: 0px !important;
            font-size: 10px !important;
        }
    }
</style>
