import { Control } from 'rete';

import NameVueControl from '../vues/NameVueControl.vue'

export default class AdvancedControl extends Control {
    constructor(emitter, key) {
        super(key);
        
        this.component = NameVueControl;
        
        this.props = {
            emitter,
            ikey: key,
        }
    };

    setValue(value) {
        const ctx = this.vueContext || this.props;
        ctx.value = value;
    }

    onChange() {}
}
