<template>
        <div class="input-unit">
            <label>{{ikey}}</label>
            <input type="text" v-model.value="value" @input="onInput"></input>
        </div>
</template>

<script>
    // vuex
    import store from '@/store';

    export default {
        name: "Parameter",
        data() {
            return {
                value: ""
            }
        },
        props: ['ikey', 'emitter', 'getData', 'putData'],
        mounted() {
        },
        methods: {
            onInput() {
                this.setData();
            },
            setData() {
                this.putData(this.ikey, this.value);
            }
        }
    }
</script>

<style scoped>
    .input-unit {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .input-unit label {
        font-size: 8px;
        color: #555555;
    }

    .input-unit input {
        width: 180px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid #999;
    }
</style>
