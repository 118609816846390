<template>
    <!--로그인 페이지 이후 메인 페이지 구성-->
	<div id="main-page">
	    <!--메인 페이지 상단 네비게이션 바-->
        <Navbar/>
        <!--메인 화면 부분-->
		<div class="main-part">
		    <!--사이드바-->
		    <Sidebar></Sidebar>
            <!--메인 화면 전환 부분-->
			<router-view></router-view>
		</div>
	</div><!--메인 페이지 구성 end-->
</template>
<script>
    // 상단 네비게이션 바 불러오기
    import Navbar from '@/components/MainPage/Navbar/Navbar.vue';
    
    // 좌측 사이드바 불러오기
    import Sidebar from '@/components/MainPage/Sidebar/Sidebar.vue';
    
    export default {
        // 컴포넌트 등록
        components: {
            Navbar,
            Sidebar
        },
    }
</script>