<template>
    <div class="DataOutputVueControl">
    </div>
</template>
<script>
    export default {
        name: 'DataOutputVueControl',
        data() {
            return {
            }
        },
    }
</script>

<style scoped>
    .DataOutputVueControl {
        min-width: 180px;
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 33.79px;
    }

    .DataOutputVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .DataOutputVueControl select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    .DataOutputVueControl button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        text-align: center;
        line-height: 12px;
        border: none;
    }
</style>
