const state = {
    show: false,
    inversion: null,
    // data input node id
    datanode: '',
    datanode_id: '',
    datanode_name: '',

    // conv2d node id + name
    convnode: '',
    convnode_id: '',

    // conv2d state
    conv2d: {
        kernelSize: 0,
        filters: 0,
        strides: 0,
        activation: '',
        kernelInitializer: ''
    },

    // maxPooling2d node id + name
    maxpoolnode: '',
    maxpoolnode_id: '',

    // MaxPooling state
    MaxPooling: {
        poolSize: '',
        strides: ''
    },

    // dense node id + name
    densenode: '',
    densenode_id: '',
    // dense state
    dense: {
        units: 0,
        kernelInitializer: '',
        activation: ''
    },

    // trainer setting

    trainer_set: {
        optimizer: '',
        loss: '',
        epoch: 0,
        batch_size: ''
    },
    file_name: '',
    model_name: 'MNISTConvNet',
    model_node_name: '',
    model_node_id: '',
    mod_node_id: '',
}

const mutations = {

    change_show(state, show) {
        state.show = show;
    },
    
    change_inversion(state, inversion) {
        state.inversion = inversion;
    },

    // data input id
    change_datanode(state, datanode) {
        state.datanode = datanode;
    },
    change_datanode_id(state, datanode_id) {
        state.datanode_id = datanode_id;
    },


    // conv2d node id + name
    changeconvnode(state, convnode) {
        state.convnode = convnode
    },
    change_convnode_id(state, convnode_id) {
        state.convnode_id = convnode_id
    },

    // Module Conv2d
    changeKernelSize(state, kernelSize) {
        state.conv2d.kernelSize = kernelSize
    },
    changefilters(state, filters) {
        state.conv2d.filters = filters
    },
    changestrides(state, strides) {
        state.conv2d.strides = strides
    },
    changeactivation(state, activation) {
        state.conv2d.activation = activation
    },
    changekernelInitializer(state, kernelInitializer) {
        state.conv2d.kernelInitializer = kernelInitializer
    },

    // maxPooling2d node id + name
    changemaxpoolnode(state, maxpoolnode) {
        state.maxpoolnode = maxpoolnode
    },
    change_maxpoolnode_id(state, maxpoolnode_id) {
        state.maxpoolnode_id = maxpoolnode_id
    },

    // maxPooling2d
    changepoolSize(state, poolSize) {
        state.MaxPooling.poolSize = poolSize
    },
    changeMaxPoolingstrides(state, strides) {
        state.MaxPooling.strides = strides
    },

    //dense node id + name
    changedensenode(state, densenode) {
        state.densenode = densenode
    },
    change_densenode_id(state, densenode_id) {
        state.densenode_id = densenode_id
    },

    // dense 
    change_dense_units(state, units) {
        state.dense.units = units
    },

    // trainer_set
    change_optimizer(state, optimizer) {
        state.trainer_set.optimizer = optimizer
    },
    change_loss(state, loss) {
        state.trainer_set.loss = loss
    },
    change_epoch(state, epoch) {
        state.trainer_set.epoch = epoch
    },
    change_batch_size(state, batch_size) {
        state.trainer_set.batch_size = batch_size
    },

    change_file_name(state, file_name) {
        state.file_name = file_name
    },

    // model name
    change_model_name(state, model_name) {
        state.model_name = model_name
    },

    // model_node_name
    change_model_node_name(state, model_node_name) {
        state.model_node_name = model_node_name
    },
    change_model_node_id(state, model_node_id) {
        state.model_node_id = model_node_id
    },

    // mod_node_id
    change_mod_node_id(state, mod_node_id) {
        state.mod_node_id = mod_node_id
    }

}

const actions = {

    call_show({ state, commit }, { show }) {
        commit("change_show", show);
    },

    call_inversion({ state, commit }, { inversion }) {
        commit("change_inversion", inversion);
    },

    // data input node id
    call_datainput({ state, commit }, { datanode }) {
        commit('change_datanode', datanode);
    },
    call_datainput_id({ state, commit }, { datanode_id }) {
        commit("change_datanode_id", datanode_id);
    },

    // conv2d node id + name
    call_convnode({ state, commit }, { convnode }) {
        commit("changeconvnode", convnode);
    },
    call_convnode_id({ state, commit }, { convnode_id }) {
        commit("change_convnode_id", convnode_id);
    },

    // Module Conv2d
    call_kernelSize({ state, commit }, { kernelSize }) {
        commit("changeKernelSize", kernelSize)
    },
    call_filters({ state, commit }, { filters }) {
        commit("changefilters", filters)
    },
    call_strides({ state, commit }, { strides }) {
        commit("changestrides", strides)
    },
    call_activation({ state, commit }, { activation }) {
        commit("changeactivation", activation);
    },
    call_kernelInitializer({ state, commit }, { kernelInitializer }) {
        commit("changekernelInitializer", kernelInitializer);
    },

    // maxPooling2d node id + name

    call_maxpoolnode({ state, commit }, { maxpoolnode }) {
        commit("changemaxpoolnode", maxpoolnode)
    },
    call_maxpoolnode_id({ state, commit }, { maxpoolnode_id }) {
        commit("change_maxpoolnode_id", maxpoolnode_id)
    },

    // maxPooling2d
    call_poolSize({ state, commit }, { poolSize }) {
        commit('changepoolSize', poolSize);
    },
    call_Maxpoolingstrides({ state, commit }, { strides }) {
        commit('changeMaxPoolingstrides', strides);
    },

    // dense node id + name
    call_densenode({ state, commit }, { densenode }) {
        commit('changedensenode', densenode);
    },
    call_densenode_id({ state, commit }, { densenode_id }) {
        commit("change_densenode_id", densenode_id)
    },

    // dense units
    call_dense_units({ state, commit }, { units }) {
        commit("change_dense_units", units)
    },

    // trainer setting
    call_optimizer({ state, commit }, { optimizer }) {
        commit('change_optimizer', optimizer);
    },

    call_loss({ state, commit }, { loss }) {
        commit('change_loss', loss);
    },

    call_epoch({ state, commit }, { epoch }) {
        commit('change_epoch', epoch);
    },
    call_batch_size({ state, commit }, { batch_size }) {
        commit('change_batch_size', batch_size);
    },

    call_file_save({ state, commit }, { file_name }) {
        commit('change_file_name', file_name);
    },


    // save model name 
    call_model_name({ state, commit }, { model_name }) {
        commit('change_model_name', model_name);
    },

    // model_node_name
    call_model_node_name({ state, commit }, { model_node_name }) {
        commit('change_model_node_name', model_node_name);
    },
    call_model_node_id({ state, commit }, { model_node_id }) {
        commit('change_model_node_id', model_node_id);
    },
    // modal_node_id
    call_mod_node_id({ state, commit }, { mod_node_id }) {
        commit("change_mod_node_id", mod_node_id);
    },

}


const getters = {
    get_show_tf(state) {
        return state.show;
    },

    get_inversion_tf(state) {
        return state.inversion;
    },
    
    get_datanode(state) {
        return state.datanode;
    },
    get_datanode_id(state) {
        return state.datanode_id;
    },

    // conv2d node id + name
    getconvnode(state) {
        return state.convnode
    },
    getconvnode_id(state) {
        return state.convnode_id
    },

    // conv2d
    getConv2dkernelSize(state) {
        return state.conv2d.kernelSize
    },
    getfilters(state) {
        return state.conv2d.filters
    },
    getstrides(state) {
        return state.conv2d.strides
    },
    getactivation(state) {
        return state.conv2d.activation
    },
    getkernelInitializer(state) {
        return state.conv2d.kernelInitializer
    },
    getconv2ddata(state) {
        return state.conv2d
    },

    // maxPooling2d node id + name
    getmaxpoolnode(state) {
        return state.maxpoolnode
    },
    getmaxpoolnode_id(state) {
        return state.maxpoolnode_id
    },

    // maxpooling2d
    getpoolSize(state) {
        return state.MaxPooling.poolSize
    },
    getMaxPoolingstrides(state) {
        return state.MaxPooling.strides
    },
    getMaxPooling(state) {
        return state.MaxPooling
    },

    //dense node id + name
    getdensenode(state) {
        return state.densenode
    },
    getdensenode_id(state) {
        return state.densenode_id
    },

    // dense
    get_dense_units(state) {
        return state.dense.units
    },

    // dense
    getdense(state) {
        return state.dense
    },

    // all trainer_set
    get_trainer_set(state) {
        return state.trainer_set
    },

    // optimzer
    get_optimizer(state) {
        return state.trainer_set.optimizer
    },

    // loss
    get_loss(state) {
        return state.trainer_set.loss
    },

    // epoch
    get_epoch(state) {
        return state.trainer_set.epoch
    },

    // batch_size
    get_batch_size(state) {
        return state.trainer_set.batch_size
    },

    // file_name
    get_file_name(state) {
        return state.file_name
    },
    get_model_name(state) {
        return state.model_name
    },

    // model_node_name
    get_model_node_name(state) {
        return state.model_node_name
    },
    get_model_node_id(state) {
        return state.model_node_id
    },

    // mod_node_id
    get_mod_node_id(state) {
        return state.mod_node_id
    }

}

export default {
    state,
    mutations,
    actions,
    getters
}
