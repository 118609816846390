import * as tf from '@tensorflow/tfjs';

import store from "@/store";
import data from "./data";


// const IRIS_CLASSES = ['Setosa', 'Versicolor', 'Virginica'];
const IRIS_CLASSES = ['세토사', '버시컬러', '버지니카'];

// let MNIST_IMAGES_SPRITE_PATH = sessionStorage.getItem('drawing_image');


export class IrisData {
    constructor() {

    }

    async load(_this, model, num_array) {

        const input = tf.tensor2d(num_array, [1, 4]);
        const predictOut = model.predict(input);
        const logits = Array.from(predictOut.dataSync());
        let logits2 = Array.from(logits);
        // const maxindex = predictOut.argMax(-1).dataSync()[0];
        const f_index = this.indexofMax(logits);

        const max_v = logits[f_index];
        const t = f_index.toString();

        logits2[f_index] = -1;
        // const t = this.indexofMax(rt_ts).toString();
        const s_index = this.indexofMax(logits2);
        const max_v2 = logits2[s_index];
        const t2 = s_index.toString();



        const winner1 = IRIS_CLASSES[f_index];
        const winner2 = IRIS_CLASSES[s_index];
        // predictOut.splice(predictOut.argMax(-1).dataSynce()[0],1);
        // console.log(maxindex);


        // console.log(logits);
        // console.log(logits2);

        // console.log(max_v);
        // console.log(max_v2);

        // console.log(winner1);
        // console.log(winner2);

        // 수정 전===================================================
        // 첫번째 값 INDEX
        // store.dispatch('call_max_rt_ts', { max_rt_ts: winner1 });

        // 첫번째 값 VALUE
        // store.dispatch('call_rt_ts', { rt_ts: logits });
        //===========================================================

        // 수정 후---------------------------------------------------

        // 첫번째 값 INDEX
        store.dispatch('call_max_value_name', { max_value_name: winner1 });
        // 첫번째 값 VALUE
        store.dispatch('call_max_value_percentage', { max_value_percentage: max_v });

        // 두번째 값 INDEX
        store.dispatch('call_second_max_value_name', { second_max_value_name: winner2 });
        // 두번째 값 VALUE
        store.dispatch('call_second_max_value_percentage', { second_max_value_percentage: max_v2 });

        //-----------------------------------------------------------

    }




    indexofMax(arr) {
        if (arr.length === 0) {
            return -1;
        }

        var max = arr[0];
        var maxIndex = 0;

        for (var i = 1; i < arr.length; i++) {
            if (arr[i] > max) {
                maxIndex = i;
                max = arr[i];
            }
        }

        return maxIndex;
    }


}
