<template>
    <!-- 계정 생성 모달 내용 부분 -->
    <div class="signup-outer">
        <header class="signup-outer__header"><span>회원 가입</span><span class="signup-outer__close-btn" @click="closeSignUp"><i class="far fa-times-circle"></i></span></header>
        <div id="signupErrorMessage" v-if="isError">
            <span><i class="fas fa-exclamation-circle"></i></span>
            {{errorMessage}}
        </div>
        <!--계정 생성 폼 부분-->
        <b-form class="form-signup" @submit="onRegister">
            <!--계정 생성 폼 내용 부분-->
            <b-form-group id="signup-group">
                <!--이메일 입력 부분-->
                <h6>이메일</h6>
                <b-form-input id="signup-email" class="form-control" type="email" v-model="signupform.email" required placeholder="이메일"></b-form-input>
                <!--패스워드 입력 부분-->
                <h6>비밀번호</h6>
                <b-form-input id="signup-password" class="form-control" type="password" v-model="signupform.password" required placeholder="비밀번호"></b-form-input>
                <h6>비밀번호 확인</h6>
                <b-form-input id="signup-confirm-password" class="form-control" type="password" v-model="signupform.confirm_password" required placeholder="비밀번호 확인"></b-form-input>
                <!--닉네임 혹은 이름 입력 부분-->
                <h6>닉네임</h6>
                <b-form-input id="signup-nickname" class="form-control" type="text" v-model="signupform.nickname" required placeholder="닉네임"></b-form-input>
            </b-form-group>
            <!--계정 생성 폼 내용 부분 end-->
            <div v-if="isRegisted" class="confirm-box">
                <p class="confirm-box__alarm-text">등록하신 이메일 <strong>{{this.signupform.email}}</strong>에 인증번호를 보냈습니다. 이메일을 확인하여 <strong>인증번호</strong>를 입력해주세요.</p>
                <h6 v-if="is_error_about_signup" id="confirmSignUpErrorMessage">{{is_error_about_signup}}</h6>
                <b-form-input id="signup-confirm-code" required placeholder="Enter Confirm Code" v-model="signupform.confirmCode"/>
                <b-button class="signup-button" type="submit" variant="primary">가입하기</b-button>
            </div>
            <!-- 생성한 계정 등록 버튼 부분 -->
            <div v-else class="register_button_group">
                <b-button @click="implementSignUp" class="signup-button" variant="primary">입력완료</b-button>
            </div>
            <!--생성한 계정 등록 버튼 부분 end-->
        </b-form>
        <!--계정 생성 폼 부분 end-->
    </div>
    <!--계정 생성 모달 내용 부분 end-->
</template>
<script>
    import { Auth } from 'aws-amplify';
    
    export default {
        name: 'SignupForm',
        props: ['is_error_about_signup'],
        data() {
            return {
                // 계정 생성 데이터
                signupform: {
                    email: '',
                    confirmCode: '',
                    password: '',
                    nickname: '',
                    confirm_password: ''
                },
                isRegisted: false,
                errorMessage:'',
                isError: false
            }
        },
        methods: {
            // 회원 등록 부분 
            checkError(){
                const {email, password, confirm_password, nickname} = this.signupform;
                if(password !== confirm_password){
                    throw new Object({code: "beDifferentConfirmPassword", message:"비밀번호와 확인 비밀번호가 서로 다릅니다."})
                }
                else if(!email){
                    throw new Object({code: "emptyEmail", message:"등록하실 이메일을 적어주세요."});
                }
                else if(!password){
                    throw new Object({code: "emptyPassword", message:"등록하실 비밀번호를 입력해주세요."});
                }
                else if(!nickname){
                    throw new Object({code: "emptyNickname", message:"등록하실 닉네임을 적어주세요."});
                }
            },
            async implementSignUp(e){
              e.preventDefault();
              
              try{
                  this.checkError();
                  const {user} = await Auth.signUp({
                      username: this.signupform.email,
                      password: this.signupform.password,
                      attributes:{
                          email: this.signupform.email,
                          nickname: this.signupform.nickname
                      }
                  });
                  this.isError = false;
                  this.errorMessage = "";
                  console.log("유저 정보:", user);
                  console.log(this.signupform.email, this.signupform.password);
                  this.isRegisted  = true;
              }
              catch({code, message}){
                  this.isError = true;
                  if(code === "InvalidParameterException"){
                      this.errorMessage = `비밀번호는 최소 8자 이상이어야 합니다.`;
                  }
                  else if(code === "UsernameExistsException"){
                      this.errorMessage = "가입되어 있는 이메일입니다.";
                  }
                  else if(code === "InvalidPasswordException"){
                      this.errorMessage = "비밀번호는 숫자, 소문자, 특수문자를 포함한 8자 이상이어야 합니다.";
                  }
                  else if(code === "emptyEmail"){
                      this.errorMessage = message;
                  }
                  else if(code === "emptyPassword"){
                      this.errorMessage = message;
                  }
                  else if(code === "emptyNickname"){
                      this.errorMessage = message;
                  }
                  else if(code === "beDifferentConfirmPassword"){
                      this.errorMessage = message;
                  }
                  console.log("에러",code, message);
              }
            },
            onRegister(e) {
                e.preventDefault();
                const confirmCode = this.signupform.confirmCode;
                const email = this.signupform.email;
                this.$emit("complete_signup", email, confirmCode);
                
                // 등록후 로그인 페이지로 돌아가기
                // this.$router.push(-1);
                // 페이지 뒤로가기설정 부분이므로 사용하지 않음

                // 등록후 모달창 지우기
                //this.$emit('implement_signup', signup_info);

                // axios를 통한 회원 가입 =============================
                //
                // axios.post('https://<project>url', RegisterformData)
                //   .then(response => console.log(response))
                //   .catch(error => console.log(error))
                //
                // ====================================================
            },
            closeSignUp(){
                 this.$emit("closeSignUpModal");
            }
        },
        mounted(){
        }
    }
</script>
<style scoped>
    
    .signup-outer {
        min-height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .signup-outer__header{
        width: 100%;
        height: 50px;
        background-color: #00b894;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        padding: 0 15px;
    }
    
    .signup-outer__header .signup-outer__close-btn{
        font-size: 20px;
        cursor: pointer;
    }
    
    #signup-group h6{
        font-size: 13px;
        font-weight: 600;
        opacity: 0.7;
    }
    
    #signupErrorMessage{
        margin-top: 15px;
        text-align: center;
        color: red;
    }
    
    .form-group{
        width: 100%;
    }

    .form-signup {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 15px 20px;

    }
    
    
    .bv-no-focus-ring{
        width: 100% !important;
    }

    .modal-dialog input {
        box-shadow: none;
        width: 100% !important;
        height: 40px !important;
        margin-bottom: 20px;
    }
    
    .modal-dialog input:last-child{
        margin-bottom: 30px;
    }
    
    .modal-dialog input::placeholder{
        opacity: 0.5;
    }

    .modal-dialog .modal-footer {
        display: none !important;
    }
    
    .register_button_group .signup-button {
        margin-left: auto;
    }
    
    .confirm-box .confirm-box__alarm-text{
        text-align: center;
        opacity: 0.7;
    }
    
    .confirm-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .confirm-box input{
        width: 100%;
        height: 40px;
        margin-bottom: 15px;
    }
    
    /* 인증 번호를 틀리게 입력했다면, 뜨는 메세지*/
    #confirmSignUpErrorMessage{
        color: red;
        margin-bottom: 15px;
    }
    
</style>
