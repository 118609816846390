<template>
    <!--데이터 입력 부분-->
        <div class="data_input_content">
            <!--데이터 입력 헤더 라벨 부분-->
            <div class="mi_content_header">
                <!--데이터입력-->
                <label>{{ mi_content_header_datainput }}</label>
            </div>
            <!--데이터 입력 카드 부분-->
            <b-card no-body class="data_input_select_part" v-if="selected_pretrain_model === null">
                <b-card-header>
                </b-card-header>
                <div class="not_selected_card">
                    <div class="not_selected_content">
                    </div>
                    <div class="not_selected_bottom"></div>
                </div>
            </b-card>
            
            <b-card no-body class="data_input_select_part" v-if="selected_pretrain_model != null">
                <!-- 모델을 선택하지 않았을 때-->
                <!-- 모델을 선택 했을때 해당 모델의 데이터입력이 출력되도록 설정 -->
                <b-tabs card align="right" >
                    <!--그리기 탭 타이틀 변경 부분 (상단 탭 클릭 부분)-->
                    <!--그리기-->
                    <!-- MNIST CONVOLUTION NET , MNIST DENSE NET 모델 선택 시 -->
                    <b-tab :title="tab_drawing_title" v-if="selected_pretrain_model === 'MNIST CONVOLUTION NET' || selected_pretrain_model === 'MNIST DENSE NET' || selected_pretrain_model === 'local_upload_model' " >
                        <drawingComp v-on:drawing_capture="drawing_to_inference" />
                    </b-tab>
                    
                   <!--웹캠 탭 타이틀 변경 부분 (상단 탭 클릭 부분) -->
                   <!--웹캠-->
                   <!-- GAN-CARTOONIZER , HANDPOSE 모델 선택 시 -->
                    <b-tab :title="tab_webcam_title" v-if="selected_pretrain_model === 'GAN-CARTOONIZER' ||  selected_pretrain_model === 'HAND POSE' || selected_pretrain_model === 'CONTEXT R-CNN'">
                        <webcamComp ref="webcam" 
                            v-on:capture="capture_to_inference" 
                            v-on:startBtClicked="startBtClicked" 
                            v-on:stopBtClicked="stopBtClicked" 
                            v-on:get_video_capture="getVideoCapture"/>
                    </b-tab>
                    
                    <!--파일 업로드 타이틀 변경 부분 (상단 탭 클릭 부분)-->
                    <!--파일-->
                    <!-- MOBILE NET , CONTEXT R CNN 모델 선택 시 -->
                    <b-tab :title="tab_fileupload_title" v-if="selected_pretrain_model === 'MOBILE NET'">
                        <fileUploadComp v-on:file_upload="file_to_inference"/>                        
                    </b-tab>
                    
                    <!--아이리스 텍스트 데이터-->
                    <b-tab :title="tab_textinput_title" v-if="selected_pretrain_model === 'IRIS DENSE NET'">
                        <textInputComp v-on:text_input="text_to_inference"/>
                    </b-tab>
                    
                </b-tabs>
                <!--데이터 탭 부분 END-->
            </b-card>
            <!--데이터 입력 카드 부분 END-->
        </div>
    <!--데이터 입력 부분 END-->
</template>
<script>
    // vuex =====================
    import store from '@/store';
    // ==========================

    // 데이터 입력 =======================================

    // 그리기 
    import drawingComp from './InputMethods/DrawingComp';
    // 웹캠
    import webcamComp from './InputMethods/WebCamComp';
    // 파일업로드
    import fileUploadComp from './InputMethods/FileUploadComp/FileUploadComp.vue';
    // 아이리스 텍스트
    import textInputComp from './InputMethods/TextInputComp';
    // ========================================================

    // 언어 설정 JSON 파일 가져오기 ===============
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================

    export default {
        name: 'DataInput',
        data() {
            return {
                // input images
                images: [],
                // drawing images and input images
                session_Inference_Image: '',

                // 데이터 입력
                mi_content_header_datainput: '',
                // alert 모달
                ainomis_alert: '',
                // 탭 타이틀
                tab_drawing_title: '',
                tab_webcam_title: '',
                tab_fileupload_title: '',
                tab_textinput_title: ''
            }
        },
        components: {
            drawingComp,
            webcamComp,
            fileUploadComp,
            textInputComp
        },
        mounted() {

            /* global navigator */
            // 브라우저 언어 설정 가져오기
            let locale = navigator.language || navigator.userLanguage;

            // 언어 설정 
            this.localization = locale.substring(0, 2);

            // 언어 설정 테스트 
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    // 데이터 입력 카드 부분
                    this.mi_content_header_datainput = korean.inference.mi_content_header_datainput;
                    // 그리기 탭
                    this.tab_drawing_title = korean.inference.tab_drawing_title;
                    // 웹캠 탭
                    this.tab_webcam_title = korean.inference.tab_webcam_title;
                    // 파일 업로드 탭
                    this.tab_fileupload_title = korean.inference.tab_fileupload_title;
                    // 아이리스 텍스트 데이터 입력 탭
                    this.tab_textinput_title = korean.inference.tab_textinput_title;
                    // 주의!
                    this.ainomis_alert = korean.inference.ainomis_alert;
                    return;

                case 'en':
                    // 데이터 입력 카드 부분
                    this.mi_content_header_datainput = english.inference.mi_content_header_datainput;
                    // 그리기 탭
                    this.tab_drawing_title = english.inference.tab_drawing_title;
                    // 웹캠 탭
                    this.tab_webcam_title = english.inference.tab_webcam_title;
                    // 파일 업로드 탭
                    this.tab_fileupload_title = english.inference.tab_fileupload_title;
                    // 아이리스 텍스트 데이터 입력 탭
                    this.tab_textinput_title = english.inference.tab_textinput_title;
                    // 주의
                    this.ainomis_alert = english.inference.ainomis_alert;
                    return;
            }
        },
        methods: {
            // 그리기에서 이벤트 수신 
            drawing_to_inference() {
                // 선택된 모델이 없을때 ALERT // 선택된 모델이 있을때는 실행
                if (this.selected_pretrain_model === null) {
                    this.$refs['model_select_alert'].show();
                }
                else {
                    // =================================================================================
                    // this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');
                    // =================================================================================

                    // 색상 반전 체크 // VUEX 
                    store.dispatch('call_inversion', { inversion: true });

                    // 추론 페이지로 드로잉 이벤트 전달
                    this.$emit('drawing_to_inference');
                }
            },
            // 아이리스 데이터에서 입력한 데이터 가져오기
            text_to_inference(iris_array) {
                // 추론 페이지에 아이리스 데이터 입력값 전달
                this.$emit('text_to_inference', iris_array);
            },
            // 파일 데이터 가져오기
            file_to_inference() {
                // 추론 페이지로 파일 업로드 이벤트 전달
                this.$emit('file_to_inference');
            },
            // 웹캠 에서 캡쳐 이벤트 수신 // 수신한 이벤트 추론 페이지로 이벤트 전달
            capture_to_inference() {

                // 선택된 모델이 없을때 ALERT 
                if (this.selected_pretrain_model === null) {
                    this.$refs['model_select_alert'].show();
                    this.$refs.webcam.webcam_capture_stop();
                }
                // 선택된 모델이 있을때는 실행
                else {
                    // ===================================================================================
                    // this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');
                    // inversion check drawing-file
                    // store.dispatch('call_inversion', { inversion: true });
                    // ===================================================================================

                    // 추론 페이지로 캡쳐 이벤트 전달
                    this.$emit('capture_to_inference');
                }
            },
            // 웹캠 스타트 버튼 클릭 이벤트 전달
            startBtClicked() {
                this.$emit('startBtClicked');
            },
            // 웹캠 스탑 버튼 클릭 이벤트 전달
            stopBtClicked() {
                this.$emit('stopBtClicked');
            },
            // 웹캠 캔버스 추론 페이지로 이벤트 전달
            getVideoCapture() {
                const canvas_webcam = this.$refs.webcam.getVideoCapture();
                return canvas_webcam
            }
        },
        computed: {
            // 선택된 사전훈련된 모델 // VUEX
            selected_pretrain_model() {
                return store.getters.get_pretrain_model_name;
            },
        }
    }
</script>
<style scoped>
    .mi_content_header {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .mi_content_header label {
        font-size: 20px;
        font-weight: bold;
    }

    /* 데이터 입력 부분 ----------------------------------------*/

    .data_input_select_part {
        width: 450px;
        height: 600px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .not_selected_card {
        width: 448px;
        height: 544px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }
    .not_selected_content {
        width: 350px;
        height: 350px;
        background: #F5F6FA;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    
    .not_selected_bottom {
        width: 350px;
        height: 40px;
    }
    

    /* OTHER DATA -----------------------------*/

    .data_input_select_part .tabs {
        width: 450px;
        height: 600px;
        display: flex;
        flex-direction: column;
    }

    .data_input_select_part .card-header {
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .data_input_select_part .card-body {
        width: 448px;
        height: 544px;
    }



    /* 데이터 입력 부분 END-------------------------------------*/
</style>
