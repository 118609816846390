<template>
    <div class="model_part">
        <!--같은 파일 -->
        <div id='container'>
        </div>
        <!--컴포넌트 화-->
        <div class="model_explain">
            <!--텐서스페이스 3d모델이 없는 경우 샘플이미지와 함께 설명-->
            <!--이 부분은 개발 진행중입니다. Coming Soon....-->
            <label class="model_custom_explain">{{ model_explain_not_complete }}</label>
        </div>
    </div>
</template>
<script>
    // 언어 설정 JSON 파일 가져오기 ===============
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================

    // JSON으로 저장한 샘플이미지 가져오기 ===============
    import sample_image from '@/assets/sample_image.json';
    // ===================================================

    export default {
        name: "model_localfile",
        data() {
            return {
                model_explain_not_complete: ''
            }
        },
        mounted() {
            this.tensorspace_model_upload();

            /* global navigator */

            // 브라우저의 언어 설정을 읽어와서 변수로 지정
            let locale = navigator.language || navigator.userLanguage;

            // 지정한 변수를 데이터로 지정 
            this.localization = locale.substring(0, 2);

            // 언어 설정 영어 테스트 // 언어를 임의로 바꿔서 테스트 할 수 없기 때문에

            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.model_explain_not_complete = korean.inference.model_explain_not_complete;
                    return;
                case 'en':
                    this.model_explain_not_complete = english.inference.model_explain_not_complete;
                    return;
            }
        },
        methods: {
            tensorspace_model_upload() {
                let tensorspace_container = document.getElementById('container');

                let vmcontainer = document.getElementById('container');

                // 텐서스페이스 컨테이너 초기화
                while (tensorspace_container.hasChildNodes()) {
                    tensorspace_container.removeChild(tensorspace_container.firstChild);
                }

                // 텐서스페이스 MODEL VIEW 초기화
                let ts_model_view = null;

                let ts_image = null;

                // 텐서스페이스 대신 만들어줄 이미지 생성
                ts_image = document.createElement('img');

                // 이미지 속성 설정 
                ts_image.src = sample_image.data;

                // 이미지 속성 설정
                ts_image.setAttribute("width", "350px");
                ts_image.setAttribute('height', "350px");

                // 이미지 붙이기
                tensorspace_container.appendChild(ts_image);
            }

        },
    }
</script>
<style scoped>
    .model_part {
        width: 100%;
        height: 100%;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }

    .model_part img {
        width: 350px;
        height: 350px;
    }

    #container {
        width: 350px;
        height: 350px;
        background: #F5F6FA;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
        margin-bottom: 10px;
    }

    #container canvas {
        width: 350px !important;
        height: 350px !important;
        border-radius: 10px !important;
    }

    #container img {
        width: 350px !important;
        height: 350px !important;
    }

    .model_explain {
        width: 350px;
        height: 40px;
    }

    .model_explain label {
        font-size: 13px;
    }
</style>
