<template>
    <div class="irisdense_part">
        <div class="irisdense_input">
            <!--아이리스데이터 입력 부분-->
            <!--꽃잎 길이(petal_length)-->
            <label>{{ iris_petal_length }}</label>
            <input type="number" v-model="petal_length"/>
            <!--꽃잎 넓이(petal_width)-->
            <label>{{ iris_petal_width }}</label>
            <input type="number" v-model="petal_width"/>
            <!--꽃받침 길이(sepal_length)-->
            <label>{{ iris_sepal_length }}</label>
            <input type="number" v-model="sepal_length"/>
            <!--꽃받침 넓이(sepal_width)-->
            <label>{{ iris_sepal_width }}</label>
            <input type="number" v-model="sepal_width"/>
            <div class="irisdense_input_button_group">
                <!--아이리스 추론 버튼 부분-->
                <!--반영-->
                <!--@click="onClick_PredictRun"-->
                <b-button variant="primary" @click="onClick_TextInput" >{{ iris_value_predict }}</b-button>
                <b-modal ref="iris_value_alert" hide-footer :title="ainomis_alert">
                    <!--alert의 내용 부분 -->
                    <!--아이리스(붓꽃) 데이터의 최소값 , 최대값 내의 값을 입력해주세요.-->
                    <label class="iris_alert_label">{{ iris_value_alert }}</label>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
    // vuex =====================
    import store from '@/store';
    // ==========================

    // 언어 설정 JSON 파일 가져오기 ===============
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================

    export default {
        name: 'TextInputComp',
        data() {
            return {
                // iris data
                petal_length: 0,
                petal_width: 0,
                sepal_length: 0,
                sepal_width: 0,

                // alert
                ainomis_alert: '',

                // 아이리스 데이터 입력
                iris_petal_length: '',
                iris_petal_width: '',
                iris_sepal_length: '',
                iris_sepal_width: '',
                iris_value_predict: '',
                iris_value_alert: '',

                // 언어 설정
                localization: ''
            }
        },
        mounted() {
            /* global navigator */

            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // 언어 설정 테스트 
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    // 아이리스 데이터 설정
                    this.iris_petal_length = korean.inference.iris_petal_length;
                    this.iris_petal_width = korean.inference.iris_petal_width;
                    this.iris_sepal_length = korean.inference.iris_sepal_length;
                    this.iris_sepal_width = korean.inference.iris_sepal_width;
                    this.iris_value_predict = korean.inference.iris_value_predict;
                    // 아이리스 데이터 입력 경고창
                    this.iris_value_alert = korean.inference.iris_value_alert;

                    this.ainomis_alert = korean.inference.ainomis_alert;
                    return;

                case 'en':
                    // 아이리스 데이터 설정
                    this.iris_petal_length = english.inference.iris_petal_length;
                    this.iris_petal_width = english.inference.iris_petal_width;
                    this.iris_sepal_length = english.inference.iris_sepal_length;
                    this.iris_sepal_width = english.inference.iris_sepal_width;
                    this.iris_value_predict = english.inference.iris_value_predict;
                    // 아이리스 데이터 입력 경고창
                    this.iris_value_alert = english.inference.iris_value_alert;
                    this.ainomis_alert = english.inference.ainomis_alert;
                    return;
            }
        },
        methods: {
            // 입력한 텍스트 반영
            onClick_TextInput() {
                
                let iris_array = [Number(this.petal_length), Number(this.petal_width), Number(this.sepal_length), Number(this.sepal_width)];
                
                // console.log(iris_array);

                // 입력한 텍스트가 하나라도 0일 경우에 주의! 알람 띄우기
                if (iris_array[0] === 0 || iris_array[1] === 0 || iris_array[2] === 0 || iris_array[3] === 0) {
                    this.$refs['iris_value_alert'].show();
                }
                else {
                    // 아이리스 텍스트 데이터를 데이터 입력으로 이벤트와 함께 전달
                    this.$emit('text_input', iris_array);
                }
            }
        }
    }
</script>
<style scoped>
    /* IRIS DATA ------------------------------*/

    .data_input_select_part .card-header {
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .data_input_select_part .card-body {
        width: 450px;
        height: 544px;
    }

    .data_input_select_part .irisdense_part {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .irisdense_part .irisdense_input {
        width: 100%;
        height: 70%;
        width: 350px;
        height: 350px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;

    }

    .irisdense_input input {
        width: 350px !important;
        height: 60px !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0 !important;
        font-size: 15px !important;
    }

    .irisdense_input_button_group {
        width: 350px;
        height: 35px;
        margin-top: 10px;

    }

    .irisdense_input_button_group button {
        width: 120px;
        height: 35px;
        margin-left: 230px;

    }

    /* IRIS DATA END---------------------------*/
</style>
