<template>
	<div class="mod-specific">
		<dockitem></dockitem>
		<div class="mod-rete">
			<div id="rete-editor" class="position-relative">
				<div class="d-flex position-absolute" style="left:10px; z-index: 999;">
					<b-button id="mod-arrangebtn" variant="outline-primary" class="arrange-btn d-flex justify-content-center align-items-center" style="margin-right:10px;">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  font-scale="1.4" class="bi bi-bar-chart-steps mb-1 mr-1" viewBox="0 0 16 16">
						  		<path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z"/>
							</svg>
							노드 모듈 정리
					</b-button>
					<b-button id="mod-trashbtn" variant="outline-primary" class="trash-btn d-flex justfiy-content-center align-items-center">
					    	<svg xmlns="http://www.w3.org/2000/svg" font-scale="1.4" width="16" height="16" fill="currentColor" class="bi bi-trash mr-1" viewBox="0 0 16 16">
						  		<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
						  		<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
							</svg>
							노드 제거
					</b-button>
				</div>
			    <div class="close-btn-container position-absolute" style="right: 10px; z-index:999;">
				    <b-button id="mod-closebtn" v-on:click="close_btn" variant="outline-primary" class="modal-close-btn d-flex justfiy-content-center align-items-center">
					    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x mr-1" viewBox="0 0 16 16">
						  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
						</svg>
					    창 닫기
				    </b-button>
			    </div>
			</div>  
		</div>
	</div>
	
	
</template>

<script>
	import dockitem from './ModelDetailsDock.vue';

	import {
		createFlowEditor
	}
	from '@/modal-editor/index';

	import store from '@/store';

	import korean from '@/language/korean.json';
	import english from '@/language/english.json';


	export default {
		name: 'modal',
		data() {
			return {
				// 다국어 처리 설정
				// 모델 상세보기 내의 rete editor 상단 버튼 부분
				node_module_cleanup: '',
				rete_undo: '',
				rete_redo: '',
				json_console_check: '',
				rete_download: '',
				node_module_delete: '',

				// 언어 설정 변경
				localization: ''
			}
		},
		components: {
			dockitem
		},
		mounted() {
			createFlowEditor();

			/* global navigator */
			let locale = navigator.language || navigator.userLanguage;

			this.localization = locale.substring(0, 2);

			// 언어 설정 테스트
			// this.localization = 'en';

			switch (this.localization) {
				case 'ko':
					this.node_module_cleanup = korean.model_details_modal.node_module_cleanup;
					this.rete_undo = korean.model_details_modal.rete_undo;
					this.rete_redo = korean.model_details_modal.rete_redo;
					this.node_module_delete = korean.model_details_modal.node_module_delete;
					return;

				case 'en':
					this.node_module_cleanup = english.model_details_modal.node_module_cleanup;
					this.rete_undo = english.model_details_modal.rete_undo;
					this.rete_redo = english.model_details_modal.rete_redo;
					this.node_module_delete = english.model_details_modal.node_module_delete;
					return;
			}

		},
		methods: {
			close_btn(e) {
				this.$emit('close_btn');
			},
			// count_click() {
			// 	store.dispatch('call_count', { count: i });
			// }
		},
		// computed: {
		// 	count() {
		// 		return store.getters.get_count;
		// 	}
		// }
	}
</script>

<style scoped>
	/* 모델 상세보기 전체 부분 크기 설정 */

	.mod-specific {
		width: 100%;
		height: 100%;
		display: flex;
	}

	/* ================================= */

	.mod-specific dockitem {
		height: 100% !important;
	}

	/* rete 사이즈 조절 */

	.mod-rete {
		width: 90% !important;
		height: 100% !important;
		display: flex;
		flex-direction: column;
		justiiy-content: center;
		align-items: center;
	}

	/* ================ */

	/* rete 상단 버튼 그룹 */

	.mode-rete .mod-operation-btn-group {
		width: 100%;
		display: flex;
	}

	/* ================== */

	#rete-editor {
		width: 100% !important;
		height: 100% !important;
		background-color: #ffffff;
		background-image: radial-gradient(rgba(81, 45, 168, 0.07) 10%, transparent 0), radial-gradient(rgba(81, 45, 168, 0.07) 10%, transparent 0);
		background-position: 0 0, 10px 10px;
		background-size: 10px 10px;
		border: 2px solid;
		border-image-slice: 1;
		border-width: 1px;
		border-image-source: linear-gradient(to right, rgba(81, 45, 168, 0.4), rgba(81, 45, 168, 0.4));
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		position: relative;
		padding: 5px;
	}

	#rete-editor .mod-operation-btn-group {
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
	}

	#rete-editor .node {
		background-color: red !important;
		border: 1px solid black !important;
	}
</style>
