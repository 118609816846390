<template>
    <!-- 추론 컴포넌트 전체 -->
    <div class="maininference">
        <!--<b-overlay variant="light" opacity="0.5" no-wrap :show="show_loading_animation"/>-->
        <!-- 추론 컴포넌트 헤더 -->
        <loading-component v-if="is_loading_about_selection"></loading-component>
        <SelectModelBar ref="selectModelBar" v-on:setPretrainedModel="setPretrainedModel" v-on:setTensorModel="setTensorModel"/>
         <div class="maininference_header">
            <!-- Prediction(추론)-->
            <label>{{ maininference_header }}</label>
            <OptionCheckBox 
                v-on:tensorspace_check_tf="tensorspace_check_tf"
                v-on:datainput_check_tf="datainput_check_tf"
                v-on:inferenceresult_check_tf="inferenceresult_check_tf"
            />
        </div>
        <!-- 추론 컴포넌트 내용 부분 -->
        <div class="maininference_content">
            <!--사전 훈련된 모델 선택 부분-->
            <!--<SelectModel v-on:setPretrainedModel="setPretrainedModel" />-->
            
            <!--텐서 스페이스 -->
            <tensor-model ref="tensormodel" v-show="tensorpace_show"></tensor-model>
            
            <!--데이터 입력 부분-->
            <DataInput v-show="datainput_show"
                ref="datainput"
                v-on:drawing_to_inference="input_drawing_image" 
                v-on:text_to_inference="input_iris_text" 
                v-on:file_to_inference="input_file_image" 
                v-on:capture_to_inference="input_capture_image"
                v-on:startBtClicked="webcam_startBtClicked"
                v-on:stopBtClicked="webcam_stopBtClicked" />
            
            <!--테스트 중 ! 컴포넌트명 바인딩으로 보이기 가능 -->
            <!--<component v-bind:is="testcom" ref="datainput"-->
            <!--    v-on:drawing_to_inference="input_drawing_image" -->
            <!--    v-on:text_to_inference="input_iris_text" -->
            <!--    v-on:file_to_inference="input_file_image" -->
            <!--    v-on:capture_to_inference="input_capture_image"-->
            <!--    v-on:startBtClicked="webcam_startBtClicked"-->
            <!--    v-on:stopBtClicked="webcam_stopBtClicked"/>-->
        
            <!--추론 결과 부분-->
            <InferenceResult v-show="inferenceresult_show" ref="inferenceResult"/>
        </div>
        <!-- 추론 컴포넌트 내용 부분 END-->
    </div>
    <!-- 추론 컴포넌트 전체 END-->
</template>
<script>
    // vuex =====================
    import store from '@/store';
    // ==========================

    // tensorflow 불러오기 ================================================
    import * as tf from '@tensorflow/tfjs';
    import * as tfvis from '@tensorflow/tfjs-vis';
    import * as tf_core from '@tensorflow/tfjs-core';
    // import '@tensorflow/tfjs-backend-webgl';

    import { fitCallbacks } from '@tensorflow/tfjs-vis/dist/show/history';
    // ====================================================================

    // coco-ssd 모델 불러오기 =============================
    import * as cocoSsd from '@tensorflow-models/coco-ssd';
    //=====================================================

    // mnist 추론데이터 불러오기 ==========================
    import { MnistData } from '@/MnistData/predictdata.js';
    // ====================================================

    // iris 추론데이터 불러오기 =========================
    import { IrisData } from '@/IrisData/predictdata.js';
    // ==================================================

    // mobile net 추론 관련 불러오기 ==============================
    import { MobileData } from '@/MobileNet/predictdata.js';
    // import MobileNetData from '@/MobileNet/data.json';
    // ============================================================

    // GAN CARTOONIZER 추론 관련 불러오기 =========================
    import { cartoon } from '@/gan/predict.js';
    // ============================================================

    // 언어 설정 JSON 파일 가져오기 ===============================
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================================

    // 데이터 입력, 모델 선택, 추론 결과 불러오기 =================

    // import SelectModel from './SelectModel/SelectModelComp';
    
    import TensorModel from './SelectModel/TensorSpaceComp';
    
    import SelectModelBar from './SelectModel/SelectModelBar/SelectModelBar';


    // 체크박스
    import OptionCheckBox from './InferenceOptionCheck/OptionCheckBox';

    import DataInput from './DataInput/DataInputComp';
    import InferenceResult from './InferenceResult/InferenceResultComp';

    // ============================================================


    // HANDPOSE ===================================================
    import * as handpose from '@tensorflow-models/handpose';
    // ============================================================    


    // Loading ====================================================
    import LoadingComponent from "@/components/Loading/Loading";
    // ============================================================


    let DOING_CARTOON = false;
    let DOING_HANDPOSE = false;
    let DOING_CONTEXT_R_CNN = false;
    // MNIST DATA
    const data = new MnistData();
    // IRIS DATA
    const irisdata = new IrisData();
    // MOBILE NET DATA
    const mobiledata = new MobileData();
    // GAN-CARTOONIZER
    const cartoonData = new cartoon();

    // 모델 초기화
    let model = null;

    // stats 변수로 선언
    const stats = new Stats();

    // ============================================================

    export default {
        name: 'maininference',
        data() {
            return {
                // input images
                images: [],

                // 드로잉, 파일 업로드, 웹캠 캡쳐 파일 
                session_Inference_Image: '',

                // 아이리스 데이터
                iris_array: [],

                // loading 표시 활성화
                // loading_animation_visible: false,
                // 다국어 변경시 이용할 부분 
                maininference_header: '',

                // 추론 결과 
                // mi_content_header_result: '',

                // 언어 설정
                localization: '',
                
                tensorpace_show: false,
                datainput_show: true,
                inferenceresult_show: true,
                testcom: '',
                
                
                //로딩 유무 판별 함수
                is_loading_about_selection: false,
            }
        },
        // component 등록
        components: {
            // SelectModel,
            TensorModel,
            SelectModelBar,
            DataInput,
            InferenceResult,
            OptionCheckBox,
            LoadingComponent
        },
        mounted() {
            // pretrain_model_name 초기화
            // 사전 훈련된 모델 선택시 모델 이름으로 분기
            // InferencePage 실행시 초기화 해줄 필요가 있음.
            store.dispatch('call_pretrain_model_name', { pretrain_model_name: null });

            // 추론 결과 부분 컴포넌트 화 작업 ================================================================
            /* global navigator */

            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // 언어 설정 테스트 
            // this.localization = 'en';

            // if locale 한국어 설정
            switch (this.localization) {
                case 'ko':
                    // 화면 설정
                    this.maininference_header = korean.inference.maininference_header;
                    return;
                case 'en':
                    // 화면 설정
                    this.maininference_header = english.inference.maininference_header;
                    return;
            }
            // ==============================================================================================
            
            // 모델 선택 모달 띄우기 
        },
        methods: {
            // 텐서 스페이스 체크 박스 =========================
            tensorspace_check_tf(check_tf) {
                this.tensorpace_show = check_tf
            },            
            // 데이터 인풋 체크 박스 ===========================
            datainput_check_tf(check_tf){
                this.datainput_show = check_tf
                // if(check_tf === true)  {
                //     this.testcom = DataInput
                // } else {
                //     this.testcom = ''
                // }
            },
            // 추론 결과 체크 박스
            inferenceresult_check_tf(check_tf) {
                this.inferenceresult_show = check_tf
            },
            
            // ===================================================================================
            

            // 데이터 인풋 // 드로잉 =============================================================
            input_drawing_image() {
                console.log("input_drawing_image");

                this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');

                this.onClick_PredictRun();
            },
            // ===================================================================================
            // 데이터 인풋 // 아이리스 텍스트 데이터 =============================================
            input_iris_text(iris_array) {
                console.log("input_iris_text");
                this.iris_array = iris_array;

                console.log(this.iris_array);
                this.onClick_PredictRun();
            },
            // 데이터 인풋 // 업로드 파일 데이터 =================================================
            input_file_image() {
                this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');

                this.onClick_PredictRun();
            },
            // ===================================================================================
            // 데이터 인풋 // 캡쳐 이미지 업로드
            input_capture_image() {
                this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');
                this.onClick_PredictRun();
            },
            // ===================================================================================
            // 추론 시작 =========================================================================
            onClick_PredictRun() {
                console.log("onclick predict run");
                // 추론 결과 부분 결과 이미지 보이기 // MNIST CONVOLUTION NET, MNIST DENSE NET, MOBILE NET
                this.inference_run();
                this.$refs.inferenceResult.show_result_image();
            },
            // ===================================================================================
            // 데이터 인풋 // 웹캠 스타트 버튼 이벤트 수신 // 웹캠을 이용하는 모델들을 분기하여 추론 실행 
            webcam_startBtClicked() {
                this.inference_run();
                console.log("webcam_startBtClicked");
            },
            // ====================================================================================

            // 데이터 인풋 // 웹캠 스탑 버튼 이벤트 수신 // 웹캠 스탑
            webcam_stopBtClicked() {
                console.log("webcam_stopBtClicked");
                DOING_CARTOON = false;
                DOING_HANDPOSE = false;
                DOING_CONTEXT_R_CNN = false;
            },
            // ========================================================

            // 모델 불러오기 부분 =================================================================
            async setPretrainedModel(pretrained_model) {
                //로딩 시작
                this.is_loading_about_selection = true;
                
                let selected_pretrain_model = store.getters.get_pretrain_model_name;

                // ===================================
                // pretrained_model 받기 // not vuex
                // let selected_pretrain_model = pretrained_model;
                // ===================================

                // // 선택된 모델이 없을때 ALERT // 선택된 모델이 있을때는 실행
                // if (selected_pretrain_model === null) {
                //     this.$refs['model_select_alert'].show();
                // }
                // else {
                // 사전 훈련된 모델 이름으로 분기
                switch (selected_pretrain_model) {
                    // 외부 local storage에서 모델 가져오기
                    case "local_upload_model":
                        let local_model = store.getters.get_local_upload_model;
                        console.log(local_model)
                        model = await tf.loadLayersModel(tf.io.browserFiles(
                            [local_model[0], local_model[1]]
                        ));
                        break;
                    case "MNIST CONVOLUTION NET":
                        model = await tf.loadLayersModel('https://nocoding-ai.s3.ap-northeast-2.amazonaws.com/pretrained-model/mnistconv.json');
                        break;
                    case "MNIST DENSE NET":
                        model = await tf.loadLayersModel('https://nocoding-ai.s3.ap-northeast-2.amazonaws.com/pretrained-model/mnistdense.json');
                        break;
                    case "IRIS DENSE NET":
                        model = await tf.loadLayersModel('https://nocoding-ai.s3.ap-northeast-2.amazonaws.com/pretrained-model/irisdense.json');
                        break;
                    case "MOBILE NET":
                        model = await tf.loadGraphModel("https://tfhub.dev/google/tfjs-model/imagenet/mobilenet_v2_035_224/classification/3/default/1", { fromTFHub: true });
                        break;
                    case "GAN-CARTOONIZER":
                        model = await tf.loadGraphModel("https://nocoding-ai.s3.ap-northeast-2.amazonaws.com/pretrained-model/model.json");
                        break;
                    case "CONTEXT R-CNN":
                        model = await cocoSsd.load();
                        break;
                    case "HAND POSE":
                        await tf_core.setBackend('webgl');
                        model = await handpose.load();
                        break;
                }
                // }
                
                
                //로딩 끝
                this.is_loading_about_selection = false;
            },
            // ===========================================================================

            // 모델 추론 하기 ============================================================
            async inference_run() {
                this.webcam_stopBtClicked();
                // 사전 훈련된 모델 이름
                let selected_pretrain_model = store.getters.get_pretrain_model_name;
                // 선택된 모델이 없을때 ALERT // 선택된 모델이 있을때는 실행
                if (selected_pretrain_model === null) {
                    this.$refs['model_select_alert'].show();
                }
                else {
                    // 사전 훈련된 모델 이름으로 분기
                    switch (selected_pretrain_model) {
                        case "local_upload_model":
                            data.load(this, model, this.session_Inference_Image, this.inversion_check);
                            break;
                        case "MNIST CONVOLUTION NET":
                            data.load(this, model, this.session_Inference_Image, this.inversion_check);
                            break;
                        case "MNIST DENSE NET":
                            data.load(this, model, this.session_Inference_Image, this.inversion_check);
                            break;
                        case "IRIS DENSE NET":
                            irisdata.load(this, model, this.iris_array);
                            break;
                        case "MOBILE NET":
                            mobiledata.load(this, model, this.session_Inference_Image);
                            break;
                        case "GAN-CARTOONIZER":
                            DOING_CARTOON = true;
                            this.repeatCartoonizerPredict();
                            break;
                        case "CONTEXT R-CNN":
                            DOING_CONTEXT_R_CNN = true;
                            this.repeatContextPredict();
                            break;
                        case "HAND POSE":
                            DOING_HANDPOSE = true;
                            this.repeatHandPosePredict();
                            break;
                    }
                }
            },
            // 추론 결과 부분 // GAN-CARTOONIZER 추론 부분
            // 모델 추론 구조가 다른 것과 상이 하여 // 추후 변경해 주어야 함.
            async repeatCartoonizerPredict() {
                // 스탯 시작
                stats.begin();

                // CARTOONIZER 시작 
                if (DOING_CARTOON) {
                    const canvas_webcam = this.$refs.datainput.getVideoCapture();

                    // 캔버스 base 64 파일로 변환 
                    let BASE64_cartoon = canvas_webcam.toDataURL('image/png');
                    let image_output = await cartoonData.load(this, model, BASE64_cartoon);
                    const rafID = requestAnimationFrame(this.repeatCartoonizerPredict);
                }
                // 스탯 중단 
                stats.end();
            },
            // ======================================================================

            // 추론 결과 부분 // HANDPOSE 추론 부분 =================================
            async repeatHandPosePredict() {
                console.log("repeatHandPosePredict");
                // 스탯 시작
                stats.begin();
                if (DOING_HANDPOSE) {
                    const canvas_webcam = this.$refs.datainput.getVideoCapture();
                    const predictions = await model.estimateHands(canvas_webcam);
                    this.$refs.inferenceResult.drawHandPose(predictions, this.$refs.datainput.getVideoCapture());
                    const rafID = requestAnimationFrame(this.repeatHandPosePredict);
                }
                // 스탯 중단
                stats.end();
            },

            // 추론 결과 부분 // CONTEXT R CNN 추론 부분 ============================
            async repeatContextPredict() {
                // 스탯 시작
                stats.begin();
                if (DOING_CONTEXT_R_CNN) {
                    const canvas_webcam = this.$refs.datainput.getVideoCapture();
                    // 이미지를 통해 DETECTION 하고 예측값 변수로 저장
                    const predictions = await model.detect(canvas_webcam);
                    // context r cnn 
                    this.$refs.inferenceResult.drawContext(predictions, this.$refs.datainput.getVideoCapture());
                    const rafID = requestAnimationFrame(this.repeatContextPredict);
                }
                // 스탯 중단
                stats.end();
            },
            // ======================================================================

            // 로딩 부분 을 보이게 하기 ===================
            // 미완성 부분 이므로 추후 동작 가능하게 할 것임
            // show_loading_animation() {
            // this.loading_animation_visible = true;
            // }
            // ============================================
            
            // 텐서스페이스 모델 불러오기 ===========================================
            setTensorModel(pretrained_model) {
                this.$refs.tensormodel.setTensorSpaceModel(pretrained_model);
             }
            // 텐서스페이스 모델 불러오기 ===========================================
        },
        computed: {
            // 선택된 사전훈련된 모델
            selected_pretrain_model() {
                return store.getters.get_pretrain_model_name;
            },
            // 색상 반전 체크
            inversion_check() {
                return store.getters.get_inversion_tf;
            }
        }
    }
</script>
<style scoped>
    #tensor_img {
        width: 350px;
        height: 350px;
    }


    .maininference {
        width: 100%;
        height: 100%;
        /*height: 977px;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        background: #F5F6FA;
        position: relative;
    }

    .maininference .overlay_comment {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media screen and (min-width: 1920px) {
        .maininference {
            height: 100%;
        }
    }

    .maininference_header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .maininference_header label {
        color: #858585;
        font-size: 35px;
        font-weight: bold;
        margin-left: 95px;
        margin-bottom: 0 !important;
    }

    .maininference_header .b-icon {
        margin-left: auto;
        margin-right: 10px;
    }

    /*============================================*/

    .maininference_content {
        display: flex;
        width: 100%;
        height: 80%;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .data_input_content,
    .model_upload_content,
    .inference_content {
        width: 500px;
        height: 700px;
        margin-top: 30px;
        margin-bottom: 100px;
    }

    .data_input_content {
        margin-left: 30px;
    }

    .model_upload_content {
        margin-left: 30px;
    }

    .inference_content {
        margin-left: 30px;
    }


    .mi_content_header {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .mi_content_header label {
        font-size: 20px;
        font-weight: bold;
    }
</style>
