<template>
    <div class="Preprocessor">
        <label>종류</label>
	    	<select v-model="kinds_selected">
		    	<option v-for="option in options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>변환될 차원</label>
	    	<select v-model="change_dims_selected">
		    	<option v-for="option in options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    </div>
</template>
<script>
    export default {
        name: 'Preprocessor',
        data() {
            return {
                kinds_selected: '차원 확장',
                change_dims_selected: '(None,244,244,3)',
                options: [
                    { text: '차원 확장', value: '차원 확장' },
                    { text: '(None,244,244,3)', value: '(None,244,244,3)' },
                    { text: '훈련/검증 비율', value: '훈련/검증 비율' },
                    { text: '훈련:8 검증:2', value: '훈련:8 검증:2' }
                ]
            }
        }
    }
</script>

<style scoped>
    .Preprocessor {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .Preprocessor label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .Preprocessor select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }
</style>
