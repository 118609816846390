<template>
    <!--훈련된 모델 선택 헤더 바-->
    <div class="select_model_bar">
        <b-row class="w-100">
            <b-col cols="10" class="d-flex align-items-center">
                <label class="title">사전 훈련된 모델</label>
                <div class="selected_model_describe" v-if="model_name === ''">
                    <label class="model_not_selected"> 선택된 모델이 없음</label>
                </div>
                <div class="selected_model_describe" v-else>
                    <label class="model_name_title">모델 이름: </label>
                    <label class="model_name_content"> {{ model_name }}</label>
                    <label class="model_describe_title">모델 설명 :</label>
                    <label class="model_describe_content">{{ model_describe }}</label>
                </div>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-end">
                <b-button @click="show_ModelSelct_Modal" variant="primary" class="select_model_modal_button"> 모델 선택하기</b-button>
                <b-modal ref="model_picker" id="select_model_modal" centered hide-header hide-footer size="xl">
                    <SelectModelModal v-on:close_btn="hide_ModelSelect_Modal" />
                </b-modal>
            </b-col>
        </b-row>
    </div>
	<!--훈련된 모델 선택 헤더 바 END-->
</template>
<script>
    import SelectModelModal from '../SelectModelModal';

    export default {
        name: 'select_model_bar',
        data() {
            return {

                // selected pretrained model
                pretrained_model: '',

                model_name: '',
                model_describe: '',

            }
        },
        components: {
            // 모델 선택하기 모달 부분 컴포넌트 등록
            SelectModelModal,
        },
        methods: {
            // 사전훈련된 모델 선택 모달 열기
            show_ModelSelct_Modal() {
                this.$refs['model_picker'].show();
            },
            // 사전훈련된 모델 선택 모달 닫기 // 텐서스페이스 실행 // 추론 이미지 초기화
            hide_ModelSelect_Modal(pretrained_model) {

                // 모델 선택이 되었을때 값을 받아서 수행.
                try {
                    // 모달 닫기
                    this.$refs['model_picker'].hide();

                    // 반영될 파일 이미지 초기화
                    // this.images = [];
                    // 추론 이미지 초기화
                    // this.session_Inference_Image = '';

                    // 로컬 파일 업로드시 지정이 안된 관계로 임시로 글 넣어줌
                    if (pretrained_model === 'local_upload_model') {
                        this.model_name = '로컬 파일 모델';
                        this.model_describe = '모델 만들기에서 훈련시킨 모델 파일입니다.';
                    }
                    else {
                        this.model_name = pretrained_model.name;
                        this.model_describe = pretrained_model.data;
                    }

                    this.pretrained_model = pretrained_model;

                    // 모델 선택 // 사전 훈련된 모델 선택에서 불러오기 할 부분
                    this.$emit('setPretrainedModel', this.pretrained_model);

                    // 텐서스페이스 모델 불러오기
                    this.$emit('setTensorModel', this.pretrained_model);
                }
                catch (e) {
                    // alert('모델이 선택되지 않았습니다. 추론 하기 위해선 모델을 선택해야 합니다.');
                }
            },
        },
    }
</script>
<style scoped>
    .select_model_bar {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        background: #ffffff !important;
        /*border: 1px solid #bfc3c5;*/
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24) !important;
        padding: 20px;
        /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);*/
    }

    /*label default 값 초기화 */

    .select_model_bar label {
        margin-bottom: 0 !important;
    }

    /* 사전훈련된 모델 타이틀 */

    .select_model_bar .title {
        font-size: 20px;
        font-weight: bold;
        margin-left: 20px;
        margin-right: 70px;
    }

    /*모델 설명*/

    .selected_model_describe {
        display: flex;
    }

    .model_not_selected {
        font-size: 16px;
        font-weight: bold;
        margin-right: 10px;
        opacity: 0.8;
    }

    .model_name_title {
        font-size: 16px;
        font-weight: bold;
        margin-right: 10px;
        opacity: 0.8;
    }

    .model_name_content {
        font-size: 16px;
        opacity: 0.8;
        margin-right: 10px;
    }

    .model_describe_title {
        font-size: 16px;
        font-weight: bold;
        margin-right: 10px;
        opacity: 0.8;
    }

    .model_describe_content {
        font-size: 16px;
        opacity: 0.8;
    }

    /* 모델 선택 버튼 */

    .select_model_modal_button {
        color: #fff;
        /*background: rgba(119, 132, 251, 0.7);*/
        background: #5E72E4;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border: none;
    }

    .select_model_modal_button:hover {
        background: #4351A3 !important;
    }

    .select_model_modal_button:focus {
        background: #4351A3 !important;
    }
</style>
