import Rete from "rete";
import { numSocket } from '../sockets';
import TrainerControl from './TrainerControl';


export default class TrainerComponent extends Rete.Component {
	constructor() {
		super("트레이너");
		this.data = {
			render: "vue"
		};
	}
	builder(node) {
		//input, output 소켓의 이름을 컴포넌트명/노드id/_input|_output으로 식별
		const input_name = "trainer" + node.id + "_input";
		// multiple 입출력 true
		let input1 = new Rete.Input(input_name, "입력", numSocket, true);
		return node
			.addControl(new TrainerControl(this.editor, "trainer"))
			.addInput(input1);
	}
	worker(node, inputs, outputs) {

	}
}
