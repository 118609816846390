import Rete from 'rete';
import { numSocket } from '../../sockets';
import DefaultInputControl from '../../controls/basic-controls/DefaultInputControl';

export default class InputDataComponent extends Rete.Component {
	constructor() {
		super("데이터입력(기본)");
	}

	builder(node) {
		let out1 = new Rete.Output("output1", "value", numSocket);

		return node
			.addControl(new DefaultInputControl(this.editor, "num"))
			.addOutput(out1);
	}
	worker(node, inputs, outputs) {
		outputs["num"] = node.data.num;
	}
}
