import Rete from "rete";
import { numSocket } from '../sockets';
import TrainModelControl from './TrainModelControl';


export default class TrainModelComponent extends Rete.Component {
    constructor() {
        super("훈련시킬모델");
        this.data = {
            render: "vue"
        };
    }
    builder(node) {
        console.log(node);
        //input, output 소켓의 이름을 컴포넌트명/노드id/_input|_output으로 식별
        // const input_name = "trainModel" + node.id + "_input";
        const output_name = "trainModel" + node.id + "_output";
        // multiple 입출력 true
        let output1 = new Rete.Output(output_name, "출력", numSocket, true);
        // let input1 = new Rete.Input(input_name, "입력", numSocket, true);
        
       if (node.data.input_data) {
            node.data.input_data.forEach(n => {
                node.addInput(new Rete.Input(n.key, n.name, numSocket, true));
            });
        }

        if (node.data.output_data) {
            node.data.output_data.forEach(n => {
                node.addOutput(new Rete.Output(n.key, n.name, numSocket, true));
            });
        }
        
        return node
            .addControl(new TrainModelControl(this.editor, "trainModel"))
            .addOutput(output1)/*.addInput(input1)*/;
    }
    worker(node, inputs, outputs) {
       

    }
}
