<template>
    <div class="modelmonitoringVueControl">
        <div class="modelmonitoringVueControl__option d-flex flex-column mb-4">
            <label class="mb-1">종류</label>
    	    	<select class="my-0" v-model="kinds_selected" @change="changeKind">
    		    	<option v-for="option in kinds_options" v-bind:value="option.value" >
    			    	{{ option.text }}
    			    </option>
    	    	</select>
        </div>
        
        <div class="modelmonitoringVueControl__option d-flex flex-column mb-4">
        	<label class="mb-1">모니터링 위치</label>
    	    	<select class="my-0" v-model="monitoring_loc_selected" @change="changelocation">
    		    	<option v-for="option in monitoring_loc_options" v-bind:value="option.value">
    			    	{{ option.text }}
    			    </option>
    	    	</select>
        </div>
        
        <div class="modelmonitoringVueControl__option d-flex flex-column mb-4">
    	    <label class="mb-1">모니터링 방식</label>
    	    	<select class="my-0" v-model="monitoring_kinds_selected" @change='changemonitorkind'>
    		    	<option v-for="option in monitoring_kinds_options" v-bind:value="option.value">
    			    	{{ option.text }}
    			    </option>
    	    	</select>
        </div>
        
    	<!--<label>구분 기준</label>-->
	    <!--	<select v-model="distribution_selected">-->
		   <!-- 	<option v-for="option in distribution_options" v-bind:value="option.value">-->
			  <!--  	{{ option.text }}-->
			  <!--  </option>-->
	    <!--	</select>-->
    </div>
</template>
<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';

    import * as tf from '@tensorflow/tfjs';
    import * as tfvis from '@tensorflow/tfjs-vis';
    import { MnistData } from '@/MnistData/data.js';
    import { fitCallbacks } from '@tensorflow/tfjs-vis/dist/show/history';


    export default {
        name: 'modelmonitoringVueControl',
        data() {
            return {
                kinds_selected: '',
                monitoring_loc_selected: '',
                monitoring_kinds_selected: '',
                distribution_selected: '폴더 이름',
                kinds_options: [
                    { text: '데이터 시각화', value: "data_visualization" },
                    { text: '데이터 문자화', value: "data_text" },
                    { text: '모델 구조 보기', value: 'model_visualization' },
                ],
                monitoring_loc_options: [
                    { text: '우측 슬라이드', value: 'right_slide' },
                    { text: '하단 왼쪽', value: 'bottom_left' }
                ],
                monitoring_kinds_options: [
                    { text: '원형 그래프', value: 'donut_graph' },
                    { text: '데이터 차트', value: 'data_chart' },
                    { text: '불러온 이미지', value: 'upload_image' },
                    { text: 'visor', value: 'visor' }
                ],
                distribution_options: [
                    { text: '폴더 이름', value: '폴더 이름' }
                ]
            }
        },
        methods: {
            init() {
                tf;
                tfvis;
                MnistData;
            },
            changeKind(e) {
                switch (e.target.value) {
                    case 'data_visualization':
                        store.dispatch('call_model_visualization', { model_visualization: 'data_visualization' });
                        return
                    case 'data_text':
                        store.dispatch('call_model_visualization', { model_visualization: 'data_text' });
                        return
                    case 'model_visualization':
                        store.dispatch('call_model_visualization', { model_visualization: 'model_visualization' });
                        return
                }
            },
            changelocation(e) {
                switch (e.target.value) {
                    case 'bottom_right':
                        store.dispatch('call_model_location', { model_location: 'bottom_right' });
                        this.monitoring_kinds_selected = ''
                        return

                    case 'bottom_left':
                        store.dispatch('call_model_location', { model_location: 'bottom_left' });
                        this.monitoring_kinds_selected = ''
                        return
                }
            },
            changemonitorkind(e) {

                if (store.getters.getConnecting === true && store.getters.getVisualization == 'model_visualization') {

                    switch (e.target.value) {
                        case 'donut_graph':
                            store.dispatch('call_monitor_kinds', { monitorKinds: 'Datagraph' });
                            return

                        case 'data_chart':
                            store.dispatch('call_monitor_kinds', { monitorKinds: 'DataTable' });
                            return

                        case 'upload_image':
                            store.dispatch("call_monitor_kinds", { monitorKinds: 'MnistImage' });
                            return
                    }
                }
            },

            changetest(e) {
                this.kinds_selected = 'model_visualization';
                this.monitoring_loc_selected = 'right_slide';
                this.monitoring_kinds_selected = '';
            }
        },
        mounted() {
            this.changetest();
        },
        updated() {

            if (this.monitoring_kinds_selected === "visor") {

                run();
                async function run() {
                    let model_name = store.getters.get_model_name;
                    const model = await tf.loadLayersModel('localstorage://'+model_name);
                    tfvis.show.modelSummary({ name: 'Model Architecture' }, model);
                }

                function getModel() {
                    const model = tf.sequential();

                    const IMAGE_WIDTH = 28;
                    const IMAGE_HEIGHT = 28;
                    const IMAGE_CHANNELS = 1;

                    // sessionStorage에 저장된 conv2d 값 가져오기
                    let session_conv_json1 = sessionStorage.getItem('Conv2d8');

                    let session_conv1 = JSON.parse(session_conv_json1);

                    model.add(tf.layers.conv2d({
                        inputShape: [IMAGE_WIDTH, IMAGE_HEIGHT, IMAGE_CHANNELS],
                        kernelSize: Number(session_conv1.kernelSize),
                        filters: Number(session_conv1.filters),
                        strides: Number(session_conv1.strides),
                        activation: session_conv1.activation,
                        kernelInitializer: session_conv1.kernelInitializer
                    }));

                    // sessionStorage에 저장된 maxpooling 값 가져오기
                    let session_maxpooling_json1 = sessionStorage.getItem('MaxPooling2d9');
                    // 가져온 maxpooling 값 string 에서 json으로 parsing
                    let session_maxpooling1 = JSON.parse(session_maxpooling_json1);

                    // 가져온 배열의 값들은 String 이나 number로 변환해주기
                    let mc_poolSize1 = session_maxpooling1.poolSize;
                    let mc_poolSize_change1 = [Number(mc_poolSize1[0]), Number(mc_poolSize1[2])];
                    let mc_strides1 = session_maxpooling1.strides;
                    let mc_strides_change1 = [Number(mc_strides1[0]), Number(mc_strides1[2])];

                    // 해당 maxpooling layer에 값 넣기
                    model.add(tf.layers.maxPooling2d({ poolSize: mc_poolSize_change1, strides: mc_strides_change1 }))

                    let session_conv_json2 = sessionStorage.getItem('Conv2d10');

                    let session_conv2 = JSON.parse(session_conv_json2);

                    model.add(tf.layers.conv2d({
                        kernelSize: Number(session_conv2.kernelSize),
                        filters: Number(session_conv2.filters),
                        strides: Number(session_conv2.strides),
                        activation: session_conv2.activation,
                        kernelInitializer: session_conv2.kernelInitializer
                    }));

                    let session_maxpooling_json2 = sessionStorage.getItem('MaxPooling2d11');

                    let session_maxpooling2 = JSON.parse(session_maxpooling_json2);

                    let mc_poolSize2 = session_maxpooling2.poolSize;
                    let mc_poolSize_change2 = [Number(mc_poolSize2[0]), Number(mc_poolSize2[2])];
                    let mc_strides2 = session_maxpooling2.strides;
                    let mc_strides_change2 = [Number(mc_strides2[0]), Number(mc_strides2[2])];

                    model.add(tf.layers.maxPooling2d({ poolSize: mc_poolSize_change2, strides: mc_strides_change2 }));

                    model.add(tf.layers.flatten());

                    let session_dense_json = sessionStorage.getItem('덴스(dense)13');


                    let session_dense = JSON.parse(session_dense_json);

                    let dense_units = Number(session_dense.units);

                    let dense_kernelInitializer = session_dense.kernelInitializer;

                    let dense_activation = session_dense.activation;

                    model.add(tf.layers.dense({
                        units: dense_units,
                        kernelInitializer: dense_kernelInitializer,
                        activation: dense_activation
                    }));

                    const optimizer = tf.train.adam();
                    model.compile({
                        optimizer: optimizer,
                        loss: 'categoricalCrossentropy',
                        metrics: ['accuracy'],
                    });

                    return model;
                }
            }

        },
    }
</script>

<style scoped>
    .modelmonitoringVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .modelmonitoringVueControl label {
        font-size: 10px !important;
        text-align: left;
    }

    .modelmonitoringVueControl select,
    input {
        border-radius: 5px !important;
        width: 180px !important;
        box-shadow: none;
        border-color: rgba(0, 0, 0, 0.22) !important;
    }
</style>
