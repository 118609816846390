import * as tf from '@tensorflow/tfjs';

const APP = {
  model: null,
  size: 256,
  //   source: document.getElementById('source'),
  canvas: document.getElementById('result'),
  //   status: document.getElementById('status'),
  //   download: document.getElementById('download'),
  $: n => document.getElementById(n),
  path: 'https://nocoding-ai.s3.ap-northeast-2.amazonaws.com/pretrained-model/model.json'
};

const runModel = async() => {
  APP.model = await tf.loadGraphModel(APP.path);
  // warm up
  //   APP.model.predict(tf.zeros([1, 1, 1, 3])).dispose()
  //   predict(APP.source)
  //   APP.source.onload = () => {
  //     setTimeout(() => {
  //       APP.status.classList.remove('d-none')
  //       APP.canvas.classList.add('d-none')
  //       APP.canvas.classList.remove('d-block')
  //     }, 0)
  //     setTimeout(() => { predict(APP.source) }, 50)
  //   }
};



// tf.setBackend('wasm').then(() => runModel());

export class cartoon {
  constructor() {
    const ct_model = APP.model;
  }

  async load(_this, model, image_path) {
    // console.log('@@@')
    // console.log(model);

    const img = new Image();
    const canvas = document.createElement('canvas');
    canvas.width = 350;
    canvas.height = 350;
    const ctx = canvas.getContext('2d');
    // console.log(canvas.width,canvas.height);


    const imgRequest = new Promise((resolve, reject) => {
      img.crossOrigin = '';

      img.onload = () => {
        // img.width = 224;
        // img.height = 224;
        //img.width = img.naturalWidth;
        //img.height = img.naturalHeight;

        // console.log(img.naturalWidth, img.naturalHeight);

        ////////////////////////////////////////////////////////////////////////
        //Sean-이미지가 왜곡되서 작은 변을 기준으로 정사각형으로 자름
        ////////////////////////////////////////////////////////////////////////

        let margin = 0;
        if (img.naturalWidth > img.naturalHeight) {
          margin = (img.naturalWidth - img.naturalHeight) / 2;
          ctx.drawImage(img, margin, 0, img.naturalHeight, img.naturalHeight, 0, 0, 350, 350);
        }
        else {
          margin = (img.naturalHeight - img.naturalWidth) / 2;
          ctx.drawImage(img, 0, margin, img.naturalWidth, img.naturalWidth, 0, 0, 350, 350);
        }
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        return this.predict(model, imageData);

        ////////////////////////////////////////////////////////////////////////
        //Sean-pre-image보여주는 곳도 이미지 파일을 그냥 뿌려주므로 찌부되는 왜곡이 있음. 위와 같은 방식으로 잘라서 붙여햐 함..
        ////////////////////////////////////////////////////////////////////////



        // const datasetBytesBuffer =
        //     new ArrayBuffer(IMAGE_SIZE * 4);

        // const chunkSize = 1;
        //canvas.width = img.width;
        //canvas.height = img.height;

        // const datasetBytesView = new Float32Array(datasetBytesBuffer, 0, IMAGE_SIZE);
        //*4 , IMAGE_SIZE * chunkSize);
        // console.log(datasetBytesView)
        // ctx.drawImage(img, 0, chunkSize, img.width, chunkSize, 0, 0, img.width, chunkSize);
        //ctx.drawImage(img, 0, 0, 350, 350);


        //const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        //return this.predict(model, imageData);
      };
      img.src = image_path;

    });
  }

  async normalize(img) {
    const [w, h] = img.shape;
    // pad
    const pad = (w > h) ? [
      [0, 0],
      [w - h, 0],
      [0, 0]
    ] : [
      [h - w, 0],
      [0, 0],
      [0, 0]
    ];
    img = img.pad(pad);
    const size = APP.size;
    img = tf.image.resizeBilinear(img, [size, size]).reshape([1, size, size, 3]);
    const offset = tf.scalar(127.5);
    return img.sub(offset).div(offset);
  }

  async predict(model, imgElement) {
    let img = tf.browser.fromPixels(imgElement);
    const shape = img.shape;
    const [w, h] = shape;
    img = await this.normalize(img);
    // const t0 = performance.now()
    // console.log(img);
    const result = await model.predict({ 'input_photo:0': img });
    console.log('^^^^^');
    // console.log(result);
    // const timer = performance.now() - t0
    let img_out = await result.squeeze().sub(tf.scalar(-1)).div(tf.scalar(2)).clipByValue(0, 1);
    const pad = Math.round(Math.abs(w - h) / Math.max(w, h) * APP.size);
    const slice = (w > h) ? [0, pad, 0] : [pad, 0, 0];
    img_out = img_out.slice(slice);

    //console.log(img_out);
    // console.log('^^^^^^^^');
    await this.drawTest(img_out, shape);
    // return img_out;
    // draw(img_out, shape)
    // console.log(Math.round(timer / 1000 * 10) / 10)
  }

  async drawTest(img_out, size) {
    const scaleby = size[0] / img_out.shape[0];
    tf.browser.toPixels(img_out, document.getElementById('result'));
    //console.log(img_out);
    //this.scaleCanvas();
  }

  scaleCanvas(pct = 4) {
    const canvas = document.getElementById('result');
    const tmpcan = document.createElement('canvas');
    const tctx = tmpcan.getContext('2d');
    const cw = canvas.width;
    const ch = canvas.height;
    console.log(cw, ch);
    tmpcan.width = cw;
    tmpcan.height = ch;
    tctx.drawImage(canvas, 0, 0);
    canvas.width = 350;
    canvas.height = 350;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(tmpcan, 0, 0, cw, ch, 0, 0, 350, 350)
    //   APP.download.href = canvas.toDataURL('image/jpeg')
  }

}
