const state = {
    IMAGE_WIDTH: 0,
    IMAGE_HEIGHT: 0,
    IMAGE_CHANNELS: 0
}

const mutations = {
    CHANGE_IMAGE_WIDTH(state, IMAGE_WIDTH) {
        state.IMAGE_WIDTH = IMAGE_WIDTH;
    },
    CHANGE_IMAGE_HEIGHT(state, IMAGE_HEIGHT) {
        state.IMAGE_HEIGHT = IMAGE_HEIGHT;
    },
    CHANGE_IMAGE_CHANNELS(state, IMAGE_CHANNELS) {
        state.IMAGE_CHANNELS = IMAGE_CHANNELS;
    }

}

const actions = {
    CALL_IMAGE_WIDTH({ state, commit }, { IMAGE_WIDTH }) {
        commit("CHANGE_IMAGE_WIDTH", IMAGE_WIDTH)
    },
    CALL_IMAGE_HEIGHT({ state, commit }, { IMAGE_HEIGHT }) {
        commit("CHANGE_IMAGE_HEIGHT", IMAGE_HEIGHT);
    },
    CALL_IMAGE_CHANNELS({ state, commit }, { IMAGE_CHANNELS }) {
        commit("CHANGE_IMAGE_CHANNELS", IMAGE_CHANNELS)
    }

}

const getters = {
    GET_IMAGE_WIDTH(state) {
        return state.IMAGE_WIDTH
    },
    GET_IMAGE_HEIGHT(state) {
        return state.IMAGE_HEIGHT
    },
    GET_IMAGE_CHANNELS(state) {
        return state.IMAGE_CHANNELS
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
