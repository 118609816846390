import Rete from "rete";
import { numSocket } from '../sockets';
import NodeControl from '../controls/NodeControl';
import ParameterControl from "./Parameters/controls/ParameterControl.js"
import AdvancedControl from "./Parameters/controls/AdvancedControl.js"
import NameControl from "./Parameters/controls/NameControl.js"

export default class TestComponent extends Rete.Component {
	//tfjs json에서 가져온 객체를 바탕으로 새 인스턴스 생성
	constructor(config) {
		super(config.class_name);
		this.data = {
			render: "vue",
		};
		this.config = config;
	}

	builder(node) {
		let config = this.config;

		node.data.name = node.name + node.id;
		node.data.trainable = true;
		node.data.group = config.group;

		node.addControl(new NameControl(this.editor, node.name + node.id));

		if (this.name != 'InputLayer') {
			let input_name = this.name + node.id + "_input";
			node.addInput(new Rete.Input(input_name, "입력", numSocket, node.data.group == 'Merge'));
		}

		if (this.name != 'Output') {
			let output_name = this.name + node.id + "_output";
			node.addOutput(new Rete.Output(output_name, "출력", numSocket, true));
		}

		// tfjs의 parameters 객체를 참조하여 입력 요소 추가

		let advanced = {}
		for (let p in config.parameters) {
			let param = config.parameters[p];
			if (p != 'name' && p != 'trainable') {
				if (param.require) {
					let control = new ParameterControl(this.editor, p, param);
					node.addControl(control);
				}
				else {
					advanced[p] = param;
				}
			}

		}

		if (Object.keys(advanced).length > 0) {
			let adv_control = new AdvancedControl(this.editor, "advanced", advanced);
			node.addControl(adv_control);
		}

		//테스트 컨트롤 추가
		// node.addControl(new NodeControl(this.editor, node.name));

		// for()
		return node;
	}

	worker() {}
}