<template>
        <div class="input-unit">
            <label>{{ikey}}</label>
            <select v-model="value" @change="onChange">
                <option v-for="input in input_value" >
                    {{input.replace(/\'/gi, '')}}
                    </option>
                </select>
                
        </div>
</template>

<script>
    // vuex
    import store from '@/store';

    export default {
        name: "SelectParameter",
        data() {
            return {
                value: this.getData(this.ikey)
            }
        },
        props: ['ikey', 'emitter', 'getData', 'putData', 'input_value'],
        mounted() {},
        methods: {
            onChange() {
                this.setData();
            },
            setData() {
                this.putData(this.ikey, this.value);
            }
        }
    }
</script>

<style scoped>
    .input-unit {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .input-unit label {
        font-size: 8px;
        color: #555555;
    }

    .input-unit select {
        width: 180px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid #999;
        margin: 2px;
    }
</style>
