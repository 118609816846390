<template>
    <div class="option_check_box">
        <label>추론 옵션</label>
        <div class="checkbox_group">
            <b-form-checkbox class="tensorspace_card_check_tf" v-model="tensorspace_card_check_tf" @change="tensorspace_check_tf">3D 모델링 구조</b-form-checkbox>            
            <b-form-checkbox class="datainput_card_check_tf" v-model="datainput_card_check_tf" @change="datainput_check_tf">데이터입력</b-form-checkbox>
            <b-form-checkbox class="inferenceresult_card_check_tf" v-model="inferenceresult_card_check_tf" @change="inferenceresult_check_tf">추론결과</b-form-checkbox>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'option_check_box',
        data() {
            return {
                tensorspace_card_check_tf: false,
                datainput_card_check_tf: true,
                inferenceresult_card_check_tf: true
            }
        },
        methods: {
            tensorspace_check_tf(event) {
                this.$emit('tensorspace_check_tf', event);
            },
            // 데이터 인풋 화면 체크박스 t/f
            datainput_check_tf(event) {
                this.$emit('datainput_check_tf', event);
            },
            // 추론 결과 화면 체크 박스 t/f
            inferenceresult_check_tf(event) {
                this.$emit('inferenceresult_check_tf', event);
            }

        },
    }
</script>
<style scoped>
    .option_check_box {
        display: flex;
        width: 500px;
        height: 50px;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        margin-left: auto;
        margin-right: 100px;
        border-radius: 10px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
    }

    .option_check_box label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0 !important;
        margin-right: 30px;
    }

    .checkbox_group {
        display: flex;
    }

    /*체크박스 간격 조절*/

    .custom-checkbox {
        margin-left: 20px;
    }
</style>
