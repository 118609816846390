import { Control } from 'rete';
import DataPreprocessVueControl from './DataPreprocessVueControl.vue';

export default class DataPreprocessControl extends Control {
	constructor(emitter, key, readonly = false) {
		super(key);
		this.component = DataPreprocessVueControl;
		this.props = {
			emitter,
			ikey: key,
			type: 'number',
			readonly,
			getNode: () => this.getNode()
		};
	}
	
	//입력 데이터를 vue 컴포넌트로 전달하기 위한 함수
	setInputData(value){
		const ctx = this.vueContext || this.props;
		ctx.input_data = value;
	}
	
	onChange() {}
}