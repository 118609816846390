<template>
    <div class="train_model_node">
        <div class="train_model_node_header">
            <!--클릭 시 모달 노출-->
            <b-button variant="primary" @click="showModal">선택하기</b-button>
            <!--모달 컴포넌트 호출부-->
            <!--node-typ : 현재 rete 노드 이름 전달 속성-->
            <!--data-sources : 샘플데이터 전달 속성-->
            <!--모달 내에서 샘플데이터 선택시 selected 이벤트 emit-->
            <TrainModelModal ref="modal" @selected="onSelected" node-type='TrainModel' :data-sources="model_list" />
        </div>
        <div class="trainmodel_advance" @click="trainmodel_advance_click">
            <label>고급</label>
            <b-icon :icon="trainmodel_advance_icon" />
        </div>
        <b-collapse id="trainmodel-advance" v-model="trainmodel_advance_visible">
            <div class="train_model_node_content">
                <!--모달에서 전달된 데이터를 출력-->
                <label>데이터</label>
                <!--<input type="number" :value="data" />-->
                <div class="data_describe">
                </div>
                <!-- b-modal 열기 버튼 예정 -->
                <b-button variant="primary" @click="showTrainModelModal">EDIT</b-button>
                <b-modal ref="train_model_editor_modal"  id="trainModelEditModal" body-class="p-0" hide-header hide-footer centered>
                    <TrainModelEditor v-on:close_train_model_edit="hideTrainModelModal" :node="getNode()" :emitter="emitter"/>
                </b-modal>
            </div>
            <div class="train_model_node_footer">
            </div>
        </b-collapse>
    </div>
</template>
<script>
    import TrainModelEditor from './TrainModelEditor/TrainModelEditor';
    import TrainModelModal from './TrainModelModal/TrainModelModalComp'
    // import DATA JSON FILE
    import MNIST_CONV from './json-data/MNIST_CONVOLUTION_NET.json';
    import MNIST_DENSE from './json-data/MNIST_DENSE_NET.json';
    //rete
    import Rete from "rete";
    import { numSocket } from '../sockets';
    import sampleTopology from './tf-json-data/Topology/sampleTopology.JSON'

    export default {
        name: 'train_model_node',
        data() {
            return {

                model_list: sampleTopology,

                editor_data: this.getData("editor_data"),

                // 컬랩스 부분 상태
                trainmodel_advance_visible: false,
                trainmodel_advance_icon: 'plus'
            }
        },
        components: {
            TrainModelEditor,
            // 모달 컴포넌트 등록
            TrainModelModal,

        },
        props: ['readonly', 'emitter', 'getData', 'putData', 'getNode'],

        methods: {
            showTrainModelModal() {
                this.$refs['train_model_editor_modal'].show();
            },
            hideTrainModelModal() {
                this.$refs['train_model_editor_modal'].hide();

                const whole_data = this.emitter.toJSON();

                sessionStorage.setItem('current_structure', JSON.stringify(whole_data));

            },
            // 선택 모달 열기 버튼 핸들링 메서드
            showModal() {
                this.$refs.modal.show();
            },
            // selected 이벤트 핸들링 메서드
            onSelected(selectedData) {
                if (selectedData) {
                    this.getNode().data.editor_data = selectedData.editor_data;
                    // 변경된 정보가 반영되면 edior에 접근하여 rete 정보를 json String으로 변환하여 세션에 저장시킨다.
                    const editor_data = this.emitter.toJSON();
                    sessionStorage.setItem('current_structure', JSON.stringify(editor_data));
                    sessionStorage.setItem('model_topology', selectedData.model_topology);
                    this.$refs['train_model_editor_modal'].show();
                }
            },

            // 컬랩스 부분 메서드 // 아이콘 변경 // 컬랩스 visible
            trainmodel_advance_click() {
                this.trainmodel_advance_visible = !this.trainmodel_advance_visible;
                if (this.trainmodel_advance_visible === true) {
                    this.trainmodel_advance_icon = 'dash';
                }
                else {
                    this.trainmodel_advance_icon = 'plus';
                }
            }
        },

        computed: {
        }
    }
</script>
<style scoped>
    /*훈련시킬 모델 노드 전체 지정*/

    .train_model_node {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    /*훈련 시킬 모델 노드 전체 라벨 지정*/

    .train_model_node label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    /*훈련 시킬 모델 노드 전체 셀렉트 인풋 지정*/

    .train_model_node select,
    input {
        border-radius: 5px !important;
        width: 180px !important;
    }

    /*훈련 시킬 모델 노드 전체 버튼 지정*/

    .train_model_node button {
        margin-top: 5px;
        height: 25px;
        width: 180px;
        font-size: 13px;
        color: #ffffff;
        background: rgba(119, 132, 251, 0.7);
        text-align: center;
        line-height: 12px;
        border: none;
    }

    /*훈련 시킬 모델 고급 기능 버튼 지정*/

    .trainmodel_advance {
        width: 220px;
        height: 30px;
        padding-top: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-top: 1px solid #7784FB;
    }

    /*고급 기능 버튼 포커스 없애기*/

    .trainmodel_advance:focus {
        outline: none;
    }

    /*고급 기능 버튼 라벨 지정*/

    .trainmodel_advance label {
        font-size: 13px !important;
        margin-left: 10px;
        color: #7784FB;
    }

    /*고급 기능 버튼 아이콘 지정*/

    .trainmodel_advance .b-icon {
        width: 28px;
        height: 28px;
        margin-left: auto;
        margin-right: 10px;
        color: #7784FB;
    }

    /*고급 기능 컬랩스 지정*/

    #trainmodel-advance {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #eee;
        margin-top: 5px;
    }

    /*고급 기능 컬랩스 라벨 인풋 버튼 지정*/

    #trainmodel-advance label,
    input,
    button {
        margin: 10px;
    }

    /*선택된 데이터가 표시될 부분 지정*/

    .data_describe {
        width: 180px;
        height: 25px;
        border-bottom: 1px solid #7784FB;
        margin: 10px;
        opacity: 0.8;
    }
</style>