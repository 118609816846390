<template>
    <div class="Inference">
            
            <!--<select v-model="inference_input">-->
            <!--    <option v-for="option in inference_options" v-bind:value="option.value">-->
            <!--        {{ option.text }}-->
            <!--    </option>-->
            <!--</select>-->
            <!--<component v-bind:is="inference_input"></component>-->
            <!--<div class="inference_result">-->
            <!--    <img class="imagetest" v-for="(image, index) in upload_image" :src="image"/>-->
            <!--</div>-->
            <label>모델 불러오기</label>
            <b-button @click="upload_model_click">모델 불러오기</b-button>
            <input type="file" ref="modelInput" multiple id="upload-json" hidden @change="upload_model"/>
            <!--<input type="file" id="upload-weight"/>-->
            <label>데이터 입력</label>
            <b-button @click="onClickImageUpload">이미지 불러오기</b-button>
            <b-button @click="showModal">이미지 그리기</b-button>
                <b-modal ref="my-modal" hide-footer size="sm">
                    <drawing_com v-on:print="hideModal"/>
                </b-modal>
            <div id="inference_image">
                <input ref="imageInput" type="file" id="images" multiple="multiple" hidden @change="onChangeImages">
                <div class="pre_image">
	               <img class="imagetest" v-for="(image, idx) in this.images" :src="image" v-if="idx<1"/>
	            </div>
	       </div>
	    	<b-button @click="predict_click">실행</b-button>
	    	<!--<b-button @click="inference_view">결과 보기</b-button>-->
    </div>
</template>
<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';

    // import inferenceimage from '@/components/InferenceImageInputComponent';
    // import inferencedrawing from '@/components/InferenceDrawingInputComponent';
    // import drawing_com from '@/components/DrawingComponent';

    import * as tf from '@tensorflow/tfjs';
    import * as tfvis from '@tensorflow/tfjs-vis';
    import { MnistData } from '@/MnistData/predictdata.js';
    import { fitCallbacks } from '@tensorflow/tfjs-vis/dist/show/history';

    export default {
        name: 'Inference',
        data() {
            return {
                // inference_input: '',
                // inference_options: [
                //     { text: '이미지 불러오기', value: 'inferenceimage' },
                //     { text: '그리기', value: 'inferencedrawing' }
                // ],
                images: [],
                get_drawing_image: '',
                // testimage: null,
                uploadmodel: [],
            }
        },
        components: {
            // inferenceimage,
            // inferencedrawing,
            // drawing_com
        },
        methods: {
            init() {
                tf;
                tfvis;
                MnistData;
            },
            onClickImageUpload() {
                this.$refs.imageInput.click();
            },
            onChangeImages(e) {

                const ttfiles = e.target.files;

                this.createImage(ttfiles[0])

                // this.images = [...files].map(URL.createObjectURL);
            },
            createImage(file) {
                let reader = new FileReader()
                // let vm = this

                reader.onload = (e) => {
                    this.images = [];
                    this.images.push(e.target.result);
                    this.get_drawing_image = e.target.result
                    // console.log(e.target.result)
                    sessionStorage.setItem('drawing_image', e.target.result);
                }
                reader.readAsDataURL(file)
            },
            showModal() {
                this.$refs['my-modal'].show();
            },
            hideModal() {
                this.$refs['my-modal'].hide();
                this.get_drawing_image = sessionStorage.getItem('drawing_image');
                ///////////////////////
                // var img = new Image();

                // console.log(img);
                // const canvas = document.createElement('canvas');
                // const ctx = canvas.getContext('2d');
                // img.crossOrigin = '';
                // img.onload = () => {
                //     const b_width = 224;
                //     const b_hight = 224;
                //     const a_width = 28;
                //     const a_hight = 28;
                //     ctx.drawImage(img, b_width, b_hight, a_width, a_hight);
                //     const imageData = ctx.getImageData(0, 0, a_width, a_hight);
                //     console.log(imageData);

                // }
                // img.src = sessionStorage.getItem('drawing_image');



                ///////////////////////
                this.images = [];
                this.images.push(this.get_drawing_image);

                // this.images.push(this.drawing);
                // console.log(this.images);
            },
            predict_click() {
                this.run();

                this.inference_view();
                // this.get_drawing_image = 사진 입니다.
            },
            upload_model_click() {
                this.$refs.modelInput.click();
            },
            upload_model(e) {
                //   console.log(e.target.files);  
                this.testmodel = e.target.files;

            },
            async run() {
                const data = new MnistData();

                // const model = await tf.loadLayersModel('localstorage://model_ai_nomis');
                // model.json model.bin 파일 두개가 한 셋트
                const model = await tf.loadLayersModel(tf.io.browserFiles(
                    [this.testmodel[0], this.testmodel[1]]
                ));
                //const model = this.getModel();
                // console.log('!!!!')
                // console.log(model);
                data.load(this, model, this.get_drawing_image);

                // this.predictiontest(model, data);

                // await this.test1();

            },
            inference_view() {
                store.dispatch('call_inference_result', { inference_result: "InferenceResult" })
            },
            // test1() {
            //     let rt_ts = sessionStorage.getItem('rt_ts');
            //     let rt_ts_1 = JSON.parse(rt_ts);
            //     console.log(rt_ts_1);
            // },
            getModel() {
                const model = tf.sequential();

                const IMAGE_WIDTH = 28;
                const IMAGE_HEIGHT = 28;
                const IMAGE_CHANNELS = 1;

                // In the first layer of our convolutional neural network we have 
                // to specify the input shape. Then we specify some parameters for 
                // the convolution operation that takes place in this layer.
                // model.add(tf.layers.conv2d({
                //     inputShape: [IMAGE_WIDTH, IMAGE_HEIGHT, IMAGE_CHANNELS],
                //     kernelSize: 5,
                //     filters: 8,
                //     strides: 1,
                //     activation: 'relu',
                //     kernelInitializer: 'varianceScaling'
                // }));

                // sessionStorage에 저장된 conv2d 값 가져오기
                let session_conv_json1 = sessionStorage.getItem('Conv2d8');

                // 가져온 conv2d 값 string 에서 json으로 parsing
                let session_conv1 = JSON.parse(session_conv_json1);

                // 해당 conv2d layer에 값 넣기
                model.add(tf.layers.conv2d({
                    inputShape: [IMAGE_WIDTH, IMAGE_HEIGHT, IMAGE_CHANNELS],
                    kernelSize: Number(session_conv1.kernelSize),
                    filters: Number(session_conv1.filters),
                    strides: Number(session_conv1.strides),
                    activation: session_conv1.activation,
                    kernelInitializer: session_conv1.kernelInitializer
                }))


                // sessionStorage에 저장된 maxpooling 값 가져오기
                let session_maxpooling_json1 = sessionStorage.getItem('MaxPooling2d9');
                // 가져온 maxpooling 값 string 에서 json으로 parsing
                let session_maxpooling1 = JSON.parse(session_maxpooling_json1);

                // 가져온 배열의 값들은 String 이나 number로 변환해주기
                let mc_poolSize1 = session_maxpooling1.poolSize;
                let mc_poolSize_change1 = [Number(mc_poolSize1[0]), Number(mc_poolSize1[2])];
                let mc_strides1 = session_maxpooling1.strides;
                let mc_strides_change1 = [Number(mc_strides1[0]), Number(mc_strides1[2])];

                // The MaxPooling layer acts as a sort of downsampling using max values
                // in a region instead of averaging.  
                // model.add(tf.layers.maxPooling2d({ poolSize: [2, 2], strides: [2, 2] }));

                // 해당 maxpooling layer에 값 넣기
                model.add(tf.layers.maxPooling2d({ poolSize: mc_poolSize_change1, strides: mc_strides_change1 }))


                // 두번째 Stack 파트

                let session_conv_json2 = sessionStorage.getItem('Conv2d10');

                let session_conv2 = JSON.parse(session_conv_json2);

                // Repeat another conv2d + maxPooling stack. 
                // Note that we have more filters in the convolution.

                // model.add(tf.layers.conv2d({
                //     kernelSize: 5,
                //     filters: 16,
                //     strides: 1,
                //     activation: 'relu',
                //     kernelInitializer: 'varianceScaling'
                // }));

                model.add(tf.layers.conv2d({
                    kernelSize: Number(session_conv2.kernelSize),
                    filters: Number(session_conv2.filters),
                    strides: Number(session_conv2.strides),
                    activation: session_conv2.activation,
                    kernelInitializer: session_conv2.kernelInitializer
                }))

                let session_maxpooling_json2 = sessionStorage.getItem('MaxPooling2d11');

                let session_maxpooling2 = JSON.parse(session_maxpooling_json2);

                let mc_poolSize2 = session_maxpooling2.poolSize;
                let mc_poolSize_change2 = [Number(mc_poolSize2[0]), Number(mc_poolSize2[2])];
                let mc_strides2 = session_maxpooling2.strides;
                let mc_strides_change2 = [Number(mc_strides2[0]), Number(mc_strides2[2])];

                // model.add(tf.layers.maxPooling2d({ poolSize: [2, 2], strides: [2, 2] }));
                model.add(tf.layers.maxPooling2d({ poolSize: mc_poolSize_change2, strides: mc_strides_change2 }));

                // Now we flatten the output from the 2D filters into a 1D vector to prepare 
                // it for input into our last layer. This is common practice when feeding
                // higher dimensional data to a final classification output layer.
                model.add(tf.layers.flatten());

                // Our last layer is a dense layer which has 10 output units, one for each  
                // output class (i.e. 0, 1, 2, 3, 4, 5, 6, 7, 8, 9).

                // const NUM_OUTPUT_CLASSES = 10;
                // model.add(tf.layers.dense({
                //     units: NUM_OUTPUT_CLASSES,
                //     kernelInitializer: 'varianceScaling',
                //     activation: 'softmax'
                // }));

                // Test CNN dense
                let session_dense_json = sessionStorage.getItem('덴스(dense)13');


                let session_dense = JSON.parse(session_dense_json);

                let dense_units = Number(session_dense.units);

                let dense_kernelInitializer = session_dense.kernelInitializer;

                let dense_activation = session_dense.activation;

                model.add(tf.layers.dense({
                    units: dense_units,
                    kernelInitializer: dense_kernelInitializer,
                    activation: dense_activation
                }));

                // Choose an optimizer, loss function and accuracy metric,
                // then compile and return the model

                // const optimizer = tf.train.adam();


                // store.getters.get_optimizer === adam 일때 adam 실행
                // if (store.getters.get_optimzer === 'adam') {
                //     const optimizer = tf.train.adam();
                // }

                switch (store.getters.get_optimizer) {

                    case 'adam':
                        const optimizer = tf.train.adam();

                        model.compile({
                            optimizer: optimizer,
                            loss: store.getters.get_loss,
                            metrics: ['accuracy'],
                        });
                        return model;

                }


                // model.compile({
                //     optimizer: optimizer,
                //     loss: 'categoricalCrossentropy',
                //     metrics: ['accuracy'],
                // });

                // return model;
            },


            predictiontest(model, data) {
                const IMAGE_WIDTH = 28;
                const IMAGE_HEIGHT = 28;
                const testData = data.imgArray();
                const testxs = testData.xs.reshape([
                    testDataSize,
                    IMAGE_WIDTH,
                    IMAGE_HEIGHT,
                    1
                ]);
                console.log(model.predict(testxs))
            },


        },
    }
</script>

<style scoped>
    .Inference {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .Inference label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .Inference select,
    input {
        border-radius: 5px !important;
        width: 130px !important;
    }

    .Inference button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        text-align: center;
        line-height: 12px;
        border: none;
    }

    .inference_result {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        padding-left: 3px;
        border: 1px solid #555555;
        border-radius: 5px;
        height: 130px;
        width: 130px;
        background: #ffffff;
    }

    .pre_image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        padding-left: 3px;
        border: 1px solid #555555;
        border-radius: 5px;
        height: 130px;
        width: 130px;
        background: #ffffff;
    }

    .pre_image img {
        width: 112px;
        height: 112px;
    }

    /*.inference_result img {*/

    /*    width: 112px;*/

    /*    height: 112px;*/

    /*}*/

    /*.imagetest {*/

    /*width: auto;*/

    /*height: auto;*/

    /*max-width: 100px;*/

    /*max-height: 100px;*/

    /*    width: 60px;*/

    /*    height: 60px;*/

    /*    border-radius: 5px;*/

    /*    margin: 1px;*/

    /*}*/
</style>
