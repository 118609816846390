<template>
    <div class="DataMonitoringVueControl">
        <canvas id="monitoring-canvas" />
        <b-button @click="data_monitoring_click">
            데이터보기
        </b-button>
    </div>
</template>
<script>
    import admin from '@/assets/admin.png';

    export default {
        name: "DataMonitoringVueControl",
        data() {
            return {

            }
        },
        mounted() {
            console.log('mounted');
        },
        methods: {
            data_monitoring_click() {
                let canvas = document.getElementById('monitoring-canvas');

                if (canvas.getContext) {
                    let context = canvas.getContext("2d");

                    /* global Image */
                    let img = new Image();
                    img.src = admin;
                    img.onload = () => {

                        //전체 이미지 그리기
                        //drawImage(이미지객체, 사각형 왼위 x, 사각형 왼위 y, 가로크기, 세로크기)
                        context.drawImage(img, 0, 0, 180, 180);
                    }
                }
            }
        }
    }
</script>
<style scoped>
    .DataMonitoringVueControl {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .DataMonitoringVueControl button {
        height: 25px;
        width: 180px;
        font-size: 13px;
        color: #ffffff;
        background: rgba(119, 132, 251, 0.7);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 12px;
        border: none;
    }

    #monitoring-canvas {
        border: 1px solid #7784FB;
        background: #FFFFFF;
        width: 180px;
        height: 180px;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        z-index: 999;
    }
</style>
