import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import user from '@/store/user/user.js';
import workspace from '@/store/workspace/workspace.js';
import machine_learning from '@/store/machine_learning/machine_learning';
import bottom_vis from '@/store/bottom_vis/bottom_vis';
import main_editor from '@/store/main_editor/main_editor';
import inference from '@/store/inference/inference';
import modal_test from '@/store/modal_test/modal_test';

export default new Vuex.Store({

    modules: {
        // 유저 정보에 관한 파일 모듈
        user: user,
        
        // 워크스페이스 관한 파일 모듈
        workspace: workspace,
        // 머신러닝 정보에 관한 파일 모듈
        machine_learning: machine_learning,
        
        // 하단 시각화 정보에 관한 파일 모듈
        bottom_vis: bottom_vis,
        
        // 메인 에디터에 관한 파일 모듈
        main_editor: main_editor,
        
        // 추론에 관한 파일 모듈
        inference: inference,
        
        // 모달 테스트를 위한 모듈
        modal_test: modal_test
    },
});
