    import * as tf from '@tensorflow/tfjs';
    import * as tfvis from '@tensorflow/tfjs-vis';
    // import { MnistData } from '@/MnistData/predictdata.js';
    // import { fitCallbacks } from '@tensorflow/tfjs-vis/dist/show/history';

    import * as TSP from 'tensorspace';

    // export const tsspace = null;
    export class tensorspace {
        constructor(container) {
            this.ts_container = container;
        }

        async ts_mnistconv() {
            let visualmodel = new TSP.models.Sequential(this.ts_container, { color: { background: 0x555555 } });
            visualmodel.add(new TSP.layers.GreyscaleInput({ shape: [28, 28] }));
            visualmodel.add(new TSP.layers.Conv2d({ filters: 8, kernelSize: 5, strides: 1 }));
            visualmodel.add(new TSP.layers.Pooling2d({ poolSize: [2, 2], strides: [2, 2] }));
            visualmodel.add(new TSP.layers.Conv2d({ filters: 16, kernelSize: 5, strides: 1 }));
            visualmodel.add(new TSP.layers.Pooling2d({ poolSize: [2, 2], strides: [2, 2] }));
            visualmodel.add(new TSP.layers.Flatten({ shape: [784] }));
            visualmodel.add(new TSP.layers.Dense({ units: 10 }));
            visualmodel.add(new TSP.layers.Output1d({
                units: 10,
                outputs: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
            }));
            return visualmodel;
        }

        async ts_mnistdense() {
            let visualmodel = new TSP.models.Sequential(this.ts_container, { color: { background: 0x555555 } });
            visualmodel.add(new TSP.layers.GreyscaleInput({ shape: [28, 28] }));
            visualmodel.add(new TSP.layers.Flatten({ shape: [784] }));
            visualmodel.add(new TSP.layers.Dense({ units: 42 }));
            visualmodel.add(new TSP.layers.Dense({ units: 10 }));
            visualmodel.add(new TSP.layers.Output1d({
                units: 10,
                outputs: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
            }));
            return visualmodel;
        }

        // async ts_mnistdense() {
        //     let visualmodel = new TSP.models.Sequential(this.ts_container, { color: { background: 0x555555 } });
        //     visualmodel.add(new TSP.layers.GreyscaleInput({ shape: [28, 28] }));
        //     visualmodel.add(new TSP.layers.Flatten({ shape: [784] }));
        //     visualmodel.add(new TSP.layers.Dense({ units: 42 }));
        //     visualmodel.add(new TSP.layers.Dense({ units: 10 }));
        //     visualmodel.add(new TSP.layers.Output1d({
        //         units: 10,
        //         outputs: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        //     }));
        //     return visualmodel;
        // }

        async ts_irisdense() {
            let visualmodel = new TSP.models.Sequential(this.ts_container, { color: { background: 0x555555 } });
            visualmodel.add(new TSP.layers.GreyscaleInput({ shape: [4] }));
            // visualmodel.add(new TSP.layers.Flatten({ shape: [784] }));
            visualmodel.add(new TSP.layers.Dense({ units: 10 }));
            visualmodel.add(new TSP.layers.Dense({ units: 3 }));
            visualmodel.add(new TSP.layers.Output1d({
                units: 3,
                outputs: ["0", "1", "2"]
            }));
            return visualmodel;
        }

        async ts_mobilenet() {
            let visualmodel = new TSP.models.Sequential(this.ts_container, { color: { background: 0x555555 } });

            visualmodel.add(new TSP.layers.RGBInput({ "shape": [1, 224, 224, 3], "sparse": false })); ///[null, 224, 224, 3]}))///,, "sparse": false, "name": "input_1"}) );
            visualmodel.add(new TSP.layers.Conv2d({
                "shape": [3, 3, 3, 32],
                initStatus: "open"
            }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 32, 1], }));

            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 32, 64], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 64, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 64, 128], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 128, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 128, 128], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 128, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 128, 256], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({
                "shape": [3, 3, 256, 1],
                initStatus: "open"
            }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 256, 256], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 256, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 256, 512], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 512, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 512, 512], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 512, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 512, 512], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 512, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 512, 512], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 512, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [512], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 512, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 512, 512], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 512, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 512, 1024], }));
            visualmodel.add(new TSP.layers.DepthwiseConv2d({ "shape": [3, 3, 1024, 1], }));
            visualmodel.add(new TSP.layers.Conv2d({ "shape": [1, 1, 1024, 1024], }));
            visualmodel.add(new TSP.layers.GlobalPooling2d({ "shape": [1, 1, 1024] }));
            visualmodel.add(new TSP.layers.Output1d({
                paging: true,
                segmentLength: 200,
                units: 29,
                outputs: ['1', '2', '3', '4', '5']
            }));

            //     visualmodel.load( {

            // 		type: "keras",
            // 		url: 'https://github.com/tensorspace-team/tensorspace/blob/master/examples/mobilenetv1/mobilenetv1/model.json'

            // 	} );                


            return visualmodel;

        }
    }
    