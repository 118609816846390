import * as tf from '@tensorflow/tfjs';

import store from "@/store";

const IMAGE_SIZE = 784;
const NUM_CLASSES = 10;
const NUM_DATASET_ELEMENTS = 1;

// let MNIST_IMAGES_SPRITE_PATH = sessionStorage.getItem('drawing_image');


export class MnistData {
    constructor() {
        // let datasetImages = null;
    }

    async load(_this, model, image_path, conv = false) {
        // console.log('@@@')

        // console.log(model)

        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');



        const imgRequest = new Promise((resolve, reject) => {
            img.crossOrigin = '';

            img.onload = () => {
                // img.width = 224;
                // img.height = 224;
                img.width = img.naturalWidth;
                img.height = img.naturalHeight;

                const datasetBytesBuffer =
                    new ArrayBuffer(IMAGE_SIZE * 4);

                const chunkSize = 1;
                canvas.width = 28;
                canvas.height = 28;

                const datasetBytesView = new Float32Array(datasetBytesBuffer, 0, IMAGE_SIZE);
                //*4 , IMAGE_SIZE * chunkSize);
                // console.log(datasetBytesView)
                // ctx.drawImage(img, 0, chunkSize, img.width, chunkSize, 0, 0, img.width, chunkSize);
                ctx.drawImage(img, 0, 0, 28, 28)
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                // console.log(imageData)
                for (let j = 0; j < imageData.data.length / 4; j++) {
                    datasetBytesView[j] = imageData.data[j * 4] / 255;
                }
                // console.log(datasetBytesView.values())

                this.datasetImages = new Float32Array(datasetBytesBuffer);
                // console.log('*******');
                // console.log(this.datasetImages);
                if (conv == true) {
                    let t_array = this.datasetImages;
                    for (let j = 0; j < t_array.length; j++) {
                        t_array[j] = 1 - t_array[j];
                    }
                    this.datasetImages = t_array;
                }
                // console.log(this.datasetImages);
                const xs = tf.tensor2d(this.datasetImages, [1, IMAGE_SIZE]);
                // var iterator = this.datasetImages.values(); 
                // for (let elements of iterator) { 
                //     console.log(elements); 
                //     } 
                // console.log(this.datasetImages);
                // // console.log(xs.print());
                // console.log('&&&&&');

                // PREDICTION TEST 
                this.predictiontest(model, xs);


                // img.onload = () => {
                // img.width = img.naturalWidth;
                // img.height = img.naturalHeight;

                // const datasetBytesBuffer =
                //     new ArrayBuffer(NUM_DATASET_ELEMENTS * IMAGE_SIZE)
                //     // * 4);

                // const chunkSize = 1;
                // canvas.width = img.width;
                // canvas.height = img.height;

                // const datasetBytesView = new Float32Array(IMAGE_SIZE);
                // //*4 , IMAGE_SIZE * chunkSize);
                // // console.log(datasetBytesView)
                // ctx.drawImage(img, 0, chunkSize, img.width, chunkSize, 0, 0, img.width, chunkSize);
                // const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                // console.log(imageData)
                // for (let j = 0; j < imageData.data.length / 4; j++) {
                //         datasetBytesView[j] = imageData.data[j * 4] / 255;
                //     }
                // console.log(datasetBytesView)
                // // this.datasetImages = new Float32Array(datasetBytesBuffer);
                // // console.log(this.datasetImages)
                // this.datasetImages = datasetBytesView;
                // console.log(this.datasetImages)

                // resolve();
            };
            img.src = image_path; //BASE64임...

            // img.src = MNIST_IMAGES_SPRITE_PATH;
            // console.log(img.src);
        });

    }

    // imgArray(){
    // 	const batchImagesArray = new Float32Array(IMAGE_SIZE);
    // 	const image = this.datasetImages;

    // 	batchImagesArray.set(image, IMAGE_SIZE);
    // // 	const xs = tf.tensor2d(batchImagesArray, [1, IMAGE_SIZE]);
    //     const xs = tf.tensor2d(image, [1, IMAGE_SIZE]);
    // 	console.log('^^^^^^');
    // 	console.log(xs);
    // 	return  xs ;
    // }


    async predictiontest(model, xs) {


        // 콘솔 체크 확인
        // console.log('###############################');
        // console.log(model);
        // console.log(xs);
        // console.log('###############################');

        // const bmodel = this.model;
        const IMAGE_WIDTH = 28;
        const IMAGE_HEIGHT = 28;
        // const testData = this.imgArray();
        const testxs = xs.reshape([
            1,
            IMAGE_WIDTH,
            IMAGE_HEIGHT,
            1
        ]);
        // console.log('-------------------------------');
        // console.log(testxs);
        // console.log('-------------------------------');
        
        // console.log('result');
        // console.log(model)
        const rt = model.predict(testxs);
        // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        // console.log(rt.print());
        // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        // console.log(rt.get(0,1));

        //  const rt_ts = await rt.reshape([10]).array();

        let rt_ts = await rt.reshape([10]).array();
        let rt_ts2 = Array.from(rt_ts);

        const pc = parseFloat(Math.max.apply(0, rt_ts) * 100).toPrecision(2).toString();

        // rt_ts.function(function(a, b){return b-a});
        // console.log(rt_ts);
        // console.log(rt_ts[0]);
        // console.log(rt_ts[1]);

        // alert(pc);
        const f_index = this.indexofMax(rt_ts);
        const max_v = rt_ts[f_index];
        const t = f_index.toString();

        rt_ts2[f_index] = -1;
        // const t = this.indexofMax(rt_ts).toString();
        const s_index = this.indexofMax(rt_ts2);
        const max_v2 = rt_ts2[s_index];
        const t2 = s_index.toString();
        // const sec = this.indexof2Max(rt_ts, t).toString();

        // ==============================
        // console.log(rt_ts);
        // console.log(rt_ts2);


        // console.log(f_index);
        // console.log(s_index);
        // console.log(max_v);
        // console.log(max_v2);
        // alert(t);
        // ==============================

        // alert 보류 
        // let str = '예측값은 ' + t + '이며, 확률은 ' + pc + '% 입니다.\n자세한 내용은 결과 보기 버튼을 눌러주세요.';
        // alert(str);

        // let rt_ts_1 = JSON.stringify(rt_ts);
        // sessionStorage.setItem('rt_ts', rt_ts_1);

        // 수정 전 =====================================================

        // store.dispatch('call_max_rt_ts', { max_rt_ts: t });

        // store.dispatch('call_rt_ts', { rt_ts: rt_ts });

        // =============================================================

        // 수정 후 -----------------------------------------------------

        // 첫번째 값 INDEX
        store.dispatch('call_max_value_name', { max_value_name: f_index });

        // 두번째 값 VALUE
        store.dispatch('call_max_value_percentage', { max_value_percentage: max_v });
        // 두번째 값 INDEX
        store.dispatch('call_second_max_value_name', { second_max_value_name: s_index });

        // 두번째 값 VALUE
        store.dispatch("call_second_max_value_percentage", { second_max_value_percentage: max_v2 });


        // -------------------------------------------------------------

    }

    indexofMax(arr) {
        if (arr.length === 0) {
            return -1;
        }

        var max = arr[0];
        var maxIndex = 0;

        for (var i = 1; i < arr.length; i++) {
            if (arr[i] > max) {
                maxIndex = i;
                max = arr[i];
            }
        }

        return maxIndex;
    }

    // indexof2Max(arr,ind){
    //     if (arr.length === 0) {
    //         return -1;
    //     }
    //     return this.indexofMax(arr);

    // }
}
