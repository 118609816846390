/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:dd501c5c-3506-4fd8-b324-b4b9ba652a62",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_1DAEYKo4D",
    "aws_user_pools_web_client_id": "1cca9fppm8fjc471h3k5fl9kg8",
    "oauth": {}
};


export default awsmobile;
