<template>
    <div class="iris_data_summary">
        <!--IRIS DATA 요약 라벨 부분-->
        <!--데이터 요약-->
        <!--<h5>{{ iris_datasummary_label }}</h5>-->
        <table class="table">
            <tbody>
                <tr>
                    <!--데이터 요약 이름 부분-->
                    <!--이름-->
                  <th scope="row">{{ iris_datasummary_name }}</th>
                  <td><p>{{dataset_name}}</p></td>
                </tr>
                <tr>
                    <!--데이터 요약 종류 부분-->
                    <!--종류-->
                  <th scope="row">{{ iris_dataset_kinds }}</th>
                  <td><p>{{dataset_kinds}}</p></td>
                </tr>
                <tr>
                    <!--데이터 요약 이미지 개수 부분-->
                    <!--이미지 개수-->
                  <th scope="row">{{ iris_dataset_image_num }}</th>
                  <td><p>{{dataset_number}}</p></td>
                </tr>
                </tr>
                <tr>
                    <!--데이터 요약 훈련 이미지 개수 부분-->
                    <!--훈련 이미지 개수-->
                  <th scope="row">{{ iris_trainlabels_num }}</th>
                  <td><p>{{trainlabels_number}}</p></td>
                </tr>
                <tr>
                    <!--데이터 요약 테스트 이미지 개수 부분-->
                    <!--테스트 이미지 개수-->
                  <th scope="row">{{ iris_testlabels_num }}</th>
                  <td><p>{{testlabels_number}}</p></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import * as tf from '@tensorflow/tfjs';
    import { IrisData, IRIS_CLASSES, IRIS_NUM_CLASSES, IRIS_DATA } from '@/IrisData/data.js';

    import store from '@/store';

    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: 'datasummary',
        data() {
            return {
                iris_datasummary_label: '',
                iris_datasummary_name: '',
                iris_dataset_kinds: '',
                iris_dataset_image_num: '',
                iris_trainlabels_num: '',
                iris_testlabels_num: '',

                //언어 설정
                localization: ''
            }
        },
        mounted() {
            run();

            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);
            // 언어 설정 테스트
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.iris_datasummary_label = korean.iris_summary.iris_datasummary_label;
                    this.iris_datasummary_name = korean.iris_summary.iris_datasummary_name;
                    this.iris_dataset_kinds = korean.iris_summary.iris_dataset_kinds;
                    this.iris_dataset_image_num = korean.iris_summary.iris_dataset_image_num;
                    this.iris_trainlabels_num = korean.iris_summary.iris_trainlabels_num;
                    this.iris_testlabels_num = korean.iris_summary.iris_testlabels_num;
                    return;
                case 'en':
                    this.iris_datasummary_label = english.iris_summary.iris_datasummary_label;
                    this.iris_datasummary_name = english.iris_summary.iris_datasummary_name;
                    this.iris_dataset_kinds = english.iris_summary.iris_dataset_kinds;
                    this.iris_dataset_image_num = english.iris_summary.iris_dataset_image_num;
                    this.iris_trainlabels_num = english.iris_summary.iris_trainlabels_num;
                    this.iris_testlabels_num = english.iris_summary.iris_testlabels_num;
                    return;
            }

            async function run() {
                await getIrisData();
            }
            async function getIrisData() {
                store.dispatch('call_iris_dataset_name', { dataset_name: 'Iris data' });
                store.dispatch('call_iris_dataset_kinds', { dataset_kinds: '숫자' });
                store.dispatch('call_iris_dataset_num', { dataset_num: IRIS_DATA.length });
                // store.dispatch('call_trainlabels_num', { trainlabels_num: 120 });
                // store.dispatch('call_testlabels_num', { testlabels_num: 30 });

            }
        },
        computed: {
            dataset_name() {
                return store.getters.get_iris_dataset_name
            },
            dataset_kinds() {
                return store.getters.get_iris_dataset_kinds
            },
            dataset_number() {
                return store.getters.get_iris_dataset_num
            },
            trainlabels_number() {
                return store.getters.get_iris_trainlabels_num
            },
            testlabels_number() {
                return store.getters.get_iris_testlabels_num
            }
        }
    }
</script>
<style scoped>
    .iris_data_summary {
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 200px;
        padding: 10px;
        align-items: center;
    }

    .table th,
    .table td {
        padding: 0px !important;
        vertical-align: top !important;
        border-top: 1px solid #dee2e6 !important;
    }
</style>
