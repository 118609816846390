<template>
    <div class="loading">
        <div class="loading__content">
            <span class="loading__icon"></span>
            <span class="loading__text">Loading...</span>
        </div>
    </div>
</template>

<script>
    export default{
        name:"loadingComponent"
    }
</script>

<style scoped>
    .loading{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        z-index: 1001;
    }
    
    .loading__content{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-45%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .loading__icon{
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 8px solid rgba(255, 255, 255, 0.5);
        border-right-color: white;
        border-left-color: white;
        animation: loadingSpin 1.2s infinite linear;
        margin-right: 15px;
    }
    
    .loading__text{
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 20px;
    }
    
    @keyframes loadingSpin{
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
</style>