import { Control } from 'rete';
import NodeVueControl from '../vue-controls/NodeVueControl.vue';

export default class NodeControl extends Control {
    constructor(emitter, key, readonly = false) {
        super(key);
        this.component = NodeVueControl;
        this.props = {
            emitter,
            ikey: key,
        }
    };

    setValue(value) {
        const ctx = this.vueContext || this.props;
        ctx.value = value;
    }

    onChange() {}
}
