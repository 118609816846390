// rete import
import Rete from 'rete';

// rete plugin import
import VueRenderPlugin from 'rete-vue-render-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import AutoArrangePlugin from 'rete-auto-arrange-plugin';
import AreaPlugin from 'rete-area-plugin';
import HistoryPlugin from 'rete-history-plugin';
// import KeyBoardPlugin from 'rete-keyboard-plugin';

// basic node
import DataInputComponent from './components/basiccomponents/DataInputComponent';
import DefaultInputComponent from './components/basiccomponents/DefaultInputComponent';
import PreprocessComponent from './components/basiccomponents/PreprocessComponent';
// import AfterprocessComponent from './components/basiccomponents/AfterprocessComponent';
import ModelSaveComponent from './components/basiccomponents/ModelSaveComponent';
import MonitoringComponent from './components/basiccomponents/MonitoringComponent';
// import PretrainedComponent from './components/basiccomponents/PretrainedComponent';
import WebCamComponent from './components/basiccomponents/WebCamComponent';

// 추론
import InferenceComponent from './components/basiccomponents/InferenceComponent';

// dataset
// import MnistDataComponent from './components/datasetcomponents/MnistDataComponent';

// test cnn
import MNISTConvNetComponent from './components/testcomponents/MNISTConvNetComponent';
import MNISTCustomCNNComponent from './components/testcomponents/MNISTCustomCNNComponent';
import MNISTDenseNetComponent from './components/testcomponents/MNISTDenseNetComponent';

// Iris densenet
import IrisDenseNetComponent from './components/testcomponents/IrisDenseNetComponent';

// monitoring
import datamonitoringComponent from './components/monitoringcomponents/datamonitoringComponent';
import modelmonitoringComponent from './components/monitoringcomponents/modelmonitoringComponent';

// default data set
import DefaultMNISTComponent from './components/basiccomponents/DefaultMNISTComponent';
import DefaultMFASHIONComponent from './components/basiccomponents/DefaultMFASHIONComponent';

// iris set
import IrisDataComponent from './components/basiccomponents/IrisDataComponent';



import store from '../store';

// Mnist Conv Net data
import MnistConvData from './data/MnistConvData';

// Mnist Dense Net data
import DenseNetdata from './data/DenseNetdata';

// Iris Dense Net data
import IrisDenseNetdata from './data/IrisDenseNetdata';

// CNN Test data
import TestCNNdata from './data/TestCNNdata';

// Custom default rete node data
import defaultCustom from './data/defaultCustom';




// Main editor execution section
export async function createFlowEditor() {

    // check Main rete editor createFlowEditor working
    // console.log('Main editor working start');

    // container 선언
    let container = document.querySelector('#rete');

    // Rete edtior에 등록 할 components
    let components = [
        new DataInputComponent(),
        new DefaultInputComponent(),
        new WebCamComponent(),
        new PreprocessComponent(),
        new MonitoringComponent(),
        new ModelSaveComponent(),
        new MNISTDenseNetComponent(),
        new MNISTConvNetComponent(),
        new datamonitoringComponent(),
        new modelmonitoringComponent(),
        new InferenceComponent(),
        new DefaultMNISTComponent(),
        new DefaultMFASHIONComponent(),
        new MNISTCustomCNNComponent(),
        new IrisDenseNetComponent(),
        new IrisDataComponent()
    ];

    // editor 설정 (NodeEditor 생성)
    let editor = new Rete.NodeEditor('demo@0.1.0', container);

    // Plugin UMD 설정
    editor.use(ConnectionPlugin);
    editor.use(VueRenderPlugin);

    // 에디터 범위 설정
    editor.use(AreaPlugin, {
        background: false,
        snap: false,
        scaleExtendt: { min: 0.1, max: 1 },
        translateExtent: { width: 5000, height: 4000 }
    });

    // 자동 정렬 설정
    editor.use(AutoArrangePlugin, {
        margin: { x: 150, y: 150 },
        depth: 100
    });

    // 키보드 설정
    // editor.use(KeyBoardPlugin);


    // redo undo history 설정
    editor.use(HistoryPlugin, { keyboard: true });

    // engine 설정
    let engine = new Rete.Engine('demo@0.1.0');

    // component들을 editor 와 engine에 register
    components.map(cmp => {
        editor.register(cmp);
        engine.register(cmp);
    });

    // monitor render connection
    editor.on('renderconnection', node => {
        // console.log(node)
    })
    // render node
    editor.on('rendernode', node => {
        // console.log(node)
    })

    // monitor connection
    // 나중에 바꿀 가능성이 있음
    // editor.on('connectioncreated', e => {
    // store.dispatch('call_monitor_connect', { monitorCon: true });
    // console.log(store.getters.getConnecting)
    // })

    // monitor not connection
    // 나중에 바꿀 가능성이 있음
    // editor.on('connectionremove', e => {
    // store.dispatch('call_monitor_connect', { monitorCon: false });
    // console.log(store.getters.getConnecting)
    // })


    // 노드 정렬 버튼 EVENT
    editor.on('nodecreated', node => {

        const btn = document.getElementById('arrangebtn');

        btn.addEventListener('click', btnClick, false);

        function btnClick(e) {
            e.preventDefault();
            editor.trigger('arrange');
            editor.view.resize();
            AreaPlugin.zoomAt(editor, editor.nodes);
        }
    })

    // 노드 뒤로가기 버튼 EVENT
    // const btn2 = document.getElementById('undobtn');

    // btn2.addEventListener('click', btnClick2, false);

    // function btnClick2(e) {
    //     e.preventDefault();
    //     editor.trigger("undo");
    // }

    // // 노드 다시실행 버튼 EVENT
    // const btn3 = document.getElementById('redobtn');

    // btn3.addEventListener('click', btnClick3, false);

    // function btnClick3(e) {
    //     e.preventDefault();
    //     editor.trigger("redo");
    // }



    // // 저장 버튼 EVENT
    // const btn4 = document.getElementById('jsonbtn');

    // btn4.addEventListener('click', btnClick4, false);

    // function btnClick4(e) {
    //     e.preventDefault();

    //     let editordata = editor.toJSON();

    //     let editdata = JSON.stringify(editordata);

    //     console.log(editdata);

    //     // sessionStorage.setItem('editdata', editdata);
    // }

    // // 연결 관계 세션 저장
    // const btn5 = document.getElementById('downloadbtn');

    // btn5.addEventListener('click', btnClick5, false);

    // function btnClick5(e) {
    //     e.preventDefault();

    //     let main_data = sessionStorage.getItem("editdata_modify");

    //     let main_json = JSON.parse(main_data);

    //     let m_json = main_json.nodes;

    //     let m_key = Object.keys(m_json);

    //     let m_value = Object.values(m_json);

    //     let arr = [];

    //     let main_arr = [];

    //     let monitor_arr = [];


    //     // main 방식
    //     for (let i = 0; i < m_key.length; i++) {

    //         let m_name = m_value[i].name;
    //         let m_id = m_value[i].id;

    //         if (i === 0) {
    //             // main_arr[0] = m_value[0].id;
    //             for (let j = 0; j < m_key.length; j++) {
    //                 let data_input = m_value[j].name;
    //                 let data_id = m_value[j].id;
    //                 if (data_input.includes("데이터입력")) {
    //                     // console.log(data_input + " " + data_id);
    //                     main_arr[0] = data_id;
    //                 }
    //             }

    //         }
    //         else if (i != 0) {
    //             if (i === 1) {
    //                 let m_con = m_json[main_arr[i - 1]].outputs.output1.connections;

    //                 for (let j = 0; j < m_con.length; j++) {
    //                     let m_test = m_json[m_con[j].node];
    //                     if (m_test.name === 'MNIST ConvNet') {
    //                         main_arr[1] = m_test.id;
    //                     }
    //                     else if (m_test.name === 'MNIST DenseNet') {
    //                         main_arr[1] = m_test.id;
    //                     }
    //                     else if (m_test.name === 'MNIST Custom CNN') {
    //                         main_arr[1] = m_test.id;
    //                     }
    //                     else if (m_test.name === 'Iris DenseNet') {
    //                         main_arr[1] = m_test.id;
    //                     }
    //                 }
    //             }
    //             else if (i === 2) {
    //                 let m_con = m_json[main_arr[i - 1]].outputs.output1.connections;

    //                 for (let j = 0; j < m_con.length; j++) {
    //                     let m_test = m_json[m_con[j].node];
    //                     if (m_test.name === "모델 저장") {
    //                         main_arr[2] = m_test.id;
    //                     }
    //                 }
    //             }
    //             else if (i === 3) {
    //                 main_arr[3] = m_json[main_arr[i - 1]].outputs.output1.connections[0].node;
    //             }
    //         }

    //         for (let k = 0; k < m_key.length; k++) {
    //             if (m_name.includes("모니터링")) {
    //                 monitor_arr[i] = m_id;
    //             }
    //         }
    //     }
    //     // 빈값 필터하기
    //     let monitor_arr_f = monitor_arr.filter(el => {
    //         return el != null;
    //     });

    //     arr = main_arr.concat(monitor_arr_f);

    //     console.log(arr)


    //     let main_numbering = JSON.stringify(arr);

    //     sessionStorage.setItem('main_numbering', main_numbering);

    //     // 프로젝트 저장
    //     // 모델 수정 및 모든 작업을 마무리할때 저장 버튼

    //     let model_num = store.getters.get_model_node_id;

    //     let model_data = sessionStorage.getItem('modaldata' + model_num);

    //     let model_json = JSON.parse(model_data);

    //     m_json[model_num].data = model_json;

    //     sessionStorage.setItem('editdata_modify', JSON.stringify(main_json));

    //     // let node_sel = store.getters.getNodeSelected;

    //     // console.log(node_sel)

    //     // console.log(store.getters.get_model_node_id)

    //     // if (node_sel != null) {
    //     //  let model_data = sessionStorage.getItem('modaldata' + node_sel);

    //     //  let model_json = JSON.parse(model_data);

    //     //  m_json[node_sel].data = model_json;

    //     //  sessionStorage.setItem('editdata_modify', JSON.stringify(main_json));
    //     // }
    //     // else {
    //     //  // 그냥 중간 저장
    //     //  sessionStorage.setItem('editdata_modify', JSON.stringify(main_json));
    //     // }
    // }


    // 노드 지우기 버튼 EVENT

    // nodeselected event 발생시 selected node id 와 저장된 node 아이디 비교
    // editor에서 노드 삭제 => 노드 삭제 이벤트 발생시 자동 저장

    const btn6 = document.getElementById('trashbtn');
    btn6.addEventListener('click', btnClick6, false);

    function btnClick6(e) {
        e.preventDefault();
        // console.log(e.type);
        switch (e.type) {
            case 'click':
                editor.selected.each(n => editor.removeNode(n));
                break;
            default:
                break;
        }
    };

    // DOCK MENU 클릭 시 노드 생성 EVENT
    document.querySelectorAll('.dock .dock-item__node').forEach(item => {
        item.addEventListener('click', event => {
            
            // console.log(item);
            switch (item.id) {
                case "datainput":
                    components[0].createNode().then(n => editor.addNode(n));
                    return;
                case "defaultinput":
                    components[1].createNode().then(n => editor.addNode(n));
                    return;
                case "webcam":
                    components[2].createNode().then(n => editor.addNode(n));
                    return;

                case "datapreprocess":
                    components[3].createNode().then(n => editor.addNode(n));
                    return;

                case "datamonitoring":
                    components[4].createNode().then(n => editor.addNode(n));
                    return;

                case "modelsave":
                    components[5].createNode().then(n => editor.addNode(n));
                    return;
                case "mnist_densenet":
                    components[6].createNode().then(n => editor.addNode(n));
                    return;

                case "mnist_convnet":
                    components[7].createNode().then(n => editor.addNode(n));
                    return;

                case "monitoring_data":
                    components[8].createNode().then(n => editor.addNode(n));
                    return;

                case "monitoring_model":
                    components[9].createNode().then(n => editor.addNode(n));
                    return;

                case "inference":
                    components[10].createNode().then(n => editor.addNode(n));
                    return

                case "mnist_data":
                    components[11].createNode().then(n => editor.addNode(n));
                    return
                case "mnist_fashion_data":
                    components[12].createNode().then(n => editor.addNode(n));
                    return
                case "mnist_custom_cnn":
                    components[13].createNode().then(n => editor.addNode(n));
                    return
                case "iris_densenet":
                    components[14].createNode().then(n => editor.addNode(n));
                    return;
                case "iris_data":
                    components[15].createNode().then(n => editor.addNode(n));
                    return;
            }

        })
    });


    // 에디터 실행
    // nodecreated noderemoved connectioncreated connectionremoved connectionpath
    editor.on('process', async() => {
        // console.log('processing...');
        await engine.process(editor.toJSON());

        let editdata_modify = JSON.parse(sessionStorage.getItem('editdata_modify'));

        if (editdata_modify === null) {
            await editor.fromJSON(MnistConvData);
            // console.log("intro data");
            await AreaPlugin.zoomAt(editor, editor.nodes);
        }
        else {
            await editor.fromJSON(editdata_modify);
            // console.log("modify_data");
            await AreaPlugin.zoomAt(editor, editor.nodes);
        }

        await engine.abort();
    });

    const mnist_test_btn = document.getElementById('mnist_test');

    mnist_test_btn.addEventListener('click', mnist_test_function, false);

    function mnist_test_function(e) {
        e.preventDefault();

        editor.fromJSON(MnistConvData);
        AreaPlugin.zoomAt(editor, editor.nodes);

        const editordata = editor.toJSON();

        let editdata = JSON.stringify(editordata);

        sessionStorage.setItem('editdata', editdata);

    }



    // MNIST 기본 SET
    editor.on('nodecreate', node => {
        // console.log("노드id :" + node.id +" 노드 name :" + node.name);

        switch (node.name) {

            // Datainput - MNIST
            case "데이터입력(MNIST)":
                store.dispatch('call_data_input_name', { data_input_name: node.name });
                return;

                // Datainput - IRIS
            case "데이터입력(IRIS)":
                store.dispatch('call_data_input_name', { data_input_name: node.name });
                return;

                // MNIST ConvNet
            case "MNIST ConvNet":
                testcnn();
                return;

                // CUSTOM MNIST
            case "MNIST Custom CNN":
                store.dispatch('call_model_node_name', { model_node_name: node.name });
                store.dispatch('call_model_node_id', { model_node_id: node.id });

                let conf_tt = sessionStorage.getItem('modaldata' + store.getters.get_model_node_id);

                if (conf_tt === null) {
                    sessionStorage.setItem('modaldata' + store.getters.get_model_node_id, JSON.stringify(defaultCustom));
                    return;
                }
                else {
                    return;
                }
                // MNIST DenseNet
            case "MNIST DenseNet":
                densenet();
                return;

                // Iris DenseNet
            case "Iris DenseNet":
                iris_densenet();
                return;
        }


        function testcnn() {
            store.dispatch('call_model_node_name', { model_node_name: node.name });
            store.dispatch('call_model_node_id', { model_node_id: node.id });

            let cnn_data = JSON.stringify(TestCNNdata);

            sessionStorage.setItem('modaldata' + node.id, cnn_data);

            let cnn_nodes = TestCNNdata.nodes;

            let cnn_key = Object.keys(cnn_nodes);

            let cnn_value = Object.values(cnn_nodes);

            let arr = [];

            for (let i = 0; i < cnn_key.length; i++) {
                if (i === 0) {
                    arr[0] = cnn_value[0].id;
                }
                else if (i != 0) {
                    if (i === 1) {
                        arr[i] = cnn_value[i - 1].outputs.output1.connections[0].node;
                    }
                    else if (i >= 1) {
                        arr[i] = cnn_nodes[arr[i - 1]].outputs.output1.connections[0].node;
                    }
                }
            }
            let model_numbering = JSON.stringify(arr);

            sessionStorage.setItem('model_numbering' + node.id, model_numbering);
        }
        // dense 불러오기 
        function densenet() {
            store.dispatch('call_model_node_name', { model_node_name: node.name });
            store.dispatch('call_model_node_id', { model_node_id: node.id });

            let dense_data = JSON.stringify(DenseNetdata);
            sessionStorage.setItem('modaldata' + node.id, dense_data);

            let dense_nodes = DenseNetdata.nodes;

            let dense_key = Object.keys(dense_nodes);

            let dense_value = Object.values(dense_nodes);

            let arr = [];

            for (let i = 0; i < dense_key.length; i++) {
                if (i === 0) {
                    arr[0] = dense_value[0].id;
                }
                else if (i != 0) {
                    if (i === 1) {
                        arr[i] = dense_value[i - 1].outputs.output1.connections[0].node;
                    }
                    else if (i >= 1) {
                        arr[i] = dense_nodes[arr[i - 1]].outputs.output1.connections[0].node;
                    }
                }
            }
            let model_numbering = JSON.stringify(arr);

            sessionStorage.setItem('model_numbering' + node.id, model_numbering);
        }

        function iris_densenet() {
            store.dispatch('call_model_node_name', { model_node_name: node.name });
            store.dispatch('call_model_node_id', { model_node_id: node.id });

            let iris_dense_data = JSON.stringify(IrisDenseNetdata);
            sessionStorage.setItem('modaldata' + node.id, iris_dense_data);

            let iris_dense_nodes = IrisDenseNetdata.nodes;

            let iris_dense_key = Object.keys(iris_dense_nodes);

            let iris_dense_value = Object.values(iris_dense_nodes);

            let arr = [];

            for (let i = 0; i < iris_dense_key.length; i++) {
                if (i === 0) {
                    arr[0] = iris_dense_value[0].id;
                }
                else if (i != 0) {
                    if (i === 1) {
                        arr[i] = iris_dense_value[i - 1].outputs.output1.connections[0].node;
                    }
                    else if (i >= 1) {
                        arr[i] = iris_dense_nodes[arr[i - 1]].outputs.output1.connections[0].node;
                    }
                }
            }
            let model_numbering = JSON.stringify(arr);

            sessionStorage.setItem('model_numbering' + node.id, model_numbering);

        }
    })

    // connection 생성 이벤트
    editor.on('connectioncreate', con => {


        switch (con.input.node.name) {

            case "데이터모니터링":
                switch (con.output.node.name) {

                    case '데이터입력(MNIST)':
                        store.dispatch('call_monitor_connect', { monitorCon: true });
                        store.dispatch('call_monitor_connect_name', { monitorCon_name: con.output.node.name });
                        if (store.getters.getConnecting === true) {
                            store.dispatch('call_visualization', { visualization: 'data_visualization' });
                            store.dispatch('call_location', { bottomlocation: 'bottom' });
                            store.dispatch('call_monitor_kinds', { monitorKinds: 'MnistImage' });


                                // popup dialog test =======================================================
                                // store.dispatch('call_dialog_mnist_sum', { dialog_mnist_sum: true });
                                // store.dispatch('call_dialog_mnist_image', { dialog_mnist_image: true });
                                // =========================================================================

                        }
                        return;

                    case '데이터입력(IRIS)':
                        store.dispatch('call_monitor_connect', { monitorCon: true });
                        store.dispatch('call_monitor_connect_name', { monitorCon_name: con.output.node.name });
                        if (store.getters.getConnecting === true) {
                            store.dispatch('call_visualization', { visualization: 'data_textualization' });
                            store.dispatch('call_location', { bottomlocation: 'bottom' });
                            store.dispatch('call_monitor_kinds', { monitorKinds: 'DataTable' });

                            // popup dialog test =======================================================
                            // store.dispatch('call_dialog_iris_sum', { dialog_iris_sum: true });
                            // store.dispatch('call_dialog_iris_table', { dialog_iris_table: true });
                            // =========================================================================
                        }
                        return;
                }
                return;

            case "MNIST ConvNet":
                store.dispatch('call_mnist_connect', { mnist_connect: true });
                return;

            case "MNIST Custom CNN":
                store.dispatch('call_mnist_connect', { mnist_connect: true });
                return;

            case "MNIST DenseNet":
                store.dispatch('call_mnist_connect', { mnist_connect: true });
                return;

                // 추후 변경 요망
            case "Iris DenseNet":
                // store.dispatch('call_data_input_connect', { data_input_connect: con.output.node.name });
                store.dispatch('call_mnist_connect', { mnist_connect: true });
                return;

                // 모델 저장 연결 
            case "모델 저장":
                store.dispatch('call_model_save_con', { model_save_con: true });
                return;

        }
    });
    // connection 지우기 이벤트
    editor.on('connectionremove', con => {
        switch (con.input.node.name) {

            case "데이터모니터링":
                switch (con.output.node.name) {

                    case '데이터입력(MNIST)':
                        store.dispatch('call_monitor_connect', { monitorCon: false });

                        // popup dialog test =======================================================

                        store.dispatch('call_dialog_mnist_sum', { dialog_mnist_sum: false });
                        store.dispatch('call_dialog_mnist_image', { dialog_mnist_image: false });

                        // =========================================================================

                        return;

                    case '데이터입력(IRIS)':
                        store.dispatch('call_monitor_connect', { monitorCon: false });

                        // popup dialog test =======================================================

                        store.dispatch('call_dialog_iris_sum', { dialog_iris_sum: false });
                        store.dispatch('call_dialog_iris_table', { dialog_iris_table: false });

                        // =========================================================================
                        return;
                }
                return;

            case "MNIST ConvNet":
                store.dispatch('call_mnist_connect', { mnist_connect: false });
                return;

            case "MNIST Custom CNN":
                store.dispatch('call_mnist_connect', { mnist_connect: false });
                return;

            case "MNIST DenseNet":
                store.dispatch('call_mnist_connect', { mnist_connect: false });
                return;
                // 추후 변경 요망
            case "Iris DenseNet":
                store.dispatch('call_mnist_connect', { mnist_connect: false });
                return;
                // model save con
            case "모델 저장":
                store.dispatch('call_model_save_con', { model_save_con: false });
                return;
        }

    })

    // 데이터가 비어있으면 초기데이터 / 들어있으면 불러오기
    if (sessionStorage.editdata == undefined) {

        // 초기 데이터 선언
        let initialData = editor.toJSON();

        // session에 넣기 위한 stringify
        let editdata = JSON.stringify(initialData);

        // session setItem
        sessionStorage.setItem('editdata', editdata);

        // 화면에 띄우기
        await editor.fromJSON(JSON.parse(sessionStorage.editdata));
    }
    else {
        // 세션 저장 데이터
        let session_data = sessionStorage.getItem('editdata');

        // 세션 저장 데이터 파싱
        let json = JSON.parse(session_data);

        // 세션 저장 데이터의 노드 json
        let session_json = json.nodes;

        await editor.fromJSON(JSON.parse(sessionStorage.editdata));
        engine.abort();
    }
    // 에디터 뷰 사이즈 재조정
    // editor.view.resize();


    // 에디터 내의 노드 위치 조정
    // editor.trigger('arrange');

    // node 위치로 zoom 조정
    AreaPlugin.zoomAt(editor, editor.nodes);

    editor.trigger('process');
    engine.abort();


    // 노드, 커낵션 생성시 세션에 데이터 저장
    editor.on('nodecreate nodecreated connectioncreate connectioncreated noderemove noderemoved connectionremoved nodedraged', node => {

        const editordata = editor.toJSON();

        let editdata = JSON.stringify(editordata);

        sessionStorage.setItem('editdata_modify', editdata);

        // AreaPlugin.zoomAt(editor, editor.nodes);

        engine.abort();
    })

    // 노드 생성 후 노드에 맞는 모달창 띄우기
    editor.on('nodeselected', node => {
        store.dispatch('call_node_selected', { nodeSelected: node.id });
        // console.log(store.getters.getNodeSelected);

        store.dispatch('call_node_selected_name', { nodeSelected_name: node.name });

        // store.dispatch('call_node_selected_name', { nodeSelected_name: node.name})
        // console.log(node.name)
    })

    editor.on('noderemoved', node => {
        let node_num = store.getters.getNodeSelected;

        sessionStorage.removeItem('modaldata' + node_num);
    });

    // 레떼 Container 안에서 더블클릭을 할 시에 zoom 이벤트 발생하는 것을 차단
    editor.on('zoom', ({ source }) => {
        return source !== 'dblclick';
    });
}