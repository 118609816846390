import { Control } from 'rete';
import InputParameterVueControl from '../vues/InputParameterVueControl.vue';
import CheckBoxParameterVueControl from '../vues/CheckBoxParameterVueControl.vue';
import SelectParameterVueControl from '../vues/SelectParameterVueControl.vue';

export default class ParameterControl extends Control {
    constructor(emitter, key, type, readonly = false) {
        super(key);
        const input_value = type.input_value.replace(/[\(\)]/gi, '').split('|');

        if ((/^\'/).test(input_value[0])) {
            this.component = SelectParameterVueControl;
        }
        else if (input_value[0] == 'boolean') {
            this.component = CheckBoxParameterVueControl;
        }
        else {
            this.component = InputParameterVueControl;
        }


        this.props = {
            emitter,
            ikey: key,
            type,
            readonly,
            input_value
        }
    };

    setValue(value) {
        const ctx = this.vueContext || this.props;
        ctx.value = value;
    }

    onChange() {}
}
