<template>
    <div class="tutorial_wrapper w-100 h-100 d-flex flex-column">
        <div class="tutorial_header w-100">
            <b-row class="w-100 h-100">
                <b-col class="h-100 d-flex align-items-center">
                    <label class="tutorial__title">튜토리얼</label>
                </b-col>
            </b-row>
        </div>
        <div class="tutorial_body d-flex flex-fill">
            <div class="tutorial_index border border-right h-100 px-4 py-4" style="width: 300px;">
                <div class="guide-menu--controls" aria-controls="guideMenu" @click="controlShowingGetStarted">
                    <b-icon icon="chevron-down" class="mr-2"></b-icon>
                    <span>가이드</span>
                </div>
                <b-collapse id="guideMenu" class="guide-menu mt-2" v-model="show_get_started">
                  <b-card class="gudie-menu__starting" body-class="w-100 guide-menus">시작하기</b-card>
                </b-collapse>
            </div>
            <b-container class="tutorial_content w-100">
                <div class="h-100">
                    <b-row class="px-5 py-4">
                        <!--<b-col cols="5">-->
                        <!--    <b-breadcrumb class="text-dark px-0" style="background:white;" :items="items"></b-breadcrumb>-->
                        <!--</b-col>-->
                        <!--<b-col cols="5">-->
                        <!--</b-col>-->
                    </b-row>
                    <b-row class="px-5">
                        <b-col>
                            <h1 class="mb-5">시작하기</h1>
                            <p style="font-size: 20px;">
                                TensorFlow.js는 브라우저 및 Node.js에서 머신러닝 모델을 학습시키고 배포하기 위한 자바스크립트 라이브러리입니다. 시작할 수 있는 다양한 방법은 아래 섹션을 참조하세요. 
                                텐서를 직접 다루지 않고 ML 프로그램 코딩 텐서 또는 옵티마이저와 같은 하위 수준의 세부정보에 관해 걱정하지 않고 머신러닝을 시작하고 싶으신가요? 
                                TensorFlow.js를 기반으로 빌드된 ml5.js 라이브러리는 간결하고 접근하기 쉬운 API를 사용하여
                                브라우저에서 머신러닝 알고리즘 및 모델에 액세스할 수 있도록 합니다.<br/> <br/><br/>
                                ml5.js 확인 TensorFlow.js 설정 텐서, 레이어, 옵티마이저 및 손실 함수와 같은 개념에 익숙하시나요(또는 익숙해지고자 하시나요)? 
                                TensorFlow.js는 자바스크립트에서 신경망 프로그래밍을 위한 유연한 빌딩 블록을 제공합니다. 
                                브라우저 또는 Node.js에서 TensorFlow.js 코드를 시작하고 실행하는 방법을 알아보세요.<br/> <br/><br/>
                                설정 선행 학습된 모델을 TensorFlow.js로 변환 선행 학습된 모델을 Python에서 TensorFlow.js로 변환하는 방법을 알아보세요.
                                Keras 모델 GraphDef 모델 기존 TensorFlow.js 코드에서 학습 tfjs-examples는 TensorFlow.js를 사용하여 다양한 ML 작업을 구현하는 짧은 코드 예시를 제공합니다. 
                                GitHub에서 확인 TensorFlow.js 모델의 동작 시각화 tfjs-vis는 TensorFlow.js와 함께
                                사용하도록 고안된 브라우저 시각화를 위한 작은 라이브러리입니다. 
                                GitHub에서 확인 데모 보기 데이터를 TensorFlow.js로 처리하도록 준비 TensorFlow.js는 ML 권장사항을 사용하여 데이터 처리를 지원합니다. 문서 보기
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Tutorials',
        data() {
            return {
                show_get_started: true,
                // items: [{
                //         text: 'nocodingAI',
                //         href: '#'
                //     },
                //     {
                //         text: '학습',
                //         href: '#'
                //     },
                //     {
                //         text: 'For Javascript',
                //         href: '#'
                //     },
                //     {
                //         text: '튜토리얼',
                //         active: true
                //     }
                // ]
            }
        },
        methods: {
            controlShowingGetStarted(){
                this.show_get_started = !this.show_get_started;
            }
        }
    }
</script>
<style scoped>
    .tutorial_header {
        height: 80px;
        padding: 20px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24) !important;
    }

    .tutorial__title {
        font-size: 20px;
        font-weight: bold;
        margin-left: 20px;
        margin-right: 70px;
        margin-bottom: 0;
    }

.tutorial_body .guide-menu--controls{
    cursor:pointer;
}

.guide-menus{
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #F0F0F7;
    color: #555555 !important;
    height: 45px;
    cursor: pointer;
}
</style>
