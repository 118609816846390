<template>
    <div class="dock">
        <div class="dock-item-list" v-for="group in Object.keys(functionGroups)">
            <div class="dock-label" v-b-toggle="group">
                <!--노드 구성 토글 버튼 부분 노드 구성-->
                <label>{{ group }}</label>
                <span><i class="fas fa-chevron-down"></i></span>
            </div>
    
            <b-collapse visible id="collapse" :id="group">
                <!--json 파일을 확인하여 각 레이어 별 버튼 생성-->
                <div v-for="processor in Object.keys(functionGroups[group])" class="dock-item" :class="group">
                    <div class="dock-item__distinguish"></div>
                    <div class="node" :data-group="group" :data-processor="processor">
                        <div class="title">{{processor}}</div>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    export default {
        name: "dock-item",
        props: ['functionGroups'],
        data() {
            return {}
        },
        mounted() {
            console.log(this.functionGroups)
        },
        methods: {}
    }
</script>

<style scoped>
    .dock {
        width: 350px;
        min-height: 750px;
        max-height: 750px;
        overflow-y: scroll;
        color: #555555;
        background-color: #ffffff;
        margin-right: 15px;
        border: 2px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(to right, rgba(81, 45, 168, 1), rgba(81, 45, 168, 0.7));
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 30px 35px;
    }

    .dock::-webkit-scrollbar {
        width: 3px;
    }

    .dock::-webkit-scrollbar-track {
        background-color: #f9f9f9;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.12);
    }

    .dock::-webkit-scrollbar-thumb {
        background-color: rgba(81, 45, 168, 0.7);
        border-radius: 20px !important;
    }

    .dock .dock-label {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #512da8;
    }

    .dock .dock-label label {
        margin: 0;
    }

    .dock .dock-item-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
    }

    .dock .dock-item-list .show {
        max-width: 100% !important;
    }

    .dock .dock-item-list:last-child {
        margin-bottom: 0;
    }

    .dock .dock-item-list .dock-item {
        display: flex;
        margin-top: 10px;
    }

    .dock-item:hover {
        font-weight: 700;
        opacity: 1;
    }

    .dock-item .node {
        cursor: pointer;
        height: 45px !important;
        display: flex;
        align-items: center !important;
        justify-content: center;
        border-right: 1px solid #555555 !important;
        border-top: 1px solid #555555 !important;
        border-bottom: 1px solid #555555 !important;
        box-shadow: none;
        color: #ccc !important;
    }

    .dock-item .dock-item__distinguish {
        width: 10px;
        height: 45px;
        background-color: #512da8;
        border-right: 1px solid #512da8;
    }

    .dock-item.random .dock-item__distinguish {
        background-color: #ff7675;
        border-right: 1px solid #ff7675;
    }

    .dock-item.sliceandjoining .dock-item__distinguish {
        background-color: #0984e3;
        border-right: 1px solid #0984e3;
    }

    .dock-item.transformation .dock-item__distinguish {
        background-color: #6ab04c;
        border-right: 1px solid #6ab04c;
    }

    .dock-item.Monitoring .dock-item__distinguish {
        background-color: #f0932b;
        border-right: 1px solid #f0932b;
    }

    .dock .node span {
        height: 30px !important;
        width: 30px !important;
        margin-left: 5px;
    }

    .dock .node .title {
        margin-left: 5px !important;
    }

    .title {
        font-size: 13px;
        color: #555555 !important;
        background: transparent;
    }
</style>
