import Rete from "rete";
import { numSocket } from '../sockets';
import DataPreprocessFunctionControl from './DataPreprocessFunctionControl';
import ParameterControl from "./Parameters/controls/ParameterControl.js";

export default class DataPreprocessFunctionComponent extends Rete.Component {
	//tfjs json에서 가져온 객체를 바탕으로 새 인스턴스 생성
	constructor(config) {
		super(config.class_name);
		this.data = {
			render: "vue",
		};
		this.config = config;
		this.engine = new Rete.Engine('demo@0.1.0');
	}

	builder(node) {
		let config = this.config;
		
		if (this.name != 'Input') {
			let input_name = this.name + node.id + "_input";
			node.addInput(new Rete.Input(input_name, "입력", numSocket, node.data.group == 'Merge'));
		}

		if (this.name != 'Output') {
			let output_name = this.name + node.id + "_output";
			node.addOutput(new Rete.Output(output_name, "출력", numSocket, true));
		}


		// 데이터 전처리의 parameters 객체를 참조하여 입력 요소 추가
		console.log(config.parameters);
		for (let p in config.parameters) {
			let param = config.parameters[p];
			let control = new ParameterControl(this.editor, p, param);
			node.addControl(control);
		}
		//기본 컨트롤 추가
		node.addControl(new DataPreprocessFunctionControl(this.editor, config.class_name));

		// for()
		return node;
	}

	worker(node, inputs, outputs) {
		console.log("실행", node, inputs, outputs);
	}
}
