<template>
    <!--좌측 사이드바 부분-->
    <div class="sidenav">
	    <!--<b-icon class="side-toggle" icon="list"/>-->
		<!-- 워크스페이스 화면 이동 -->
			<router-link to="/workspaces" v-b-popover.hover.right="'홈'">
				<svg xmlns="http://www.w3.org/2000/svg" width="20.495" height="15.938" viewBox="0 0 20.495 15.938">
	  				<path id="Icon_awesome-home" data-name="Icon awesome-home" d="M9.975,6.388l-6.56,5.4v5.831a.569.569,0,0,0,.569.569l3.987-.01a.569.569,0,0,0,.566-.569V14.206a.569.569,0,0,1,.569-.569h2.277a.569.569,0,0,1,.569.569v3.4a.569.569,0,0,0,.569.571l3.986.011a.569.569,0,0,0,.569-.569V11.787l-6.558-5.4A.434.434,0,0,0,9.975,6.388ZM20.336,10.06,17.362,7.608V2.68a.427.427,0,0,0-.427-.427H14.943a.427.427,0,0,0-.427.427V5.264L11.33,2.643a1.708,1.708,0,0,0-2.17,0L.154,10.06a.427.427,0,0,0-.057.6L1,11.764a.427.427,0,0,0,.6.058L9.975,4.929a.434.434,0,0,1,.544,0l8.369,6.893a.427.427,0,0,0,.6-.057l.907-1.1a.427.427,0,0,0-.06-.6Z" transform="translate(0.001 -2.254)" fill="#464646"/>
				</svg>
			</router-link>
			
		<!-- 채팅 화면 이동 임시 지정 -->
		<!--<router-link to="#" v-b-popover.hover.right="'채팅-학습자와의 대화 - Coming Soon...'">-->
	  	<!--		<div class="temp_link" v-b-popover.hover.right="'채팅-학습자와의 대화 - Coming Soon...'">-->
	    <!--			<svg xmlns="http://www.w3.org/2000/svg" width="14.801" height="14.801" viewBox="0 0 14.801 14.801">-->
		<!--	  <g id="Icon_ionic-md-chatbubbles" data-name="Icon ionic-md-chatbubbles" transform="translate(0 0)">-->
		<!--	    <path id="패스_445" data-name="패스 445" d="M6.392,18.65A1.857,1.857,0,0,1,4.8,17.056V11.25H4.457a1.086,1.086,0,0,0-1.082,1.082v9.734L5.456,20h7.653a1.1,1.1,0,0,0,1.082-1.1V18.65Z" transform="translate(-3.375 -7.265)" fill="#464646"/>-->
		<!--	    <path id="패스_446" data-name="패스 446" d="M19.261,3.375H9.328A1.245,1.245,0,0,0,8.086,4.617v8.044a1.248,1.248,0,0,0,1.242,1.245H17.8l2.7,1.886V4.617A1.245,1.245,0,0,0,19.261,3.375Z" transform="translate(-5.702 -3.375)" fill="#464646"/>-->
		<!--	  </g>-->
		<!--	</svg>-->
		<!--</div>-->
  		<!--</router-link>-->
  				
  		<!-- 추론 화면 이동 -->
		<router-link to="/inference" v-b-popover.hover.right="page_inference_popover">
			<svg xmlns="http://www.w3.org/2000/svg" width="19.818" height="15.854" viewBox="0 0 19.818 15.854">
			  <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,7.927v3.964a.99.99,0,0,0,.991.991h.991V6.936H.991A.99.99,0,0,0,0,7.927ZM14.368,2.973H10.9V.991a.991.991,0,1,0-1.982,0V2.973H5.45A2.477,2.477,0,0,0,2.973,5.45v8.423a1.984,1.984,0,0,0,1.982,1.982h9.909a1.984,1.984,0,0,0,1.982-1.982V5.45A2.477,2.477,0,0,0,14.368,2.973ZM7.927,12.882H5.945v-.991H7.927ZM6.936,9.166A1.239,1.239,0,1,1,8.175,7.927,1.238,1.238,0,0,1,6.936,9.166ZM10.9,12.882H8.918v-.991H10.9Zm2.973,0H11.891v-.991h1.982Zm-.991-3.716A1.239,1.239,0,1,1,14.12,7.927,1.238,1.238,0,0,1,12.882,9.166Zm5.945-2.23h-.991v5.945h.991a.99.99,0,0,0,.991-.991V7.927A.99.99,0,0,0,18.827,6.936Z" fill="#464646"/>
			</svg>
		</router-link>
  				
  		<!-- 데이터 전처리 화면 이동 임시 지정-->
		<!--<router-link to="testpage" v-b-popover.hover.right="'데이터 전처리 - Coming Soon...'">-->
		<!--<div class="temp_link" v-b-popover.hover.right="'데이터 전처리 - Coming Soon...'">-->
			<!--<svg xmlns="http://www.w3.org/2000/svg" width="13.873" height="15.854" viewBox="0 0 13.873 15.854">-->
			  <!--<path id="Icon_awesome-database" data-name="Icon awesome-database" d="M13.873,2.265V3.68c0,1.247-3.107,2.265-6.936,2.265S0,4.928,0,3.68V2.265C0,1.017,3.107,0,6.936,0S13.873,1.017,13.873,2.265Zm0,3.185V8.635c0,1.247-3.107,2.265-6.936,2.265S0,9.882,0,8.635V5.45c1.49,1.026,4.218,1.5,6.936,1.5S12.382,6.476,13.873,5.45Zm0,4.955v3.185c0,1.247-3.107,2.265-6.936,2.265S0,14.837,0,13.589V10.4c1.49,1.026,4.218,1.5,6.936,1.5S12.382,11.431,13.873,10.4Z" fill="#464646"/>-->
			<!--</svg>-->
		<!--</div>-->
		<!--</router-link>-->
		
		<!-- 모델 만들기 화면 이동 -->
		<router-link to="/trainmodel" v-b-popover.hover.right="page_createmodel_popover">
			<svg xmlns="http://www.w3.org/2000/svg" width="19.818" height="15.854" viewBox="0 0 19.818 15.854">
			  <path id="Icon_awesome-project-diagram" data-name="Icon awesome-project-diagram" d="M11.891,9.909H7.927a.991.991,0,0,0-.991.991v3.964a.991.991,0,0,0,.991.991h3.964a.991.991,0,0,0,.991-.991V10.9A.991.991,0,0,0,11.891,9.909ZM5.945.991A.991.991,0,0,0,4.954,0H.991A.991.991,0,0,0,0,.991V4.955a.991.991,0,0,0,.991.991H3.955L6.22,9.91a1.975,1.975,0,0,1,1.707-.992h.009L5.945,5.435V3.964h6.936V1.982H5.945ZM18.827,0H14.863a.991.991,0,0,0-.991.991V4.955a.991.991,0,0,0,.991.991h3.964a.991.991,0,0,0,.991-.991V.991A.991.991,0,0,0,18.827,0Z" fill="#464646"/>
			</svg>
		</router-link>
		
		<!-- 튜토리얼 화면 이동 -->
		<!--<router-link to="/tutorials" v-b-popover.hover.right="'튜토리얼'">-->
		<!--			<svg xmlns="http://www.w3.org/2000/svg" width="18.217" height="18.216" viewBox="0 0 18.217 18.216">-->
		<!--  		  <path id="Icon_awesome-book-reader" data-name="Icon awesome-book-reader" d="M12.524,3.416A3.416,3.416,0,1,0,9.108,6.831,3.416,3.416,0,0,0,12.524,3.416ZM8.311,8.578C6.2,7.286,2.781,6.931,1.06,6.833A.984.984,0,0,0,0,7.8v7.927a.979.979,0,0,0,.942.962A19.139,19.139,0,0,1,7.811,18.16a.5.5,0,0,0,.729-.422V8.986a.473.473,0,0,0-.228-.408Zm8.845-1.745c-1.72.1-5.14.453-7.25,1.745a.482.482,0,0,0-.228.414v8.745a.5.5,0,0,0,.731.423,19.147,19.147,0,0,1,6.866-1.473.979.979,0,0,0,.942-.963V7.8A.985.985,0,0,0,17.156,6.833Z" fill="#464646"/>-->
		<!--  		</svg>-->
		<!--</router-link>-->
  		
  		<!-- 저장하기 화면 이동 -->
		<!--<router-link to="#" v-b-popover.hover.right="'저장하기 - Coming Soon...'">-->
		<!--<div class="temp_link" v-b-popover.hover.right="'저장하기 - Coming Soon...'">-->
		<!--	<svg xmlns="http://www.w3.org/2000/svg" width="17.835" height="15.854" viewBox="0 0 17.835 15.854">-->
		<!--	  <path id="Icon_awesome-file-export" data-name="Icon awesome-file-export" d="M11.891,3.775a.741.741,0,0,0-.217-.523L8.642.217A.743.743,0,0,0,8.116,0H7.927V3.964h3.964Zm5.791,5.763L14.718,6.552a.5.5,0,0,0-.848.35V8.918H11.888V10.9h1.982v2.019a.5.5,0,0,0,.848.35l2.963-2.988A.527.527,0,0,0,17.681,9.537ZM5.945,10.4V9.414a.5.5,0,0,1,.5-.5h5.45V4.955H7.679a.745.745,0,0,1-.743-.743V0H.743A.741.741,0,0,0,0,.743V15.111a.741.741,0,0,0,.743.743h10.4a.741.741,0,0,0,.743-.743V10.9H6.441A.5.5,0,0,1,5.945,10.4Z" fill="#464646"/>-->
		<!--	</svg>-->
		<!--</div>-->
		<!--</router-link>-->
	</div><!--좌측 사이드바 end-->
</template>
<script>

    // 언어 설정 JSON 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: 'Sidebar',
        data() {
            return {
                // 다국어 설정
                page_home_popover: '',
                page_inference_popover: '',
                page_createmodel_popover: '',

                // 언어 설정
                localization: ''
            }
        },
        mounted() {
            /* global navigator */
            // 로케일로 브라우저의 설정언어 가져오기
            let locale = navigator.language || navigator.userLanguage;

            //
            this.localization = locale.substring(0, 2);

            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.page_home_popover = korean.MainPage.page_home_popover;
                    this.page_inference_popover = korean.MainPage.page_inference_popover;
                    this.page_createmodel_popover = korean.MainPage.page_createmodel_popover;
                    return;
                case 'en':
                    this.page_home_popover = english.MainPage.page_home_popover;
                    this.page_inference_popover = english.MainPage.page_inference_popover;
                    this.page_createmodel_popover = english.MainPage.page_createmodel_popover;
                    return;
            }
        }
    }
</script>
<style scoped>

    /* 사이드 바 전체 */
    .sidenav {
        min-width: 60px;
        height: 977px;
        /*border-right: 1px solid #555555;*/
        border-right: 1px solid #bfc3c5;
        display: flex;
        /*justify-content: center;*/
        align-items: center;
        flex-direction: column;
        text-align: center;
        /*background: #43425D;*/
        background: #ffffff;
        /*padding-top: 130px;*/

        /* 이부분은 나중에 지정한 부분*/
        position: relative;
        z-index: 999;
    }

    /* 대화면 적용 */

    @media screen and (min-width: 1920px) {
        .sidenav {
            height: 100%;
        }
    }

    /* 사이드 바 토글 */
    .sidenav .side-toggle {
        box-shadow: none !important;
    }


    .b-icon.bi {
        vertical-align: 0 !important;
    }

    .sidenav a {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sidenav a:hover {
        background: #C8DDFF;
        cursor: pointer;
        box-shadow: 4px 0px 0px 0px #0074C2 inset;
    }

    .sidenav a:hover svg path {
        fill: #0074C2;
    }

    .sidenav .router-link-exact-active {
        background: #C8DDFF;
        box-shadow: 4px 0px 0px 0px #0074C2 inset;
    }

    .sidenav .router-link-exact-active svg path {
        fill: #0074C2;
    }

    /*진행중인 사이드바 ROUTER LINK 아이콘 이벤트 및 CSS 설정 */

    .temp_link {
        width: 60px;
        height: 60px;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .temp_link:hover {
        background: #C8DDFF;
        cursor: pointer;
        box-shadow: 4px 0px 0px 0px #0074C2 inset;
    }

    .temp_link:hover svg path {
        fill: #0074C2;
    }



    /*========================================================*/
</style>
