import Rete from "rete";
import { numSocket } from '../../sockets';
import FlattenControl from '../../controls/FlattenControl';

export default class FlattenComponent extends Rete.Component {
	constructor() {
		super("flatten");
		this.data = {
			render: "vue"
		};
	}
	builder(node) {
		let input1 = new Rete.Input("input1","입력",numSocket);
		let output1 = new Rete.Output("output1","출력",numSocket);
		return node
			.addControl(new FlattenControl(this.editor, "input"))
			.addInput(input1)
			.addOutput(output1);
	}
	worker() {}
}