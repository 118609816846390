import Rete from "rete";
import { numSocket } from '../../sockets';
import WebCamControl from '../../controls/basic-controls/WebCamControl';

export default class WebCamComponent extends Rete.Component {
	constructor() {
		super("데이터입력(웹캠)");
		this.data = {
			render: "vue"
		};
	}
	builder(node) {
		let input1 = new Rete.Input("input1","Input1",numSocket);
		let output1 = new Rete.Output("output1","Output1",numSocket);
		return node
			.addControl(new WebCamControl(this.editor, "input"))
			.addOutput(output1)
			.addInput(input1);
	}
	worker() {}
}