<template>
    <!--Train Manager 전체-->

    <div class="trainmanager_outer">
        <!--트레이너 사이드 바-->
        <div class="trainmanager_editor" @mouseup="stopMovingTrainManagerDock" @mousemove="startToMoveTrainManagerDock">
            <!--트레이너 에디터 부분 TrainManagerDock 컴포넌트 직계 자손 태그인 div.trainmanager_dock-container
                로 부터 mousedown 이벤트[@startDrag], mouseleave 이벤트[@stopDrag]를 받아 처리하도록 하였음. -->
            <TrainManagerDock ref="train_manager_dock" @startDrag="readyToMoveTrainManagerDock" />
            <div id="trainmanager_rete">
                <div class="operation-btn-group">
                    <!--v-b-tooltip.hover="node_module_cleanup"-->
                    <b-icon icon="justify" id="arrangebtn" class="arrange-btn"  v-b-popover.hover.bottom="'정렬'"></b-icon>
                    <!--v-b-tooltip.hover.bottom="node_module_delete"-->
                    <b-icon icon="code" id="jsonbtn" class="json-btn"></b-icon>
                    <b-icon icon="trash" id="trashbtn" class="trash-btn" v-b-popover.hover.bottom="'삭제'"></b-icon>
                </div>
                <div class="train_parameter_group">
                    <b-icon icon="play" id="playbtn" class="play-btn" @click="model_training" v-b-popover.hover.bottom="'실행'"></b-icon>
                    <label>epoch</label>
                    <input type="number" v-model="epoch"/>
                    <label>batchSize</label>
                    <select v-model="batchSize" id="batchSize_select">
                		<option v-for="option in batchSize_options" v-bind:value="option.value">
                			{{ option.text }}
                		</option>
                	</select>
                    <component :key="componentkey" v-bind:is="model_training_component"></component>
                </div>
            </div>
        </div>
        <!--트레이너 에디터 부분 END-->

    </div>
    <!--Train Manager 전체 END-->
</template>
<script>
    import TrainManagerDock from './TrainManagerDock/TrainManagerDockComp';


    import {
        createFlowEditor
    }
    from './TrainManagerEditor/editor';
    import ModelTraining from './TestModelTraining/ModelTraining';


    /**
     * Sean 수정....
     */
    //import DialogDrag from 'vue-dialog-drag';
    //import * as ncai_tutor2 from '@/ncai-core/tutorials/ncai_tutor2.js'//mnist
    //ncai_tutor2.run();

     /**
     * ~Sean 수정....
     */
     
     
    export default {
        name: 'trainmanager',
        data() {
            return {
                is_moving_train_manager_dock: null,
                x_point_in_modal: null,
                y_point_in_modal: null,

                componentkey: 0,
                model_training_component: '',

                // 하이퍼 파라미터
                epoch: 5,
                batchSize: '512',
                batchSize_options: [
                    { text: '512', value: '512' },
                    { text: '256', value: '256' },
                    { text: '128', value: '128' },
                    { text: '64', value: '64' },
                    { text: '32', value: '32' },
                    { text: '16', value: '16' },
                    { text: '8', value: '8' },
                    { text: '4', value: '4' },
                    { text: '2', value: '2' },
                    { text: 'none', value: 'none' }
                ],
            }
        },
        components: {
            TrainManagerDock,
            ModelTraining,
        },
        methods: {
            model_training() {
                // tensorflow visor 컨테이너 이름으로 받아오기
                let tfjs_visor_container = document.getElementById('tfjs-visor-container');
                let body = document.body;
                // 모델 트레이닝이 처음 실행 될때
                if (this.componentkey === 0) {
                    this.model_training_component = ModelTraining;
                    this.componentkey += 1;
                }
                else {
                    // 모델 트레이닝이 처음 실행이 아닐때
                    if (tfjs_visor_container != null) {
                        body.removeChild(tfjs_visor_container);
                        this.componentkey += 1;
                    }
                    else {
                        this.componentkey += 1;
                    }
                }
            },
            isTrainManagerDockHeader(el) {
                return el.classList.contains("sidemenu-header");
            },
            setModalWhere(event, x_point_in_el, y_point_in_el) {
                if (this.is_moving_train_manager_dock) {
                    // mouselocated: 페이지 포인터에서 topnav 높이(60px)와 sidenav 너비(60px)를 빼준다.
                    const mouselocated_x = event.pageX - 60;
                    const mouselocated_y = event.pageY - 60;
                    const current_x_point = Number(mouselocated_x - x_point_in_el);
                    const current_y_point = Number(mouselocated_y - y_point_in_el);
                    // x, y좌표의 endPoint 추출을 위해 x좌표의 끝, y좌표의 오른쪽 끝 값을 구한다.
                    const x_end_point = event.currentTarget.getBoundingClientRect().right - 60 - 405;
                    const y_end_point = event.currentTarget.getBoundingClientRect().bottom - 522;
                    // 모달 창 이동을 위한 경우의 수 if문이다.
                    /*
                        1. 현재 x 좌표가 x의 앤드포인트를 넘었을 경우
                         - x, y 좌표 모두 각 앤드 포인트를 넘었을 경우, 각 좌표에 앤드포인트 값을 대입하여, 모달 창이 끝을 넘지 않도록 한다.
                         - x만 넘은 경우, x축만 앤드포인트를 넘지 않도록 한다.
                     */
                    if (current_x_point >= x_end_point) {
                        if (current_y_point >= y_end_point) {
                            this.$refs.train_manager_dock.$el.style.left = x_end_point + "px";
                            this.$refs.train_manager_dock.$el.style.top = y_end_point + "px";
                        }
                        else {
                            this.$refs.train_manager_dock.$el.style.left = x_end_point + "px"
                        }
                    }
                    /*
                    2. 현재 y 좌표가 x의 앤드포인트를 넘었을 경우
                     - x, y 좌표 모두 각 앤드 포인트를 넘었을 경우, 1의 경우와 같다.
                     - y만 넘은 경우, y축만 앤드포인트를 넘지 않도록 한다.
                    */
                    else if (current_y_point >= y_end_point) {
                        if (current_x_point >= x_end_point) {
                            this.$refs.train_manager_dock.$el.style.left = x_end_point + "px";
                            this.$refs.train_manager_dock.$el.style.top = y_end_point + "px";
                        }
                        else {
                            this.$refs.train_manager_dock.$el.style.top = y_end_point + "px";
                        }
                    }
                    /*
                    3. 현재 x 좌표가 + 범위인 경우
                     - x, y 좌표 모두 + 범위인 경우, 현재 x값, y값 좌표를 지정
                     - x 좌표만 + 범위인 경우, 현재 x 값 좌표 지정.
                    */
                    else if (current_x_point > 0) {
                        if (current_y_point > 0) {
                            this.$refs.train_manager_dock.$el.style.left = current_x_point + "px";
                            this.$refs.train_manager_dock.$el.style.top = current_y_point + "px";
                        }
                        else {
                            this.$refs.train_manager_dock.$el.style.left = current_x_point + "px";
                        }
                    }
                    /*
                     4. 현재 x 좌표가 - 범위인 경우
                       - x, y 좌표 모두 - 범위인 경우, 둘 다 좌표 값을 0으로 지정.
                       - y 좌표만 넘은 경우, y 좌표만 값을 0으로 지정
                    */
                    else if (current_x_point <= 0) {
                        if (current_y_point <= 0) {
                            this.$refs.train_manager_dock.$el.style.left = 0;
                            this.$refs.train_manager_dock.$el.style.top = 0;
                        }
                        else {
                            this.$refs.train_manager_dock.$el.style.left = 0;
                        }
                    }
                }
            },
            readyToMoveTrainManagerDock(event) {
                //TrainManagerDock 중 클릭한 target 태그가 헤더부분(노드 구성, .sidemenu-header)을 포함하고 있는지 확인한다.
                const is_train_manager_dock_header = this.isTrainManagerDockHeader(event.target);
                if (is_train_manager_dock_header) {
                    // TrainManagerDock의 (0, 0)[왼쪽 끝, 윗쪽 끝]을 기준으로 한 offset 좌표를 저장한 후,
                    // 모달 창 이동을 위한 드래그 중임을 알리기 위해 is_moving_train_manager_dock 변수에 true 값을 대입한다.
                    this.x_point_in_modal = event.offsetX;
                    this.y_point_in_modal = event.offsetY;
                    this.is_moving_train_manager_dock = true;
                }
            },
            startToMoveTrainManagerDock(event) {
                // 드래그 할 준비가 되었다면, 모달 창을 이동시킨다.
                if (this.is_moving_train_manager_dock) {
                    this.setModalWhere(event, this.x_point_in_modal, this.y_point_in_modal);
                }
            },
            stopMovingTrainManagerDock() {
                // 마우스 버튼을 떼거나, 마우스 포인터가 모달 창 밖을 벗어났을 경우, 드래그를 종료한다.
                this.is_moving_train_manager_dock = false;
            }
        },
        mounted() {
            createFlowEditor();

            //-------------스크립트 사용...
        },
    }
</script>
<style scoped>
    .trainmanager_outer {
        width: 100%;
        height: 100%;
        display: flex;
        float: none;
        background-color: rgba(244, 247, 252, 1);
        background-image: radial-gradient(rgba(0, 0, 0, 0.07) 10%, transparent 0), radial-gradient(rgba(0, 0, 0, 0.07) 10%, transparent 0);
        background-position: 0 0, 25px 25px;
        background-size: 25px 25px;
    }

    .trainmanager_editor {
        width: 100%;
        height: 100%;
        /*min-height: 910px;*/
        /*max-height: 100%;*/
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }

    #trainmanager_rete {
        width: 100%;
        height: 100%;
    }

    #trainmanager_rete .operation-btn-group {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 1rem;
        margin-top: 1rem;
        z-index: 999;
    }

    .train_parameter_group {
        display: flex;
        width: 400px;
        height: 50px;
        align-items: center;
        background: #ffffff;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 100px;
        border-radius: 10px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
    }

    .train_parameter_group label {
        font-size: 13px !important;
        margin-bottom: 0px !important;
        margin-left: 10px;
    }

    .train_parameter_group input,
    select {
        border-radius: 5px !important;
        width: 100px !important;
        margin-left: 10px;
    }

    .play-btn,
    .arrange-btn,
    .trash-btn {
        width: 30px;
        height: 30px;
        background-color: #fff;
        border: 1px solid #555555;
        border-radius: 100px;
        padding: 5px;
        margin-left: 10px;
        cursor: pointer;
    }

    .play-btn:hover,
    .arrange-btn:hover,
    .trash-btn:hover {
        background: #CCCCCC;
        border: 2px solid #CCCCCC;
        cursor: pointer;
    }
</style>
