<template>
    <!-- 강의 페이지 -->
    <div class="lectures_outer">
        <!-- Header -->
        <div class="lectures_header">
            <label>Lectures(강의)</label>
        </div>
        <!-- Body -->
        <div class="lectures_content">
            <!-- 강의 검색 -->
            <div class="lectures_search">
                <input type="text" placeholder="찾는 이름..."></input>
                <select v-model="sort_lectures">
                    <option>최근 업데이트순</option>
                    <option>이름 순</option>
                    <option>강의 번호 순</option>
                </select> 
            </div>
            <!--강의 수-->
            <div class="lecture_label">
                <label>AI 강의 ({{ lectures.length }})</label>
            </div>
            <div class="lecture_list">
                <b-card class="lecture_card" no-body v-for="(lecture, index) in lectures" :key="index">
                    <div class="authorize">
                        <div class="authorize_account">
                        <label>{{ lecture.authorize }}</label>
                        </div>
                        <div class="authorize_setting">
                            <b-icon icon="three-dots-vertical"/>
                        </div>
                    </div>
                    <div class="lecture_name">
                        <label>{{ lecture.name }}</label>
                    </div>
                    <div class="lecture_rating">
                        <b-form-rating v-model="lecture.test_value" variant="warning"></b-form-rating>
                    </div>
                    <div class="lecture_explain">
                        <p>{{ lecture.data }}</p>
                    </div>
                    <div class="lecture_card_select">
                        <b-button @click="lecture_click(lecture)" variant="primary" :disabled="lecture.isDisabled">강의 들어가기</b-button>
                        <!--<b-avatar src="https://placekitten.com/300/300"></b-avatar>-->
                        <img :src="lecture.img_path"/>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
    // import new_workspace from '@/components/Newworkspaceform';

    export default {
        name: 'workspaces',
        data() {
            return {
                sort_lectures: '최근 업데이트순',
                lectures: [{
                        authorize: '관리자',
                        name: 'MNIST 강의(1)',
                        test_value: 0,
                        data: 'MNIST 데이터셋, CONVOLUTION NET LAYER 모델 강의',
                        img_path: 'https://placekitten.com/300/300',
                        isDisabled: true
                    },
                    {
                        authorize: '관리자',
                        name: 'MNIST 강의(2)',
                        test_value: 0,
                        data: 'MNIST 데이터셋, DENSE NET LAYER 모델 강의',
                        img_path: 'https://placekitten.com/300/300',
                        isDisabled: true

                    },
                    {
                        authorize: '관리자',
                        name: 'MNIST 강의(3)',
                        test_value: 0,
                        data: 'MNIST 데이터셋, CUSTOM LAYER 모델 강의',
                        img_path: 'https://placekitten.com/300/300',
                        isDisabled: true

                    },
                    {
                        authorize: '관리자',
                        name: 'IRIS 강의',
                        test_value: 0,
                        data: 'IRIS 데이터셋, DENSE NET LAYER 모델 강의',
                        img_path: 'https://placekitten.com/300/300',
                        isDisabled: true
                    },
                    {
                        authorize: '관리자',
                        name: 'MOBILE NET 강의',
                        test_value: 0,
                        data: 'MOBILE NET 을 통한 동식물 구별 모델 강의',
                        img_path: 'https://placekitten.com/300/300',
                        isDisabled: true
                    },
                    {
                        authorize: '관리자',
                        name: 'LENET 강의',
                        test_value: 0,
                        data: '간단한 설명을 넣습니다. 기억하기 쉽게 프로젝트 관련 내용을 적으면 좋습니다.',
                        img_path: 'https://placekitten.com/300/300',
                        isDisabled: true
                    },
                ],
                uploadmodel: [],
            }
        },
        components: {
            // new_workspace
        },
        methods: {
            lecture_click(lecture) {
                console.log(lecture.test_value)
                this.$router.push('main');
            },
            // ==============================================================================
            // 아직 쓰고 있지 않은 파일 불러오기 함수
            upload_file(e) {
                let test1 = e.target.files;

                let alerttest = '';

                for (let i = 0; i < test1.length; i++) {
                    let n_test = test1[i].name;

                    let model_name = store.getters.get_model_name;

                    if (n_test.includes(model_name)) {
                        this.uploadmodel[i] = test1[i];
                    }
                    else {
                        alerttest = '모델 이름을 다시한번 확인해 주세요';
                    }
                }

                if (alerttest.length != 0) {
                    alert(alerttest)
                }
                else {
                    alerttest = '';
                }
            }
            // ==============================================================================
        }
    }
</script>
<style scoped>
    .lectures_outer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #F5F6FA;
    }

    .lectures_header {
        display: flex;
        align-items: center;
        margin-top: 12px;
    }

    .lectures_header label {
        color: #858585;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px;
    }

    .lectures_content {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3%;
    }

    .lectures_search {
        height: 10%;
        width: 75%;
        display: flex;
        align-items: center;
    }

    .lectures_search input {
        height: 50px;
        width: 500px !important;
        border: none !important;
        border-radius: 5px;
        margin-left: 1.7%;
        font-size: 15px !important;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
    }

    .lectures_search select {
        height: 40px;
        width: 200px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: auto;
        font-size: 15px !important;
    }

    .lecture_label {
        height: 10%;
        width: 75%;
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .lecture_label label {
        height: 35px !important;
        margin-left: 1.7%;
        border-bottom: 3px solid #519DFF;
    }

    .lecture_list {
        width: 75%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: auto;
    }

    .get_local_exist_file {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    .get_local_exist_file .b-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        color: #C2C2C2;
    }

    .get_local_exist_file label {
        font-weight: bold;
        font-size: 20px;
        color: #C2C2C2;
    }

    .get_local_exist_file p {
        font-size: 12px;
        color: #C2C2C2;
        text-align: center;
    }


    .get_local_exist_file:hover {
        background: #eeeeee;
        cursor: pointer;
    }


    /*.lecture_list .card {*/

    /*    border: none !important;*/

    /*}*/

    .lecture_list .lecture_card {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    /*.lecture_list .lecture_card:hover {*/

    /*    border: 3px solid #6579FE;*/

    /*}*/

    .authorize {
        width: 100%;
        display: flex;
    }

    .authorize_account {
        width: 60px;
        height: 20px;
        border-radius: 10px;
        background: #E3ECFB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 10px;
    }

    .authorize_account label {
        font-size: 8px;
        margin-bottom: 0 !important;
        color: #3E7AD3;
    }

    .authorize_setting {
        margin-top: 10px;
        margin-right: 10px;
    }

    .lecture_name {
        width: 100%;
    }

    .lecture_name label {
        font-weight: bold;
        margin-left: 30px;
    }

    .lecture_rating {
        width: 100%;
    }

    .lecture_rating .b-rating {
        border: none !important;
        width: 50%;
        margin-left: 15px;
    }

    .lecture_explain {
        width: 100%;
        height: 40%;
    }

    .lecture_explain p {
        font-size: 13px;
        color: #858585;
        work-break: keep-all;
        margin-left: 30px;
        margin-right: 30px;
    }

    .lecture_card_select {
        width: 100%;
        height: 60px;
        /*background: #DDE6F2;*/
        display: flex;
        align-items: center;
    }

    .lecture_card_select button {
        width: 130px;
        height: 35px;
        font-size: 13px !important;
        margin-left: 30px;
        margin-bottom: 40px;
    }

    .lecture_card_select img {
        width: 35px !important;
        height: 35px !important;
        border-radius: 100px;
        margin-bottom: 40px;
        margin-left: 50px;
    }
</style>
