// rete import
import Rete from 'rete';

// rete plugin import
import VueRenderPlugin from 'rete-vue-render-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import AutoArrangePlugin from 'rete-auto-arrange-plugin';
import AreaPlugin from 'rete-area-plugin';
import HistoryPlugin from 'rete-history-plugin';

import store from '@/store';


// Train Manager Editor 컴포넌트 불러오기 
import RawDataComponent from './RawData/RawDataComp';
import DataPreprocessComponent from './DataPreprocess/DataPreprocessComp';
import TrainModelComponent from './TrainModel/TrainModelComp';
import TrainerComponent from './Trainer/TrainerComp';

import MonitoringComponent from './Monitoring/MonitoringComp';
import DataPreprocessVueSelectOption from './DataPreprocess/DataPreprocessVueSelectOption.vue';


import default_data from './default_structure.json';

import { numSocket } from './sockets.js';

// dynamoDB 람다 요청 객체
import dynamoDB from '@/js/dynamoDB.js';

// Main editor execution section

const userinfo = JSON.parse(sessionStorage.getItem("userInfo")) || null;

export async function createFlowEditor() {

    // Train Manager 동작 확인 콘솔 
    console.log('Train Manager 에디터가 동작하고 있습니다.');
    // container 선언
    let container = document.querySelector('#trainmanager_rete');


    // Rete edtior에 등록 할 components
    let components = [
        new RawDataComponent(),
        new DataPreprocessComponent(),
        new TrainModelComponent(),
        new TrainerComponent(),
        // new CanvasComponent()
        new MonitoringComponent()
    ];

    // editor 설정 (NodeEditor 생성)
    let editor = new Rete.NodeEditor('demo@0.1.0', container);

    // Plugin UMD 설정
    editor.use(ConnectionPlugin);
    editor.use(VueRenderPlugin);

    // 에디터 범위 설정
    editor.use(AreaPlugin, {
        background: false,
        snap: false,
        scaleExtendt: { min: 0.1, max: 1 },
        translateExtent: { width: 1860, height: 937 }
    });

    // 자동 정렬 설정
    editor.use(AutoArrangePlugin, {
        margin: { x: 80, y: 80 },
        depth: 100
    });

    // 키보드 설정
    // editor.use(KeyBoardPlugin);

    // node arrange button
    const btn = document.getElementById('arrangebtn');

    btn.addEventListener('click', btnClick, false);

    function btnClick(e) {
        // console.log('Arranging...');
        editor.trigger('arrange');
        editor.view.resize();
        AreaPlugin.zoomAt(editor, editor.nodes);

    }

    // 저장 버튼 EVENT
    const btn4 = document.getElementById('jsonbtn');

    btn4.addEventListener('click', btnClick4, false);

    function btnClick4(e) {
        e.preventDefault();

        let editordata = editor.toJSON();

        let editdata = JSON.stringify(editordata);

        sessionStorage.setItem('current_structure', editdata);

        if (userinfo.email) {
            dynamoDB.putData(userinfo.email, editordata);
        }
    }



    // 노드 지우기 버튼 
    const btn6 = document.getElementById('trashbtn');

    btn6.addEventListener('click', btnClick6, false);

    function btnClick6(e) {
        e.preventDefault();
        switch (e.type) {
            case 'click':
                editor.selected.each(n => editor.removeNode(n));
                break;
            default:
                break;
        }
    };

    // redo undo history 설정
    editor.use(HistoryPlugin, { keyboard: true });

    // engine 설정
    let engine = new Rete.Engine('demo@0.1.0');

    // component들을 editor 와 engine에 register
    components.map(cmp => {
        editor.register(cmp);
        engine.register(cmp);
    });

    // DOCK MENU 클릭 시 노드 생성 EVENT
    document.querySelectorAll('.trainmanager_dock .dock-item__node').forEach(item => {
        item.addEventListener('click', event => {
            switch (item.id) {
                case "original_data":
                    components[0].createNode().then(n => {
                        editor.addNode(n)
                    });
                    return;
                case "data_preprocess":
                    components[1].createNode().then(n => editor.addNode(n));
                    return;
                case "train_model":
                    components[2].createNode().then(n => editor.addNode(n));
                    return;
                case "trainer":
                    components[3].createNode().then(n => editor.addNode(n));
                    return;

                case 'monitoring_data':
                    components[4].createNode().then(n => editor.addNode(n));
                    return;
            }

        })
    });

    // 에디터 실행
    // 세션데이터가 있을시 세션에 있는 json 스트링을 변환하여 editor를 실행하고
    // 세션에 내용이 없을 시 DB확인하고 없을 시 기본 형태로 실행한다.
    if (sessionStorage.getItem('current_structure') || userinfo.email) {
        let current_structure = sessionStorage.getItem('current_structure') || null;

        if (!current_structure) {
            dynamoDB.getData(userinfo.email).then((result) => {
                if (result.data.Item) {
                    current_structure = result.data.Item.data;
                    editor.fromJSON(current_structure)
                }
                else {
                    editor.fromJSON(default_data);
                }
            })
        }
        else {
            current_structure = JSON.parse(current_structure);
            editor.fromJSON(current_structure);
        }

    }
    else {
        editor.fromJSON(default_data);
    }

    // 노드가 렌더될 때 나타나는 이벤트
    editor.on('rendernode', ({ el, node }) => {
        /* 타이틀 안에 생성된 닫기 아이콘을 누르면 노드 제거가 실행되는 이벤트 함수 */
        const removeNodeInTrainModel = (e) => editor.selected.each(n => editor.removeNode(n));

        const selected_node = el.childNodes[0];
        const selected_name = selected_node.className.split(" ")[1];
        const node_title = selected_node.childNodes[0];
        const close_btn_container = document.createElement("span");
        const close_btn_in_node = document.createElement("i");
        const control_tag = selected_node.querySelectorAll(".control");

        // .title 클래스 안에 span 요소 생성
        // span 요소 안에 닫기 아이콘 생성
        close_btn_in_node.className = "fas fa-times";
        close_btn_container.id = "closeBtnInTrainModelEdit";

        close_btn_container.appendChild(close_btn_in_node);
        node_title.appendChild(close_btn_container);


        /* 닫기 화면을 누르면, 노드가 사라짐(removeNodeInTrainModel) */
        close_btn_container.addEventListener("click", removeNodeInTrainModel);
        control_tag[0].className = "first-control";

        if (selected_name === "데이터-전처리") {
            // console.log("현재 노드", node);
            // console.log(control_tag);
        }
    });

    //노드간의 데이터 송수신을 위한 이벤트 핸들링
    editor.on('process', async() => {
        console.log("process");
        //engine 초기화
        await engine.abort();
        //engine.process를 통해 각 노드 컴포넌트의 worker에 명시된 코드를 실행하고 input, output으로 이동시킨다.
        await engine.process(editor.toJSON());
        // await AreaPlugin.zoomAt(editor, editor.nodes);
    });

    editor.on('connectioncreated connectionremoved nodecreated noderemoved', () => {
        sessionStorage.setItem("current_structure", JSON.stringify(editor.toJSON()));
        // editor.trigger('updateconnection');
    });

    // 레떼 Container 안에서 더블클릭을 할 시에 zoom 이벤트 발생하는 것을 차단
    editor.on('zoom', ({ source }) => {
        return source !== 'dblclick';
    });


    // 에디터 노드 위치에 따라서 화면 조정
    // AreaPlugin.zoomAt(editor, editor.nodes);
    // editor.view.resize();

}
