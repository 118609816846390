// rete import
import Rete from 'rete';

// rete plugin import
import VueRenderPlugin from 'rete-vue-render-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import AutoArrangePlugin from 'rete-auto-arrange-plugin';
import AreaPlugin from 'rete-area-plugin';
import HistoryPlugin from 'rete-history-plugin';
// import KeyBoardPlugin from 'rete-keyboard-plugin';

import store from '@/store';


// Train Manager Editor 컴포넌트 불러오기 ===================
// import DataInputComponent from './DataInput/DataInputComp';
// import DataResizeComponent from './DataResize/DataResizeComp';
// import DataInversionComponent from './DataInversion/DataInversionComp';
// import DataTrainTestRatioComponent from './DataTrainTestRatio/DataTrainTestRatioComp';
// import DataOutputComponent from './DataOutput/DataOutputComp';
// import DataMonitoringComponent from './DataMonitoring/DataMonitoringComp';

// ==========================================================

//  DataPreProcessFunction
import DataPreProcessFunctionComp from './DataPreprocessFunction/DataPreprocessFunctionComp';

// import DataPreprocessFunction from './DataPreprocessor-json/DataPreprocessFunction.json';




// Main editor execution section
export async function createFlowEditor(editordata, functionGroups) {
    // Data Preprocess 에디터 동작 확인 콘솔 
    console.log('Data Preprocess 에디터가 동작하고 있습니다.');

    /* 각 그룹 이름 상수화 */
    const INPUT_AND_OUTPUT = "InputAndOutput";
    const RANDOM = "random";
    const SLICE_AND_JOINING = "sliceandjoining";
    const TRANSFORMATION = "transformation";
    const MONITORING = "Monitoring";

    // container 선언
    let container = document.querySelector('#data_preprocess_rete');

    // Rete edtior에 등록 할 components
    // let components = [
        // new DataInputComponent(),
        // new DataResizeComponent(),
        // new DataInversionComponent(),
        // new DataTrainTestRatioComponent(),
        // new DataOutputComponent(),
        // new DataMonitoringComponent()
    // ];

    // editor 설정 (NodeEditor 생성)
    let editor = new Rete.NodeEditor('demo@0.1.0', container);

    // Plugin UMD 설정
    editor.use(ConnectionPlugin);
    editor.use(VueRenderPlugin);

    // 에디터 범위 설정
    editor.use(AreaPlugin, {
        background: false,
        snap: false,
        scaleExtendt: { min: 0.1, max: 1 },
        translateExtent: { width: 5000, height: 4000 }
    });

    // 자동 정렬 설정
    editor.use(AutoArrangePlugin, {
        margin: { x: 80, y: 80 },
        depth: 100
    });

    // 키보드 설정
    // editor.use(KeyBoardPlugin);

    // node arrange button
    const btn = document.getElementById('data_preprocess_arrange');

    btn.addEventListener('click', btnClick, false);

    function btnClick(e) {
        console.log('Arranging...');
        editor.trigger('arrange');
        editor.view.resize();
        AreaPlugin.zoomAt(editor, editor.nodes);

    }

    // // 저장 버튼 EVENT
    const btn4 = document.getElementById('data_preprocess_json');

    btn4.addEventListener('click', btnClick4, false);

    function btnClick4(e) {
        e.preventDefault();

        let editordata = editor.toJSON();

        let editdata = JSON.stringify(editordata);

        // sessionStorage.setItem('editdata', editdata);
    }



    // 노드 지우기 버튼 
    const btn6 = document.getElementById('data_preprocess_trash');

    btn6.addEventListener('click', btnClick6, false);

    function btnClick6(e) {
        e.preventDefault();
        switch (e.type) {
            case 'click':
                editor.selected.each(n => editor.removeNode(n));
                break;
            default:
                break;
        }
    };

    // redo undo history 설정
    editor.use(HistoryPlugin, { keyboard: true });

    // engine 설정
    let engine = new Rete.Engine('demo@0.1.0');

    // component들을 editor 와 engine에 register
    // components.map(cmp => {
    //     editor.register(cmp);
    //     engine.register(cmp);
    // });



    // 동적으로 컴포넌트를 등록하기 위한 함수
    const createComponent = (group, processor) => {
        // 입력받은 이름으로 JSON 파일 조회
        let config = functionGroups[group][processor];
        // JSON 객체 정보로 node 컴포넌트 생성
        let comp = new DataPreProcessFunctionComp(config);

        //생성된 노드 컴포넌트를 엔진과 에디터에 등록
        editor.components.set(processor, comp);
        engine.components.set(processor, comp);

        return comp;
    }

    // DOCK MENU 클릭 시 노드 생성 EVENT
    document.querySelectorAll('.dock .node').forEach(item => {
        item.addEventListener('click', event => {
            createComponent(item.dataset.group, item.dataset.processor).
            createNode().then(n => {
                n.data.group = item.dataset.group;
                editor.addNode(n);
            });

        })
    });

    // sessionData 확인하여 초기 노드 설정
    if (editordata) {
        for (let n in editordata.nodes) {
            //에디터 확인 후 컴포넌트 등록 안되어 있을 시 생성전에 등록시킨다.
            if (!editor.components.get(editordata.nodes[n].name)) {
                const group = editordata.nodes[n].data.group;
                createComponent(group, editordata.nodes[n].name);
            }
        }

        editor.fromJSON(editordata);
    }


    // 노드가 렌더될 때 나타나는 이벤트
    editor.on('rendernode', ({ el, node }) => {
        /* 타이틀 안에 생성된 닫기 아이콘을 누르면 노드 제거가 실행되는 이벤트 함수 */
        const removeNodeInTrainModel = (e) => editor.selected.each(n => editor.removeNode(n));

        const selected_node = el.childNodes[0];
        const node_title = selected_node.childNodes[0];
        const close_btn_container = document.createElement("span");
        const close_btn_in_node = document.createElement("i");

        // .title 클래스 안에 span 요소 생성
        // span 요소 안에 닫기 아이콘 생성
        close_btn_in_node.className = "fas fa-times";
        close_btn_container.id = "closeBtnInTrainModelEdit";

        close_btn_container.appendChild(close_btn_in_node);
        node_title.appendChild(close_btn_container);

        /* 닫기 화면을 누르면, 노드가 사라짐(removeNodeInTrainModel) */
        close_btn_container.addEventListener("click", removeNodeInTrainModel);

        //선택된 노드의 그룹을 구별시키기 위해 클래스 이름 추가.
        switch (node.data.group) {
            case INPUT_AND_OUTPUT:
                selected_node.classList.add(node.data.group);
                return;
            case RANDOM:
                selected_node.classList.add(node.data.group);
                // 타이틀 색 분홍색으로 변경
                node_title.style.backgroundColor = "#ff7675";
                return;
            case SLICE_AND_JOINING:
                selected_node.classList.add(node.data.group);
                // 타이틀 색 하늘색으로 변경
                node_title.style.backgroundColor = "#0984e3";
                return;
            case TRANSFORMATION:
                selected_node.classList.add(node.data.group);
                // 타이틀 색 연두색으로 변경
                node_title.style.backgroundColor = "#6ab04c";
                return;
            case MONITORING:
                selected_node.classList.add(node.data.group);
                // 타이틀 색 하늘색으로 변경
                node_title.style.backgroundColor = "#0984e3";
                return;
        }
    });

    // nodecreated noderemoved connectioncreated connectionremoved connectionpath
    editor.on('process', async() => {
        console.log('process');
        await engine.abort();
        await engine.process(editor.toJSON());
        // await AreaPlugin.zoomAt(editor, editor.nodes);
    });

    return editor;
}
