<template>
    <div class="datamonitoringVueControl">
        <div class="datamonitoringVueControl__option d-flex flex-column mb-4">
            <label>종류</label>
    	    	<select v-model="data_vis">
    	    	    <option value = "" disabled></option>
    	    	    <option value = "data_visualization">데이터 시각화</option>
    	    	    <option value = "data_textualization">데이터 문자화</option>
    	    	</select>
        </div>
        
        <div class="datamonitoringVueControl__option d-flex flex-column mb-4">
        	<label class="mb-1">모니터링 위치</label>
    	    	<select v-model='vis_loc'>
    	    	    <option value = "" disabled hidden></option>
    	    	    <option value = "bottom">팝업</option>
    	    	    <option value = "bottom_right">하단 오른쪽</option>
    	    	    <option value = "bottom_left">하단 왼쪽</option>
    	    	</select>
        </div>
        
        <div class="datamonitoringVueControl__option d-flex flex-column mb-4">
    	    <label class="mb-1">모니터링 방식</label>
    			   <select v-model="monitor_kind">
    			       <option value = "" disabled hidden></option>
    			       <option value = "DataTable">데이터 차트</option>
    			       <option value = "Datagraph">데이터 그래프</option>
    			       <option value = "MnistImage">데이터 이미지</option>
    			   </select>
    	    	</select>
    	        <b-button @click="show_data_details">데이터 보기</b-button>
        </div>
        
    </div>
</template>
<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';


    export default {
        name: 'datamonitoringVueControl',
        data() {
            return {
            }
        },
        methods: {

            // 다이얼로그 표시 
            show_data_details() {



                switch (store.getters.get_monitorCon_name) {
                    case '데이터입력(MNIST)':
                        if (store.getters.getConnecting === true) {
                            store.dispatch('call_dialog_mnist_sum', { dialog_mnist_sum: true });
                            store.dispatch('call_dialog_mnist_image', { dialog_mnist_image: true });
                            break;
                        }

                    case '데이터입력(IRIS)':
                        if (store.getters.getConnecting === true) {
                            store.dispatch('call_dialog_iris_sum', { dialog_iris_sum: true });
                            store.dispatch('call_dialog_iris_table', { dialog_iris_table: true });
                        }
                        break;
                }
            }
        },
        // mounted() {
        //     this.changetest();
        // },
        computed: {
            data_input_name() {
                return store.getters.get_data_input_name
            },
            data_vis: {
                get() {
                    return store.getters.getVisualization
                },
                set(value) {
                    // console.log('value changed');
                    store.dispatch('call_visualization', value);
                }
            },
            vis_loc: {
                get() {
                    return store.getters.getBottomLocation
                },
                set(value) {
                    // console.log('location changed');
                    store.dispatch('call_location', { bottomlocation: value });
                }
            },
            monitor_kind: {
                get() {
                    return store.getters.getMonitorKinds
                },
                set(value) {
                    // console.log('monitor kind change');
                    store.dispatch('call_monitor_kinds', { monitorKinds: value });
                }
            }
        }
    }
</script>

<style scoped>
    .datamonitoringVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .datamonitoringVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0;
    }

    .datamonitoringVueControl select,
    input {
        border-radius: 5px !important;
        width: 180px !important;
        box-shadow: none;
        border-color: rgba(0, 0, 0, 0.22) !important;
    }

    .datamonitoringVueControl button {
        margin-top: 5px;
        height: 25px;
        width: 180px;
        font-size: 13px;
        color: #ffffff;
        /*background: rgba(119, 132, 251, 0.7);*/
        background: #5e72e4;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 12px;
        border: none;
    }
</style>
