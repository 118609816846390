const state = {
    userInfo: {
        userNickname: null,
        profileimage: null,
    }
}

const mutations = {
    // Module Kakao login
    changeNickname(state, user_nickname) {
        state.userInfo.userNickname = user_nickname
    },
    changeProfile(state, user_profile) {
        state.userInfo.profileimage = user_profile
    },
}

const actions = {
    // Module Kakao login
    call_nickname({ state, commit }, { user_nickname }) {
        commit('changeNickname', user_nickname);
    },
    call_profile({ state, commit }, { user_profile }) {
        commit("changeProfile", user_profile)
    },
}

const getters = {
    getNickname(state) {
        return state.userInfo.userNickname
    },
    getProfile(state) {
        return state.userInfo.profileimage
    },
    
    // 유저 정보 가져오기 (TEST)
    getuserInfo(state) {
        return state.userInfo
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
