const state = {
    inference_data: {
        get_input_image: null,
        // drawing_image: null
    },
    pretrain_model_name: null,
    local_upload_model: null,
}

const mutations = {
    change_get_input_image(state, get_input_image) {
        state.inference_data.get_input_image = get_input_image;
    },
    change_pretrain_model(state, pretrain_model_name) {
        state.pretrain_model_name = pretrain_model_name;
    },
    change_local_upload_model(state, local_upload_model) {
        state.local_upload_model = local_upload_model;
    }
}

const actions = {
    call_get_input_image({ state, commit }, { get_input_image }) {
        commit('change_get_input_image', get_input_image);
    },
    call_pretrain_model_name({ state, commit }, { pretrain_model_name }) {
        commit("change_pretrain_model", pretrain_model_name);
    },
    call_local_upload_model({ state, commit }, { local_upload_model }) {
        commit("change_local_upload_model", local_upload_model)
    }
}

const getters = {
    get_image(state) {
        return state.inference_data.get_input_image;
    },
    get_pretrain_model_name(state) {
        return state.pretrain_model_name;
    },
    get_local_upload_model(state) {
        return state.local_upload_model;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
