<template>
    <!-- 사전 훈련된 모델 불러오기 모달 부분-->
    <div class="pretrained_model_modal_outer">

        <!-- 샘플 이미지 선택 모달 헤더 -->
        <div class="pretrained_model_modal_header">
            <label>샘플 이미지 선택</label>
            <b-icon icon="x" id="mod-closebtn" class="close-btn" v-b-tooltip.hover.bottom="'Modal Close Button'" v-on:click="close_btn"></b-icon> 
        </div>
        <!-- 샘플 이미지 선택 모달 헤더 :: END -->
        
        <!-- 샘플 이미지 선택 모달 바디 -->
        <div class="pretrained_model_modal_body">
            <!-- trained model 불러오기-->
            <div class="model_upload_part">
                
                <!-- 로컬 파일 불러오기 -->
                <!--<div class="local_pretrained_file_upload" @click="local_file_upload">-->
                <!--    <b-icon icon="plus-square-fill"></b-icon>-->
                <!--    <label>로컬에서 불러오기</label>-->
                <!--    <p>저장된 모델을 하드디스크에서 불러옵니다.</p>-->
                <!--    <input ref="local_file_input" type="file" multiple hidden @change="upload_local_model_file"/>-->
                <!--</div>-->
                <!-- 로컬 파일 불러오기 :: END-->
                
                <!-- 샘플 이미지 카드 불러오기 -->
                <!-- MOBILE NET 이용 하기 위한 이미지 카드로 // 모바일넷은 동물, 물건 등 이미지 파일을 구별하여 결과를 나타내준다. -->
                
                 <!--v-for="(pretrain_model, index) in pretrain_models" :key="index"-->
                <b-card class="sample_image_card" v-for="(sample_image, index) in sample_images" :key="index" no-body>
                    <div class="sample_image_card_body">
                        <div class="sample_image_card_icon">
                            <div class="icon_boundary">
                                <b-icon icon="card-image"/>
                            </div>
                        </div>
                        
                        <!--<div class="sample_image_card_explain">-->
                        <!--    <label>{{ sample_image.image_name }}</label>-->
                            <!--<p>{{ sample_image.image_desc }}</p>-->
                        <!--</div>-->
                        
                        <!-- test -->
                        <div class="sample_image_card_explain2">
                            <div class="sample_image_card_desc">
                                <label>{{ sample_image.image_name }}</label>
                                <p>{{ sample_image.image_desc}}</p>
                            </div>
                            <img class="ml-auto mr-3 rounded" 
                                :src="sample_image.image_src" 
                                style="width: 50px; height: 50px;" />
                        </div>
                        
                        <div class="sample_image_card_select">
                            
                            <!--테스트 중 .... (미리보기) ... -->
                            
                            <!--<a class="text-dark" href="javascript:void(0)">미리보기</a>-->
                            <!--<b-modal ref="preview_image_modal">-->
                            <!--    preview_image_modal-->
                            <!--</b-modal>-->
                            
                            <!--테스트 중 .... (미리보기) ... -->
                            <b-button variant="primary" @click="uploadSampleImage(sample_image.image_src)">선택하기</b-button>
                        </div>
                    </div>
                </b-card>
                <!-- 샘플 이미지 카드 불러오기 :: END-->
            </div>
        </div>
        <!-- 샘플 이미지 선택 모달 바디 :: END -->
    </div>
</template>
<script>
    export default {
        name: "sample_image_upload_modal",
        data() {
            return {
                sort_model: '이미지 관련 모음만',

                // 샘플 이미지 // 카드 리스트

                sample_images: [{
                        image_name: '사자',
                        image_desc: 'MOBILE NET 추론을 위한 사자 이미지',
                        image_src: 'img/mobilenet/lion1.jpg'
                    },
                    {
                        image_name: '흰머리수리',
                        image_desc: 'MOBILE NET 추론을 위한 흰머리수리 이미지',
                        image_src: 'img/mobilenet/bald_eagle1.jpg'
                    },
                    {
                        image_name: '수탉',
                        image_desc: 'MOBILE NET 추론을 위한 수탉 이미지',
                        image_src: 'img/mobilenet/cock1.jpg'
                    },
                    {
                        image_name: '황금방울새',
                        image_desc: 'MOBILE NET 추론을 위한 황금방울새 이미지',
                        image_src: 'img/mobilenet/gold_finch1.jpg'
                    },
                    {
                        image_name: '거위',
                        image_desc: 'MOBILE NET 추론을 위한 거위 이미지',
                        image_src: 'img/mobilenet/goose.jpg'
                    },
                    {
                        image_name: '귀상어',
                        image_desc: 'MOBILE NET 추론을 위한 귀상어 이미지',
                        image_src: 'img/mobilenet/hammerhead1.jpg'
                    },
                    {
                        image_name: '킹크랩',
                        image_desc: 'MOBILE NET 추론을 위한 킹크랩 이미지',
                        image_src: 'img/mobilenet/king_crab1.jpg'
                    },
                    {
                        image_name: '샴 고양이',
                        image_desc: 'MOBILE NET 추론을 위한 샴 고양이 이미지',
                        image_src: 'img/mobilenet/siamese_cat1.jpg'
                    },
                    {
                        image_name: '타란툴라',
                        image_desc: 'MOBILE NET 추론을 위한 타란툴라 이미지',
                        image_src: 'img/mobilenet/tarantula1.jpg'
                    },
                    {
                        image_name: '우파루파',
                        image_desc: 'MOBILE NET 추론을 위한 우파루파 이미지',
                        image_src: 'img/mobilenet/uparupa1.jpg'
                    },
                ],
                

                local_upload_model: [],

            }
        },
        mounted() {


        },
        methods: {
            close_btn() {
                this.$emit('close_btn');
            },
            local_file_upload() {
                this.$refs.local_file_input.click();
            },
            upload_local_model_file(e) {
                let local_upload_file = e.target.files;
            },

            // 선택한 샘플이미지 path 보내기
            uploadSampleImage(sampleImage) {
                this.$emit('select_sample_image', sampleImage);

                this.$emit('close_btn');
            },
            
            showPreviewImage(){
                this.$refs.preview_image_modal.show();
            }
        },

    }
</script>
<style scoped>
    .pretrained_model_modal_outer {
        height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pretrained_model_modal_header {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .pretrained_model_modal_header label {
        color: #858585;
        font-size: 30px;
        font-weight: bold;
        margin-left: 10px;
    }

    .pretrained_model_modal_header .b-icon {
        margin-left: auto;
        margin-right: 10px;
    }

    .pretrained_model_modal_body {
        height: 80%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
    }

    .pretrained_model_modal_search {
        height: 10%;
        width: 90%;
        display: flex;
        align-items: center;
    }

    .pretrained_model_modal_search input {
        height: 50px;
        width: 500px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: 2.3%;
        font-size: 15px !important;
    }

    .pretrained_model_modal_search select {
        height: 40px;
        width: 200px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: auto;
        font-size: 15px !important;
        /*margin-right: 2.4%;*/
    }

    .model_upload_part {
        width: 90%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: auto;
    }

    .local_pretrained_file_upload {
        width: 250px;
        height: 180px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    .local_pretrained_file_upload .b-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        color: #C2C2C2;
    }

    .local_pretrained_file_upload label {
        font-weight: bold;
        font-size: 20px;
        color: #C2C2C2;
    }

    .local_pretrained_file_upload p {
        font-size: 12px;
        color: #C2C2C2;
        text-align: center;
    }


    .local_pretrained_file_upload:hover {
        background: #eeeeee;
        cursor: pointer;
    }


    .model_upload_part .card {
        border: none !important;
    }

    .model_upload_part .sample_image_card {
        width: 250px;
        height: 180px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    /*.model_upload_part .sample_image_card:hover {*/

    /*    cursor: pointer;*/

    /*}*/

    .sample_image_card_body {
        width: 250px;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sample_image_card_icon {
        width: 100%;
        height: 30%;
        display: flex;
    }

    .sample_image_card_icon .icon_boundary {
        width: 35px;
        height: 35px;
        border-radius: 10px;
        background: #E3ECFB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .sample_image_card_icon .icon_boundary .b-icon {
        width: 20px;
        height: 20px;
        color: #3E7AD3;
    }

    .sample_image_card_icon .label_boundary {
        width: 60px;
        height: 20px;
        border-radius: 10px;
        background: #E3ECFB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 10px;
    }

    .sample_image_card_icon .label_boundary label {
        font-size: 8px;
        margin-bottom: 0 !important;
        color: #3E7AD3;
    }

    .sample_image_card_icon .model_setting {
        margin-top: 10px;
        margin-right: 10px;
    }

    .sample_image_card_icon .model_setting .b-icon:hover {
        cursor: pointer;
    }

    .sample_image_card_explain {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*align-items: center;*/
    }


    .sample_image_card_explain label {
        font-weight: bold;
        margin-left: 15px;
    }

    .sample_image_card_explain p {
        font-size: 10px;
        color: #858585;
        word-break: keep-all;
        margin-left: 15px;
        margin-right: 10px;
    }
    
    /* 2021 01 21 수정 부분 */
     .sample_image_card_explain2 {
        width: 100%;
        height: 40%;
        display: flex;
        /*justify-content: center;*/
        /*align-items: center;*/
    }


    .sample_image_card_explain2 label {
        font-weight: bold;
        margin-left: 15px;
    }

    .sample_image_card_explain2 p {
        font-size: 10px;
        color: #858585;
        word-break: keep-all;
        margin-left: 15px;
        margin-right: 10px;
    }


    .sample_image_card_select {
        width: 250px;
        height: 30%;
        background: #DDE6F2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sample_image_card_select label {
        margin-left: 15px;
        font-size: 8px;
        margin-top: 10px;
        color: #858585;
    }

    .sample_image_card_select button {
        width: 100px;
        height: 30px;
        margin-left: auto;
        margin-right: 10px;
        font-size: 13px;
    }
</style>
