<template>
    <div class="Dense">
        <div class="Dense__option d-flex flex-column mb-4">
            <div class="Dense_unit">
                <label class="ml-0 mb-1">unit</label>
                <input class="m-0 shadow-none" type="number" v-model.number="Dense_unit"></input>
            </div>
        </div>
        
        <div class="Dense__option d-flex flex-column mb-4">
            <div class="Dense_kernelInitializer">
                <label class="ml-0 mb-1">kernelInitializer</label>
                <b-form-select class="m-0 shadow-none" v-model="kernelInitializer_selected" :options="kernelInitializer_options">
                </b-form-select>
            </div>
        </div>
        
        <div class="Dense__option d-flex flex-column mb-4">
           <div class="Dense_activation">
                <label class="ml-0 mb-1">activation</label>
                <b-form-select class="m-0 shadow-none" v-model="activation_selected" :options="activation_options">
                </b-form-select>
            </div>
        </div>
        
    </div>
</template>

<script>
    // vuex
    import store from '@/store';

    export default {
        name: 'Dense',
        data() {
            return {
                Dense_unit: 0,
                kernelInitializer_selected: '',
                activation_selected: '',
                kernelInitializer_options: [
                    { value: 'varianceScaling', text: 'varianceScaling' }
                ],
                activation_options: [
                    { value: 'softmax', text: 'softmax' },
                    { value: 'relu', text: 'relu' },
                    { value: 'sigmoid', text: 'sigmoid' }
                ],
                input_data: {
                    units: 0,
                    kernelInitializer: '',
                    activation: ''
                }
            }
        },
        updated() {
            this.input_data.units = this.Dense_unit;
            this.input_data.kernelInitializer = this.kernelInitializer_selected;
            this.input_data.activation = this.activation_selected;

            let m_num = store.getters.get_mod_node_id;

            if (m_num != '') {
                sessionStorage.setItem(m_num, JSON.stringify(this.input_data));
            }
            else {
                return;
            }
        },
        mounted() {

            //MNIST SET
            if (store.getters.get_model_node_name === "MNIST ConvNet") {

                // switch (store.getters.getdensenode) {

                //     case "dense13":
                //         this.Dense_unit = 10;
                //         this.kernelInitializer_selected = 'varianceScaling';
                //         this.activation_selected = 'softmax';
                //         return;
                // }

                // 해당하는 모달의 데이터를 세션에서 가져오기
                let saved_model_data = sessionStorage.getItem('modaldata' + store.getters.getNodeSelected);

                // JSON 파싱
                let saved_model_data_json = JSON.parse(saved_model_data);

                // JSON NODE
                let s_json = saved_model_data_json.nodes;

                // NODE KEY
                let s_keys = Object.keys(s_json);

                // NODE VALUE
                let s_values = Object.values(s_json);

                // 선택 DENSE NODE의 ID
                let dense_id = store.getters.getdensenode_id;

                // DENSE NODE 값과 내용들을 세션에 저장
                for (let i = 0; i < s_keys.length; i++) {
                    if (s_values[i].id === dense_id) {
                        sessionStorage.setItem(dense_id, JSON.stringify(s_values[i].data));

                        this.Dense_unit = s_values[i].data.units;
                        this.kernelInitializer_selected = s_values[i].data.kernelInitializer;
                        this.activation_selected = s_values[i].data.activation;

                    }
                }
            }
            else {
                // 해당하는 모달의 데이터를 세션에서 가져오기
                let saved_model_data = sessionStorage.getItem('modaldata' + store.getters.getNodeSelected);
                // JSON 파싱
                let saved_model_data_json = JSON.parse(saved_model_data);
                // JSON NODE
                let s_json = saved_model_data_json.nodes;
                // NODE KEY
                let s_keys = Object.keys(s_json);
                // NODE VALUE
                let s_values = Object.values(s_json);
                // 선택 DENSE NODE의 ID
                let dense_id = store.getters.getdensenode_id;

                // DENSE NODE 값과 내용들을 세션에 저장
                for (let i = 0; i < s_keys.length; i++) {
                    if (s_values[i].id === dense_id) {
                        sessionStorage.setItem(dense_id, JSON.stringify(s_values[i].data));

                        this.Dense_unit = s_values[i].data.units;
                        this.kernelInitializer_selected = s_values[i].data.kernelInitializer;
                        this.activation_selected = s_values[i].data.activation;

                    }
                }
            }

        }
    }
</script>

<style scoped>
    .Dense {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #555555;
    }

    .Dense_unit {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Dense_unit label {
        font-size: 8px;
        color: #555555;
        margin-left: 5px;
    }

    .Dense_unit input {
        width: 180px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid rgba(0, 0, 0, 0.22);
    }

    .Dense_kernelInitializer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Dense_kernelInitializer label {
        color: #555555;
        font-size: 8px;
        margin-left: 5px;
    }

    .Dense_kernelInitializer select {
        width: 180px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid rgba(0, 0, 0, 0.22);
    }


    .Dense_activation {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Dense_activation label {
        color: #555555;
        font-size: 8px;
        margin-left: 5px;
    }


    .Dense_activation select {
        width: 180px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid rgba(0, 0, 0, 0.22);
    }
</style>
