<template>
    <div class="table_com">
        <div class="bottom-left-area-header">
		    <!--<label>데이터 값</label>-->
	    </div>
	    <b-table sticky-header :items="iris_data" head-variant="light"></b-table>
	</div>
</template>
<script>
    import store from '@/store';

    import { IrisData, IRIS_CLASSES, IRIS_NUM_CLASSES, IRIS_DATA } from '@/IrisData/data.js';

    export default {
        name: "DataTable",
        data() {
            return {}
        },
        mounted() {
            run();
            async function run() {

                let data_input_name = store.getters.get_data_input_name;

                switch (data_input_name) {

                    case "데이터입력(MNIST)":
                        const data = new MnistData();
                        await data.load();
                        await getMnistData(data);
                        return;

                    case "데이터입력(IRIS)":

                        // Iris-setosa', 'Iris-versicolor', 'Iris-virginica'

                        let arr = [];
                        IRIS_DATA.sort(function() {
                            return Math.random() - Math.random();
                        })
                        for (let i = 0; i < 20; i++) {
                            for (let j = 0; j < IRIS_DATA[i].length; j++) {
                                let iris_kind = '';
                                if (IRIS_DATA[i][4] === 0) {
                                    iris_kind = 'Iris-setosa'
                                }
                                else if (IRIS_DATA[i][4] === 1) {
                                    iris_kind = 'Iris-versicolor'
                                }
                                else if (IRIS_DATA[i][4] === 2) {
                                    iris_kind = 'Iris-virginica'
                                }

                                let arr_content = {
                                    "꽃받침 길이": IRIS_DATA[i][0],
                                    "꽃받침 넓이": IRIS_DATA[i][1],
                                    "꽃입 길이": IRIS_DATA[i][2],
                                    "꽃잎 넓이": IRIS_DATA[i][3],
                                    "품종 번호": IRIS_DATA[i][4],
                                    "품종 이름": iris_kind,

                                }
                                arr[i] = arr_content;
                            }
                        }
                        store.dispatch('call_iris_data', { iris_data: arr })
                        return;
                }
            }
        },
        computed: {
            iris_data() {
                return store.getters.get_iris_data
            }
        }
    }
</script>
<style scoped>
    .table_com {
        width: 100%;
        height: 100%;
        margin-left: 10px;
    }

    .bottom-left-area-header {
        margin-top: 10px;
    }

    .b-table-sticky-header {
        width: 98%;
        height: 95%;
        overflow: none;
        font-size: 13px;
    }
</style>
