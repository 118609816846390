import * as tf from '@tensorflow/tfjs';
import * as tfvis from '@tensorflow/tfjs-vis';

export async function test() {

}





export async function getJSONData_from_URL(data_URL) {
  return tf.util.fetch(data_URL).then(function(response) {
    return response.json();
  });
}

//arr_selected_element : 뽑아낼 엘리먼트 이름 배열
//arr_new_element_name : 엘리먼트 이름 변경
export function cleanData_arrJson(arr_jsonData, arr_selected_element, arr_new_element_name = null) {
  var returnArray = arr_jsonData.filter(function(element, index) {
    var isNullData = false
    arr_selected_element.forEach(function(element_name) {
      if (element[element_name] == null) {
        isNullData = true;
      }
    });
    return !isNullData;
  }).map(function(element, index) {
    var returnObj = {}
    arr_selected_element.forEach(function(element_name, _index) {
      returnObj[arr_new_element_name ? arr_new_element_name[_index] : element_name] = element[element_name];
    });
    return returnObj;
  });

  return returnArray;
}

/**
 *values:{x, y} 튜플의 배열 (또는 중첩 배열)
 * 
 */
export function showGraph(values, series=['첫번째'], kind = "scatterplot", title = "그래프", xLabel = 'x값', yLabel = 'y값', height = 300) {
  const data = { values: values,series:series }//data = { values: values, series }
  const surface = { name: title, tab:'그래프' };//surface = { name: 'Scatterplot', tab: 'Charts' }
  const opt = { xLabel: xLabel, yLabel: yLabel, height: height };
  
  tfvis.render.scatterplot(surface, data, opt);
}

export function showModelSummary(model){
  const surface = { name: '모델 요약', tab: '모델 점검'};
  tfvis.show.modelSummary(surface,model);
}

//무작위 섞기
export function shuffleData(data) {
  return tf.tidy(() => {
    tf.util.shuffle(data);
    return data;
  });
}

//텐서로 변환
export function convertToTensor(data,shape) {
  return tf.tidy(() => {
    const tensor = tf.tensor(data, shape);
    return tensor;
  });
}

//정규화
export function normalize(tensor) {
  return tf.tidy(() => {
    const tensorMax = tensor.max();
    const tensorMin = tensor.min();
    const normalizedTensor = tensor.sub(tensorMin).div(tensorMax.sub(tensorMin)); //(inputTensor-inputMin)/(inputMax-inputMin)
    return {
      'normalized': normalizedTensor,
      'max': tensorMax,
      'min': tensorMin
    }
  });
}

// Sleeps for a given ms.
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export async function displayData(HTMLelement, data, dataKind = 'img', dataTitle = '') {
  console.log("data's Tensor:", data.shape);
  const numExamples = data.shape[0];
  for (let i = 0; i < numExamples; i++) {
    const eachTensor = tf.tidy(() => {
      var returnTF = tf.squeeze(data.slice([i, 0], [1, data.shape[1]]));
      return returnTF;
    });

    var child;
    var node;
    switch (dataKind) {
      case 'img':
        child = document.createElement('canvas');
        await tf.browser.toPixels(eachTensor, child);
        break;
      case 'text':
        node = document.createTextNode(eachTensor);
        child = document.createElement("label");
        child.appendChild(node);
        break;
      case 'argmax':
        node = document.createTextNode(tf.argMax(eachTensor).arraySync());
        child = document.createElement("label");
        child.appendChild(node);
        break;
      case 'table':
        node = document.createTextNode(tf.argMax(eachTensor));
        child = document.createElement("textField");
        child.appendChild(node);
        break;
      default:
        // code
    }

    child.style = 'margin: 4px;';
    HTMLelement.appendChild(child);
    eachTensor.dispose();
  }
}
