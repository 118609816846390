<template>
    <b-modal ref="modal" id="rawDataSelectModal" centered hide-header hide-footer size="xl">
        <div class="raw-data-modal-outer">
            <!-- header -->
            <div class="raw-data-modal-header">
                <label>원본데이터(RawData)</label>
                <b-icon icon="x" id="mod-closebtn" class="close-btn" v-b-tooltip.hover.bottom="'Modal Close Button'" v-on:click="close_btn"></b-icon>
            </div>
            <div class="raw-data-modal-body">
                <div class="rawdata_list">
                    <div class="create_rawdata_part" @click="showCreateDataModal">
                        <b-icon icon="plus-square-fill"></b-icon>
                        <label>데이터 생성</label>
                        <!--로컬에서 불러오기 설명-->
                        <p>새로운 데이터를 생성합니다.</p>
                    </div>
                    <!--<b-modal ref="local_file_name_check" hide-footer>-->
                        <!--로컬 데이터 이름 확인 알람창-->
                    <!--    <label class="local_check_label">데이터 생성 주의 하시오</label>-->
                    <!--</b-modal>-->
    
                    <!--새로운 데이터 생성 모달-->
                    <b-modal ref="createRawDataModal" id="createRawDataModal" centered hide-header hide-footer size="lg">
                        <CreateRawDataForm @hideForm="hideCreateDataModal"/>
                    </b-modal>
                    
                    <!--기본 제공되는 샘플 데이터 선택 부분-->
                    <!--rete node 컴포넌트에서 제공된 샘플 데이터 정보를 바탕으로 카드를 생성한다.-->
                    <div v-for="(source, index) in dataSources" :key="index">
                        <!--카드에서 샘플 선택이 완료 되면 selected 이벤트를 핸들링하여 rete node 컴포넌트로 샘플 데이터를 전송-->
                        <ModalCard :node-type="node_type" :data-source="source" @selected="onSelected" />
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
    // ModalCard 불러오기 
    import ModalCard from './ModalCardComp';

    // 데이터 생성 모달 불러오기
    import CreateRawDataForm from './CreateRawData/CreateRawDataForm';

    // dynamoDB
    import dynamoDB from '@/js/dynamoDB';

    // // 게스트 기본 제이슨 구조
    import guest_list from '../rawdata.json';


    // 유저정보를 가진 세션을 가져오기(임시? 추후 바뀔 것으로 예상)
    const userinfo = JSON.parse(sessionStorage.getItem("userInfo")) || null;

    export default {
        name: 'RawDataModal',
        data() {
            return {
                new_data: null,

                dataSources: [],
                guest_rawdata_list: null,
                node_type: 'RawData'
            }
        },
        // 부모 컴포넌트에서 제공받은 rete node 타입과 샘플 데이터 정보
        components: {
            // 카드 생성을 위한 카드 컴포넌트 호출
            ModalCard,
            CreateRawDataForm

        },
        mounted() {
            // console.log("MOUNTED: 유저 인포 체크");

            // 세션에 저장된 이메일을 기준으로 CHECK!
            // 이메일이 유저 정보에 존재하면 데이터 리스트 가져오기 (전역으로 설정된 유저 정보 = userinfo)
            if (userinfo.email) {
                // console.log("MOUNTED: 유저 인포 있음");
                
                // 세션에 저장되어 있는지 값 체크
                if (sessionStorage.getItem('rawdata_list')) {
                    
                    // 세션에 값이 저장되어 있으면 값 불러오기 
                    this.dataSources = JSON.parse(sessionStorage.getItem('rawdata_list'));
                }
                else {
                    // 세션에 값이 저장되어 있지 않으면 값 dynamDB에서 가져오기 
                    
                    // dynamoDB 에서 데이터 가져오기 
                    dynamoDB.getRawData()
                        .then(res => {
                            // 성공
                            // console.log("success", res);
                            this.dataSources = res.data.rawdata_list;
                        })
                        .catch(err => {
                            console.log("ERROR:", err);
                        });
                }
            }
            else {
                // console.log("MOUNTED: 유저 인포 없음");

                // 게스트 모드로 진입 시
                // console.log(guest_list.rawdata_list)

                // 게스트 원본데이터 리스트를 세션 스토리지에서 다시 가져오기
                if (sessionStorage.getItem('guest_rawdata_list')) {
                    // console.log("MOUNTED: 게스트 리스트 있음");
                    this.dataSources = JSON.parse(sessionStorage.getItem('guest_rawdata_list'));
                }
                else {
                    // console.log("MOUNTED: 게스트 리스트 없음");

                    sessionStorage.setItem('guest_rawdata_list', JSON.stringify(guest_list.rawdata_list));

                    // console.log('MOUNTED: 게스트 리스트 화면 반영');

                    this.guest_rawdata_list = JSON.parse(sessionStorage.getItem('guest_rawdata_list'));

                    this.dataSources = this.guest_rawdata_list;
                }

            }
        },
        methods: {
            show() {
                // 모달 호출 메서드
                this.$refs.modal.show();
            },

            hide() {
                // 모달 닫기 메서드
                this.$refs.modal.hide();
            },
            close_btn() {
                // 모달 닫기 버튼 클릭을 핸들링하여 모달을 닫는다.
                this.hide();
            },

            showCreateDataModal() {
                // 데이터 생성 모달 열기 
                this.$refs['createRawDataModal'].show();
            },
            hideCreateDataModal(new_data) {

                // 생성한 데이터, 데이터 리스트에 넣기
                this.dataSources.push(new_data);

                // 데이터 생성 모달 닫기            
                this.$refs['createRawDataModal'].hide();
            },
            // 전달 함수
            onSelected(selectedData) {

                // 데이터 확인용 콘솔
                // console.log(selectedData);

                // 이부분에서 데이터리스트를 저장 하는게 좋을 것 같다.
                if (userinfo.email) {
                    sessionStorage.setItem('rawdata_list', JSON.stringify(this.dataSources));
                }
                else {
                    sessionStorage.setItem('guest_rawdata_list', JSON.stringify(this.dataSources));
                }
                // ================================================================================

                // 카드에서 선택된 데이터를 부모 컴포넌트로 전달한다.
                this.$emit('selected', selectedData);
                this.hide();
            },
        },

    }
</script>
<style scoped>
    .card-container {
        width: 100%;

    }

    .raw-data-modal-outer {
        height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;

    }

    .raw-data-modal-header {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .raw-data-modal-header label {
        color: #858585;
        font-size: 30px;
        font-weight: bold;
        margin-left: 10px;
    }

    .raw-data-modal-header .b-icon {
        margin-left: auto;
        margin-right: 10px;
    }

    .raw-data-modal-body {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
    }

    .raw-data-modal-search {
        height: 10%;
        width: 80%;
        display: flex;
        align-items: center;
    }

    .raw-data-modal-search input {
        height: 50px;
        width: 500px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: 2.3%;
        font-size: 15px !important;
    }

    .rawdata_list {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(4, 300px);
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: auto;
    }

    .create_rawdata_part {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    .create_rawdata_part .b-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        color: #C2C2C2;
    }

    .create_rawdata_part label {
        font-weight: bold;
        font-size: 20px;
        color: #C2C2C2;
    }

    .create_rawdata_part p {
        font-size: 12px;
        color: #C2C2C2;
        text-align: center;
    }


    .create_rawdata_part:hover {
        background: #eeeeee;
        cursor: pointer;
    }


    .rawdata_list .card {
        border: none !important;
    }
</style>
