<template>
    <div class="contents_outer">
        <!--사이드바-->
        <!--<Sidebar/>-->
        <!--컨텐츠 화면 전환 부분-->
    	<router-view></router-view>
    </div>
</template>
<script>
    // import Sidebar from '@/components/MainPage/Contents/Sidebar/Sidebar.vue';
    export default {
        name: 'contents',
        data() {
            return {

            }
        },
        components: {
            // Sidebar
        }
    }
</script>
<style scoped>
    .contents_outer {
        width: 100%;
        height: 100%;
        display: flex;
    }
</style>
