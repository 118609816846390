<template>
    <div class="trainer_node">
        <div class="trainer_node_header">
        </div>
        <div class="trainer_node_content">
            <!--<b-button v-b-toggle.trainer-advance variant="primary">Advanced</b-button>-->
            <div class="trainer_advance" @click="trainer_advance_click">
                <label>고급</label>
                <b-icon :icon="trainer_advance_icon" />
            </div>
            <b-collapse id="trainer-advance" v-model="trainer_advance_visible">
                <div class="optimizer_div">
                    <label>OPTIMIZER</label>
                    <select v-model="trainer_data.optimizer" @change="onChange">
                	<option v-for="option in optimizer_options" v-bind:value="option.value">
                		{{ option.text }}
                	</option>
                </select>
                </div>
                <div class="loss_div">
                    <label>LOSS</label>
                    <select v-model="trainer_data.loss" id="loss_select" @change="onChange">
            		<option v-for="option in loss_options" v-bind:value="option.value">
            			{{ option.text }}
            		</option>
            	</select>
                </div>
            </b-collapse>
        </div>
        <div class="trainer_node_footer">
        </div>
    </div>
</template>
<script>
    import Rete from "rete";
    import { numSocket } from '../sockets';

    export default {
        name: 'trainer',
        props: ['emitter', 'getNode'],
        data() {
            return {
                trainer_data: {
                    optimizer: 'adam',
                    loss: 'categoricalCrossentropy',
                },
                optimizer_options: [
                    { text: 'ADAM(아담)', value: 'adam' },
                    { text: 'SGD', value: 'sgd' },
                    { text: 'MOMENTUM', value: 'momentum' },
                    { text: 'AdaGrad', value: 'adagrad' },
                    { text: 'AdaDelta', value: 'adadelta' },
                    { text: 'AdaMax', value: 'adamax' },
                    { text: 'RMSProp', value: 'rmsprop' },
                ],
                loss_options: [
                    { text: '크로스엔트로피에러', value: 'categoricalCrossentropy' },
                    { text: '평균제곱오차', value: 'meanSquaredError' },
                    { text: '절대차', value: 'absoluteDifference' },
                    { text: '가중손실', value: 'computeWeightedLoss' },
                    { text: '힌지손실', value: 'hingeLoss' },
                    { text: '허버손실', value: 'huberLoss' },
                    { text: '로그손실', value: 'logLoss' },
                    { text: '시그모이드크로스엔트로피', value: 'sigmoidCrossEntropy' },
                    { text: '소프트맥스크로그엔트로피', value: 'softmaxCrossEntropy ' },
                ],

                // 컬랩스 부분 상태
                trainer_advance_visible: false,
                trainer_advance_icon: 'plus',
            }
        },
        props: ['readonly', 'emitter', 'getData', 'putData', 'getNode'],
        mounted() {
            const sessionData = this.getData("trainer_data");
            if (sessionData) {
                this.trainer_data = sessionData;
            }
            // 초기 값 입력
            this.putData('trainer_data', this.trainer_data);
        },
        methods: {
            //셀렉트 입력값 핸들링 메서드
            onChange() {
                this.update();
            },
            //업데이트
            update() {
                this.putData('trainer_data', this.trainer_data);
                const whole_data = this.emitter.toJSON();
                sessionStorage.setItem('current_structure', JSON.stringify(whole_data));
            },

            // 컬랩스 부분 메서드 // 아이콘 변경 // 컬랩스 visible
            trainer_advance_click() {
                this.trainer_advance_visible = !this.trainer_advance_visible;
                if (this.trainer_advance_visible === true) {
                    this.trainer_advance_icon = 'dash';
                }
                else {
                    this.trainer_advance_icon = 'plus';
                }
            },

            addInput() {
                // input 이름 지정
                const input_name = "trainer" + this.getNode().id + "_input" + (this.getNode().inputs.size + 1)

                // input 객체 생성
                const input = new Rete.Input(input_name, "입력", numSocket, true)
                // 소켓 추가
                this.getNode().addInput(input);
                this.putData("added_inputs", this.getNode().inputs.size);
                this.getNode().update();
            },

            addOutput() {
                // output 이름 지정
                const output_name = "trainer" + this.getNode().id + "_output" + (this.getNode().outputs.size + 1)
                // output 객체 생성
                const output = new Rete.Output(output_name, "출력", numSocket, true);
                // 소켓 추가
                this.getNode().addOutput(output);
                this.putData("added_outputs", this.getNode().outputs.size);
                this.getNode().update();
            }
        }
    }
</script>
<style scoped>
    /*트레이너 노드 전체 지정*/

    .trainer_node {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /*트레이너 노드 전체 라벨 지정*/

    .trainer_node label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    /*트레이너 노드 전체 셀렉트, 인풋 지정*/

    .trainer_node select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    /*트레이너 노드 전체 버튼 지정*/

    .trainer_node button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 12px;
        border: none;
    }

    /*트레이너 노드 고급 버튼 지정*/

    .trainer_advance {
        width: 220px;
        height: 22px;
        padding-top: 6px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        /*border-top: 1px solid #7784FB;*/
    }

    /*고급 기능 버튼 포커스 없애기 */

    .trainer_advance:focus {
        outline: none;
    }

    /*트레이너 노드 고급 버튼 라벨 지정*/

    .trainer_advance label {
        font-size: 13px !important;
        margin-left: 10px;
        color: #7784FB;
    }

    /*트레이너 노드 고급 버튼 라벨 지정*/

    .trainer_advance .b-icon {
        width: 28px;
        height: 28px;
        margin-left: auto;
        margin-right: 10px;
        color: #7784FB;
    }

    /*고급 기능 컬랩스 지정*/

    #trainer-advance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top: 1px solid #eee;
        margin-top: 5px;
    }

    /*고급 기능 컬랩스 라벨 지정*/

    .optimizer_div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .optimizer_div label {
        width: 60px;
        height: 25px;
        margin: 5px;
    }

    .optimizer_div select {
        margin: 5px;
    }

    .loss_div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loss_div label {
        width: 60px;
        height: 25px;
        margin: 5px;
    }

    .loss_div select {
        margin: 5px;
    }
</style>
