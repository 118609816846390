<template>
	<div id="app">
		<!--main page router view -->
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
	}
</script>
