<template>
    <div>
        <b-card class="workspace_card" no-body>
            <div class="authorize">
                <div class="authorize_account">
                    <!--프로젝트 권한자-->
                    <label>게스트</label>
                </div>
                <div class="authorize_setting">
                    <b-dropdown class="setting_dropdown" size="sm" variant="link" right no-caret>
                        <div slot="button-content">
                            <b-icon icon="three-dots-vertical" />
                        </div>
                        <!--프로젝트 삭제-->
                        <!--삭제-->
                        <b-dropdown-item>{{ delete_project }}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <!--authorize_setting end -->
            </div>
            <div class="workspace_name">
                <!--워크스페이스 프로젝트 이름-->
                <label>임시 프로젝트</label>
            </div>
            <div class="workspace_rating">
                <b-form-rating color="#FF6565"></b-form-rating>
            </div>
            <div class="workspace_explain">
                <!--워크스페이스 프로젝트 설명-->
                <p>임시로 적용된 프로젝트 입니다.</p>
            </div>
            <div class="workspace_card_select">
                <!--현재 프로젝트 표시 -->
                <!--프로젝트 열기 버튼 링크랑 조건식 연결해야 함-->
                <!--현재 프로젝트-->
                <b-button @click="sample_project_button_click" variant="warning">{{ current_project }}</b-button>
                <!--나머지 프로젝트 표시 -->
                <!--<b-avatar src="https://placekitten.com/300/300"></b-avatar>-->
                <!--<img :src/ />-->
                <img :src="profile_image"/>
            </div>
            <!-- workspace_card_select end -->
        </b-card>
    </div>
</template>
<script>
    // 언어 설정 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: 'Guest_Workspace',
        data() {
            return {
                profile_image: '',

                delete_project: '',
                current_project: '',

                localization: '',
            }
        },
        methods: {
            sample_project_button_click() {
                this.$router.push('trainmodel');
            }
        },
        mounted() {

            let userinfo = sessionStorage.getItem('userInfo');

            let userinfo_parse = JSON.parse(userinfo);

            this.profile_image = userinfo_parse.profile_image;

            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);
            switch (this.localization) {
                case 'ko':
                    this.delete_project = korean.workspaces.delete_project;
                    this.current_project = korean.workspaces.current_project;
                    break;
                case 'en':
                    this.delete_project = english.workspaces.delete_project;
                    this.current_project = english.workspaces.current_project;
                    break;
            }
        }
    }
</script>
<style scoped>
    .workspace_card {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }


    .authorize {
        width: 100%;
        display: flex;
    }

    .authorize_account {
        width: 60px;
        height: 20px;
        border-radius: 10px;
        background: #E3ECFB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 5px;
    }

    .authorize_account label {
        font-size: 8px;
        margin-bottom: 0 !important;
        color: #3E7AD3;
    }

    .authorize_setting {
        margin-top: 7px;
        /*margin-right: 10px;*/
    }

    .authorize_setting .b-icon {
        color: #555555;
    }

    .authorize_setting .lang-dropdown.dropdown-menu {
        min-width: 4rem !important;
    }



    .workspace_name {
        width: 100%;
    }

    .workspace_name label {
        font-weight: bold;
        margin-left: 30px;
    }

    .workspace_rating {
        width: 100%;
    }

    .workspace_rating .b-rating {
        border: none !important;
        width: 50%;
        margin-left: 15px;
    }

    .workspace_explain {
        width: 100%;
        height: 40%;
    }

    .workspace_explain p {
        font-size: 13px;
        color: #858585;
        work-break: keep-all;
        margin-left: 30px;
        margin-right: 30px;
    }

    .workspace_card_select {
        width: 100%;
        height: 60px;
        /*background: #DDE6F2;*/
        display: flex;
        align-items: center;
    }

    .workspace_card_select button {
        width: 130px;
        height: 35px;
        font-size: 13px !important;
        margin-left: 30px;
        margin-bottom: 40px;
    }

    .workspace_card_select img {
        width: 35px !important;
        height: 35px !important;
        border-radius: 100px;
        margin-bottom: 40px;
        margin-left: 50px;
    }

    @media screen and (max-width: 1024px) {
        .workspace_list .workspace_card {
            width: 220px;
            height: 200px;
        }

        .authorize {
            height: 25px;
        }

        .authorize_account {
            width: 35px;
            height: 23px;
        }

        .authorize_account label {
            font-size: 3px;
        }

        .workspace_name label {
            margin-left: 30px;
            font-size: 13px;
        }

        .workspace_explain p {
            font-size: 9px;
        }

        .workspace_card_select button {
            width: 100px;
            height: 25px;
            font-size: 10px !important;
        }

        .workspace_card_select img {
            width: 35px !important;
            height: 35px !important;
            border-radius: 100px;
            margin-bottom: 40px;
            margin-left: 30px;
        }
    }

    /*====================================*/
</style>
