import * as tf from '@tensorflow/tfjs';
import * as tfvis from '@tensorflow/tfjs-vis';

import { classNames } from '@/ncai-core/tfjs_data.js'
import sampleModelTopology from '@/ncai-core/sampleTopologys/modeltopology_sample.json'
import sampleModelTopology2 from '@/ncai-core/sampleTopologys/modeltopology_sample2.json'
import irisModelTopology from '@/ncai-core/sampleTopologys/modeltopology_iris.json'

import * as ncai_data from '@/ncai-core/ncai_data.js'
import * as ncai_layer from '@/ncai-core/ncai_layer.js'

import * as ncai_tutor1 from '@/ncai-core/tutorials/ncai_tutor1.js' //선형회귀..
import * as ncai_tutor2 from '@/ncai-core/tutorials/ncai_tutor2.js' //mnist

export function test() {
    console.log("ncai ==> test()");
    setModel(irisModelTopology);
    //ncai_tutor1.predictFromData2D();
    //ncai_tutor2.run();
    //console.log("==========tfjs_model.js=============", sampleModelTopology);
    // console.log("MEMORY:", tf.memory());
    //var model = setModel(irisModelTopology);
    //const surface = { name: '서머리', tab: 'Model Inspection'};
    // tfvis.show.layer(surface, model.getLayer(undefined, 1));
    //tfvis.show.modelSummary(surface, model);
    // setModel(sampleModelTopology2);
    // setModel(irisModelTopology);
    // console.log("MEMORY:", tf.memory());
    /*
    const input1 = tf.input({ shape: [2, 2] });
    const input2 = tf.input({ shape: [2, 3] });
    var x, x2;
    x = tf.layers.dense({ units: 32 }).apply(input1);
    x2 = tf.layers.dense({ units: 32 }).apply(x);
    x2 = tf.layers.dense({ units: 32 }).apply(x2);
    x2 = tf.layers.dense({ units: 32 }).apply(x2);
    x2 = tf.layers.dense({ units: 32 }).apply(x2);
    const model = tf.model({ inputs: input1, outputs: x2 });
    console.log(model.summary());
    
    const input1 = tf.input({ shape: [10] });
    const input2 = tf.input({ shape: [20] });
    const dense1 = tf.layers.dense({ units: 4 }).apply(input1);
    const dense2 = tf.layers.dense({ units: 8 }).apply(input2);
    const concat = tf.layers.concatenate().apply([dense1, dense2]);
    const output =
        tf.layers.dense({ units: 3, activation: 'softmax' }).apply(concat);
    const output2 =
        tf.layers.dense({ units: 4, activation: 'softmax' }).apply(concat);

    const model = tf.model({ inputs: [input1, input2], outputs: [output, output2] });
    model.summary();
    console.log(model.summary());
    const saveResult = model.save('localstorage://tfjs-sample-model-1');
    */

}

export function sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
}


export async function setModel(modelTopology) {
    console.log("modelTopology:", modelTopology);

    var tf_layers = {};

    modelTopology.config.layers.forEach(function(layer_info) {
        //console.log(layer_info.config.name + " : ", layer_info.config);
        const tf_layer = ncai_layer.makeLayerFromLayerInfo(layer_info);
        tf_layers[layer_info.config.name] = tf_layer; //originName이 있고 name이 저절로 바뀌기도 한다.. 고려할 것!!
    });
    console.log("================tf_layers:", tf_layers);


    const { input_layer_names, output_layer_names } = getInOutputLayerNamesFromInfo(modelTopology);

    var backGraph = [output_layer_names];
    makeBackGraph(modelTopology, output_layer_names, backGraph);
    const outputNodes = backGraph.reverse();
    clearOutputNodes(outputNodes);
    
    applySymbolicTensorLayer(outputNodes, modelTopology, tf_layers);

    //console.log("tf_layers:", tf_layers); //SymbolicTensor로 변환된 레이어들..
    //console.log("===============SET MODEL!!!===============");
    //모델 새로 생성...
    const model = tf.model({ inputs: getLayers(tf_layers,input_layer_names), outputs: getLayers(tf_layers,output_layer_names) });
    console.log(model.summary());

    //return model;
}

export function clearOutputNodes(outputNodes){
    for (var i = 1; i < outputNodes.length; i++) {
        outputNodes[i-1].forEach(function(outputNode) {
            const idx = outputNodes[i].indexOf(outputNode)
            if (idx > -1) outputNodes[i].splice(idx, 1)
        });
    }
}

/**
 * 입력레이어부터 차례대로 적용하기..
 * SymbolicTensor만 apply할 수 있으므로 Input부터 차례대로 apply할 수 있게 해야 함..
 * 
 */
export function applySymbolicTensorLayer(outputNodes, modelTopology, tf_layers) {
    console.log("outputNodes:",outputNodes);
    for (var i = 1; i < outputNodes.length; i++) {
        outputNodes[i].forEach(function(outputNode) {
            const layer_info = getLayerInfoByName(modelTopology, outputNode)
            var input_layers = [];
            if (layer_info.inbound_nodes && layer_info.inbound_nodes.length > 0) {
                layer_info.inbound_nodes[0].forEach(function(inbound_node) {
                    input_layers.push(tf_layers[inbound_node[0]]);
                });
                console.log("outputNode:",outputNode);
                tf_layers[outputNode] = tf_layers[outputNode].apply(input_layers);
            }
        })
    }
}



/**
 * 아웃풋부터 시작하는 백 그래프를 만든다.
 */
export function makeBackGraph(modelTopology, output_layer_names, backGraph) {
    const inputNodes = searchInputNode(modelTopology, output_layer_names);
    if (inputNodes.length != 0) {
        backGraph.push(Array.from(new Set(inputNodes)));
        makeBackGraph(modelTopology, inputNodes, backGraph);
    }
}

export function searchInputNode(modelTopology, output_layer_names) {
    var cur_nodes = [];
    output_layer_names.forEach(function(output_layer_name) {
        const layer_info = getLayerInfoByName(modelTopology, output_layer_name);
        if (layer_info.inbound_nodes && layer_info.inbound_nodes.length > 0) {
            layer_info.inbound_nodes[0].forEach(function(inbound_node) {
                cur_nodes.push(inbound_node[0]);
            });
        }
    });
    return cur_nodes;
}

export function getLayerInfoByName(modelTopology, layer_name) {
    for (var n in modelTopology.config.layers) {
        const layer_info = modelTopology.config.layers[n];
        if (layer_info.name == layer_name) return layer_info;
    }
    return null;
}



/**
 * input_layer_name이 inbound_nodes안에 있는 모델을 찾는다.
 * 순차적으로 검색해서 apply하는 방식
 * 인풋이 두군데 이상일 경우데도 되는 지는 테스트 요망 ==> 안됨 ㅠㅠ...
 */
export function applyAndGetNextLayer(input_layer_name, tf_layers, modelTopology) {
    modelTopology.config.layers.forEach(function(layer_info) {
        //console.log("layer_info",layer_info);
        if (layer_info.inbound_nodes && layer_info.inbound_nodes.length > 0) {
            //console.log("layer_info.inbound_nodes[0]:",layer_info.inbound_nodes[0]);
            layer_info.inbound_nodes[0].forEach(function(inbound_node) {
                const inbound_node_name = inbound_node[0];
                console.log(input_layer_name + "==" + inbound_node_name, layer_info.name);
                if (input_layer_name == inbound_node_name) {
                    console.log("!!!!!!!!!!!!!")
                    tf_layers[layer_info.name].apply(tf_layers[input_layer_name]);
                    //applyAndGetNextLayer(layer_info.name, tf_layers, modelTopology);
                    return layer_info.name;
                }
            });
        }
    });

    return null;
}

/**
 * 아래 형식임.
 * "input_layers": [["input1", 0, 0],["input2", 0, 0]]
 * 시퀀셜 모델은 제외함.. 
 */
export function getInOutputLayerNamesFromInfo(modelTopology) {
    const input_layers_info = modelTopology.config.input_layers;
    const input_layer_names = input_layers_info.map(layer_info => layer_info[0]);
    const output_layers_info = modelTopology.config.output_layers;
    const output_layer_names = output_layers_info.map(layer_info => layer_info[0]);
    return { input_layer_names, output_layer_names };
}

export function getLayers(tf_layers,layer_names) {
    var layers = [];
    layer_names.forEach(layer_name => layers.push(tf_layers[layer_name]));
    return layers;
}

export function getTfLayers(modelTopology){
    var tf_layers = [];
    modelTopology.config.layers.forEach(function(layer_info) {
        //console.log(layer_info.config.name + " : ", layer_info.config);
        const tf_layer = ncai_layer.makeLayerFromLayerInfo(layer_info);
        tf_layers[layer_info.config.name] = tf_layer; //originName이 있고 name이 저절로 바뀌기도 한다.. 고려할 것!!
    });
    console.log("================tf_layers:", tf_layers);
    return tf_layers;
}


export function getNodeStructure(modelTopology,output_layer_names){
    var backGraph = [output_layer_names];
    makeBackGraph(modelTopology, output_layer_names, backGraph);
    var forwardNodeStructure = backGraph.reverse();
    clearOutputNodes(forwardNodeStructure);
    return forwardNodeStructure;
}

//topology의 구성이 Class가 있는 구조로 되어 있어서 문자열로 바꾸어 주는 부분
//"kernel_regularizer": null,
//"bias_regularizer": null,
//"activity_regularizer": null,
//"kernel_constraint": null,
//"bias_constraint": null,
export function setBiasInitializer() {

}

export function setKernelInitializer() {

}
