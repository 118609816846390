<template>
    <!-- MNIST 이미지 -->
    <div class="Mnist_Image_outer">
        <!--MNIST 이미지를 랜덤으로 가져와 화면에 보여주기-->
        <!--MNIST 이미지-->
        <b-container class="Mnist_Image_container">
            <div id="Mnist_Image"/>
        </b-container>    
    </div>
</template>

<script>
    import * as tf from '@tensorflow/tfjs';
    import { MnistData } from '@/MnistData/data.js';

    import store from '@/store';

    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: 'Mnist_Image',
        data() {
            return {
                MNIST_IMAGE: '',

                // 언어 설정
                localization: '',
            }
        },
        methods: {
            // init() {
            //     tf;
            //     MnistData;
            // },
        },

        // VUE COMPONENT CREATED
        created() {
            run();

            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);
            // 언어 설정 테스트
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.MNIST_IMAGE = korean.mnist_image.MNIST_IMAGE;
                    return;

                case 'en':
                    this.MNIST_IMAGE = english.mnist_image.MNIST_IMAGE;
                    return;
            }

            async function run() {

                // MNIST DATA 가져오기
                const data = new MnistData();
                await data.load();

                // 예시 이미지 파일 가져와서 화면에 띄우기
                await showExamples(data);
            }

            // 예시 이미지 파일 가져오기 
            async function showExamples(data) {

                try {

                    const surface = document.getElementById('Mnist_Image');

                    const examples = data.nextTestBatch(100);
                    const numExamples = examples.xs.shape[0];

                    for (let i = 0; i < numExamples; i++) {


                        const imageTensor = tf.tidy(() => {
                            // Reshape the image to 28 X 28 pixels

                            return examples.xs
                                .slice([i, 0], [1, examples.xs.shape[1]])
                                .reshape([28, 28, 1]);
                        });

                        const canvas = document.createElement('canvas');
                        canvas.width = 28;
                        canvas.height = 28;
                        canvas.style = 'margin: 4px; border-radius: 5px;';
                        await tf.browser.toPixels(imageTensor, canvas);

                        surface.appendChild(canvas);
                        imageTensor.dispose();

                    }

                }
                catch (error) {
                    // Mnist div가 존재하지 않아 생기는 에러 
                    // 추후 체크 해서 고쳐야 할것
                    // console.log(error);
                }


            }
        },
        // VUE COMPONENT MOUNTED
        // mounted() {
        //     /* global navigator */
        //     let locale = navigator.language || navigator.userLanguage;

        //     // this.localization = locale.substring(0, 2);

        //     this.localization = 'en';

        //     switch(this.localization) {
        //         case 'ko':
        //             this.MNIST_IMAGE = korean.mnist_image.MNIST_IMAGE;
        //             return;

        //         case 'en':
        //             this.MNIST_IMAGE = english.mnist_image.MNIST_IMAGE;
        //             return;
        //     }
        // }
    }
</script>
<style scoped>
    .Mnist_Image_outer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        /*justify-content: center;*/
    }

    .Mnist_Image_outer label {
        font-size: 20px;
        font-weight: bold;
    }

    #Mnist_Image {
        border: 1px solid #eeeeee;
        /*border-radius: 5px;*/
        /*width: 730px;*/
        min-width: 100px;
        max-width: 100%;
        height: 205px;
        max-height: 100%;
        min-height: 50px;
        overflow-y: auto;
    }
</style>
