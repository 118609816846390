import { Control } from 'rete';
import MaxPoolingVueControl from './vue-controls/MaxPoolingVueControl.vue';

export default class MaxPoolingControl extends Control {
    constructor(emitter, key, readonly = false) {
        super(key);
        this.component = MaxPoolingVueControl;
    };

    setValue(value) {

    }

    onChange() {

    }
}
