<template>
    <div class="DataInputVueControl">
      	<label>종류</label>
	    	<select v-model="kinds_selected">
		    	<option v-for="option in kinds_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>크기</label>
	    	<select v-model="size_selected">
		    	<option v-for="option in size_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>색상</label>
	    	<select v-model="color_selected">
		    	<option v-for="option in color_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>개수</label>
	    	<select v-model="amount_selected">
		    	<option v-for="option in amount_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
	    <label>현재 차원</label>
	    	<select v-model="current_dim_selected">
		    	<option v-for="option in current_dim_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
	    <b-button type="button" @click="onClickImageUpload">이미지 불러오기</b-button>
        <div id="imgCom">
            <input ref="imageInput" type="file" id="images" multiple="multiple" hidden @change="onChangeImages" >
            <div class="pre_image">
                <img class="imagetest" v-for="(image, idx) in this.images" :src="image" v-if="idx < 5" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';

    export default {
        name: 'DataInputVueControl',
        data() {
            return {
                kinds_selected: 'image',
                size_selected: '224X224',
                color_selected: 'rgb',
                amount_selected: '50000',
                current_dim_selected: '(224,224,3)',
                kinds_options: [
                    { text: '이미지', value: 'image' },
                    { text: '텍스트', value: 'text' },
                    { text: '음성', value: 'voice' }
                ],
                size_options: [
                    { text: '224X224', value: '224X224' },
                    { text: '150X150', value: '150X150' },
                    { text: '32X32', value: '32X32' },
                    { text: '16X16', value: '16X16' }
                ],
                color_options: [
                    { text: '컬러(RGB)', value: 'rgb' },
                    { text: '흑백(gray)', value: 'gray' },
                ],
                amount_options: [
                    { text: '60000', value: '60000' },
                    { text: '50000', value: '50000' },
                    { text: '40000', value: '40000' },
                    { text: '30000', value: '30000' },
                    { text: '20000', value: '20000' },
                    { text: '10000', value: '10000' },

                ],
                current_dim_options: [
                    { text: '(224,224,3)', value: '(224,224,3)' }
                ],
                images: [],
                userimages: [],
            }
        },
        methods: {
            onClickImageUpload() {
                this.$refs.imageInput.click();
            },
            onClickImageUpload1() {
                this.$refs.imageInput1.click();
            },
            onChangeImages(e) {

                // store.dispatch('call_leftitems', { leftitems: 'ImageUpload' })
                // console.log(store.getters.getItems);

                const files = e.target.files;
                
                // this.images = [...files].map(URL.createObjectURL);
                store.dispatch('callMutation', { newImages: this.images });
                // console.log(store.getters.getImages);
            },
        },
    }
</script>

<style scoped>
    .DataInputVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .DataInputVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .DataInputVueControl select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    .DataInputVueControl button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        text-align: center;
        line-height: 12px;
        border: none;
    }

    .DataInputVueControl input[type="file"] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .pre_image {
        display: flex;
        align-items: center;
        margin-top: 5px;
        padding-left: 3px;
        border: 1px solid #555555;
        border-radius: 5px;
        height: 35px;
        width: 130px;
        background: #ffffff;
    }

    .picker_test {
        margin-top: 5px;
    }

    .imagetest {
        width: 22px;
        height: 22px;
        margin: 1px;
        border-radius: 5px;
    }
</style>
