import { Control } from 'rete';
import FlattenVueControl from './vue-controls/FlattenVueControl.vue';

export default class FlattenControl extends Control {
    constructor(emitter, key, readonly = false) {
        super(key);
        this.component = FlattenVueControl;
    };

    setValue(value) {
        
    }

    onChange() {
        
    }
}
