<template>
    <div class="ModelSave">
        <div class="ModelSave__option d-flex flex-column mb-4">
        	<label>모델 이름</label>
            <input type="text" v-model="model_name"/>
        </div>
    </div>
</template>
<script>
    import store from '@/store';

    export default {
        name: 'ModelSave',
        data() {
            return {
                model_save_name: null,
            }
        },
        updated() {
            // 값에 변동이 있으면 store > model_name 
            store.dispatch('call_model_name', { model_name: this.model_save_name });
        },
        computed: {
            model_name: {
                get() {
                    // 모델 노드의 이름 가져오기
                    let model_node_name = store.getters.get_model_node_name;
                    // console.log(model_node_name)
                    // 모델 노드 이름 필터링
                    let model_name_filter = model_node_name.replace(/(\s*)/g, "");
                    this.model_save_name = model_name_filter;
                    return model_name_filter;
                },
                set(m_name) {
                    // v-model로 input check 하여 model_name으로 넣기
                    let model_save = m_name;
                    store.dispatch('call_model_name', { model_name: model_save });
                }
            }
        }
    }
</script>

<style scoped>
    .ModelSave {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .ModelSave label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0;
    }

    .ModelSave select,
    input {
        border-radius: 5px !important;
        width: 180px !important;
        box-shadow: none;
        border-color: rgba(0, 0, 0, 0.22) !important;
    }

    .ModelSave button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        text-align: center;
        line-height: 12px;
        border: none;
    }
</style>
