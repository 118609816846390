<template>
<b-card class="origin-data-card" no-body>
    <div class="header">
        <div class="header-name">
            <label>테스트 1</label>
        </div>
        <!-- 설정 버튼 차후 사용을 위해 주석화
        <div class="header-setting">
            <b-dropdown class="setting_dropdown" size="sm" variant="link" right no-caret>
                <div slot="button-content">
                    <b-icon icon="three-dots-vertical" />
                </div>
              <!--  <b-dropdown-item>상세 보기</b-dropdown-item>
            </b-dropdown>
        </div>-->
        <!-- authorize_setting end -->
    </div>
    <div class="data-card-explain">
        <!--샘플 데이터 이름-->
        <label>{{selectMode.title}}</label>
        <!--샘플 데이터 설명-->
        <p>{{selectMode.description}}</p>
    </div>
    <div class="data-card-select">
        <label>
            <!--샘플 데이터의 속성(데이터 수, 사이즈, 타입 등) 출력-->
            <div>TYPE : 타입 {{selectMode.selected_type}}</div>
        </label>
        <!--선택시 부모컴포넌트(rete 노드)로 선택 샘플 데이터 전송-->
        <b-button variant="primary" @click="onClick">선택하기</b-button>
    </div>
</b-card>
</template>
<script>
    export default {
        name: 'origin-data-card',
        props:['selectMode'],
        data() {
            return {

            }
        },
        // 부모 컴포넌트의 종류와 샘플 데이터를 받기위한 prop
        methods: {
            onClick(){
                // 선택된 샘플 데이터 정보를 부모컴포넌트로 전송한다
                const {selected_type, description} = this.selectMode;
                this.$emit('selected', selected_type, description);
            }
        },
    }
</script>
<style scoped>

    .origin-data-card {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin-top:10px;
        margin-bottom:10px;
    }


    .header {
        width: 100%;
        display: flex;
    }

    .header-name {
        width: 60px;
        height: 20px;
        border-radius: 10px;
        background: #E3ECFB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 5px;
    }

    .header-name label {
        font-size: 8px;
        margin-bottom: 0 !important;
        color: #3E7AD3;
    }

    .header-setting {
        margin-top: 7px;
        /*margin-right: 10px;*/
    }

    .header-setting .b-icon {
        color: #555555;
    }

    .header-setting .lang-dropdown.dropdown-menu {
        min-width: 4rem !important;
    }

    .data-card-explain {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*align-items: center;*/
    }

    .data-card-explain label {
        font-weight: bold;
        margin-left: 15px;
    }

    .data-card-explain p {
        font-size: 10px;
        color: #858585;
        word-break: keep-all;
        margin-left: 15px;
        margin-right: 10px;
    }

    .data-card-select {
        width: 280px;
        height: 30%;
        background: #DDE6F2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .data-card-select label {
        margin-left: 15px;
        font-size: 8px;
        margin-top: 10px;
        color: #858585;
    }

    .data-card-select button {
        width: 100px;
        height: 30px;
        margin-left: auto;
        margin-right: 10px;
        font-size: 13px;
    }

    /*====================================*/
</style>
