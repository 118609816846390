import { render, staticRenderFns } from "./CheckBoxParameterVueControl.vue?vue&type=template&id=063222b5&scoped=true&"
import script from "./CheckBoxParameterVueControl.vue?vue&type=script&lang=js&"
export * from "./CheckBoxParameterVueControl.vue?vue&type=script&lang=js&"
import style0 from "./CheckBoxParameterVueControl.vue?vue&type=style&index=0&id=063222b5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063222b5",
  null
  
)

export default component.exports