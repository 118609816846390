<template>
    <b-modal ref="modal" id="trainModelSelectModal" centered hide-header hide-footer size="xl">
        <div class="train-manager-modal-outer">
            <!-- header -->
            <div class="train-manager-modal-header">
                <label> 훈련시킬 모델</label>
                <b-icon icon="x" id="mod-closebtn" class="close-btn" v-b-tooltip.hover.bottom="'Modal Close Button'" v-on:click="close_btn"></b-icon>
            </div>
            <!-- body -->
            <div class="train-manager-modal-body">
                <!-- trained model 불러오기-->
                <div class="upload_part">
                    <div class="local_pretrained_file_upload">
                        <b-icon icon="plus-square-fill"></b-icon>
                        <!--로컬에 저장되어 있는 데이터 불러오기 부분-->
                        <!--로컬에서 불러오기-->
                        <label>모델 불러오기</label>
                        <!--로컬에서 불러오기 설명-->
                        <p>저장된 데이터 하드디스크에서 불러옵니다.</p>
                        <input ref="local_file_input" type="file" multiple hidden />
                    </div>
                    <b-modal ref="local_file_name_check" hide-footer>
                        <!--로컬 데이터 이름 확인 알람창-->
                        <label class="local_check_label">로컬 모델 이름을 확인해 주세요!</label>
                    </b-modal>
    
                    <!--기본 제공되는 샘플 데이터 선택 부분-->
                    <!--rete node 컴포넌트에서 제공된 샘플 데이터 정보를 바탕으로 카드를 생성한다.-->
                    <div v-for="(source, index) in dataSources" :key="index">
                        <!--카드에서 샘플 선택이 완료 되면 selected 이벤트를 핸들링하여 rete node 컴포넌트로 샘플 데이터를 전송-->
                        <ModalCard :node-type="nodeType" :data-source="source" @selected="onSelected" />
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
    import ModalCard from './ModalCardComp'

    export default {
        name: 'TrainModelModal',
        data() {
            return {}
        },
        // 부모 컴포넌트에서 제공받은 rete node 타입과 샘플 데이터 정보
        props: ['dataSources', 'nodeType'],
        components: {
            // 카드 생성을 위한 카드 컴포넌트 호출
            ModalCard

        },
        mounted(){
            
        }
        ,
        methods: {
            show() {
                // 모달 호출 메서드
                this.$refs.modal.show();
            },

            hide() {
                // 모달 닫기 메서드
                this.$refs.modal.hide();
            },
            close_btn() {
                // 모달 닫기 버튼 클릭을 핸들링하여 모달을 닫는다.
                this.hide();
            },

            onSelected(selectedData) {
                // 카드에서 선택된 데이터를 부모 컴포넌트로 전달한다.
                this.$emit('selected', selectedData);
                this.hide();
            }
        },

    }
</script>
<style scoped>
    .card-container {
        width: 100%;

    }

    .train-manager-modal-outer {
        height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;

    }

    .train-manager-modal-header {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .train-manager-modal-header label {
        color: #858585;
        font-size: 30px;
        font-weight: bold;
        margin-left: 10px;
    }

    .train-manager-modal-header .b-icon {
        margin-left: auto;
        margin-right: 10px;
    }

    .train-manager-modal-body {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
    }

    .train-manager-modal-search {
        height: 10%;
        width: 80%;
        display: flex;
        align-items: center;
    }

    .train-manager-modal-search input {
        height: 50px;
        width: 500px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: 2.3%;
        font-size: 15px !important;
    }

    .upload_part {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(4, 300px);
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: auto;
    }

    .local_pretrained_file_upload {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    .local_pretrained_file_upload .b-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        color: #C2C2C2;
    }

    .local_pretrained_file_upload label {
        font-weight: bold;
        font-size: 20px;
        color: #C2C2C2;
    }

    .local_pretrained_file_upload p {
        font-size: 12px;
        color: #C2C2C2;
        text-align: center;
    }


    .local_pretrained_file_upload:hover {
        background: #eeeeee;
        cursor: pointer;
    }


    .upload_part .card {
        border: none !important;
    }
</style>
