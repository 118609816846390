<template>
<div>
    <div class="bottom-right-area-header">
	<label>데이터 시각화</label>
	<select v-model="sectionSelct">
		<option v-for="option in section_options" v-bind:value="option.value">
			{{option.text}}
		</option>
	</select>
	</div>
	<div class="bottom-right-area-content">
	    <div class="bottom-right-area-left"> <!--:size="200" -->
				<vc-donut background="white" foreground="grey" :size="200" unit="px" :thickness="30" has-legend legend-placement="right":sections="sections"
			    :total="14074" :start-angle="0" :auto-adjust-text-size="true" @section-click="handleSectionClick">
			 	230,900 sales
			</vc-donut>
		</div>
		<div class="bottom-right-area-right">
			<label>4260 sales</label>
			<label>3970 sales</label>
			<label>3454 sales</label>
			<label>2390 sales</label>
		</div>
	</div>
</div>
</template>
<script>
    export default {
        name: "DataGraphComponent",
        data() {
            return {
                sectionSelct: "Europe",
                section_options: [
                    { text: 'Europe', value: 'Europe' },
                    { text: '32', value: '32' },
                ],
                sections: [
                    { label: 'France', value: 4260, color: '#55D8FE' },
                    { label: 'Italy', value: 3970, color: '#FF8373' },
                    { label: 'Spain', value: 3454, color: '#FFDA83' },
                    { label: 'Germany', value: 2390, color: '#A3A0FB' }
                ],
            }
        },
        methods: {
            handleSectionClick(section, event) {
                console.log(`${section.label} clicked`);
            }
        }
    }
</script>
<style scoped>
    .bottom-right-area-header {
        width: 100%;
        display: flex;
        margin-top: 10px;
    }
    

    .bottom-right-area-header select {
        margin-left: auto;
        margin-right: 20px;
        border-radius: 5px;
    }

    .bottom-right-area label {
        margin-right: auto;
        margin-left: 20px;
    }

    .bottom-right-area-content {
        width: 100%;
        display: flex;
    }

    .bottom-right-area-content .bottom-right-area-left {
        width: 50%;
    }

    .bottom-right-area-content .bottom-right-area-left .cdc-container {
        margin-right: auto;
        margin-left: 150px;
    }


    .bottom-right-area-content .bottom-right-area-right {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .bottom-right-area-content .bottom-right-area-right label {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 130px;
        color: #BABABA;
    }
    
</style>
