import axios from 'axios'

const API_GATEWAY = 'https://1yrer9got6.execute-api.ap-northeast-2.amazonaws.com/Prod'

const getData = async(id) => {

    const result = await axios.post(API_GATEWAY, {
        "action": "user",
        "Key": {
            "id": id
        }
    })
    console.log(result);
    return result;
}

const getRawData = async() => {

    const result = await axios.post(API_GATEWAY, {
        "action": "rawdata-info"
    })
    console.log(result);
    return result;
}

const putData = (id, data) => {

    axios.post(API_GATEWAY, {
        "Item": {
            "id": id,
            "data": data,
        }
    }).then((response) => {
        console.log(response);
    }).catch((error) => {
        console.log(error);
    });
}

export default {
    getData,
    putData,
    getRawData
}