import { Control } from 'rete';

import AdvancedVueControl from '../vues/AdvancedVueControl.vue'

export default class AdvancedControl extends Control {
    constructor(emitter, key, type, readonly = false) {
        super(key);
        
        this.component = AdvancedVueControl;
        
        this.props = {
            emitter,
            ikey: key,
            type,
            readonly,
            getNode: () => this.getNode()
        }
    };

    setValue(value) {
        const ctx = this.vueContext || this.props;
        ctx.value = value;
    }

    onChange() {}
}
