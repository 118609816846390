<template>
        <div id="machinelearning"/>
</template>

<script>
    // tensorflow-js 관련 불러오기
    import * as tf from '@tensorflow/tfjs';
    import * as tfvis from '@tensorflow/tfjs-vis';

    // MnistData
    import { MnistData } from '@/MnistData/data.js';

    import { fitCallbacks } from '@tensorflow/tfjs-vis/dist/show/history';

    // vuex
    import store from '@/store';

    export default {
        name: "ModelTraining",
        data() {
            return {}
        },
        methods: {
            init() {
                tf;
                tfvis;
                MnistData;
            },
        },
        mounted() {
            run();
            async function run() {
                // MnisData
                const data = new MnistData();
                await data.load();
                
                // 모델
                const model = getModel();
                
                // TF VISOR 모델 구조 // 오른쪽 바이저에 표시
                tfvis.show.modelSummary({ name: '모델 구조' }, model);
    
                // 모델 훈련
                await train(model, data);
                // TF VISOR 모델 정확도 // 오른쪽 바이저에 표시
                await showAccuracy(model, data);
                // TF VISOR 혼동횡렬 // 오른쪽 바이저에 표시
                await showConfusion(model, data);

                // 웹 로컬스토리지(크롬 브라우저) // MNIST_TEST 이름으로 저장
                await model.save('localstorage://MNIST_TEST');
                // 로컬 스토리지 (다운로드 폴더) // MNIST_TEST 이름으로 저장
                await model.save('downloads://MNIST_TEST');
                return;

            }

            // 모델 가져오기
            function getModel() {
                
                // tf sequential 모델 
                const model = tf.sequential();
                
                // 이미지 값 초기화
                let IMAGE_WIDTH;
                let IMAGE_HEIGHT;
                let IMAGE_CHANNELS;

                // 임시 // 현재 Trainmanager 에디터 내의 전체 세션 저장 값 가져오기
                let current_structure = sessionStorage.getItem('current_structure');

                // 임시// 전체 세션값 파싱
                let parse_current_structure = JSON.parse(current_structure);

                // 임시// 전체 세션값 노드만 분리
                let structure_nodes = parse_current_structure.nodes;
                
                // 임시// 전체 세션값 중 훈련시킬 모델 노드내에 모달 노드 에디터의 데이터 파싱해서 가져오기
                let editor_data = JSON.parse(structure_nodes[3].data.editor_data);

                // 임시// 훈련시킬 모달 노드 에디터의 노드 정보
                let editor_node = editor_data.nodes;
                
                // 임시// 반복하기위해 key값만 빼기 
                let m = Object.keys(editor_node);

                // 값을 넣어줄 반복문 시작
                for (let i = 0; i < m.length; i++) {

                    // 분기 할 노드 이름
                    let j = editor_node[m[i]].name;
                    // 노드 별 데이터
                    let k = editor_node[m[i]].data;

                    // 노드 이름으로 분기 
                    switch (editor_node[m[i]].name) {
                        
                        case '데이터입력':
                            // console.log("데이터입력");
                            IMAGE_WIDTH = k.data_input.IMAGE_WIDTH;
                            IMAGE_HEIGHT = k.data_input.IMAGE_HEIGHT;
                            IMAGE_CHANNELS = k.data_input.IMAGE_CHANNELS;
                            break;

                        case 'conv2d':
                            // console.log("conv2d");
                            // console.log(k.conv_data);
                            model.add(tf.layers.conv2d({
                                inputShape: [IMAGE_WIDTH, IMAGE_HEIGHT, IMAGE_CHANNELS],
                                kernelSize: k.conv_data.kernelSize,
                                filters: k.conv_data.filters,
                                activation: k.conv_data.activation,
                                kernelInitializer: k.conv_data.kernelInitializer
                            }))
                            break;

                        case 'maxPooling2d':
                            // console.log("maxPooling2d");
                            let t_1 = [Number(k.maxpool_data.poolSize[0]), Number(k.maxpool_data.poolSize[2])];
                            let t_2 = [Number(k.maxpool_data.strides[0]), Number(k.maxpool_data.strides[2])];

                            model.add(tf.layers.maxPooling2d({
                                poolSize: t_1,
                                strides: t_2
                            }))
                            break;

                        case 'flatten':
                            // console.log('flatten');
                            model.add(tf.layers.flatten({
                                inputShape: [IMAGE_WIDTH, IMAGE_HEIGHT, IMAGE_CHANNELS]
                            }))
                            break;

                        case 'dense':
                            // console.log('dense');
                            model.add(tf.layers.dense({
                                units: k.dense_data.units,
                                kernelInitializer: k.dense_data.kernelInitializer,
                                activation: k.dense_data.activation
                            }))
                            break;

                        case '데이터출력':
                            break;
                    }
                }
                
                // 하이퍼 파라미터 값 넣어줄 데이터 가져오기
                let trainer_data = structure_nodes[4].data.trainer_data;
                // console.log(trainer_data.optimizer);

                // 옵티마이저
                switch (trainer_data.optimizer) {

                    case 'adam':
                        const optimizer1 = tf.train.adam();
                        model.compile({
                            optimizer: optimizer1,
                            loss: trainer_data.loss,
                            metrics: ['accuracy'],
                        });
                        return model;

                    case 'sgd':
                        const optimizer2 = tf.train.sgd(0.05);
                        console.log('sgd');
                        model.compile({
                            optimizer: optimizer2,
                            loss: trainer_data.loss,
                            // loss : 'meanSquaredError',
                            metrics: ['accuracy'],
                        });
                        return model;

                    case 'momentum':
                        const optimizer3 = tf.train.momentum(0.05, 0.05);
                        model.compile({
                            optimizer: optimizer3,
                            loss: trainer_data.loss,
                            metrics: ['accuracy'],
                        });
                        return model;

                    case 'adagrad':
                        const optimizer4 = tf.train.adagrad(0.05);
                        model.compile({
                            optimizer: optimizer4,
                            loss: trainer_data.loss,
                            metrics: ['accuracy'],
                        });
                        return model;

                    case 'adadelta':
                        const optimizer5 = tf.train.adadelta();
                        model.compile({
                            optimizer: optimizer5,
                            loss: trainer_data.loss,
                            metrics: ['accuracy'],
                        });
                        return model;

                    case 'adamax':
                        const optimizer6 = tf.train.adamax();
                        model.compile({
                            optimizer: optimizer6,
                            loss: trainer_data.loss,
                            metrics: ['accuracy'],
                        });
                        return model;

                    case 'rmsprop':
                        const optimizer7 = tf.train.rmsprop(0.05);
                        model.compile({
                            optimizer: optimizer7,
                            loss: trainer_data.loss,
                            metrics: ['accuracy'],
                        });
                        return model;
                }
            }
            // 모델 훈련
            async function train(model, data) {
                const metrics = ['loss', 'val_loss', 'acc', 'val_acc'];
                const container = {
                    name: '모델 훈련',
                    styles: { height: '1000px' }
                };

                const fitCallbacks = tfvis.show.fitCallbacks(container, metrics);

                const BATCH_SIZE = 512;
                const TRAIN_DATA_SIZE = 5500;
                const TEST_DATA_SIZE = 1000;

                const [trainXs, trainYs] = tf.tidy(() => {
                    const d = data.nextTrainBatch(TRAIN_DATA_SIZE);
                    return [
                        d.xs.reshape([TRAIN_DATA_SIZE, 28, 28, 1]),
                        d.labels
                    ];
                });

                const [testXs, testYs] = tf.tidy(() => {
                    const d = data.nextTestBatch(TEST_DATA_SIZE);
                    return [
                        d.xs.reshape([TEST_DATA_SIZE, 28, 28, 1]),
                        d.labels
                    ];
                });

                return model.fit(trainXs, trainYs, {
                    batchSize: BATCH_SIZE,
                    validationData: [testXs, testYs],
                    epochs: 10,
                    shuffle: true,
                    callbacks: fitCallbacks
                });
            }
            const classNames = [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9"
            ];
            
            // 모델 PREDICTION
            function doPrediction(model, data, testDataSize = 500) {
                const IMAGE_WIDTH = 28;
                const IMAGE_HEIGHT = 28;
                const testData = data.nextTestBatch(testDataSize);
                const testxs = testData.xs.reshape([
                    testDataSize,
                    IMAGE_WIDTH,
                    IMAGE_HEIGHT,
                    1
                ]);
                const labels = testData.labels.argMax([-1]);
                const preds = model.predict(testxs).argMax([-1]);

                testxs.dispose();
                return [preds, labels];
            }

            // 정확도
            async function showAccuracy(model, data) {
                const [preds, labels] = doPrediction(model, data);
                const classAccuracy = await tfvis.metrics.perClassAccuracy(labels, preds);
                const container = { name: "정확도", tab: "평가" };
                tfvis.show.perClassAccuracy(container, classAccuracy, classNames);

                labels.dispose();
            }
            
            // 컨퓨전 매트릭스 
            async function showConfusion(model, data) {
                const [preds, labels] = doPrediction(model, data);
                const confusionMatrix = await tfvis.metrics.confusionMatrix(labels, preds);
                const container = { name: "컨퓨전 매트릭스(혼동 횡렬)", tab: "평가" };
                tfvis.render.confusionMatrix(
                    container, { values: confusionMatrix },
                    classNames
                );

                labels.dispose();
            }

        },
    }
</script>
<style scoped>
    #machinelearning {
        display: none;
    }
</style>
