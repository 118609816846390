import { Control } from 'rete';
import DenseVueControl from './vue-controls/DenseVueControl.vue';

export default class DenseControl extends Control {
    constructor(emitter, key, readonly = false) {
        super(key);
        this.component = DenseVueControl;
    };

    setValue(value) {

    }

    onChange() {

    }
}
