<template>
    <div class="data_preprocess_node">
        <div class="data_preprocess_content">
                <!--<label>데이터 처리</label>-->
                <!--<input type="number"/>-->
                <div class="data_preprocess_content__selected-alarm">
                    <div class="alarm__title">
                        <label>선택된 모드</label>
                        <div class="select-name">{{selected_mode_name}}</div>
                    </div>
                    <!--<div class="alarm__content">
                        <label>모드 설명</label>
                        <p>{{selected_mode_description}}</p>
                    </div>-->
                </div>
                <b-button @click="showDataPreprocessorSelectModal">모드 선택</b-button>
                <DataPreprocessorSelect @selected="alarmSelect" ref="data_preprocess_select_modal" node-type="DataPreprocessor" />
                
                <b-button variant="primary" @click="showDataPreprocessModal">설정</b-button>
                <b-modal ref="data_preprocess_modal" id="dataProcessEditModal" body-class="p-0" hide-header hide-footer centered size="xl">
                    <DataPreprocessEdit v-on:close_data_preprocess_edit="hideDataPreprocessModal" :editor-data="editor_data" :getNode="getNode"/>
                </b-modal>
                <!--모달 컴포넌트 호출부-->
                <!-- @selected="onSelected" :data-sources="samples"-->
            </div>
    </div>
</template>
<script>
    import Rete from "rete";
    import { numSocket } from '../sockets';

    import DataPreprocessEdit from './DataPreprocessModalEditor/DataPreprocessModalEditor';
    import DataPreprocessorSelect from './DataPreprocessorSelectModal/DataProprocessorSelectModal';
    import { mode1, mode2 } from './DataPreprocessModalEditor/json-data/modeDataFromDataPreprocessorSelect.json'

    export default {
        name: 'data_preprocess',
        data() {
            return {
                // 컬랩스 부분 상태
                editor_data: this.getData("editor_data"),
                input_data: this.getData("input_data"),
                
                // 컬랩스 부분 상태
                datapreprocess_advance_visible: false,
                datapreprocess_advance_icon: 'plus',
                selected_mode_description: "데이터 전 처리기 노드에 입력, 출력 각각 1 개씩 생성합니다.",
                selected_mode_name: "모드 1"
            }
        },
        props: ['readonly', 'emitter', 'getData', 'putData', 'getNode'],
        components: {
            DataPreprocessorSelect,
            DataPreprocessEdit
        },

        mounted() {
            /* 
                처음 로딩했을 때, editor_data가 비어있으면, 기본으로 선택된 모드 1의 editor 데이터를 넣는다.
            */
            const node = this.getNode();
            const is_editor_data = node.data.editor_data || null;

            console.log(node);
            console.log("유 무 파악", node.data.editor_data);
            /* 
              선택된 모드, 모드 설명에 최근에 저장한 모드에 대한 제목, 설명란을 넣는다.
              만약, 없으면 기본 값(모드 1)에 대한 정보를 넣는다.
            */
            this.selected_mode_name = node.data.selected_mode_name || this.selected_mode_name;
            this.selected_mode_description = node.data.selected_mode_description || this.selected_mode_description;

            /* 저장된 editor_data가 없으면, mode 1에 대한 에디터 데이터를 삽입한다. */
            if (!is_editor_data) node.data.editor_data = mode1;
        },

        methods: {
            showDataPreprocessorSelectModal() {
                this.$refs['data_preprocess_select_modal'].show();
            },
            hideDataPreprocessorSelectModal() {
                this.$refs['data_preprocess_select_modal'].hide();
            },

            alarmSelect(selected_type, description) {
                /* alarmSelect 메소드 안에서 필요한 함수들 ====================================================================*/

                /* makeInputAndOutput: 입력과 아웃풋을 새로 생성해서 노드에 넣고 업데이트 */
                const makeInputAndOutput = (node) => {
                    const input_id = node.inputs.size + 1;
                    const output_id = node.outputs.size + 1;
                    const input = new Rete.Input(`preprocess_input${input_id}`, "입력", numSocket, true);
                    const output = new Rete.Output(`preprocess_output${output_id}`, "출력", numSocket, false);
                    node.addInput(input);
                    node.addOutput(output);
                    node.update();
                }


                /* clearInputAndOutputMap: 노드 안에 있는 입, 출력 맵 데이터 모두 제거 */
                const clearInputAndOutputMap = () => {
                    node.inputs.forEach(i => {
                        i.connections.forEach(c => {
                            this.emitter.removeConnection(c);
                        })
                    });

                    node.outputs.forEach(o => {
                        o.connections.forEach(c => {
                            this.emitter.removeConnection(c);
                        })
                    });

                    node.inputs.clear();
                    node.outputs.clear();
                }


                /* inputs_data / output_data: 입/출력 value 값들을 배열 형태로 리턴 */
                const input_data = (node) => {
                    return Array.from(node.inputs.values()).map(i => ({ key: i.key, name: i.name }));
                };
                const output_data = (node) => {
                    return Array.from(node.outputs.values()).map(i => ({ key: i.key, name: i.name }));
                }

                /* saveSelectedMode: 선택했던 것을 저장 */
                const saveSelectedMode = () => sessionStorage.setItem('current_structure', JSON.stringify(this.emitter.toJSON()));

                /*================================================================================================================*/




                /* 코드 시작 */
                const node = this.getNode();
                console.log("이벤트 도착");
                clearInputAndOutputMap();

                switch (selected_type) {
                    case 1:
                        makeInputAndOutput(node);
                        this.selected_mode_description = description;
                        this.selected_mode_name = "모드1";

                        node.data.selected_mode_name = this.selected_mode_name;
                        node.data.selected_mode_description = this.selected_mode_description;
                        node.data.input_data = input_data(node);
                        node.data.output_data = output_data(node);
                        node.data.editor_data = mode1;

                        /* 선택 값 저장 */
                        saveSelectedMode();
                        // this.emitter.fromJSON();
                        // 고급 메뉴 보이기
                        node.vueContext.$el.querySelector(".control").style.display = "block";
                        this.showDataPreprocessModal();
                        return;
                    case 2:
                        for (let i = 0; i < 2; i++) makeInputAndOutput(node);
                        this.selected_mode_description = description;
                        this.selected_mode_name = "모드2";

                        node.data.selected_mode_name = this.selected_mode_name;
                        node.data.selected_mode_description = this.selected_mode_description;
                        node.data.input_data = input_data(node);
                        node.data.output_data = output_data(node);
                        node.data.editor_data = mode2;

                        saveSelectedMode();

                        // 고급 메뉴 보이기
                        node.vueContext.$el.querySelector(".control").style.display = "block";
                        this.showDataPreprocessModal();
                        return;
                }

            },
             showDataPreprocessModal() {
                this.$refs['data_preprocess_modal'].show();
            },
            hideDataPreprocessModal(modal_editor_data) {
                /* ================================함수 설정 부분=================================*/
                /* addedInputs / addedOutputs: 입/출력 value 값들을 배열 형태로 리턴 */
                const input_data = (node) => {
                    return Array.from(node.inputs.values()).map(i => ({ key: i.key, name: i.name }));
                };
                const output_data = (node) => {
                    return Array.from(node.outputs.values()).map(i => ({ key: i.key, name: i.name }));
                };
			    /*=================================================================================*/
			    
                this.$refs['data_preprocess_modal'].hide();
                this.putData("editor_data", modal_editor_data);
                this.editor_data = modal_editor_data;
                
                const nodes = Object.values(this.editor_data.nodes);
                
                // 커넥션 초기화
                this.getNode().inputs.forEach(i => {
					i.connections.forEach(c => {
						this.emitter.removeConnection(c);
					});
				});
				
				this.getNode().outputs.forEach(o => {
					o.connections.forEach(c => {
						this.emitter.removeConnection(c);
					});
				});
                
                /* 인풋과 아웃풋 제거 */
                this.getNode().inputs.clear();
                this.getNode().outputs.clear();
                
                
                /* editor_data의 인풋, 아웃풋 개수에 따라 현재 노드(데이터 전처리)에 입/출력 갯수 증가 */
                nodes.forEach((node, idx) => {
                   if(node.name === "Input"){
                       const input_id = this.getNode().inputs.size+1;
                       const input = new Rete.Input(`preprocess_input${input_id}`, "입력", numSocket, true);
                       this.getNode().addInput(input);
                   }
                   else if(node.name === "Output"){
                       const output_id = this.getNode().outputs.size+1;
                       const output = new Rete.Output(`preprocess_output${output_id}`, "출력", numSocket, false);
                       this.getNode().addOutput(output);
                   }
                });
                
                /* 처음 렌더링 받을 때,  input_data, output_data 에 주제에 맞게 입 출력 객체를 저장함*/
                this.getNode().data.input_data = input_data(this.getNode());
                this.getNode().data.output_data = output_data(this.getNode());
                this.getNode().update();
                
                const whole_data = this.emitter.toJSON();
                console.log(whole_data);
                console.log(this.getNode());
                sessionStorage.setItem('current_structure', JSON.stringify(whole_data));
            },
            
            // 컬랩스 부분 메서드 // 아이콘 변경 // 컬랩스 visible
            datapreprocess_advance_click() {
                this.datapreprocess_advance_visible = !this.datapreprocess_advance_visible;
                if(this.datapreprocess_advance_visible === true) {
                    this.datapreprocess_advance_icon = 'dash';
                }else {
                    this.datapreprocess_advance_icon = 'plus';
                }
            }

        },
    }
</script>
<style scoped>
    /*데이터 전처리 노드 전체 지정*/

    .data_preprocess_node {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    /*데이터 전처리 노드 헤더 부분 지정*/

    .data_select_node .data_preprocess_header {
        padding: 5px;
        margin-bottom: 10px;
    }

    /*데이터 전처리 노드 내용 부분 지정*/

    .data_select_node .data_preprocess_content {
        padding: 5px;
    }

    /*데이터 전처리 노드 하단부 지정*/

    .data_select_node .data_preprocess_footer {
        padding: 5px;
    }

    /*데이터 전처리 노드 전체 라벨 지정*/

    .data_preprocess_node label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    /*데이터 전처리 노드 전체 셀렉트, 인풋 지정*/

    .data_preprocess_node select,
    input {
        border-radius: 5px !important;
        width: 180px !important;
    }

    /*데이터 전처리 노드 버튼 지정*/

    .data_preprocess_node .data_preprocess_content__selected-alarm {
        max-width: 200px;
        padding: 0 10px;
        padding-top: 15px;
    }

    .data_preprocess_node .data_preprocess_content__selected-alarm label {
        font-size: 13px !important;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .data_preprocess_node .data_preprocess_content__selected-alarm .alarm__title .select-name {
        font-size: 13px;
        border-bottom: 1px solid #7784FB;
        margin: 5px 0;
        padding-bottom: 5px;
    }

    .data_preprocess_node .data_preprocess_content__selected-alarm .alarm__content p {
        margin-top: 5px;
        font-size: 13px;
        padding: 10px;
        border: 1px solid #7784FB;
        opacity: 0.7;
    }

    .data_preprocess_node button {
        height: 25px;
        width: 180px;
        font-size: 13px;
        color: #ffffff;
        background: rgba(119, 132, 251, 0.7);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 12px;
        border: none;
        margin-bottom: 0;
    }

    /*데이터 전처리 노드 고급 버튼 지정*/

    .datapreprocess_advance {
        width: 220px;
        height: 22px;
        padding-top: 6px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        /*border-top: 1px solid #7784FB;*/
    }

    /*고급 기능 버튼 포커스 없애기 */

    .datapreprocess_advance:focus {
        outline: none;
    }

    /*데이터 전처리 노드 고급 버튼 라벨 지정*/

    .datapreprocess_advance label {
        font-size: 13px !important;
        color: #7784FB;
        margin-left: 10px;
    }

    /*데이터 전처리 노드 고급 버튼 아이콘 지정*/

    .datapreprocess_advance .b-icon {
        width: 28px;
        height: 28px;
        margin-left: auto;
        color: #7784FB;
        margin-right: 10px;
    }

    /* 고급 기능 컬랩스 지정*/

    #datapreprocess-advance {
        border-top: 1px solid #eee;
        margin-top: 5px;
    }

    /*고급 기능 컬랩스 내부 라벨 인풋 버튼 지정*/

    #datapreprocess-advance label,
    button,
    input {
        margin: 10px;
    }

    /*선택된 데이터가 표시될 부분 지정*/

    .data_describe {
        width: 180px;
        height: 25px;
        border-bottom: 1px solid #7784FB;
        margin: 10px;
        opacity: 0.8;
    }
</style>
