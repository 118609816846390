<template>
    <!--사전 훈련된 모델 선택 내용 부분-->
        <div class="model_upload_content">
            <!--모델 선택 카드 상단 라벨 부분-->
            <div class="mi_content_header">
                <label>3D 모델링 구조</label>
            </div>
            <!--모델 선택 카드 상단 라벨 부분 end-->
            
            <!-- 모델 선택 카드 부분 -->
            <b-card no-body class="model_input_part">
                
                <!--모델 선택하기 카드 헤더 부분 -->
                <b-card-header>
                </b-card-header>
                <!--모델 선택하기 카드 헤더 부분 end -->

                <!--텐서 스페이스 모델 불러오기 -->
                <tensor-space-model ref="tensorspace_model" v-show="pretrained_model"/>
                <!--텐서 스페이스 모델 불러오기 END-->
            </b-card>
            <!-- 모델 선택 카드 부분 end -->
            
        </div>
    <!--사전 훈련된 모델 선택 내용 부분 end-->
</template>

<script>

    // TensorSpaceModel =============================================================

    // 텐서 스페이스 모델 로컬파일
    import tensorspace_model_localfile from './TensorSpaceModel/TensorSpaceModel_LocalFile';
    import TensorSpaceModel from './TensorSpaceModel/TensorSpaceModel';
    // =======================================================================

    export default {
        name: 'TensorSpaceComp',
        data() {
            return {
                // selected pretrained model
                pretrained_model: '',
            }
        },
        components: {
            // 모델 선택하기 텐서스페이스 부분

            // 로컬파일 선택
            tensorspace_model_localfile,
           TensorSpaceModel
            // ===============================

        },
        methods: {
            setTensorSpaceModel(pretrained_model) {

                // 텐서스페이스 모델 불러오기 
                this.pretrained_model = pretrained_model.name;
                
                this.$refs.tensorspace_model.tensorspace_model_upload(this.pretrained_model);
            },
        },

    }
</script>
<style scoped>
    /* 모델 업로드 상단 라벨 부분 ------------------------------*/

    .mi_content_header {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .mi_content_header label {
        font-size: 20px;
        font-weight: bold;
    }

    /* 모델 업로드 부분 ----------------------------------------*/

    .model_input_part {
        width: 450px;
        height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .model_input_part .card-header {
        width: 450px;
        height: 54px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .model_input_part .card-header label {
        font-size: 20px;
        font-weight: bold;
    }

    .model_input_part .card-header button {
        margin-left: auto;
        width: 100px;
    }
</style>
