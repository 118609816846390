<template>
    <div class="data_summary">
        <!-- MNIST DATA 요약 라벨 부분-->
        <!--데이터 요약-->
        <!--<label>{{ mnist_datasummary_label }}</label>-->
        <table class="table">
            <tbody>
                <tr>
                    <!--데이터 요약 이름 부분-->
                    <!--이름-->
                  <th scope="row">{{ mnist_datasummary_name }}</th>
                  <td><p>{{dataset_name}}</p></td>
                </tr>
                <tr>
                    <!--데이터 요약 종류 부분-->
                    <!--종류-->
                  <th scope="row">{{ mnist_dataset_kinds }}</th>
                  <td><p>{{dataset_kinds}}</p></td>
                </tr>
                <tr>
                    <!--데이터 요약 이미지 개수 부분-->
                    <!--이미지 개수-->
                  <th scope="row">{{ mnist_dataset_image_num }}</th>
                  <td><p>{{dataset_number}}</p></td>
                </tr>
                </tr>
                <tr>
                    <!--데이터 요약 훈련 이미지 개수 부분-->
                    <!--훈련 이미지 개수-->
                  <th scope="row">{{ mnist_trainlabels_num }}</th>
                  <td><p>{{trainlabels_number}}</p></td>
                </tr>
                <tr>
                    <!--데이터 요약 테스트 이미지 개수 부분-->
                    <!--테스트 이미지 개수-->
                  <th scope="row">{{ mnist_testlabels_num }}</th>
                  <td><p>{{testlabels_number}}</p></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import * as tf from '@tensorflow/tfjs';
    import { MnistData } from '@/MnistData/data.js';

    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    import store from '@/store';

    export default {
        name: 'datasummary',
        data() {
            return {
                // 다국어 설정
                mnist_datasummary_label: '',
                mnist_datasummary_name: '',
                mnist_dataset_kinds: '',
                mnist_dataset_image_num: '',
                mnist_trainlabels_num: '',
                mnist_testlabels_num: '',

                // 언어 설정
                localization: ''
            }
        },
        methods: {},
        mounted() {
            run();

            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // 언어 설정 테스트
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.mnist_datasummary_label = korean.mnist_summary.mnist_datasummary_label;
                    this.mnist_datasummary_name = korean.mnist_summary.mnist_datasummary_name;
                    this.mnist_dataset_kinds = korean.mnist_summary.mnist_dataset_kinds;
                    this.mnist_dataset_image_num = korean.mnist_summary.mnist_dataset_image_num;
                    this.mnist_trainlabels_num = korean.mnist_summary.mnist_trainlabels_num;
                    this.mnist_testlabels_num = korean.mnist_summary.mnist_testlabels_num;
                    return;

                case 'en':
                    this.mnist_datasummary_label = english.mnist_summary.mnist_datasummary_label;
                    this.mnist_datasummary_name = english.mnist_summary.mnist_datasummary_name;
                    this.mnist_dataset_kinds = english.mnist_summary.mnist_dataset_kinds;
                    this.mnist_dataset_image_num = english.mnist_summary.mnist_dataset_image_num;
                    this.mnist_trainlabels_num = english.mnist_summary.mnist_trainlabels_num;
                    this.mnist_testlabels_num = english.mnist_summary.mnist_testlabels_num;
                    return;
            }


            async function run() {

                const data = new MnistData();
                await data.load();
                await getMnistData(data);
                return;

            }

            async function getMnistData(data) {

                // 데이터셋 이름
                store.dispatch('call_dataset_name', { dataset_name: 'MNIST' });
                // 데이터셋 종류
                store.dispatch('call_dataset_kinds', { dataset_kinds: '이미지' });

                // 데이터셋
                let dataset_num = data.datasetLabels.length / 10;

                // 데이터셋 값 store에 등록
                store.dispatch('call_dataset_num', { dataset_num: dataset_num });
            }

        },
        computed: {
            dataset_name() {
                return store.getters.get_dataset_name
            },
            dataset_kinds() {
                return store.getters.get_dataset_kinds
            },
            dataset_number() {
                return store.getters.get_dataset_num
            },
            trainlabels_number() {
                return store.getters.get_trainlabels_num
            },
            testlabels_number() {
                return store.getters.get_testlabels_num
            },
        }
    }
</script>
<style scoped>
    .data_summary {
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 200px;
        /*width: 280px;*/
        padding: 10px;
        align-items: center;
    }

    .data_summary label {
        font-size: 20px;
        font-weight: bold;
    }

    .table th,
    .table td {
        padding: 0px !important;
        vertical-align: top !important;
        border-top: 1px solid #dee2e6 !important;
    }
</style>
