import Rete from 'rete';
import { numSocket } from '../../sockets';
import InferenceControl from '../../controls/basic-controls/InferenceControl';

export default class InferenceComponent extends Rete.Component {
    constructor() {
        super("추론");
        this.data = {
            render: 'vue'
        };
    }

    builder(node) {
        let input1 = new Rete.Input('input1', 'Input1', numSocket);
        return node
            .addControl(new InferenceControl(this.editor, "num"))
            .addInput(input1);
    }
    worker(node, inputs, outputs) {
        outputs["num"] = node.data.num;
    }
}
