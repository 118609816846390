<template>
    <div class="new_workspaces_outer">
        <div class="form_label">
            <!--새로운 프로젝트 만들기 라벨 부분-->
            <!--새로운 프로젝트 생성-->
            <label>{{ new_workspaces_form_label }}</label>
        </div>
        <div class="input_form">
            <!--프로젝트 이름 입력 부분-->
            <!--프로젝트 이름-->
            <input type="text" :placeholder="project_name" v-model="project.name" />
            <!--프로젝트 설명 입력 부분-->
            <!--프로젝트 설명-->
            <input type="text" :placeholder="project_explain" v-model="project.explain"/>
            <!--프로젝트 공유 그룹 설정 부분-->
            <!--프로젝트 공유 그룹-->
            <input type="text" :placeholder="project_group" v-model="project.group"/>
            <!--프로젝트 암호 입력 부분-->
            <!--프로젝트 암호 (옵션)-->
            <input type="text" :placeholder="project_password" v-model="project.password"/>
        </div>
        <div class="input_form_btn_group">
            <!--프로젝트 생성 버튼 부분-->
            <!--생성-->
            <b-button class="input_setting" variant="primary" @click="project_save">{{ project_form_save }}</b-button>
            <!--프로젝트 만들기 취소 버튼 부분-->
            <!--취소-->
            <b-button class="form_close" @click="close_btn" variant="outline-dark">{{ project_form_close }}</b-button>
            <!--<b-button class="test-button" @click="test_click_child" variant="outline-dark">테스트</b-button>-->
        </div>
    </div>
</template>
<script>
    // 언어 설정 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: 'new_workspaces',
        data() {
            return {
                project: {
                    name: '',
                    explain: '',
                    group: '',
                    password: ''
                },
                // 다국어모드 설정
                new_workspaces_form_label: '',
                project_name: '',
                project_explain: '',
                project_group: '',
                project_password: '',

                project_form_save: '',
                project_form_close: '',

                // 언어설정
                localization: ''
            }
        },
        mounted() {
            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.new_workspaces_form_label = korean.create_project.new_workspaces_form_label;
                    this.project_name = korean.create_project.project_name;
                    this.project_explain = korean.create_project.project_explain;
                    this.project_group = korean.create_project.project_group;
                    this.project_password = korean.create_project.project_password;
                    this.project_form_save = korean.create_project.project_form_save;
                    this.project_form_close = korean.create_project.project_form_close;
                    return;
                case 'en':
                    this.new_workspaces_form_label = english.create_project.new_workspaces_form_label;
                    this.project_name = english.create_project.project_name;
                    this.project_explain = english.create_project.project_explain;
                    this.project_group = english.create_project.project_group;
                    this.project_password = english.create_project.project_password;
                    this.project_form_save = english.create_project.project_form_save;
                    this.project_form_close = english.create_project.project_form_close;
                    return;
            }
        },
        methods: {
            close_btn() {
                this.$emit('close_btn');
            },
            // test_click_child() {
            //     let test_button_click_content = "테스트 버튼을 클릭하였습니다";
            //     this.$emit('test_click_child', test_button_click_content);
            // },
            project_save() {
                // 프로젝트 생성 부분 (임시)
                let project_name = this.project.name;
                let project_explain = this.project.explain;

                // 세션에 저장되어 있는 USERINFO에서 유저이미지 가져오기
                let session_userinfo = sessionStorage.getItem('userInfo');

                // 유저 정보 파싱
                let userinfo_parse = JSON.parse(session_userinfo);

                // 프로필 이미지 가져오기
                let profile_image = userinfo_parse.profile_image;


                // 프로젝트 생성에 필요한 변수 체크
                // console.log("프로젝트 이름:" + project_name + "프로젝트 설명:" + project_explain);

                // 세션스토리지 저장된 목록 가져오기 
                let workspaces = sessionStorage.getItem('session_saved_workspaces');

                // 제이슨으로 파싱
                let workspaces_parse = JSON.parse(workspaces);

                // 추가할 내용 변수로 지정 (임시)
                let project_input = {
                    authorize: '관리자',
                    project_name: project_name,
                    project_rating: 0,
                    project_explain: project_explain,
                    user_profile_image: profile_image,
                    isDisabled: false
                };

                // 세션 스토리지 목록 LENGTH 체크 
                // console.log(workspaces_parse.length);

                // 목록 개수를 변수로 지정 
                let data_len = workspaces_parse.length;

                // 데이터를 추가
                workspaces_parse[data_len] = project_input;

                // 추가한 내용들을 세션스토리지에 넣기 알맞은 형태로 변환

                let save_project = JSON.stringify(workspaces_parse);

                // 세션 스토리지에 다시 저장
                sessionStorage.setItem('session_saved_workspaces', save_project);



                // 창 종료
                this.$emit('close_btn');

                // axios post =================================

                // 추후에 이 부분에 axios post 부분을 통해 통신 하는 부분 설정 예정

                // ============================================

            }
        },
    }
</script>
<style scoped>
    .new_workspaces_outer {
        height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .form_label {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;

    }

    .form_label label {
        font-size: 30px;
        font-weight: bold;
        color: #1B4891;
    }

    .input_form {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .input_form input {
        width: 500px !important;
        height: 50px !important;
        border-radius: 0 !important;
        border-right: none !important;
        border-left: none !important;
        border-top: none !important;
        border-bottom: 2px solid #eeeeee !important;
        margin-top: 30px;
        font-size: 15px !important;
    }

    .input_form_btn_group {
        width: 500px;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 30px;
    }

    .input_setting {
        width: 40%;
        height: 50px;
        border: 1px solid #555555;
    }

    .form_close {
        width: 40%;
        height: 50px;
        border: 1px solid #555555;
    }
</style>
