import Rete from 'rete';

// numSocket = number를 넣는 socket 설정

let numSocket = new Rete.Socket("Number");

let actionSocket = new Rete.Socket('Action');

let dataSocket = new Rete.Socket('Data');

export { numSocket, actionSocket, dataSocket };