<template>
    <!-- 선택된 모델이 CONTEXT R-CNN 일때-->
    <div class="model_part">
        <div id='container'/>
        <!--컴포넌트 화-->
        <div class="model_explain">
        </div>
    </div>
    <!-- 선택된 모델이 CONTEXT R-CNN 일때 end -->
</template>
<script>
    // vuex =====================
    import store from '@/store';
    // ==========================

    // 언어 설정 JSON 파일 가져오기 ===============
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================

    // JSON으로 저장한 샘플이미지 가져오기 ===============
    import sample_image from '@/assets/sample_image.json';
    // ===================================================

    // tensorspace 불러오기 ================================
    import * as TSP from 'tensorspace';
    import { tensorspace } from '@/tensorspace/tensorspace';
    // =====================================================

    export default {
        name: "model",
        data() {
            return {
                model_explain_complete: '',
                // 언어 설정
                localization: '',

            }
        },
        mounted() {
            /* global navigator */

            // 브라우저의 언어 설정을 읽어와서 변수로 지정
            // let locale = navigator.language || navigator.userLanguage;

            // 지정한 변수를 데이터로 지정 
            // this.localization = locale.substring(0, 2);

            // 언어 설정 영어 테스트 // 언어를 임의로 바꿔서 테스트 할 수 없기 때문에
            // this.localization = 'en';

            // switch (this.localization) {
            //     case 'ko':
            //         this.model_explain_complete = korean.inference.model_explain_complete;
            //         return;
            //     case 'en':
            //         this.model_explain_complete = english.inference.model_explain_complete;
            //         return;
            // }
        },
        methods: {
            async tensorspace_model_upload(selected_pretrain_model) {

                let tensorspace_container = document.getElementById('container');

                // 텐서스페이스 컨테이너 초기화
                while (tensorspace_container.hasChildNodes()) {
                    tensorspace_container.removeChild(tensorspace_container.firstChild);
                }

                // 텐서스페이스 MODEL VIEW 초기화
                let ts_space;
                let ts_model_view;

                // 사전 훈련된 모델 선택 기준 분기 
                switch (selected_pretrain_model) {
                    case 'MNIST CONVOLUTION NET':
                        ts_space = new tensorspace(tensorspace_container);
                        ts_model_view = await ts_space.ts_mnistconv();
                        break;
                    case 'MNIST DENSE NET':
                        ts_space = new tensorspace(tensorspace_container);
                        ts_model_view = await ts_space.ts_mnistdense();
                        break;
                    case 'IRIS DENSE NET':
                        ts_space = new tensorspace(tensorspace_container);
                        ts_model_view = await ts_space.ts_irisdense();
                        break;
                    case 'MOBILE NET':
                        ts_space = new tensorspace(tensorspace_container);
                        ts_model_view = await ts_space.ts_mobilenet();
                        break;
                        
                    default:
                    
                        let ts_image = null;
                        // 텐서스페이스 대신 만들어줄 이미지 생성
                        ts_image = document.createElement('img');

                        // 이미지 속성 설정 
                        ts_image.src = sample_image.data;

                        // 이미지 속성 설정
                        ts_image.setAttribute("width", "350px");
                        ts_image.setAttribute('height', "350px");

                        // 이미지 붙이기
                        tensorspace_container.appendChild(ts_image);
                }

                if (ts_model_view) {
                    ts_model_view.init(() => {
                        console.log('tensorspace is working (' + this.selected_pretrain_model + ')');
                    });
                }
            }
        },
        // computed: {
        //     selected_pretrain_model() {
        //         return store.getters.get_pretrain_model_name;
        //     },
        //     model_explain_name() {
        //         switch (this.selected_pretrain_model) {
        //             case 'MNIST CONVOLUTION NET':
        //                 return 'MNIST_CONVOLUTION_NET'
        //             case 'MNIST DENSE NET':
        //                 return 'MNIST_DENSE_NET';
        //             case 'IRIS DENSE NET':
        //                 return 'IRIS_DENSE_NET';
        //             case 'MOBILE NET':
        //                 return 'MOBILE_NET';
        //             case 'HAND POSE':
        //                 return 'HAND_POSE';
        //             case 'CONTEXT R-CNN':
        //                 return 'CONTEXT_R_CNN';
        //             case 'GAN-CARTOONIZER':
        //                 return 'GAN_CARTOON';
        //         }
        //     }
        // }
    }
</script>
<style scoped>
    .model_part {
        width: 100%;
        height: 100%;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }

    .model_part img {
        width: 350px;
        height: 350px;
    }

    #container {
        width: 350px;
        height: 350px;
        background: #F5F6FA;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
        margin-bottom: 10px;
    }

    #container canvas {
        width: 350px !important;
        height: 350px !important;
        border-radius: 10px !important;
    }

    #container img {
        width: 350px !important;
        height: 350px !important;
    }

    .model_explain {
        width: 350px;
        height: 40px;
    }

    .model_explain label {
        font-size: 13px;
    }

    .model_explain strong {
        font-size: 17px !important;
    }

    .model_custom_explain {
        font-size: 13px !important;
    }
</style>
