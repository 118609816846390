<template>
    <div class="trainmanager_dock-container" @mousedown="$emit('startDrag', $event)" >
        <div class="dock-label sidemenu-category sidemenu-header">
                <!--노드 구성 토글 버튼 부분-->
            <label class="dock-label__title sidemenu-header">노드 구성</label>
        </div>
        <div class="trainmanager_dock">
            <div class="dock-label" v-b-toggle.nodeConsist>
                <b-icon icon="chevron-down"></b-icon>
                    <!--노드 구성 토글 버튼 부분-->
                    <!--노드 구성-->
                <label>기본 노드</label>
            </div>
            <b-collapse visible id="nodeConsist">
                <div class="dock-item-list">
                     <div class="dock-item">
                        <div class="dock-item__node 원본데이터" id="original_data">
                            <!--데이터 입력(이미지) 노드 생성-->
                            <!--데이터 입력(이미지)-->
                            <div class="title">
                                <span class="dock-item__node__text">원본 데이터</span>
                            </div>
                        </div>
                    </div>
                    <div class="dock-item">
                        <div class="dock-item__node 데이터전처리" id="data_preprocess">
                            <!--데이터 입력(기본) 노드 생성-->
                            <!--데이터 입력(기본)-->
                            <div class="title">
                                <span class="dock-item__node__text">데이터 전처리</span>
                            </div>
                        </div>
                    </div>
                    <div class="dock-item">
                        <div class="dock-item__node 훈련시킬모델" id="train_model">
                            <!--데이터 입력(웹캠) 노드 생성-->
                            <!--데이터 입력(웹캠)-->
                            <div class="title">
                                <span class="dock-item__node__text">훈련시킬 모델</span>
                            </div>
                        </div>
                    </div>
                    <div class="dock-item">
                        <div class="dock-item__node 트레이너" id="trainer">
                            <!--데이터 모니터링 노드 생성-->
                            <!--데이터 모니터링-->
                            <div class="title">
                                <span class="dock-item__node__text">트레이너</span>
                            </div>
                        </div>
                    </div>
                    <!--<div class="dock-item">-->
                    <!--    <div class="dock-item__node 캔버스" id="testcanvas">-->
                            <!--데이터 모니터링 노드 생성-->
                            <!--데이터 모니터링-->
                    <!--        <div class="title">-->
                    <!--            <span class="dock-item__node__text">캔버스</span>-->
                    <!--        </div>-->
                    <!--    </div>-->
                    <!--</div>-->
                </div>
            </b-collapse>
            <hr/>
            <!-- basic Model-->
            <div class="dock-label" v-b-toggle.basicModel>
                <b-icon icon="chevron-down"></b-icon>
                    <!--노드 구성 토글 버튼 부분-->
                    <!--노드 구성-->
                <label>모니터링 노드</label>
            </div>
            <b-collapse visible id="basicModel">
                <div class="dock-item-list">
                    <div class="dock-item">
                        <div class="dock-item__node 데이터모니터링" id="monitoring_data">
                            <!--데이터 입력(기본) 노드 생성-->
                            <!--데이터 입력(기본)-->
                            <div class="title">
                                <span class="dock-item__node__text">데이터 모니터링</span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-collapse>
            <hr/>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {}
    }
</script>

<style scoped>
    .trainmanager_dock-container {
        -ms-user-select: none;
        -moz-user-select: -moz-none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        user-select: none;
        position: absolute;
        top: 30px;
        left: 30px;
        min-width: 275px !important;
        max-height: 560px;
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        background-color: rgba(81,45,168,1);
        display:flex;
        flex-direction: column;
        padding: 1px;
        z-index: 999;
    }

    .sidemenu-category {
        min-height: 50px;
        box-shadow: 0 1px 3px rgba(119, 132, 251, 0.12), 0 1px 2px rgba(119, 132, 251, 0.24);
        background: rgba(81, 45, 168, 1);
        /* fallback for old browsers */
        border-bottom: 1px solid #999999;
        color: #fff;
        padding-left: 10px;
    }

    .sidemenu-category label {
        font-size: 15px;
        font-weight: 800;
    }

    .trainmanager_dock {
        width: 100%;
        height: 100%;
        color: #555555 !important;
        text-align: center !important;
        background-color: #ffffff !important;
        overflow-y: scroll;
        padding: 0 20px;
        padding-top: 1rem;
    }

    .trainmanager_dock::-webkit-scrollbar {
        width: 5px;
    }

    .trainmanager_dock::-webkit-scrollbar-track {
        background-color: #f9f9f9;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.12);
    }

    .trainmanager_dock::-webkit-scrollbar-thumb {
        background-color: rgba(81, 45, 168, 0.5);
        border-radius: 20px !important;
    }

    @media screen and (min-height: 1080px) {
        .trainmanager_dock {
            height: 100% !important;
        }
    }

    .dock-item-list {
        width: 100%;
        margin-top: 20px;
    }

    .dock-item-list .dock-item {
        display: block;
        width: 100%;
        cursor: pointer;
        background-color: #f6fafd;
        margin-bottom: 15px;
        border: 1px solid #999999;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        overflow: hidden;
    }

    .dock-item-list .dock-item:hover {
        color: #000 !important;
        font-weight: 700;
    }

    .dock-item-list .dock-item:last-child {
        margin-bottom: 0;
    }


    .dock-item .title {
        display: flex;
        text-align: left;
        font-size: 13px;
    }

    .dock-item .title .dock-item__node__icon {
        display: block;
        width: 10px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 13px;
        margin-right: 5px;
        border-right: 1px solid #999999;
        background-color: #909bfa;
    }

    .dock-item .title .dock-item__node__text {
        display: block;
        width: 100%;
        text-align: center;
        line-height: 50px;
    }

    .sidemenu_category {
        display: flex;
        justify-content: space-between;
    }
</style>
