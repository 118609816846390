<template>
    <!--원본 데이터 전체-->
    <div class="data_select_node">
        <!--원본 데이터 헤더-->
        <div class="data_select_node_header">
            <!--클릭 시 모달 노출-->
            <!--<b-button variant="primary" @click="showRawDataSelectModal">데이터 유형 선택</b-button>-->
            <!--모달 컴포넌트 호출부-->
            <!--node-type : 현재 rete 노드 이름 전달 속성-->
            <!--data-sources : 샘플데이터 전달 속성-->
            <!--모달 내에서 샘플데이터 선택시 selected 이벤트 emit-->
            <!--로딩중-->
            <div class="data_select_node_content">
                <!--모달에서 전달된 데이터를 출력-->
                <div class="data_select_node_content__item">
                    <label>데이터</label>
                    <div class="data_name">임시 이름{{raw_data.name}}</div>
                </div>
                <div class="data_select_node_content__item">
                    <label>개수</label>
                    <div class="data_size">{{raw_data.data.dataSize}}</div>
                </div>
                <!--<div class="data_select_node_content__item">-->
                <!--    <label>데이터 종류</label>-->
                <!--    <div class="data_type">임시 타입{{ raw_data.data.type }}</div>-->
                <!--</div>-->
            </div>
            <loading-component v-if="is_loading_tf"></loading-component>
         <b-button variant="primary" @click="showRawDataSelectModal">데이터 유형 선택</b-button>
         <RawDataModal ref="modal" @selected="onSelected"/>
        <!--원본 데이터 내용 END-->
        <!--원본 데이터 하단-->
        <!--원본 데이터 하단 END-->
        </div>
    </div>
    <!--원본 데이터 전체 END-->
</template>
<script>
    // rete 불러오기
    import Rete from "rete";

    // 아웃풋 소켓 추가 등
    import { numSocket } from '../sockets';

    // RawDataModal 불러오기 
    import RawDataModal from './RawDataModal/RawDataModalComp';

    // dynamoDB
    import dynamoDB from '@/js/dynamoDB';

    import LoadingComponent from "@/components/Loading/Loading";


    export default {
        name: 'RawDataVueControl',
        data() {
            return {
                // input 내 출력 데이터를 위한 상태
                raw_data: {
                    data: {
                        dataSize: 0,
                        type: '',
                        INPUT_WIDTH: 0,
                        INPUT_HEIGHT: 0,
                        INPUT_CHANNELS: 0,
                    },
                    explain: '',
                    name: ''
                },
                // 로딩
                is_loading_tf: false,
                timeout: null
            }
        },
        // 부모에서 인자 내려받기 
        props: ['readonly', 'emitter', 'getData', 'putData', 'getNode'],
        components: {
            // 모달 컴포넌트 등록
            RawDataModal,

            // 로딩 컴포넌트
            LoadingComponent
        },
        mounted() {
            // 세션에서 저장된 정보를 가져오기 ============
            let sessionData = this.getData("raw_data");


            if (sessionData) {
                this.raw_data = sessionData;
            }
            // ============================================
        },
        methods: {
            // 모달 열기 버튼 핸들링 메서드
            showRawDataSelectModal() {
                this.$refs.modal.show();

            },
            // ============================================
            // clearTimeout() {
            //     if (this.timeout) {
            //         clearTimeout(this.timeout)
            //         this.timeout = null
            //     }
            // },
            // setTimeout(callback) {
            //     this.clearTimeout()
            //     this.timeout = setTimeout(() => {
            //         this.clearTimeout()
            //         callback()
            //     }, 1000)
            // },

            // selected 이벤트 핸들링 메서드
            // input 부분에 선택된 데이터를 출력한다.
            onSelected(selectedData) {
                // 선택되었을때 로딩
                this.is_loading_tf = true;


                if (selectedData) {
                    // 선택 된 데이터 가져오기                      
                    this.raw_data = selectedData;

                    //rete 노드 컴포넌트에 필요한 데이터를 전달하여 저장한다.
                    this.update();
                    this.createOutputData();

                    // 변경된 정보가 반영되면 edior에 접근하여 rete 정보를 json String으로 변환하여 세션에 저장시킨다.
                    const whole_data = this.emitter.toJSON();
                    sessionStorage.setItem('current_structure', JSON.stringify(whole_data));

                    // 로딩 끝났을때
                    this.is_loading_tf = false;

                    // 시간초 로딩 
                    // this.setTimeout(() => {
                    //     this.is_loading_tf = false;
                    // })
                }
            },
            // ============================================

            // 업데이트
            // 노드 내의 입력 내용이 변경되면 process 이벤트를 활성화한다.
            update() {
                //변경된 데이터를 노드 정보에 저장
                this.putData("raw_data", this.raw_data);
                //프로세스 이벤트를 에디터로 전달하여 변경사항을 연결된 노드로 전달
                // this.emitter.trigger('process');
            },
            // ============================================

            // 출력 데이터 표시 부분 변경하기
            createOutputData() {
                // console.log(this.raw_data.data);

                let output_data = '(' + this.raw_data.data.dataSize + ',' + this.raw_data.data.INPUT_WIDTH + ',' + this.raw_data.data.INPUT_HEIGHT + ',' +
                    this.raw_data.data.INPUT_CHANNELS + ')';


                this.getNode().outputs.get('raw_data_output').name = output_data;
                this.getNode().update();
            },

            // =========================================================================================================

            // addInput() {
            // input 이름 지정
            // const input_name = "raw_data" + this.getNode().id + "_input" + (this.getNode().inputs.size + 1)
            // input 객체 생성
            // const input = new Rete.Input(input_name, "입력", numSocket, true)
            // 소켓 추가
            // this.getNode().addInput(input);
            // this.putData("added_inputs", this.getNode().inputs.size);
            // this.getNode().update()
            // },

            // addOutput() {
            // output 이름 지정
            // const output_name = "raw_data" + this.getNode().id + "_output" + (this.getNode().outputs.size + 1)
            // output 객체 생성
            // const output = new Rete.Output(output_name, "출력", numSocket, true);
            // 소켓 추가
            // this.getNode().addOutput(output);
            // this.putData("added_outputs", this.getNode().outputs.size);
            // this.getNode().update()
            // },

            // =========================================================================================================

            // 테스트 버튼 =============================================================================================
            rawdata_test_click() {

                // raw-data check
                // console.log(this.raw_data);


                // 출력 데이터 부분 변경 테스트
                // console.log(this.getNode().outputs.get('raw_data1_output').name);
                // this.getNode().outputs.get('raw_data1_output').name = "test";
                // this.getNode().update();
            }
            // =========================================================================================================
        },
    }
</script>
<style scoped>
    /*데이터 선택 노드 전체 지정 */

    .data_select_node {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    /*데이터 선택 노드 헤더*/

    .data_select_node .data_select_node_header {
        width: 100%;
        margin-top: 5px;
        padding: 15px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /*데이터 선택 노드 내용*/

    .data_select_node .data_select_node_content {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px solid #dfe6e9;
        margin-bottom: 20px;
    }

    .data_select_node .data_select_node_content .data_select_node_content__item {
        display: flex;
        align-items: center;
        padding: 0 20px;
    }
    
    .data_select_node .data_select_node_content .data_select_node_content__item label{
        width: 70px;
    }
    
     .data_select_node .data_select_node_content .data_select_node_content__item .data_name,
     .data_select_node .data_select_node_content .data_select_node_content__item .data_size
     {
        width: 70px;
    }

    /*데이터 선택 노드 하단부*/

    .data_select_node .data_select_node_footer {
        /*padding: 5px;*/
    }

    /*데이터 선택 전체 라벨 지정*/

    .data_select_node label {
        font-size: 15px !important;
        font-weight: 600;
        text-align: left;
        margin-bottom: 0 !important;
        margin-left: 5px;
    }

    /*데이터 선택 전체 셀렉트 및 인풋 지정*/

    .data_select_node select,
    input {
        border-radius: 5px !important;
        width: 180px !important;
    }

    /*데이터 선택 전체 버튼 지정*/

    .data_select_node button {
        width: 100%;
        font-size: 13px;
        color: #ffffff;
        background: rgba(119, 132, 251, 0.7);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        text-align: center;
        line-height: 12px;
        border: none;
        padding: 12px 0;
    }

    /*선택된 데이터가 표시될 부분 지정*/


    .data_describe {
        font-size: 13px;
        width: 180px;
        height: auto;
        border: 1px solid #7784FB;
        padding: 10px;
        margin: 5px;
        padding: 2px;
        opacity: 0.8;
    }

    /*=================================*/
</style>
