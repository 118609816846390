<template>
    <b-modal ref="modal" id="dataPreprocessorSelectModal" centered hide-header hide-footer size="xl">
    <div class="data-preprocessor-select-modal-outer">
        <!-- header -->
        <div class="data-preprocessor-select-modal-header">
            <label> {{nodeType}}</label>
            <b-icon icon="x" id="mod-closebtn" class="close-btn" v-b-tooltip.hover.bottom="'Modal Close Button'" v-on:click="close_btn"></b-icon> 
        </div>
        <!-- body -->
        <div class="data-preprocessor-select-modal-body">
            <!-- trained model 불러오기-->
            <div class="upload_part">
                <div class="data_preprocessor_default_upload" @click="create_data_preprocessor">
                    <b-icon icon="plus-square-fill"></b-icon>
                    <!--로컬에 저장되어 있는 데이터 불러오기 부분-->
                    <!--로컬에서 불러오기-->
                    <label>데이터 전처리기 만들기</label>
                    <!--로컬에서 불러오기 설명-->
                    <p>새롭게 생성할 데이터 전처리기 기본 노드를 불러 옵니다.</p>
                    <!--<input ref="local_file_input" type="file" multiple hidden />-->
                </div>
                <div v-for="(mode, index) in modes" :key="index">
                    <ModalCard :select-mode="mode" @selected="onSelected"/>
                </div>
                <!--기본 제공되는 샘플 데이터 선택 부분-->
                <!--rete node 컴포넌트에서 제공된 샘플 데이터 정보를 바탕으로 카드를 생성한다.-->
                <!--<div v-for="(source, index) in dataSources" :key="index">-->
                    <!--카드에서 샘플 선택이 완료 되면 selected 이벤트를 핸들링하여 rete node 컴포넌트로 샘플 데이터를 전송-->
                <!--</div>-->
            </div>
        </div>

    </div>
    </b-modal>
</template>
<script>
    import ModalCard from './ModalCardComp'

    export default {
        name: 'OriginalDataModal',
        data() {
            return {
                modes: [
                    {
                        title: "입력 1, 출력 1",
                        description: "데이터 전 처리기 노드에 입력, 출력 각각 1 개씩 생성합니다.",
                        selected_type: 1
                        
                    },
                    {
                        title: "입력 2, 출력 2",
                        description: "데이터 전 처리기 노드에 입력, 출력 각각 2 개씩 생성합니다.",
                        selected_type: 2
                    }
                ]
            }
        },
        // 부모 컴포넌트에서 제공받은 rete node 타입과 샘플 데이터 정보
        props: ['dataSources', 'nodeType'],
        components: {
            // 카드 생성을 위한 카드 컴포넌트 호출
            ModalCard
        },
        methods: {
            show() {
                // 모달 호출 메서드
                this.$refs.modal.show();
            },

            hide() {
                // 모달 닫기 메서드
                this.$refs.modal.hide();
            },
            // 데이터 전처리기 생성
            create_data_preprocessor() {
                console.log('CREATE data_preprocessor');
            },
            
            close_btn() {
                // 모달 닫기 버튼 클릭을 핸들링하여 모달을 닫는다.
                this.hide();
            },
            
            onSelected(selected_type, description) {
                // 카드에서 선택된 데이터를 부모 컴포넌트로 전달한다.
                this.$emit('selected', selected_type, description);
                this.hide();
            }
        },

    }
</script>
<style scoped>

    .card-container {
        width: 100%;

    }

    .data-preprocessor-select-modal-outer {
        height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;

    }

    .data-preprocessor-select-modal-header {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .data-preprocessor-select-modal-header label {
        color: #858585;
        font-size: 30px;
        font-weight: bold;
        margin-left: 10px;
    }

    .data-preprocessor-select-modal-header .b-icon {
        margin-left: auto;
        margin-right: 10px;
    }

    .data-preprocessor-select-modal-body {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
    }

    .data-preprocessor-select-modal-search {
        height: 10%;
        width: 80%;
        display: flex;
        align-items: center;
    }

    .data-preprocessor-select-modal-search input {
        height: 50px;
        width: 500px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: 2.3%;
        font-size: 15px !important;
    }

    .upload_part {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(4, 300px);
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: auto;
    }

    .data_preprocessor_default_upload {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    .data_preprocessor_default_upload .b-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        color: #C2C2C2;
    }

    .data_preprocessor_default_upload label {
        font-weight: bold;
        font-size: 20px;
        color: #C2C2C2;
    }

    .data_preprocessor_default_upload p {
        font-size: 12px;
        color: #C2C2C2;
        text-align: center;
    }


    .data_preprocessor_default_upload:hover {
        background: #eeeeee;
        cursor: pointer;
    }


    .upload_part .card {
        border: none !important;
    }
</style>
