import Rete from 'rete';
import { numSocket } from '../../sockets';
import DataInputControl from '../../controls/basic-controls/DataInputControl';

export default class InputDataComponent extends Rete.Component {
	constructor() {
		super("데이터입력(이미지)");
	}

	builder(node) {
		let output1 = new Rete.Output("output1", "value", numSocket);

		return node
			.addControl(new DataInputControl(this.editor, "num"))
			.addOutput(output1);
	}
	worker(node, inputs, outputs) {
		outputs["num"] = node.data.num;
	}
}
