import { Control } from 'rete';
import ParameterVueControl from '../vues/ParameterVueControl.vue';
import MonitoringVueControl from '../vues/MonitoringVueControl.vue';
import CheckBoxParameterVueControl from '../vues/CheckBoxParameterVueControl.vue';
import SelectParameterVueControl from '../vues/SelectParameterVueControl.vue';

export default class ParameterControl extends Control {
    constructor(emitter, key, type, readonly = false) {
        super(key);

        const input_value = type.input_value.replace(/[\(\)]/gi, '').split('|');

        console.log(input_value);

        if ((/^\'/).test(input_value[0])) {
            this.component = SelectParameterVueControl;
        }
        else if (input_value[0] == 'boolean') {
            this.component = CheckBoxParameterVueControl;
        }
        else if (input_value[0] === 'canvas') {
            this.component = MonitoringVueControl;
        }
        else {
            this.component = ParameterVueControl;
        }



        this.props = {
            emitter,
            ikey: key,
            type,
            readonly,
            change: () => this.onChange()
        }
    };

    setValue(value) {
        const ctx = this.vueContext || this.props;
        ctx.value = value;
    }

    onChange() {}
}
